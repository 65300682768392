import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import ReactEcharts from "echarts-for-react"
import {
  Container,
  Row,
  Button,
  Col,
  Table,
  Card,
  CardBody,
  Progress,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import "react-slideshow-image/dist/styles.css"

import { Link } from "react-router-dom"
import classnames from "classnames"
import Select from "react-select"

// Custom Scrollbar

// import images
import duration from "../../assets/images/services-icon/duration.png"
import awicon from "../../assets/images/services-icon/coin.png"
import money from "../../assets/images/services-icon/money.svg"
import trophy from "../../assets/images/services-icon/trophy.png"
import leader from "../../assets/images/services-icon/leader.png"
import first_medal from "../../assets/images/services-icon/first_medal.svg"
import second_medal from "../../assets/images/services-icon/second_medal.svg"
import third_medal from "../../assets/images/services-icon/third_medal.svg"

import hw1 from "../../assets/images/services-icon/hw1.svg"
import hw2 from "../../assets/images/services-icon/hw2.svg"
import hw5 from "../../assets/images/services-icon/meter.svg"
import home from "../../assets/images/services-icon/home.svg"
import us1 from "../../assets/images/services-icon/us1.png"

//Boys user Profile
import b1 from "../../assets/images/users/boyuser/b1.png"
import b2 from "../../assets/images/users/boyuser/b2.png"
import b3 from "../../assets/images/users/boyuser/b3.png"
import b4 from "../../assets/images/users/boyuser/b4.png"
import b5 from "../../assets/images/users/boyuser/b5.png"
import b6 from "../../assets/images/users/boyuser/b6.png"
import b7 from "../../assets/images/users/boyuser/b7.png"
import b8 from "../../assets/images/users/boyuser/b8.png"
import b9 from "../../assets/images/users/boyuser/b9.png"
import b10 from "../../assets/images/users/boyuser/b10.png"

//girl user profile
import g1 from "../../assets/images/users/girluser/g1.png"
import g2 from "../../assets/images/users/girluser/g2.png"
import g3 from "../../assets/images/users/girluser/g3.png"
import g4 from "../../assets/images/users/girluser/g4.png"
import g5 from "../../assets/images/users/girluser/g5.png"
import g6 from "../../assets/images/users/girluser/g6.png"
import g7 from "../../assets/images/users/girluser/g7.png"
import g8 from "../../assets/images/users/girluser/g8.png"
import g9 from "../../assets/images/users/girluser/g9.png"
import g10 from "../../assets/images/users/girluser/g10.png"

import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"

import jwtDecode from "jwt-decode"
import { BASE_URL } from "../../global"
//pass request back end
import axios from "axios"
import moment from "moment"
import Loader from "react-spinner-loader"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import piratemale from "../../assets/images/profile-avatars/avatar1/piratemale.png"

import piratefemale from "../../assets/images/profile-avatars/avatar2/piratefemale.png"
// import hd1 from "../../assets/images/profile-avatars/avatar1/head/hd1.png";
// import dr1 from "../../assets/images/profile-avatars/avatar1/dress/dr1.png";
// import cm1 from "../../assets/images/profile-avatars/avatar1/companion/cm1.png";
// import wp1 from "../../assets/images/profile-avatars/avatar1/weapon/wp1.png";
// import hnd1 from "../../assets/images/profile-avatars/avatar1/hand/hnd1.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const Dashboard = props => {
  //Dedoce user details start
  function decodeAccessToken(token) {
    try {
      const decoded = jwtDecode(token)
      return { success: true, data: decoded }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  const [username, setusername] = useState(null)
  useEffect(() => {
    fetchStudEnrollsubject()
  }, [])
  useEffect(() => {
    if (localStorage.getItem("authStudentUser")) {
      const obj = JSON.parse(localStorage.getItem("authStudentUser"))
      const jwtdecode = decodeAccessToken(obj)

      setusername(jwtdecode.data)
      fetchStudentAvatarData()
      fetchCoinHistryData()
      fetchGlobalUserDetails()
      fetchActivityLevelList()
      fetchMastryScoreData()
      //}
    }
  }, [props.success])

  //Dedoce user details end

  const [userProfileImg, setUserProfileimg] = useState(
    localStorage.getItem("userprofile") || null
  )
  const [userGender, setUserGender] = useState(
    localStorage.getItem("usergender") || null
  )

  const [loader, setLoader] = useState(false)
  const [Leaderloader, setLeaderloader] = useState(false)

  const [customActiveTab, setcustomActiveTab] = useState("1")
  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  //subject start
  const [selectedSubject, setSelectedSubject] = useState(2)

  const [selectedUpcomingType, setSelectedUpcomingType] = useState(1)

  const [globalUserDetails, setGlobalUserDetails] = useState({})
  const [activitylevelList, setActivitylevelList] = useState([])
  const [leaderboardData, setleaderboardData] = useState([])

  const [CoinData, setCoinData] = useState(null)
  const [practiceTopicsMoreData, setpracticeTopicsMoreData] = useState([])
  const [topicsStrongestData, settopicsStrongestData] = useState([])
  const [MostpracticedTopicsData, setMostpracticedTopicsData] = useState([])

  const [accuracyData, setaccuracyData] = useState([])
  const [accuracyCorrectPercentData, setAccuracyCorrectPercentData] =
    useState(0)

  const [questionsCompletedData, setquestionsCompletedData] = useState([])
  const [questionsComCorrectPercentData, setquestionsComCorrectPercentData] =
    useState(0)

  const [activityCompletedData, setactivityCompletedData] = useState([])
  const [activityComCorrectPercentData, setactivityComCorrectPercentData] =
    useState(0)
  //UpcommingSession start
  const [UpcommingSession, setUpcommingSession] = useState([])
  const [UpcommingSessionToday, setUpcommingSessionToday] = useState([])
  //UpcommingSession end
  //Homework start
  const [HomeWorkSession, setHomeworkSession] = useState([])
  //Homework end

  //Mastery Score Details

  const [masryScoreData, setmasryScoreData] = useState([])
  const fetchMastryScoreData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      //setLoader(true);
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "masteryScore/mastryScoreData", {
          subject_id: selectedSubject,
        })
        .then(responseMastryScore => {
          // Assuming the API response is an array of objects with 'id' and 'value' properties

          if (responseMastryScore.data.length > 0) {
            setmasryScoreData(responseMastryScore.data)
            // Sorting the data based on adjusted_mastery_score in ascending order
            let ASC_data = responseMastryScore.data.sort(
              (a, b) => a.adjusted_mastery_score - b.adjusted_mastery_score
            )

            // Selecting the last five activities
            const lastFiveActivities = ASC_data.slice(0, 5)
            setpracticeTopicsMoreData(lastFiveActivities)

            // Sorting the data based on adjusted_mastery_score in descending order
            let DESC_data = responseMastryScore.data.sort(
              (a, b) => b.adjusted_mastery_score - a.adjusted_mastery_score
            )

            // Selecting the top five activities
            const topFiveActivities = DESC_data.slice(0, 5)

            settopicsStrongestData(topFiveActivities)
          } else {
            setmasryScoreData([])
            setpracticeTopicsMoreData([])
            settopicsStrongestData([])
          }

          //setLoader(false);
        })
        .catch(error => {
          //setLoader(false);

          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
            setLoader(false)
          }
        })
    } else {
      setLoader(false)
      navigate("/login")
    }
  }
  //Mastery Score Deatisl

  //accrucy start
  useEffect(() => {
    if (
      accuracyData.length > 0 &&
      parseInt(accuracyData[0].total_question) !== 0
    ) {
      const percent =
        (accuracyData[0].correct_answer / accuracyData[0].total_question) * 100
      setAccuracyCorrectPercentData(percent.toFixed(2))
    } else {
      setAccuracyCorrectPercentData(0)
    }
  }, [accuracyData])

  const getOption = () => {
    const correctAnswer = accuracyCorrectPercentData
    const wrongAnswer = 100 - correctAnswer

    return {
      toolbox: {
        show: true,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "right",
        data: ["Correct Ans", "Wrong Ans"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#009688", "#D83D59"],
      series: [
        {
          name: "Accuracy",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: false,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: true,
            },
          },
          data: [
            { value: correctAnswer, name: "Correct Ans" },
            { value: wrongAnswer, name: "Wrong Ans" },
          ],
        },
      ],
    }
  }

  //acuracy end
  //question start
  useEffect(() => {
    if (
      questionsCompletedData.length > 0 &&
      parseInt(questionsCompletedData[0].total_questions) !== 0
    ) {
      const percent =
        (questionsCompletedData[0].completed_questions /
          questionsCompletedData[0].total_questions) *
        100
      setquestionsComCorrectPercentData(percent.toFixed(2))
    } else {
      setquestionsComCorrectPercentData(0)
    }
  }, [questionsCompletedData])

  const getquestionOption = () => {
    const correctAnswer = questionsComCorrectPercentData
    const wrongAnswer = 100 - correctAnswer

    return {
      toolbox: {
        show: true,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "right",
        data: ["Completed", "Incomplete"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#5E547A", "#B5C4D7"],
      series: [
        {
          name: "Questions",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: false,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: true,
            },
          },
          data: [
            { value: correctAnswer, name: "Completed" },
            { value: wrongAnswer, name: "Incomplete" },
          ],
        },
      ],
    }
  }

  //question end
  //activity start
  useEffect(() => {
    if (
      activityCompletedData.length > 0 &&
      parseInt(activityCompletedData[0].total_activity) !== 0
    ) {
      const percent =
        (activityCompletedData[0].completed_activity /
          activityCompletedData[0].total_activity) *
        100
      setactivityComCorrectPercentData(percent.toFixed(2))
    } else {
      setactivityComCorrectPercentData(0)
    }
  }, [activityCompletedData])

  const getactivityOption = () => {
    const correctAnswer = activityComCorrectPercentData
    const wrongAnswer = 100 - correctAnswer

    return {
      toolbox: {
        show: true,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "right",
        data: ["Completed", "Incomplete"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#FF9800", "#F9D49D"],
      series: [
        {
          name: "Activities",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: false,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: true,
            },
          },
          data: [
            { value: correctAnswer, name: "Completed" },
            { value: wrongAnswer, name: "Incomplete" },
          ],
        },
      ],
    }
  }
  // let subjectOptions = [
  //   { subject_name: "Mathematics", subject_id: 2 },
  //   { subject_name: "English", subject_id: 1 },
  //   // { subject_name: 'Others', subject_id: 3 }
  // ]

  const [subjectOptions, setSubjectOption] = useState([
    { subject_name: "Mathematics", subject_id: 2 },
    { subject_name: "English", subject_id: 1 },
    // { subject_name: 'Others', subject_id: 3 }
  ])
 
  const authUserSubject = JSON.parse(localStorage.getItem("subject")) || null
  // useEffect(() => {
  //   if (!authUserSubject || authUserSubject.trim() === '') {
  //     setSubjectOption([
  //       { subject_name: "Mathematics", subject_id: 2 },
  //       { subject_name: "English", subject_id: 1 },
  //     ]);
  //     if (selectedSubject !== 2) {
  //       setSelectedSubject(2);
  //     }
  //   } else {
  //     let subjectIdsArray;
  //       if (authUserSubject.includes(',')) {
  //         subjectIdsArray = authUserSubject.split(',');
  //       } else {
  //         // Handle case when authUserSubject is a single ID without comma
  //         subjectIdsArray = [authUserSubject];
  //       }

  //     if (subjectIdsArray.length === 2 && subjectIdsArray.includes('1') && subjectIdsArray.includes('2')) {
  //       setSubjectOption([
  //         { subject_name: "Mathematics", subject_id: 2 },
  //         { subject_name: "English", subject_id: 1 },
  //       ])
  //       if (selectedSubject !== 2) {
  //         setSelectedSubject(2);
  //       }
  //     } else if (subjectIdsArray.length === 1 && subjectIdsArray.includes('1')) {
  //       setSubjectOption([
  //         { subject_name: "English", subject_id: 1 },
  //       ]);
  //       if (selectedSubject !== 1) {
  //         setSelectedSubject(1);
  //       }
  //     } else if (subjectIdsArray.length === 1 && subjectIdsArray.includes('2')) {
  //       setSubjectOption([
  //         { subject_name: "Mathematics", subject_id: 2 },
  //       ]);
  //       if (selectedSubject !== 2) {
  //         setSelectedSubject(2);
  //       }
  //     } else {
  //       setSubjectOption([
  //         { subject_name: "Mathematics", subject_id: 2 },
  //         { subject_name: "English", subject_id: 1 },
  //       ]);
  //       if (selectedSubject !== 2) {
  //         setSelectedSubject(2);
  //       }
  //     }
  //   }
  //   }, [authUserSubject])
  const [isenrolsubjectreceived, setisenrolsubjectreceived] = useState(false)
  const fetchStudEnrollsubject = async () => {
    if (authUser && authUser != "" && authUser != null) {
      setLoader(true)
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "studentHomeWork/GetStudentEnrolledSubjects")
        .then(responseSession => {
          if (responseSession.data.length > 0) {
            setSubjectOption(responseSession.data)
            setSelectedSubject(responseSession.data[0]?.subject_id || 2)
            setisenrolsubjectreceived(true)
          } else {
            setSelectedSubject(2)
            setisenrolsubjectreceived(true)
          }
        })
        .catch(error => {
          setisenrolsubjectreceived(true)

          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      setisenrolsubjectreceived(true)
      navigate("/login")
    }
  }

  const handleSelectSubject = subject => {
    setSelectedSubject(subject)
  }

  //subject end

  const UpcomingTypeOptions = [
    { type_name: "Today", type_id: 1 },
    { type_name: "All", type_id: 2 },
    // { subject_name: 'Others', subject_id: 3 }
  ]

  const handleSelectType = type => {
    setSelectedUpcomingType(type)
  }

  //Upcomming Type end

  const getUniqueTopicsNames = jsonData => {
    const uniqueTopicsNames = new Set()

    // Check if the necessary data is available
    if (jsonData) {
      // Iterate through student enrollments
      // jsonData.forEach((enrollment) => {
      // Check if the necessary data is available and is an array
      if (jsonData && Array.isArray(jsonData)) {
        // Iterate through enrollment subjects
        jsonData.forEach(subject => {
          subject.forEach(data => {
            if (data && data.topic_name != null) {
              uniqueTopicsNames.add(data.topic_name)
            }
          })
        })
      }
      //});
    }

    // Convert Set to array if needed
    return Array.from(uniqueTopicsNames)
  }

  const authUser = JSON.parse(localStorage.getItem("authStudentUser")) || null

  const navigate = useNavigate()

  // useEffect(() => {
  //   if (isenrolsubjectreceived){
  //     fetchCardData()
  //     fetchUpcommingSessionData()
  //     fetchHomeWorkSessionData()
  //     fetchActivityLevelList()
  //     fetchMastryScoreData()
  //   }

  // }, [selectedSubject])

  // useEffect(() => {
  //   if (isenrolsubjectreceived) {
  //     // Start loader
  //     setLoader(true)

  //       Promise.all([fetchUpcommingSessionData(), fetchHomeWorkSessionData()])
  //         .then(() => {

  //           setLoader(false)
  //         })
  //         .catch(error => {

  //           console.error("Error fetching data:", error)
  //           setLoader(false)
  //         })

  //         fetchActivityLevelList(),
  //         fetchMastryScoreData(),
  //        fetchCardData()
  //   }
  // }, [selectedSubject, isenrolsubjectreceived])
  useEffect(() => {
    if (isenrolsubjectreceived) {
      // Start loader
      setLoader(true)
      fetchCardData()

      Promise.all([
        fetchUpcommingSessionData(),
        fetchHomeWorkSessionData(),
        fetchActivityLevelList(),
        fetchMastryScoreData(),
      ])
        .then(() => {
          setLoader(false)
        })

        .catch(error => {
          // In case of error, also set loader to false
          console.error("Error fetching data:", error)
          setLoader(false)
        })
    }
  }, [isenrolsubjectreceived, selectedSubject])

  const fetchUpcommingSessionData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      // setLoader(true);
      setUpcommingSession([])
      setUpcommingSessionToday([])
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "studenthome/getAllStudentUpcomingDetails", {
          subject_id: selectedSubject,
          cent_timezone_identifier:
            localStorage.getItem("cent_timezone_identifier") || "",
        })
        .then(responseUpcommingSession => {
          // Assuming the API response is an array of objects with 'id' and 'value' properties
          if (responseUpcommingSession.data != "") {
            if (responseUpcommingSession.data.AllUpcomingSession.length > 0) {
              setUpcommingSession(
                responseUpcommingSession.data.AllUpcomingSession
              )
            } else {
              setUpcommingSession([])
            }
            if (responseUpcommingSession.data.TodayUpcomingSession.length > 0) {
              setUpcommingSessionToday(
                responseUpcommingSession.data.TodayUpcomingSession
              )
            } else {
              setUpcommingSessionToday([])
            }
            // setLoader(false);
          }
        })
        .catch(error => {
          // setLoader(false);
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      // setLoader(false);
      navigate("/login")
    }
  }

  const fetchHomeWorkSessionData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "studenthome/HomeWorkSessionActivityDetails", {
          subject_id: selectedSubject,
        })
        .then(responseHomeWorkSession => {
          // Assuming the API response is an array of objects with 'id' and 'value' properties
          if (responseHomeWorkSession.data.length > 0) {
            setHomeworkSession(responseHomeWorkSession.data)
          } else {
            setHomeworkSession([])
          }
        })
        .catch(error => {
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }
  }

  const fetchGlobalUserDetails = async () => {
    if (authUser && authUser != "" && authUser != null) {
      // setLoader(true);

      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const response = await axios.post(
          BASE_URL + "studenthome/getglobalUserDetails",
          {
            // If there are any request parameters, include them here
          }
        )

        if (response.data.length > 0) {
          setGlobalUserDetails(response.data[0])
        } else {
          setGlobalUserDetails({})
        }
        // Update state with response data
      } catch (error) {
        // console.error("Error in fetching global user details:", error);
        // Handle errors appropriately

        if (
          error.response.status === 403 &&
          error.response.data.error === "jwt expired"
        ) {
          localStorage.clear()
          navigate("/login")
        }
      }
    } else {
      navigate("/login")
    }
  }
  const fetchCardData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      // setLoader(true)
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "studenthome/cardDetails", {
          subject_id: selectedSubject,
        })
        .then(responseCard => {
          // Assuming the API response is an array of objects with 'id' and 'value' properties

          // setLoader(false)

          if (responseCard.data != "") {
            if (responseCard.data.CoinDetails != "") {
              setCoinData(responseCard.data.CoinDetails)
            } else {
              setCoinData(null)
            }

            // if (responseCard.data.practiceTopicsMoreData.length > 0) {
            //   setpracticeTopicsMoreData(
            //     responseCard.data.practiceTopicsMoreData
            //   )
            // } else {
            //   setpracticeTopicsMoreData([])
            // }

            // if (responseCard.data.topicsStrongestData.length > 0) {
            //   settopicsStrongestData(responseCard.data.topicsStrongestData)
            // } else {
            //   settopicsStrongestData([])
            // }

            if (responseCard.data.MostpracticedTopicsData.length > 0) {
              setMostpracticedTopicsData(
                responseCard.data.MostpracticedTopicsData
              )
            } else {
              setMostpracticedTopicsData([])
            }

            if (responseCard.data.accuracyData.length > 0) {
              setaccuracyData(responseCard.data.accuracyData)
            } else {
              setaccuracyData([])
            }
            if (responseCard.data.questionsCompletedData.length > 0) {
              setquestionsCompletedData(
                responseCard.data.questionsCompletedData
              )
            } else {
              setquestionsCompletedData([])
            }

            if (responseCard.data.activityCompletedData.length > 0) {
              setactivityCompletedData(responseCard.data.activityCompletedData)
            } else {
              setactivityCompletedData([])
            }
            // setLoader(false)
          } else {
            //No data
            // setLoader(false)
          }
        })
        .catch(error => {
          // setLoader(false)

          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      setLoader(false)
      navigate("/login")
    }
  }

  // api call for getactivitylevellist
  const fetchActivityLevelList = async () => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const response = await axios.post(
          BASE_URL + "studenthome/getactivitylevellist",
          {
            subject_id: selectedSubject,
          }
        )

        // Assuming response.data is an array of objects containing activity level and subject information
        const formattedActivityLevelData = {}

        if (response.data.length > 0) {
          // Group the activity levels by subject
          response.data.forEach(level => {
            if (!formattedActivityLevelData[level.subject_name]) {
              formattedActivityLevelData[level.subject_name] = []
            }
            formattedActivityLevelData[level.subject_name].push({
              value: level.activity_level_id,
              label: level.level_name,
            })
          })

          // Create options with subjects as labels and activity levels as options
          let options = []

          // Add "All" option
          options.push({ value: "0", label: "All" })

          // Add subject-specific options
          options = options.concat(
            Object.keys(formattedActivityLevelData).map(subject => ({
              label: subject,
              options: formattedActivityLevelData[subject],
            }))
          )

          // Set the options to state or do whatever is appropriate
          setActivitylevelList(options)
        }
      } catch (error) {
        //console.error("Error in fetchActivityLevelList:", error);
        // Handle errors appropriately
        if (
          error.response.status === 403 &&
          error.response.data.error === "jwt expired"
        ) {
          localStorage.clear()
          navigate("/login")
        }
      }
    } else {
      navigate("/login")
    }
  }

  const fetchLeaderboardData = async (
    customActiveTab,
    selectedGroup,
    selectedGroup1,
    selectedGroup2
  ) => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        setLeaderloader(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        if (selectedGroup && selectedGroup1) {
          const response = await axios.post(
            BASE_URL + "studenthome/getleaderboardData",
            {
              filter_type: customActiveTab,
              sortObj: {
                activitylevel: selectedGroup,
                key: selectedGroup1,
                range: selectedGroup2,
              },
            }
          )

          if (response.data.length > 0) {
            setLeaderloader(false)
            setleaderboardData(response.data) // Update state with response data
          } else {
            setLeaderloader(false)
            setleaderboardData([])
          }

          // setIsDataFetched(true);
        } else {
          setLeaderloader(false)
          console.error(
            "Error in fetchLeaderboardData: selectedGroup or selectedGroup1 is null"
          )
        }
      } catch (error) {
        console.error("Error in fetchLeaderboardData:", error)
        // Handle errors appropriately
        setLeaderloader(false)
        if (
          error.response.status === 403 &&
          error.response.data.error === "jwt expired"
        ) {
          localStorage.clear()
          navigate("/login")
        }
      }
    } else {
      setLeaderloader(false)
      navigate("/login")
    }
  }

  //leader board

  //loading a data for optionGroup1 from the globaldetails
  const optionGroup1 = [
    {
      label: globalUserDetails.country_name,
      value: globalUserDetails.country_id,
    },
    {
      label: globalUserDetails.cent_name,
      value: globalUserDetails.pk_cent_key,
    },
  ]

  const optionGroup2 = [
    { label: "All time", value: "0" },
    { label: "Past week", value: "1" },
  ]

  //
  // Inside your functional component

  const [selectedGroup, setSelectedGroup] = useState(10)
  const [selectedGroup1, setSelectedGroup1] = useState(null)
  const [selectedGroup2, setSelectedGroup2] = useState(null)

  // Function to handle group selection
  function handleSelectGroup(selectedGroup) {
    setSelectedGroup(selectedGroup)
  }

  function handleSelectGroup1(selectedGroup1) {
    setSelectedGroup1(selectedGroup1)
  }
  function handleSelectGroup2(selectedGroup2) {
    setSelectedGroup2(selectedGroup2)
  }

  useEffect(() => {
    if (
      globalUserDetails &&
      globalUserDetails.country_id &&
      activitylevelList.length > 0 &&
      activitylevelList[0].value &&
      optionGroup2.length > 0 &&
      optionGroup2[0].value
    ) {
      console.log(activitylevelList)
      const hasActivityLevel = activitylevelList[1].options.some(
        item =>
          parseInt(item.value) === parseInt(globalUserDetails.st_year_level)
      )
      let year_level = activitylevelList[0].value
      console.log(hasActivityLevel)
      if (hasActivityLevel) {
        year_level = parseInt(globalUserDetails.st_year_level)
      } else {
        year_level = activitylevelList[0].value
      }

      setSelectedGroup(year_level)
      setSelectedGroup1(globalUserDetails.country_id)
      setSelectedGroup2(optionGroup2[0].value)
    }
  }, [activitylevelList, globalUserDetails])

  console.log(selectedGroup, selectedGroup1, selectedGroup2, customActiveTab)
  useEffect(() => {
    if (
      customActiveTab &&
      selectedGroup &&
      selectedGroup1 &&
      selectedGroup2 &&
      customActiveTab != null &&
      selectedGroup != null &&
      selectedGroup1 != null &&
      selectedGroup2 != null
    ) {
      fetchLeaderboardData(
        customActiveTab,
        selectedGroup,
        selectedGroup1,
        selectedGroup2
      )
    }
  }, [customActiveTab, selectedGroup, selectedGroup1, selectedGroup2])

  // const subjectsString = globalUserDetails.student_subject;
  // const subjectOptions = subjectsString?.split(',') || [];

  //lrader boader

  // const [selectedGroup, setselectedGroup] = useState(null)

  // function handleSelectGroup(selectedGroup) {
  // setselectedGroup(selectedGroup)
  // }

  // const optionGroup = [
  // {
  // label: "Select Subject",
  // options: [
  // { label: "All", value: "All" },
  // { label: "English", value: "English" },
  // { label: "Maths", value: "Maths" },
  // { label: "English & Maths", value: "English & Maths" },
  // ],
  // },
  // ]

  // const optionGroup1 = [
  // {
  // label: "Select Type",
  // options: [
  // { label: "All", value: "All" },
  // { label: "Recurring", value: "REcurring" },
  // { label: "One Off", value: "One Off" },
  // ],
  // },
  // ]

  // const optionGroup2 = [
  // {
  // label: "Select Type",
  // options: [
  // { label: "All", value: "All" },
  // { label: "Upcoming", value: "Upcoming" },
  // { label: "Re-Assigned", value: "Re-Assigned" },
  // { label: "Ongoing", value: "Ongoing" },
  // { label: "Completed", value: "Completed" },
  // ],
  // },
  // ]
  const formatTime = totalSeconds => {
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60
    const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
    return formattedTime
  }

  //redirect Link
  const assessementActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
        navigate(
          "/assessment/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1"
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  const diagnosticActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
        navigate(
          "/diagnostics/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1"
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }
  const VideoActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
        navigate(
          "/video/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1"
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  const LinkActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
        navigate(
          "/link/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1"
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  const TaskActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
        navigate(
          "/task/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1"
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  const WorksheetActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
        navigate(
          "/worksheet/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1"
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }
  const connectLost = () => {
    toast.error("Connection to server lost! Kindly inform admin", {
      autoClose: 3000, // 3 seconds in this example
    })
  }

  //Avathr image functionality

  //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'
  const [maleAvatarData, setMaleAvatarData] = useState([])
  const [femaleAvatarData, setFemaleAvatarData] = useState([])

  const [AvatarGendar, setAvatarGendar] = useState(
    localStorage.getItem("usergender") === "Female" ? true : false
  )
  useEffect(() => {
    if (userGender && userGender == "Male") {
      setAvatarGendar(false)
    } else if (userGender && userGender == "Female") {
      setAvatarGendar(true)
    } else {
      setAvatarGendar(false)
    }
  }, [userGender])

  const togAvatarGendar = () => {
    setAvatarGendar(!AvatarGendar)
  }

  const properties = {
    prevArrow: (
      <button className="prev-btn">
        <i className="fas fa-arrow-circle-left"></i>
      </button>
    ),
    nextArrow: (
      <button className="next-btn">
        <i className="fas fa-arrow-circle-right"></i>
      </button>
    ),
  }
  //resource male
  const [maleAvatarHeadData, setMaleAvatarHeadData] = useState({})
  const [maleAvatarArmourData, setMaleAvatarArmourData] = useState({})
  const [maleAvatarHandData, setMaleAvatarHandData] = useState({})
  const [maleAvatarWeaponData, setMaleAvatarWeaponData] = useState({})
  const [maleAvatarPetData, setMaleAvatarPetData] = useState({})

  //resource female
  const [femaleAvatarHeadData, setFemaleAvatarHeadData] = useState({})
  const [femaleAvatarArmourData, setFemaleAvatarArmourData] = useState({})
  const [femaleAvatarHandData, setFemaleAvatarHandData] = useState({})
  const [femaleAvatarWeaponData, setFemaleAvatarWeaponData] = useState({})
  const [femaleAvatarPetData, setFemaleAvatarPetData] = useState({})

  const fetchStudentAvatarData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "studentAvatar/StudentAvatarDeatils", {})
        .then(responseAvatar => {
          if (responseAvatar.data != "") {
            if (responseAvatar.data.maleInventory.length > 0) {
              setMaleAvatarData(responseAvatar.data.maleInventory)

              //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'

              const filteredMaleHeadArray =
                responseAvatar.data.maleInventory.filter(
                  item => parseInt(item.resource_type) === 0
                )
              if (filteredMaleHeadArray.length > 0) {
                setMaleAvatarHeadData(filteredMaleHeadArray[0])
              } else {
                setMaleAvatarHeadData({})
              }

              const filteredMaleArmourArray =
                responseAvatar.data.maleInventory.filter(
                  item => parseInt(item.resource_type) === 1
                )
              if (filteredMaleArmourArray.length > 0) {
                setMaleAvatarArmourData(filteredMaleArmourArray[0])
              } else {
                setMaleAvatarArmourData({})
              }

              const filteredMaleHandArray =
                responseAvatar.data.maleInventory.filter(
                  item => parseInt(item.resource_type) === 2
                )
              if (filteredMaleHandArray.length > 0) {
                setMaleAvatarHandData(filteredMaleHandArray[0])
              } else {
                setMaleAvatarHandData({})
              }

              const filteredMaleWeaponArray =
                responseAvatar.data.maleInventory.filter(
                  item => parseInt(item.resource_type) === 3
                )
              if (filteredMaleWeaponArray.length > 0) {
                setMaleAvatarWeaponData(filteredMaleWeaponArray[0])
              } else {
                setMaleAvatarWeaponData({})
              }

              const filteredMalePetArray =
                responseAvatar.data.maleInventory.filter(
                  item => parseInt(item.resource_type) === 4
                )
              if (filteredMalePetArray.length > 0) {
                setMaleAvatarPetData(filteredMalePetArray[0])
              } else {
                setMaleAvatarPetData({})
              }
            } else {
              setMaleAvatarData([])
              setMaleAvatarHeadData({})
              setMaleAvatarArmourData({})
              setMaleAvatarHandData({})
              setMaleAvatarWeaponData({})
              setMaleAvatarPetData({})
            }

            if (responseAvatar.data.femaleInventory.length > 0) {
              setFemaleAvatarData(responseAvatar.data.femaleInventory)
              //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'

              const filteredFeMaleHeadArray =
                responseAvatar.data.femaleInventory.filter(
                  item => parseInt(item.resource_type) === 0
                )
              if (filteredFeMaleHeadArray.length > 0) {
                setFemaleAvatarHeadData(filteredFeMaleHeadArray[0])
              } else {
                setFemaleAvatarHeadData({})
              }

              const filteredFeMaleArmourArray =
                responseAvatar.data.femaleInventory.filter(
                  item => parseInt(item.resource_type) === 1
                )
              if (filteredFeMaleArmourArray.length > 0) {
                setFemaleAvatarArmourData(filteredFeMaleArmourArray[0])
              } else {
                setFemaleAvatarArmourData({})
              }

              const filteredFeMaleHandArray =
                responseAvatar.data.femaleInventory.filter(
                  item => parseInt(item.resource_type) === 2
                )
              if (filteredFeMaleHandArray.length > 0) {
                setFemaleAvatarHandData(filteredFeMaleHandArray[0])
              } else {
                setFemaleAvatarHandData({})
              }

              const filteredFeMaleWeaponArray =
                responseAvatar.data.femaleInventory.filter(
                  item => parseInt(item.resource_type) === 3
                )
              if (filteredFeMaleWeaponArray.length > 0) {
                setFemaleAvatarWeaponData(filteredFeMaleWeaponArray[0])
              } else {
                setFemaleAvatarWeaponData({})
              }

              const filteredFeMalePetArray =
                responseAvatar.data.femaleInventory.filter(
                  item => parseInt(item.resource_type) === 4
                )
              if (filteredFeMalePetArray.length > 0) {
                setFemaleAvatarPetData(filteredFeMalePetArray[0])
              } else {
                setFemaleAvatarPetData({})
              }
            } else {
              setFemaleAvatarData([])
              setFemaleAvatarHeadData({})
              setFemaleAvatarArmourData({})
              setFemaleAvatarHandData({})
              setFemaleAvatarWeaponData({})
              setFemaleAvatarPetData({})
            }
          } else {
            setMaleAvatarData([])
            setMaleAvatarHeadData({})
            setMaleAvatarArmourData({})
            setMaleAvatarHandData({})
            setMaleAvatarWeaponData({})
            setMaleAvatarPetData({})

            setFemaleAvatarData([])
            setFemaleAvatarHeadData({})
            setFemaleAvatarArmourData({})
            setFemaleAvatarHandData({})
            setFemaleAvatarWeaponData({})
            setFemaleAvatarPetData({})
          }
        })
        .catch(error => {
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }
  }

  // Function to add "character_" prefix to filename
  const modifyUrl = url => {
    const parts = url.split("/")
    const filename = parts[parts.length - 1]
    const modifiedFilename = `character_${filename}`
    parts[parts.length - 1] = modifiedFilename
    return parts.join("/")
  }
  const ReDirectProfile = async () => {
    navigate("/profile")
  }
  // student avatar system

  //coin histry functionality start
  const [modal_center, setmodal_center] = useState(false)
  const [CoinHistryData, setCoinHistryData] = useState([])
  const [CoinHistryPurData, setCoinHistryPurData] = useState([])

  const [SumEarnedCoin, setSumEarnedCoin] = useState(0)
  const [SumSpentCoins, setSumSpentCoins] = useState(0)

  const tog_center = () => {
    setmodal_center(!modal_center)
    //removeBodyCss()
  }

  // Function to sum coins
  function sumCoins(jsonArray) {
    let totalCoins = 0
    jsonArray.forEach(obj => {
      totalCoins += obj.coins
    })
    return totalCoins
  }

  // Function to sum coins
  function sumCoinsEarned(jsonCounArray) {
    let totalCoinsEarned = 0
    jsonCounArray.forEach(obj => {
      totalCoinsEarned += obj.coins_earned
    })
    return totalCoinsEarned
  }
  const fetchCoinHistryData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "studenthome/StudentCoinHistryDetails", {})
        .then(responseCoinHistry => {
          // Assuming the API response is an array of objects with 'id' and 'value' properties

          if (
            responseCoinHistry.data &&
            responseCoinHistry.data.CoinEarned &&
            responseCoinHistry.data.CoinEarned.length > 0
          ) {
            setCoinHistryData(responseCoinHistry.data.CoinEarned)
            setSumEarnedCoin(sumCoinsEarned(responseCoinHistry.data.CoinEarned))
          } else {
            setCoinHistryData([])
            setSumEarnedCoin(sumCoinsEarned([]))
          }
          if (
            responseCoinHistry.data &&
            responseCoinHistry.data.CoinPurchased &&
            responseCoinHistry.data.CoinPurchased.length > 0
          ) {
            setCoinHistryPurData(responseCoinHistry.data.CoinPurchased)
            setSumSpentCoins(sumCoins(responseCoinHistry.data.CoinPurchased))
          } else {
            setCoinHistryPurData([])
            setSumSpentCoins(sumCoins([]))
          }
        })
        .catch(error => {
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }
  }

  const readCoinHistryData = async () => {
    fetchCoinHistryData()
    tog_center()
  }

  const [customCoinActiveTab, setcustomCoinActiveTab] = useState("1")
  function toggleCustomCoin(tab) {
    if (customCoinActiveTab !== tab) {
      setcustomCoinActiveTab(tab)
    }
  }

  //coin histry end

  const findSelectedOption = (options, value) => {
    for (let option of options) {
      if (option.value === value) {
        return option
      }
      if (option.options) {
        const found = findSelectedOption(option.options, value)
        if (found) {
          return found
        }
      }
    }
    return null
  }
  return (
    <React.Fragment>
      <Loader
        show={loader}
        type="body"
        // stack="vertical"
        // message="Loading Data"
      />
      <ToastContainer autoClose={3000} />

      <div className="page-wrapper">
        <Container className="custom-wt">
          <Modal size="lg" isOpen={modal_center} toggle={tog_center} centered>
            <ModalHeader
              className="mt-0 p-0 pb-3 border-0"
              toggle={tog_center}
            ></ModalHeader>
            <ModalBody className="p-0">
              <h5 className="font-size-20 text-dark text-center fw-bold mb-3">
                Coins History
              </h5>

              <div className="lb-details">
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customCoinActiveTab === "1",
                      })}
                      onClick={() => {
                        setcustomCoinActiveTab("1")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        Coins Earned -{" "}
                        {
                          <>
                            <img
                              className="rounded-circle obj-fit-cover me-1"
                              src={awicon}
                              width={24}
                              alt=""
                            />
                            {SumEarnedCoin}
                          </>
                        }
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customCoinActiveTab === "2",
                      })}
                      onClick={() => {
                        setcustomCoinActiveTab("2")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        Coins Spent -{" "}
                        {
                          <>
                            <img
                              className="rounded-circle obj-fit-cover me-1"
                              src={awicon}
                              width={24}
                              alt=""
                            />
                            {SumSpentCoins}
                          </>
                        }
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={customCoinActiveTab} className="">
                  <TabPane tabId="1">
                    <div
                      className="table-responsive mt-4"
                      style={{ height: "500px" }}
                    >
                      <Table className="table custom-table noti-table">
                        <thead>
                          <tr>
                            <th width="10%" className="">
                              Question ID
                            </th>
                            <th width="20%" className="">
                              Coins
                            </th>
                            <th width="20%" className="">
                              Type
                            </th>
                            <th width="30%" className="">
                              Tutor
                            </th>
                            <th width="20%" className="">
                              Date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {CoinHistryData && CoinHistryData.length > 0 ? (
                            CoinHistryData.map((coinHistry, index) => (
                              <tr key={index}>
                                <td>
                                  {coinHistry.fk_question_id != null &&
                                  coinHistry.fk_question_id !== ""
                                    ? "#" + coinHistry.fk_question_id
                                    : "-"}
                                </td>
                                <td>
                                  {
                                    <>
                                      <img
                                        className="rounded-circle obj-fit-cover me-1"
                                        src={awicon}
                                        width={24}
                                        alt=""
                                      />
                                      {coinHistry.coins_earned}
                                    </>
                                  }
                                </td>
                                <td>
                                  {coinHistry.fk_question_id != null &&
                                  coinHistry.fk_question_id !== "" &&
                                  parseInt(coinHistry.is_rewarded) === 0 &&
                                  parseInt(coinHistry.activity_type) === 0 ? (
                                    <h6 className="font-size-14 text-center text-dark mb-0">
                                      ASSESSMENT
                                    </h6>
                                  ) : coinHistry.fk_question_id != null &&
                                    coinHistry.fk_question_id !== "" &&
                                    parseInt(coinHistry.is_rewarded) === 0 &&
                                    parseInt(coinHistry.activity_type) === 1 ? (
                                    <h6 className="font-size-14 text-center text-dark mb-0">
                                      VIDEO
                                    </h6>
                                  ) : coinHistry.fk_question_id != null &&
                                    coinHistry.fk_question_id !== "" &&
                                    parseInt(coinHistry.is_rewarded) === 0 &&
                                    parseInt(coinHistry.activity_type) === 2 ? (
                                    <h6 className="font-size-14 text-center text-dark mb-0">
                                      DIAGNOSTICS
                                    </h6>
                                  ) : coinHistry.fk_question_id != null &&
                                    coinHistry.fk_question_id !== "" &&
                                    parseInt(coinHistry.is_rewarded) === 0 &&
                                    parseInt(coinHistry.activity_type) === 3 ? (
                                    <h6 className="font-size-14 text-center text-dark mb-0">
                                      LINK
                                    </h6>
                                  ) : coinHistry.fk_question_id != null &&
                                    coinHistry.fk_question_id !== "" &&
                                    parseInt(coinHistry.is_rewarded) === 0 &&
                                    parseInt(coinHistry.activity_type) === 4 ? (
                                    <h6 className="font-size-14 text-center text-dark mb-0">
                                      TASK
                                    </h6>
                                  ) : coinHistry.fk_question_id != null &&
                                    coinHistry.fk_question_id !== "" &&
                                    parseInt(coinHistry.is_rewarded) === 0 &&
                                    parseInt(coinHistry.activity_type) === 5 ? (
                                    <h6 className="font-size-14 text-center text-dark mb-0">
                                      WORKSHEET
                                    </h6>
                                  ) : coinHistry.fk_question_id != null &&
                                    coinHistry.fk_question_id !== "" &&
                                    parseInt(coinHistry.is_rewarded) === 0 &&
                                    parseInt(coinHistry.activity_type) === 6 ? (
                                    <h6 className="font-size-14 text-center text-dark mb-0">
                                      VIDEO
                                    </h6>
                                  ) : coinHistry.fk_question_id != null &&
                                    coinHistry.fk_question_id !== "" &&
                                    parseInt(coinHistry.is_rewarded) === 0 &&
                                    parseInt(coinHistry.activity_type) === 7 ? (
                                    <h6 className="font-size-14 text-center text-dark mb-0">
                                      WORKSHEET
                                    </h6>
                                  ) : parseInt(coinHistry.is_rewarded) === 1 &&
                                    parseInt(coinHistry.reward_type) === 0 ? (
                                    <h6 className="font-size-14 text-center text-dark mb-0">
                                      REWARD
                                    </h6>
                                  ) : parseInt(coinHistry.is_rewarded) === 1 &&
                                    parseInt(coinHistry.reward_type) === 1 ? (
                                    <h6 className="font-size-14 text-center text-dark mb-0">
                                      LEADER BOARD WEEKLY REWARD
                                    </h6>
                                  ) : parseInt(coinHistry.is_rewarded) === 1 &&
                                    parseInt(coinHistry.reward_type) === 2 ? (
                                    <h6 className="font-size-14 text-center text-dark mb-0">
                                      Q/A REWARD
                                    </h6>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>
                                  {coinHistry.tut_fname != null &&
                                  coinHistry.tut_fname !== ""
                                    ? coinHistry.tut_fname +
                                      " " +
                                      coinHistry.tut_surname
                                    : "-"}
                                </td>
                                <td>
                                  {coinHistry.created_on != null &&
                                  coinHistry.created_on !== ""
                                    ? moment(
                                        coinHistry.created_on,
                                        moment.ISO_8601
                                      ).format("DD-MM-YYYY")
                                    : "-"}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5}>
                                <p className="font-size-15 opacity-50 text-center mb-0 py-1">
                                  No Data Found
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {/* <div className="d-block ">
                            <p className="font-size-15 opacity-50 text-center mb-0 py-1">No Reports Found</p>
                          </div> */}
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div
                      className="table-responsive mt-4"
                      style={{ height: "500px" }}
                    >
                      <Table className="table custom-table noti-table">
                        <thead>
                          <tr>
                            <th width="20%" className="">
                              Purchased ID
                            </th>
                            <th width="20%" className="">
                              Coins
                            </th>
                            <th width="20%" className="">
                              Character
                            </th>
                            <th width="20%" className="">
                              Item
                            </th>
                            <th width="20%" className="">
                              Date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {CoinHistryPurData && CoinHistryPurData.length > 0 ? (
                            CoinHistryPurData.map((coinPurHistry, index) => (
                              <tr key={index}>
                                <td>
                                  {coinPurHistry.pk_supply_purchase_id !=
                                    null &&
                                  coinPurHistry.pk_supply_purchase_id !== ""
                                    ? "#" + coinPurHistry.pk_supply_purchase_id
                                    : "-"}
                                </td>
                                <td>
                                  {
                                    <>
                                      <img
                                        className="rounded-circle obj-fit-cover me-1"
                                        src={awicon}
                                        width={24}
                                        alt=""
                                      />
                                      {coinPurHistry.coins}
                                    </>
                                  }
                                </td>
                                <td>
                                  {parseInt(coinPurHistry.character_type) === 0
                                    ? "Male"
                                    : "Female"}
                                </td>
                                <td>
                                  {parseInt(coinPurHistry.resource_type) ===
                                  0 ? (
                                    <>
                                      {" "}
                                      <span className="text-center d-none d-sm-block">
                                        {coinPurHistry &&
                                        coinPurHistry != "" &&
                                        coinPurHistry.image ? (
                                          <img
                                            width={30}
                                            height={30}
                                            className=""
                                            src={coinPurHistry.image}
                                            alt=""
                                          />
                                        ) : (
                                          <i className="mdi mdi-crown h2"></i>
                                        )}
                                      </span>
                                    </>
                                  ) : parseInt(coinPurHistry.resource_type) ===
                                    1 ? (
                                    <>
                                      {" "}
                                      <span className="text-center d-none d-sm-block">
                                        {coinPurHistry &&
                                        coinPurHistry != "" &&
                                        coinPurHistry.image ? (
                                          <img
                                            width={30}
                                            height={30}
                                            className=""
                                            src={coinPurHistry.image}
                                            alt=""
                                          />
                                        ) : (
                                          <i className="mdi mdi-tshirt-crew h2"></i>
                                        )}
                                      </span>
                                    </>
                                  ) : parseInt(coinPurHistry.resource_type) ===
                                    2 ? (
                                    <>
                                      {" "}
                                      <span className="text-center d-none d-sm-block">
                                        {coinPurHistry &&
                                        coinPurHistry != "" &&
                                        coinPurHistry.image ? (
                                          <img
                                            width={30}
                                            height={30}
                                            className=""
                                            src={coinPurHistry.image}
                                            alt=""
                                          />
                                        ) : (
                                          <i className="mdi mdi-hand-left h2"></i>
                                        )}
                                      </span>
                                    </>
                                  ) : parseInt(coinPurHistry.resource_type) ===
                                    3 ? (
                                    <>
                                      {" "}
                                      <span className="text-center d-none d-sm-block">
                                        {coinPurHistry &&
                                        coinPurHistry != "" &&
                                        coinPurHistry.image ? (
                                          <img
                                            width={30}
                                            height={30}
                                            className=""
                                            src={coinPurHistry.image}
                                            alt=""
                                          />
                                        ) : (
                                          <i className="mdi mdi-axe h2"></i>
                                        )}
                                      </span>
                                    </>
                                  ) : parseInt(coinPurHistry.resource_type) ===
                                    4 ? (
                                    <>
                                      {" "}
                                      <span className="text-center d-none d-sm-block">
                                        {coinPurHistry &&
                                        coinPurHistry != "" &&
                                        coinPurHistry.image ? (
                                          <img
                                            width={30}
                                            height={30}
                                            className=""
                                            src={coinPurHistry.image}
                                            alt=""
                                          />
                                        ) : (
                                          <i className="mdi mdi-bird h2"></i>
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>
                                  {coinPurHistry.created_at != null &&
                                  coinPurHistry.created_at !== ""
                                    ? moment(
                                        coinPurHistry.created_at,
                                        moment.ISO_8601
                                      ).format("DD-MM-YYYY")
                                    : "-"}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4}>
                                <p className="font-size-15 opacity-50 text-center mb-0 py-1">
                                  No Data Found
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {/* <div className="d-block ">
                          <p className="font-size-15 opacity-50 text-center mb-0 py-1">No Reports Found</p>
                        </div> */}
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </ModalBody>
          </Modal>
          {/* <div className="db-profile-section pt-4 mb-5 d-flex mt-4 justify-content-between">
           <div className="d-flex align-items-center">
              <div className="me-3">
                <img
                  className="rounded-circle obj-fit-cover"
                  src={
                    userProfileImg
                      ? parseInt(userProfileImg) === 1
                        ? usprofile1
                        : parseInt(userProfileImg) === 2
                        ? usprofile2
                        : parseInt(userProfileImg) === 3
                        ? usprofile3
                        : parseInt(userProfileImg) === 4
                        ? usprofile4
                        : parseInt(userProfileImg) === 5
                        ? usprofile5
                        : parseInt(userProfileImg) === 6
                        ? usprofile6
                        : usprofile6
                      : usprofile6
                  }
                  alt=""
                />
              </div>
              <div>
                <h5 className="mb-0 text-dark fw-semibold font-size-20">
                  Welcome back,
                </h5>
                <h3 className="cs-fs fw-bold mb-0">
                  {username
                    ? username.st_first_name
                      ? username.st_first_name + " " + username.st_surname
                      : ""
                    : ""}!
                </h3>
              </div>
                  </div> 

          

             <div className="d-flex align-items-center">
              <div className="text-end me-3">
                <h3 className="cs-fs fw-bold mb-0 custom-red">
                  {" "}
                  {CoinData &&
                  CoinData.total_coins &&
                  CoinData.total_coins != ""
                    ? CoinData.total_coins
                    : 0}
                </h3>
                <div
                  className={`badge ${
                    CoinData &&
                    CoinData.past_week_coins &&
                    CoinData.past_week_coins !== "" &&
                    parseInt(CoinData.past_week_coins) !== 0
                      ? "cs-success text-success"
                      : "cs-success text-success"
                  } font-size-14 fw-bold`}
                >
                  {CoinData &&
                  CoinData.past_week_coins &&
                  CoinData.past_week_coins != "" &&
                  parseInt(CoinData.past_week_coins) !== 0
                    ? "↑ " + CoinData.past_week_coins + " in the past week"
                    : "No coins earned this week yet"}
                </div>
              </div>
              <div>
                <img
                  className="rounded-circle obj-fit-cover"
                  src={money}
                  alt=""
                />
              </div>
            </div> 
          </div> */}
          <div className="d-flex justify-content-between align-items-center ">
            <div className="db-profile-section d-flex justify-content-between flex-grow-1">
              <div
                className="d-flex justify-content-between align-items-center gap-3 cursor-pointer"
                onClick={() => ReDirectProfile()}
              >
                <div className="profile_icon ">
                  <img
                    className="rounded-circle obj-fit-cover"
                    src={
                      userProfileImg && userGender
                        ? userGender === "Male"
                          ? parseInt(userProfileImg) === 1
                            ? b1
                            : parseInt(userProfileImg) === 2
                            ? b2
                            : parseInt(userProfileImg) === 3
                            ? b3
                            : parseInt(userProfileImg) === 4
                            ? b4
                            : parseInt(userProfileImg) === 5
                            ? b5
                            : parseInt(userProfileImg) === 6
                            ? b6
                            : parseInt(userProfileImg) === 7
                            ? b7
                            : parseInt(userProfileImg) === 8
                            ? b8
                            : parseInt(userProfileImg) === 9
                            ? b9
                            : parseInt(userProfileImg) === 10
                            ? b10
                            : b1
                          : userGender === "Female"
                          ? parseInt(userProfileImg) === 1
                            ? g1
                            : parseInt(userProfileImg) === 2
                            ? g2
                            : parseInt(userProfileImg) === 3
                            ? g3
                            : parseInt(userProfileImg) === 4
                            ? g4
                            : parseInt(userProfileImg) === 5
                            ? g5
                            : parseInt(userProfileImg) === 6
                            ? g6
                            : parseInt(userProfileImg) === 7
                            ? g7
                            : parseInt(userProfileImg) === 8
                            ? g8
                            : parseInt(userProfileImg) === 9
                            ? g9
                            : parseInt(userProfileImg) === 10
                            ? g10
                            : g1
                          : parseInt(userProfileImg) === 1
                          ? b1
                          : parseInt(userProfileImg) === 2
                          ? b2
                          : parseInt(userProfileImg) === 3
                          ? b3
                          : parseInt(userProfileImg) === 4
                          ? b4
                          : parseInt(userProfileImg) === 5
                          ? b5
                          : parseInt(userProfileImg) === 6
                          ? b6
                          : parseInt(userProfileImg) === 7
                          ? b7
                          : parseInt(userProfileImg) === 8
                          ? b8
                          : parseInt(userProfileImg) === 9
                          ? b9
                          : parseInt(userProfileImg) === 10
                          ? b10
                          : b1
                        : b1
                    }
                    alt=""
                  />
                </div>
                <div>
                  <h5 className="mb-0 text-dark fw-semibold font-size-20">
                    Welcome back,
                  </h5>
                  <h3 className="cs-fs fw-bold mb-0">
                    {username
                      ? username.st_first_name
                        ? username.st_first_name + " " + username.st_surname
                        : ""
                      : ""}
                  </h3>
                </div>
              </div>

              <div className="page-header mt-4">
                {/* <h5 className="mb-4 text-dark fw-bold font-size-24">Overview</h5> */}
                <div className="pg-info">
                  <ul className="pg-tab list-unstyled mb-0 d-flex align-items-center">
                    <li>SUBJECT</li>
                    {subjectOptions.map(
                      (
                        subject // Map over each subject in subjectOptions
                      ) => (
                        <li
                          key={subject} // Set a unique key for each list item
                          className={
                            selectedSubject === subject.subject_id
                              ? "active"
                              : ""
                          } // Set class to "active" if selectedSubject matches the current subject
                          onClick={() =>
                            handleSelectSubject(subject.subject_id)
                          } // Set up click event handler to call handleSelectSubject with the current subject
                        >
                          {selectedSubject === subject.subject_id && ( // Conditionally render a checkmark if selectedSubject matches the current subject
                            <span>
                              <i className="fas fa-check"></i>
                            </span>
                          )}{" "}
                          {subject.subject_name}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>

              <div
                className="d-flex align-items-center gap-3 cursor-pointer z-3"
                onClick={() => readCoinHistryData()}
              >
                <div className="text-end">
                  <h3 className="cs-fs fw-bold mb-0 custom-red">
                    {" "}
                    {CoinData &&
                    CoinData.total_coins &&
                    CoinData.total_coins != ""
                      ? CoinData.total_coins
                      : 0}
                  </h3>
                  <div
                    className={`badge ${
                      CoinData &&
                      CoinData.past_week_coins &&
                      CoinData.past_week_coins !== "" &&
                      parseInt(CoinData.past_week_coins) !== 0
                        ? "cs-success text-success"
                        : "cs-success text-success"
                    } font-size-14 fw-bold`}
                  >
                    {CoinData &&
                    CoinData.past_week_coins &&
                    CoinData.past_week_coins != "" &&
                    parseInt(CoinData.past_week_coins) !== 0
                      ? "↑ " + CoinData.past_week_coins + " in the past week"
                      : "No coins earned this week yet"}
                  </div>
                </div>
                <div>
                  <img
                    className="rounded-circle obj-fit-cover"
                    src={money}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="avatar-wrapper">
              {/* student avatar start */}
              <div className="super-profile_wrapper img-consolidate-display">
                <div className="content-left">
                  <div className="super-avatar-display">
                    {/* <Slide
                      autoplay={false}
                      infinite={false}
                      onStartChange={togAvatarGendar}
                      {...properties}
                    > */}
                    {/* male functionality start */}
                    {!AvatarGendar ? (
                      <div
                        className="each-slide-effect cursor-pointer"
                        onClick={() => ReDirectProfile()}
                      >
                        <div className="pirate-media male position-relative">
                          <div className="avatar-display text-center position-relative">
                            <div className="avatar-default">
                              <img
                                width={450}
                                className=""
                                src={piratemale}
                                alt=""
                              />
                            </div>
                            <div className="avatar-attachments">
                              {maleAvatarHeadData &&
                              maleAvatarHeadData != "" &&
                              maleAvatarHeadData.image ? (
                                <div className="head-float position-absolute">
                                  <img
                                    className=""
                                    src={modifyUrl(maleAvatarHeadData.image)}
                                    alt=""
                                  />
                                </div>
                              ) : null}

                              {maleAvatarArmourData &&
                              maleAvatarArmourData != "" &&
                              maleAvatarArmourData.image ? (
                                <div className="dress-float position-absolute">
                                  <img
                                    className=""
                                    src={maleAvatarArmourData.image}
                                    alt=""
                                  />
                                </div>
                              ) : null}

                              {maleAvatarPetData &&
                              maleAvatarPetData != "" &&
                              maleAvatarPetData.image ? (
                                <div className="companion-float position-absolute">
                                  <img
                                    className=""
                                    src={maleAvatarPetData.image}
                                    alt=""
                                  />
                                </div>
                              ) : null}

                              {maleAvatarHandData &&
                              maleAvatarHandData != "" &&
                              maleAvatarHandData.image ? (
                                <>
                                  <div className="hand-float position-absolute">
                                    <img
                                      className=""
                                      src={maleAvatarHandData.image}
                                      alt=""
                                    />
                                  </div>

                                  <div className="hand-float-revert position-absolute">
                                    <img
                                      className=""
                                      src={maleAvatarHandData.image}
                                      alt=""
                                    />
                                  </div>
                                </>
                              ) : null}

                              {maleAvatarWeaponData &&
                              maleAvatarWeaponData != "" &&
                              maleAvatarWeaponData.image ? (
                                <div className="weapon-float position-absolute">
                                  <img
                                    className=""
                                    src={maleAvatarWeaponData.image}
                                    alt=""
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="each-slide-effect cursor-pointer"
                        onClick={() => ReDirectProfile()}
                      >
                        <div className="pirate-media female position-relative">
                          <div className="avatar-display text-center position-relative">
                            <div className="avatar-default">
                              <img
                                width={450}
                                className=""
                                src={piratefemale}
                                alt=""
                              />
                            </div>
                            <div className="avatar-attachments">
                              {femaleAvatarHeadData &&
                              femaleAvatarHeadData != "" &&
                              femaleAvatarHeadData.image ? (
                                <div className="head-float female-hd position-absolute">
                                  <img
                                    className=""
                                    src={modifyUrl(femaleAvatarHeadData.image)}
                                    alt=""
                                  />
                                </div>
                              ) : null}

                              {femaleAvatarArmourData &&
                              femaleAvatarArmourData != "" &&
                              femaleAvatarArmourData.image ? (
                                <div className="dress-float position-absolute">
                                  <img
                                    className=""
                                    src={femaleAvatarArmourData.image}
                                    alt=""
                                  />
                                </div>
                              ) : null}

                              {femaleAvatarPetData &&
                              femaleAvatarPetData != "" &&
                              femaleAvatarPetData.image ? (
                                <div className="companion-float position-absolute">
                                  <img
                                    className=""
                                    src={femaleAvatarPetData.image}
                                    alt=""
                                  />
                                </div>
                              ) : null}

                              {femaleAvatarHandData &&
                              femaleAvatarHandData != "" &&
                              femaleAvatarHandData.image ? (
                                <>
                                  <div className="hand-float position-absolute">
                                    <img
                                      className=""
                                      src={femaleAvatarHandData.image}
                                      alt=""
                                    />
                                  </div>
                                  <div className="hand-float-revert position-absolute">
                                    <img
                                      className=""
                                      src={femaleAvatarHandData.image}
                                      alt=""
                                    />
                                  </div>
                                </>
                              ) : null}

                              {femaleAvatarWeaponData &&
                              femaleAvatarWeaponData != "" &&
                              femaleAvatarWeaponData.image ? (
                                <div className="weapon-float position-absolute">
                                  <img
                                    className=""
                                    src={femaleAvatarWeaponData.image}
                                    alt=""
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Female functionality end */}
                    {/* </Slide> */}
                  </div>
                </div>
              </div>

              {/* student avatar end */}
            </div>
          </div>
          <div className="page-inner_section">
            <div className="leaderboard-wrapper mt-4 mb-5">
              <Row>
                <Col xl={4}>
                  <div className="leader-banner">
                    <img className="" src={leader} alt="" />
                    <div className="leader-hdn">
                      <h3 className="issa-text mb-0">LEADERBOARD</h3>
                    </div>
                  </div>

                  <div className="lb-details">
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1")
                          }}
                        >
                          <span className="d-none d-sm-block">Top Earner</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleCustom("2")
                          }}
                        >
                          <span className="d-none d-sm-block">
                            Most Improved
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "3",
                          })}
                          onClick={() => {
                            toggleCustom("3")
                          }}
                        >
                          <span className="d-none d-sm-block">Speed</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "4",
                          })}
                          onClick={() => {
                            toggleCustom("4")
                          }}
                        >
                          <span className="d-none d-sm-block">Accuracy</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="bg-white mb-0 d-flex justify-content-end">
                      {/* <p className="mb-0 me-2 text-danger">{leaderboardData.length > 0 ? 'Updated Date : '+moment.utc(leaderboardData[0].updated_date).format('D MMM, YYYY') : ''}</p> */}
                      {/* <p  className="mb-0 me-2 text-danger">Leaderboard is updated every Sunday end of Day.</p> */}
                    </div>
                    <div className="leaderboard-filters p-2 bg-white d-flex gap-2">
                      <div className="flex-grow-1">
                        <Select
                          className="cs-style"
                          // value={activitylevelList.find(
                          //   option => parseInt(option.value) === parseInt(selectedGroup)
                          // )}
                          value={findSelectedOption(
                            activitylevelList,
                            selectedGroup
                          )}
                          onChange={selectedOption => {
                            handleSelectGroup(selectedOption["value"])
                          }}
                          options={activitylevelList}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <Select
                          className="cs-style"
                          //placeholder= {defaultSelectedOption ? defaultSelectedOption.label : 'hello'}
                          value={optionGroup1.find(
                            option => option.value === selectedGroup1
                          )}
                          onChange={selectedoption1 =>
                            handleSelectGroup1(selectedoption1["value"])
                          }
                          options={optionGroup1}
                          classNamePrefix="select2-selection"
                        />
                      </div>

                      <div className="flex-grow-1">
                        <Select
                          className="cs-style"
                          placeholder="All Time"
                          value={optionGroup2.find(
                            option => option.value === selectedGroup2
                          )}
                          onChange={selectedoption2 => {
                            handleSelectGroup2(selectedoption2["value"])
                          }}
                          options={optionGroup2}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </div>

                    <TabContent activeTab={customActiveTab} className="">
                      <div>
                        {" "}
                        <Loader
                          show={Leaderloader}
                          type="inline"
                          // stack="vertical"
                          // message="Loading Data"
                        />
                      </div>
                      <TabPane tabId="1">
                        <div className="leaderbd-contents">
                          <div className="table-responsive">
                            <Table className="table table-transparent mb-0">
                              <tbody>
                                {!Leaderloader &&
                                leaderboardData.length === 0 ? (
                                  <tr>
                                    <td colSpan="5" className="text-center">
                                      No data found
                                    </td>
                                  </tr>
                                ) : (
                                  leaderboardData.map((student, index) => (
                                    <tr key={index}>
                                      <td className="text-center">
                                        {index === 0 && (
                                          <img
                                            className=""
                                            src={first_medal}
                                            alt=""
                                          />
                                        )}
                                        {index === 1 && (
                                          <img
                                            className=""
                                            src={second_medal}
                                            alt=""
                                          />
                                        )}
                                        {index === 2 && (
                                          <img
                                            className=""
                                            src={third_medal}
                                            alt=""
                                          />
                                        )}
                                        {index > 2 && (
                                          <span className="text-center">
                                            {index + 1}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <img
                                          className="rounded-circle obj-fit-cover"
                                          width={48}
                                          height={48}
                                          src={
                                            student &&
                                            student.st_gender &&
                                            student.st_avatar
                                              ? student.st_gender === "Male"
                                                ? parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                  ? b1
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 2
                                                  ? b2
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 3
                                                  ? b3
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 4
                                                  ? b4
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 5
                                                  ? b5
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 6
                                                  ? b6
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 7
                                                  ? b7
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 8
                                                  ? b8
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 9
                                                  ? b9
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 10
                                                  ? b10
                                                  : b1
                                                : student.st_gender === "Female"
                                                ? parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                  ? g1
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 2
                                                  ? g2
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 3
                                                  ? g3
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 4
                                                  ? g4
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 5
                                                  ? g5
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 6
                                                  ? g6
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 7
                                                  ? g7
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 8
                                                  ? g8
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 9
                                                  ? g9
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 10
                                                  ? g10
                                                  : g1
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                ? b1
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 2
                                                ? b2
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 3
                                                ? b3
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 4
                                                ? b4
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 5
                                                ? b5
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 6
                                                ? b6
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 7
                                                ? b7
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 8
                                                ? b8
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 9
                                                ? b9
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 10
                                                ? b10
                                                : b1
                                              : b1
                                          }
                                          alt=""
                                        />
                                      </td>
                                      <td>
                                        {student.st_first_name}{" "}
                                        {student.st_surname}
                                      </td>
                                      <td className="opacity-50">
                                        {/* {student.level_name &&
                                          student.level_name
                                            .split(" ")
                                            .map(word => word.charAt(0))
                                            .join("")} */}
                                        {student.level_name &&
                                          student.level_name
                                            .split(" ")
                                            .map(word => {
                                              if (/^\d{2}$/.test(word)) {
                                                return word
                                              }
                                              return word
                                                .charAt(0)
                                                .toUpperCase()
                                            })
                                            .join("")}
                                      </td>

                                      <td className="whitespace-nowrap">
                                        <img
                                          className="rounded-circle obj-fit-cover me-1"
                                          src={awicon}
                                          width={24}
                                          alt=""
                                        />
                                        {student.total_coins}
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="leaderbd-contents">
                          <div className="table-responsive">
                            <Table className="table table-transparent mb-0">
                              <tbody>
                                {leaderboardData.length === 0 ? (
                                  <tr>
                                    <td colSpan="5" className="text-center">
                                      No data found
                                    </td>
                                  </tr>
                                ) : (
                                  leaderboardData.map((student, index) => (
                                    <tr key={index}>
                                      <td className="text-center">
                                        {index === 0 && (
                                          <img
                                            className=""
                                            src={first_medal}
                                            alt=""
                                          />
                                        )}
                                        {index === 1 && (
                                          <img
                                            className=""
                                            src={second_medal}
                                            alt=""
                                          />
                                        )}
                                        {index === 2 && (
                                          <img
                                            className=""
                                            src={third_medal}
                                            alt=""
                                          />
                                        )}
                                        {index > 2 && (
                                          <span className="text-center">
                                            {index + 1}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <img
                                          className="rounded-circle obj-fit-cover"
                                          width={48}
                                          height={48}
                                          src={
                                            student &&
                                            student.st_gender &&
                                            student.st_avatar
                                              ? student.st_gender === "Male"
                                                ? parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                  ? b1
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 2
                                                  ? b2
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 3
                                                  ? b3
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 4
                                                  ? b4
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 5
                                                  ? b5
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 6
                                                  ? b6
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 7
                                                  ? b7
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 8
                                                  ? b8
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 9
                                                  ? b9
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 10
                                                  ? b10
                                                  : b1
                                                : student.st_gender === "Female"
                                                ? parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                  ? g1
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 2
                                                  ? g2
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 3
                                                  ? g3
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 4
                                                  ? g4
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 5
                                                  ? g5
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 6
                                                  ? g6
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 7
                                                  ? g7
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 8
                                                  ? g8
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 9
                                                  ? g9
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 10
                                                  ? g10
                                                  : g1
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                ? b1
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 2
                                                ? b2
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 3
                                                ? b3
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 4
                                                ? b4
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 5
                                                ? b5
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 6
                                                ? b6
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 7
                                                ? b7
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 8
                                                ? b8
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 9
                                                ? b9
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 10
                                                ? b10
                                                : b1
                                              : b1
                                          }
                                          alt=""
                                        />
                                      </td>
                                      <td>
                                        {student.st_first_name}{" "}
                                        {student.st_surname}
                                      </td>
                                      <td className="opacity-50">
                                        {/* {student.level_name &&
                                          student.level_name
                                            .split(" ")
                                            .map(word => word.charAt(0))
                                            .join("")} */}
                                        {student.level_name &&
                                          student.level_name
                                            .split(" ")
                                            .map(word => {
                                              if (/^\d{2}$/.test(word)) {
                                                return word
                                              }
                                              return word
                                                .charAt(0)
                                                .toUpperCase()
                                            })
                                            .join("")}
                                      </td>

                                      <td className="whitespace-nowrap">
                                        <img
                                          className="rounded-circle obj-fit-cover me-1"
                                          src={awicon}
                                          width={24}
                                          alt=""
                                        />
                                        {student.total_coins}
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="3">
                        <div className="leaderbd-contents">
                          <div className="table-responsive">
                            <Table className="table table-transparent mb-0">
                              <tbody>
                                {leaderboardData.length === 0 ? (
                                  <tr>
                                    <td colSpan="5" className="text-center">
                                      No data found
                                    </td>
                                  </tr>
                                ) : (
                                  leaderboardData.map((student, index) => (
                                    <tr key={index}>
                                      <td className="text-center">
                                        {index === 0 && (
                                          <img
                                            className=""
                                            src={first_medal}
                                            alt=""
                                          />
                                        )}
                                        {index === 1 && (
                                          <img
                                            className=""
                                            src={second_medal}
                                            alt=""
                                          />
                                        )}
                                        {index === 2 && (
                                          <img
                                            className=""
                                            src={third_medal}
                                            alt=""
                                          />
                                        )}
                                        {index > 2 && (
                                          <span className="text-center">
                                            {index + 1}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <img
                                          className="rounded-circle obj-fit-cover"
                                          width={48}
                                          height={48}
                                          src={
                                            student &&
                                            student.st_gender &&
                                            student.st_avatar
                                              ? student.st_gender === "Male"
                                                ? parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                  ? b1
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 2
                                                  ? b2
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 3
                                                  ? b3
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 4
                                                  ? b4
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 5
                                                  ? b5
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 6
                                                  ? b6
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 7
                                                  ? b7
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 8
                                                  ? b8
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 9
                                                  ? b9
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 10
                                                  ? b10
                                                  : b1
                                                : student.st_gender === "Female"
                                                ? parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                  ? g1
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 2
                                                  ? g2
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 3
                                                  ? g3
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 4
                                                  ? g4
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 5
                                                  ? g5
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 6
                                                  ? g6
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 7
                                                  ? g7
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 8
                                                  ? g8
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 9
                                                  ? g9
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 10
                                                  ? g10
                                                  : g1
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                ? b1
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 2
                                                ? b2
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 3
                                                ? b3
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 4
                                                ? b4
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 5
                                                ? b5
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 6
                                                ? b6
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 7
                                                ? b7
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 8
                                                ? b8
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 9
                                                ? b9
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 10
                                                ? b10
                                                : b1
                                              : b1
                                          }
                                          alt=""
                                        />
                                      </td>
                                      <td>
                                        {student.st_first_name}{" "}
                                        {student.st_surname}
                                      </td>
                                      <td className="opacity-50">
                                        {/* {student.level_name &&
                                          student.level_name
                                            .split(" ")
                                            .map(word => word.charAt(0))
                                            .join("")} */}
                                        {student.level_name &&
                                          student.level_name
                                            .split(" ")
                                            .map(word => {
                                              if (/^\d{2}$/.test(word)) {
                                                return word
                                              }
                                              return word
                                                .charAt(0)
                                                .toUpperCase()
                                            })
                                            .join("")}
                                      </td>

                                      <td className="whitespace-nowrap">
                                        <img
                                          className="rounded-circle obj-fit-cover me-1"
                                          src={awicon}
                                          width={24}
                                          alt=""
                                        />
                                        {student.total_coins}
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="4">
                        <div className="leaderbd-contents">
                          <div className="table-responsive">
                            <Table className="table table-transparent mb-0">
                              <tbody>
                                {leaderboardData.length === 0 ? (
                                  <tr>
                                    <td colSpan="5" className="text-center">
                                      No data found
                                    </td>
                                  </tr>
                                ) : (
                                  leaderboardData.map((student, index) => (
                                    <tr key={index}>
                                      <td className="text-center">
                                        {index === 0 && (
                                          <img
                                            className=""
                                            src={first_medal}
                                            alt=""
                                          />
                                        )}
                                        {index === 1 && (
                                          <img
                                            className=""
                                            src={second_medal}
                                            alt=""
                                          />
                                        )}
                                        {index === 2 && (
                                          <img
                                            className=""
                                            src={third_medal}
                                            alt=""
                                          />
                                        )}
                                        {index > 2 && (
                                          <span className="text-center">
                                            {index + 1}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <img
                                          className="rounded-circle obj-fit-cover"
                                          width={48}
                                          height={48}
                                          src={
                                            student &&
                                            student.st_gender &&
                                            student.st_avatar
                                              ? student.st_gender === "Male"
                                                ? parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                  ? b1
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 2
                                                  ? b2
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 3
                                                  ? b3
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 4
                                                  ? b4
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 5
                                                  ? b5
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 6
                                                  ? b6
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 7
                                                  ? b7
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 8
                                                  ? b8
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 9
                                                  ? b9
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 10
                                                  ? b10
                                                  : b1
                                                : student.st_gender === "Female"
                                                ? parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                  ? g1
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 2
                                                  ? g2
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 3
                                                  ? g3
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 4
                                                  ? g4
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 5
                                                  ? g5
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 6
                                                  ? g6
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 7
                                                  ? g7
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 8
                                                  ? g8
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 9
                                                  ? g9
                                                  : parseInt(
                                                      student.st_avatar
                                                    ) === 10
                                                  ? g10
                                                  : g1
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 1
                                                ? b1
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 2
                                                ? b2
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 3
                                                ? b3
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 4
                                                ? b4
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 5
                                                ? b5
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 6
                                                ? b6
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 7
                                                ? b7
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 8
                                                ? b8
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 9
                                                ? b9
                                                : parseInt(
                                                    student.st_avatar
                                                  ) === 10
                                                ? b10
                                                : b1
                                              : b1
                                          }
                                          alt=""
                                        />
                                      </td>
                                      <td>
                                        {student.st_first_name}{" "}
                                        {student.st_surname}
                                      </td>
                                      <td className="opacity-50">
                                        {/* {student.level_name &&
                                          student.level_name
                                            .split(" ")
                                            .map(word => word.charAt(0))
                                            .join("")} */}
                                        {student.level_name &&
                                          student.level_name
                                            .split(" ")
                                            .map(word => {
                                              if (/^\d{2}$/.test(word)) {
                                                return word
                                              }
                                              return word
                                                .charAt(0)
                                                .toUpperCase()
                                            })
                                            .join("")}
                                      </td>

                                      <td className="whitespace-nowrap">
                                        <img
                                          className="rounded-circle obj-fit-cover me-1"
                                          src={awicon}
                                          width={24}
                                          alt=""
                                        />
                                        {student.total_coins}
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
                <Col xl={8}>
                  <div className="up-classes-section mb-5">
                    <div className="d-flex align-items-start justify-content-between mb-3">
                      <div>
                        <h5 className="mb-4 text-dark fw-bold font-size-24">
                          Upcoming Classes
                        </h5>
                        <div className="pg-info">
                          <ul className="pg-tab list-unstyled mb-0 d-flex align-items-center ">
                            <li></li>
                            {UpcomingTypeOptions.map(
                              (
                                type // Map over each subject in subjectOptions
                              ) => (
                                <li
                                  key={type} // Set a unique key for each list item
                                  className={
                                    selectedUpcomingType === type.type_id
                                      ? "active"
                                      : ""
                                  } // Set class to "active" if selectedSubject matches the current subject
                                  onClick={() => handleSelectType(type.type_id)} // Set up click event handler to call handleSelectSubject with the current subject
                                >
                                  {selectedUpcomingType === type.type_id && ( // Conditionally render a checkmark if selectedSubject matches the current subject
                                    <span>
                                      <i className="fas fa-check"></i>
                                    </span>
                                  )}{" "}
                                  {type.type_name}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      <Link to="classes-list" className="btn custom-btn-green">
                        VIEW ALL CLASSES
                      </Link>
                    </div>
                    {selectedUpcomingType && selectedUpcomingType === 2 ? (
                      <Carousel responsive={responsive}>
                        {UpcommingSession && UpcommingSession.length > 0 ? (
                          UpcommingSession.map(
                            (UpcommingSessionData, index) => {
                              // const first_value = MostpracticedTopicsData[0].total_question;
                              // const percent = (MosttopicData.total_question / first_value ) * 100; // percentage
                              //
                              // let color_data = '';
                              // if(index === 0){
                              //   color_data = 'bg-primary'
                              // }else if(index === 1){
                              //   color_data = 'pgb-dk-purple'
                              // }else if(index === 2){
                              //   color_data = 'bg-warning'
                              // }else if(index === 3){
                              //   color_data = 'bg-success'
                              // }else if(index === 4){
                              //   color_data = 'bg-info'
                              // }else if(index === 5){
                              //   color_data = 'bg-secondary'
                              // }
                              return (
                                <div className="ucs-hz-list" key={index}>
                                  <Link
                                    to={
                                      UpcommingSessionData &&
                                      parseInt(
                                        UpcommingSessionData.attendance_status
                                      ) === 6
                                        ? "#"
                                        : "/lesson-plan/" +
                                          UpcommingSessionData.pk_ses_key
                                    }
                                  >
                                    <div
                                      className={
                                        UpcommingSessionData &&
                                        UpcommingSessionData.subject_id
                                          ? parseInt(
                                              UpcommingSessionData.subject_id
                                            ) === 1
                                            ? "badge bg-info font-size-14 p-2 mb-3"
                                            : "badge bg-success font-size-14 p-2 mb-3"
                                          : "badge bg-info font-size-14 p-2 mb-3"
                                      }
                                    >
                                      {UpcommingSessionData &&
                                      UpcommingSessionData.subject_name
                                        ? UpcommingSessionData.subject_name
                                        : null}
                                    </div>

                                    <p className="fw-bold font-size-16 mb-2 opacity-75">
                                      {UpcommingSessionData &&
                                      UpcommingSessionData.ses_date
                                        ? moment(
                                            UpcommingSessionData.ses_date,
                                            moment.ISO_8601
                                          ).format("D MMM, YYYY")
                                        : "-"}
                                    </p>
                                    <h4 className="fw-bold mb-1 font-size-24">
                                      {" "}
                                      {UpcommingSessionData &&
                                      UpcommingSessionData.ses_date
                                        ? moment(
                                            UpcommingSessionData.ses_date,
                                            moment.ISO_8601
                                          ).format("dddd")
                                        : "-"}
                                    </h4>
                                    <h4 className="fw-bold mb-3 font-size-24">
                                      {UpcommingSessionData &&
                                      UpcommingSessionData.ses_start_time
                                        ? moment(
                                            UpcommingSessionData.ses_start_time,
                                            "HH:mm:ss"
                                          ).format("hh:mm A")
                                        : "-"}{" "}
                                      -{" "}
                                      {UpcommingSessionData &&
                                      UpcommingSessionData.ses_end_time
                                        ? moment(
                                            UpcommingSessionData.ses_end_time,
                                            "HH:mm:ss"
                                          ).format("hh:mm A")
                                        : "-"}
                                    </h4>
                                    <div className="d-flex mb-3">
                                      <div className="font-size-16 me-3">
                                        <img
                                          width={24}
                                          className="me-2"
                                          src={home}
                                          alt=""
                                        />
                                        {UpcommingSessionData &&
                                        UpcommingSessionData.cent_name
                                          ? UpcommingSessionData.cent_name
                                          : null}
                                      </div>
                                      <div className="font-size-16">
                                        <img
                                          width={28}
                                          height={28}
                                          className="rounded-circle obj-fit-cover me-2"
                                          src={us1}
                                          alt=""
                                        />
                                        {UpcommingSessionData &&
                                        UpcommingSessionData.tut_fname &&
                                        UpcommingSessionData.tut_fname != ""
                                          ? UpcommingSessionData.tut_fname +
                                            " " +
                                            UpcommingSessionData.tut_surname
                                          : null}
                                      </div>
                                    </div>
                                    {UpcommingSessionData &&
                                    parseInt(
                                      UpcommingSessionData.ses_is_flexi
                                    ) === 1 &&
                                    UpcommingSessionData.attendance_status !=
                                      null &&
                                    parseInt(
                                      UpcommingSessionData.attendance_status
                                    ) !== 6 ? (
                                      <div className="ucs-status ucs-missed w-100 position-relative">
                                        Flexi Class
                                      </div>
                                    ) : UpcommingSessionData &&
                                      UpcommingSessionData.attendance_status &&
                                      parseInt(
                                        UpcommingSessionData.attendance_status
                                      ) === 1 &&
                                      parseInt(
                                        UpcommingSessionData.ses_status
                                      ) === 1 ? (
                                      <div className="ucs-status ucs-ongoing w-100 position-relative">
                                        Ongoing
                                      </div>
                                    ) : UpcommingSessionData &&
                                      parseInt(
                                        UpcommingSessionData.attendance_status
                                      ) === 0 &&
                                      parseInt(
                                        UpcommingSessionData.ses_status
                                      ) === 1 ? (
                                      <div className="ucs-status w-100">
                                        Lesson Started
                                      </div>
                                    ) : UpcommingSessionData &&
                                      parseInt(
                                        UpcommingSessionData.attendance_status
                                      ) === 6 ? (
                                      <div className="ucs-status ucs-missed w-100 position-relative">
                                        Cancelled
                                      </div>
                                    ) : (
                                      <div className="ucs-status w-100">
                                        Not started yet
                                      </div>
                                    )}
                                  </Link>
                                </div>
                              )
                            }
                          )
                        ) : (
                          <div className="">
                            <h5 className="text-dark opacity-75 my-3 ms-3">
                              No Classes{" "}
                            </h5>
                          </div>
                        )}
                      </Carousel>
                    ) : (
                      <Carousel responsive={responsive}>
                        {UpcommingSessionToday &&
                        UpcommingSessionToday.length > 0 ? (
                          UpcommingSessionToday.map(
                            (UpcommingSessionData, index) => {
                              // const first_value = MostpracticedTopicsData[0].total_question;
                              // const percent = (MosttopicData.total_question / first_value ) * 100; // percentage
                              //
                              // let color_data = '';
                              // if(index === 0){
                              //   color_data = 'bg-primary'
                              // }else if(index === 1){
                              //   color_data = 'pgb-dk-purple'
                              // }else if(index === 2){
                              //   color_data = 'bg-warning'
                              // }else if(index === 3){
                              //   color_data = 'bg-success'
                              // }else if(index === 4){
                              //   color_data = 'bg-info'
                              // }else if(index === 5){
                              //   color_data = 'bg-secondary'
                              // }
                              return (
                                <div className="ucs-hz-list" key={index}>
                                  <Link
                                    to={
                                      UpcommingSessionData &&
                                      parseInt(
                                        UpcommingSessionData.attendance_status
                                      ) === 6
                                        ? "#"
                                        : "/lesson-plan/" +
                                          UpcommingSessionData.pk_ses_key
                                    }
                                  >
                                    <div
                                      className={
                                        UpcommingSessionData &&
                                        UpcommingSessionData.subject_id
                                          ? parseInt(
                                              UpcommingSessionData.subject_id
                                            ) === 1
                                            ? "badge bg-info font-size-14 p-2 mb-3"
                                            : "badge bg-success font-size-14 p-2 mb-3"
                                          : "badge bg-info font-size-14 p-2 mb-3"
                                      }
                                    >
                                      {UpcommingSessionData &&
                                      UpcommingSessionData.subject_name
                                        ? UpcommingSessionData.subject_name
                                        : null}
                                    </div>

                                    <p className="fw-bold font-size-16 mb-2 opacity-75">
                                      {UpcommingSessionData &&
                                      UpcommingSessionData.ses_date
                                        ? moment(
                                            UpcommingSessionData.ses_date,
                                            moment.ISO_8601
                                          ).format("D MMM, YYYY")
                                        : "-"}
                                    </p>
                                    <h4 className="fw-bold mb-1 font-size-24">
                                      {" "}
                                      {UpcommingSessionData &&
                                      UpcommingSessionData.ses_date
                                        ? moment(
                                            UpcommingSessionData.ses_date,
                                            moment.ISO_8601
                                          ).format("dddd")
                                        : "-"}
                                    </h4>
                                    <h4 className="fw-bold mb-3 font-size-24">
                                      {UpcommingSessionData &&
                                      UpcommingSessionData.ses_start_time
                                        ? moment(
                                            UpcommingSessionData.ses_start_time,
                                            "HH:mm:ss"
                                          ).format("hh:mm A")
                                        : "-"}{" "}
                                      -{" "}
                                      {UpcommingSessionData &&
                                      UpcommingSessionData.ses_end_time
                                        ? moment(
                                            UpcommingSessionData.ses_end_time,
                                            "HH:mm:ss"
                                          ).format("hh:mm A")
                                        : "-"}
                                    </h4>
                                    <div className="d-flex mb-3">
                                      <div className="font-size-16 me-3">
                                        <img
                                          width={24}
                                          className="me-2"
                                          src={home}
                                          alt=""
                                        />
                                        {UpcommingSessionData &&
                                        UpcommingSessionData.cent_name
                                          ? UpcommingSessionData.cent_name
                                          : null}
                                      </div>
                                      <div className="font-size-16">
                                        <img
                                          width={28}
                                          height={28}
                                          className="rounded-circle obj-fit-cover me-2"
                                          src={us1}
                                          alt=""
                                        />
                                        {UpcommingSessionData &&
                                        UpcommingSessionData.tut_fname &&
                                        UpcommingSessionData.tut_fname != ""
                                          ? UpcommingSessionData.tut_fname +
                                            " " +
                                            UpcommingSessionData.tut_surname
                                          : null}
                                      </div>
                                    </div>
                                    {UpcommingSessionData &&
                                    parseInt(
                                      UpcommingSessionData.ses_is_flexi
                                    ) === 1 &&
                                    UpcommingSessionData.attendance_status !=
                                      null &&
                                    parseInt(
                                      UpcommingSessionData.attendance_status
                                    ) !== 6 ? (
                                      <div className="ucs-status ucs-missed w-100 position-relative">
                                        Flexi Class
                                      </div>
                                    ) : UpcommingSessionData &&
                                      UpcommingSessionData.attendance_status &&
                                      parseInt(
                                        UpcommingSessionData.attendance_status
                                      ) === 1 &&
                                      parseInt(
                                        UpcommingSessionData.ses_status
                                      ) === 1 ? (
                                      <div className="ucs-status ucs-ongoing w-100 position-relative">
                                        Ongoing
                                      </div>
                                    ) : UpcommingSessionData &&
                                      parseInt(
                                        UpcommingSessionData.attendance_status
                                      ) === 0 &&
                                      parseInt(
                                        UpcommingSessionData.ses_status
                                      ) === 1 ? (
                                      <div className="ucs-status w-100">
                                        Lesson Started
                                      </div>
                                    ) : UpcommingSessionData &&
                                      parseInt(
                                        UpcommingSessionData.attendance_status
                                      ) === 6 ? (
                                      <div className="ucs-status ucs-missed w-100 position-relative">
                                        Cancelled
                                      </div>
                                    ) : (
                                      <div className="ucs-status w-100">
                                        Not started yet
                                      </div>
                                    )}
                                  </Link>
                                </div>
                              )
                            }
                          )
                        ) : (
                          <div className="">
                            <h5 className="text-dark opacity-75 my-3 ms-3">
                              No Classes Today{" "}
                            </h5>
                          </div>
                        )}
                      </Carousel>
                    )}
                  </div>
                  <div className="pd-homework-section">
                    <div className="d-flex align-items-start justify-content-between">
                      <h5 className="mb-4 text-dark fw-bold font-size-24">
                        Pending Homework{" "}
                        <span>
                          (
                          {HomeWorkSession.length > 0
                            ? HomeWorkSession[0].total_count
                            : 0}
                          )
                        </span>
                      </h5>
                      <Link
                        to={"homework-list"}
                        className="btn custom-btn-green"
                      >
                        VIEW ALL HOMEWORK
                      </Link>
                    </div>

                    <div className="hw-list mt-4">
                      <ul className="list-unstyled mb-0">
                        {HomeWorkSession.length > 0 ? (
                          HomeWorkSession.map((activity, index1) => {
                            {
                            }
                            if (parseInt(activity.marked_for_homework) === 1) {
                              if (
                                activity.activity_type === 0 &&
                                activity.fk_sub_id === 2
                              ) {
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                let percentage = 0
                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                if (
                                  total_question >= count_answer &&
                                  total_question != 0
                                ) {
                                  percentage =
                                    (count_answer / total_question) * 100
                                }

                                return (
                                  <li
                                    className="d-flex justify-content-between"
                                    key={index1}
                                  >
                                    {/* <li> */}

                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="hw-icon">
                                        <img
                                          width={24}
                                          className=""
                                          src={hw1}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p className="text-warning mb-2 fw-bold font-size-12">
                                          ASSESSMENT
                                        </p>
                                        <h4 className="fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0]
                                                .node_name
                                            : null}
                                        </h4>
                                        <div className="ft-util d-flex">
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={duration}
                                              alt=""
                                            />
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            Minutes
                                          </div>
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins} Coins
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {percentage !== 0 ? (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            assessementActivity(activity)
                                          }
                                        >
                                          RESUME
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            assessementActivity(activity)
                                          }
                                        >
                                          START
                                        </Button>
                                      </div>
                                    )}
                                    <div className="ph_list-progressbar">
                                      <Progress
                                        color="success"
                                        value={
                                          percentage !== 0 ? percentage : 0
                                        }
                                      />
                                    </div>

                                    {/* </li> */}
                                  </li>
                                )
                              } else if (
                                activity.activity_type === 0 &&
                                activity.fk_sub_id === 1
                              ) {
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                let percentage = 0
                                let manual_mark = 0
                                let time_editing_category = 0
                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.english_question &&
                                        activityQuestion.english_question
                                          .length > 0
                                      ) {
                                        let filteredManualMark =
                                          activityQuestion.english_question.filter(
                                            manualMark =>
                                              parseInt(
                                                manualMark.english_question_part_automark
                                              ) === 0
                                          )
                                        if (filteredManualMark.length > 0) {
                                          manual_mark += 1
                                        }
                                        activityQuestion.english_question.forEach(
                                          (question, index3) => {
                                            if (
                                              parseInt(
                                                question.english_question_category_id
                                              ) === 10 ||
                                              parseInt(
                                                question.english_question_category_id
                                              ) === 13 ||
                                              parseInt(
                                                question.english_question_category_id
                                              ) === 12 ||
                                              parseInt(
                                                question.english_question_category_id
                                              ) === 8
                                            ) {
                                              if (
                                                index3 === 0 &&
                                                question.english_question_time_needed ===
                                                  "Less than 2 minutes"
                                              ) {
                                                time_editing_category += 2
                                              } else if (
                                                index3 === 0 &&
                                                question.english_question_time_needed ===
                                                  "2-5 minutes"
                                              ) {
                                                time_editing_category += 5
                                              } else if (
                                                index3 === 0 &&
                                                question.english_question_time_needed ===
                                                  "5-10 minutes"
                                              ) {
                                                time_editing_category += 10
                                              } else if (
                                                index3 === 0 &&
                                                question.english_question_time_needed ===
                                                  "10-15 minutes"
                                              ) {
                                                time_editing_category += 15
                                              } else if (
                                                index3 === 0 &&
                                                question.english_question_time_needed ===
                                                  "15-20 minutes"
                                              ) {
                                                time_editing_category += 20
                                              } else if (
                                                index3 === 0 &&
                                                question.english_question_time_needed ===
                                                  "20-30 minutes"
                                              ) {
                                                time_editing_category += 30
                                              } else if (
                                                index3 === 0 &&
                                                question.english_question_time_needed ===
                                                  "30-60 minutes"
                                              ) {
                                                time_editing_category += 60
                                              }
                                            } else {
                                              time_editing_category =
                                                activity.total_activity_time
                                            }

                                            if (
                                              parseInt(
                                                question.english_question_category_id
                                              ) === 10 ||
                                              parseInt(
                                                question.english_question_category_id
                                              ) === 13
                                            ) {
                                              question.english_question_answers
                                                .length > 0 &&
                                              question
                                                .english_question_answers[0]
                                                .english_answer_text &&
                                              question
                                                .english_question_answers[0]
                                                .english_answer_text != ""
                                                ? ((total_question += 1),
                                                  (total_coins += 2))
                                                : null
                                            } else {
                                              total_question += 1
                                              total_coins += 2
                                            }
                                            // total_question += 1
                                            // total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                if (
                                  total_question >= count_answer &&
                                  total_question != 0
                                ) {
                                  percentage =
                                    (count_answer / total_question) * 100
                                }

                                return (
                                  <li
                                    className="d-flex justify-content-between"
                                    key={index1}
                                  >
                                    {/* <li> */}

                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="hw-icon">
                                        <img
                                          width={24}
                                          className=""
                                          src={hw1}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p className="text-warning mb-2 fw-bold font-size-12">
                                          ASSESSMENT
                                        </p>
                                        <h4 className="fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0]
                                                .node_name
                                            : null}
                                        </h4>
                                        <div className="ft-util d-flex">
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={duration}
                                              alt=""
                                            />
                                            {time_editing_category
                                              ? time_editing_category
                                              : 0}{" "}
                                            Minutes
                                          </div>
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins} Coins
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {percentage !== 0 ? (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            assessementActivity(activity)
                                          }
                                        >
                                          RESUME
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            assessementActivity(activity)
                                          }
                                        >
                                          START
                                        </Button>
                                      </div>
                                    )}
                                    <div className="ph_list-progressbar">
                                      <Progress
                                        color="success"
                                        value={
                                          percentage !== 0 ? percentage : 0
                                        }
                                      />
                                    </div>

                                    {/* </li> */}
                                  </li>
                                )
                              } else if (activity.activity_type === 1) {
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                let percentage = 0
                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                if (
                                  total_question >= count_answer &&
                                  total_question != 0
                                ) {
                                  percentage =
                                    (count_answer / total_question) * 100
                                }

                                return (
                                  <li
                                    className="d-flex justify-content-between"
                                    key={index1}
                                  >
                                    {/* <li> */}

                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="hw-icon">
                                        <img
                                          width={24}
                                          className=""
                                          src={hw2}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p className="text-warning mb-2 fw-bold font-size-12">
                                          VIDEO
                                        </p>
                                        <h4 className="fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h4>
                                        <div className="ft-util d-flex">
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={duration}
                                              alt=""
                                            />
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            Minutes
                                          </div>
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins} Coins
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {percentage !== 0 ? (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            VideoActivity(activity)
                                          }
                                        >
                                          RESUME
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            VideoActivity(activity)
                                          }
                                        >
                                          START
                                        </Button>
                                      </div>
                                    )}
                                    <div className="ph_list-progressbar">
                                      <Progress
                                        color="success"
                                        value={
                                          percentage !== 0 ? percentage : 0
                                        }
                                      />
                                    </div>

                                    {/* </li> */}
                                  </li>
                                )
                              } else if (
                                activity.activity_type === 2 &&
                                activity.fk_sub_id === 1
                              ) {
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                let percentage = 0
                                let manual_mark = 0
                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.english_question &&
                                        activityQuestion.english_question
                                          .length > 0
                                      ) {
                                        let filteredManualMark = []
                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        //   if(filteredManualMark.length > 0){
                                        //     manual_mark += 1;
                                        //   }
                                        activityQuestion.english_question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                if (
                                  total_question >= count_answer &&
                                  total_question != 0
                                ) {
                                  percentage =
                                    (count_answer / total_question) * 100
                                }

                                return (
                                  <li
                                    className="d-flex justify-content-between"
                                    key={index1}
                                  >
                                    {/* <li> */}

                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="hw-icon">
                                        <img
                                          width={24}
                                          className=""
                                          src={hw5}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p className="text-warning mb-2 fw-bold font-size-12">
                                          DIAGNOSTICS
                                        </p>
                                        <h4 className="fw-bold font-size-24">
                                          {activity.topics &&
                                          activity.topics.length > 0
                                            ? getUniqueTopicsNames(
                                                activity.topics
                                              )
                                            : null}
                                        </h4>
                                        <div className="ft-util d-flex">
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={duration}
                                              alt=""
                                            />
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            Minutes
                                          </div>
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins} Coins
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {percentage !== 0 ? (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            diagnosticActivity(activity)
                                          }
                                        >
                                          RESUME
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            diagnosticActivity(activity)
                                          }
                                        >
                                          START
                                        </Button>
                                      </div>
                                    )}
                                    <div className="ph_list-progressbar">
                                      <Progress
                                        color="success"
                                        value={
                                          percentage !== 0 ? percentage : 0
                                        }
                                      />
                                    </div>

                                    {/* </li> */}
                                  </li>
                                )
                              } else if (
                                activity.activity_type === 2 &&
                                activity.fk_sub_id === 2
                              ) {
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                let percentage = 0
                                let manual_mark = 0
                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []
                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        //   if(filteredManualMark.length > 0){
                                        //     manual_mark += 1;
                                        //   }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                if (
                                  total_question >= count_answer &&
                                  total_question != 0
                                ) {
                                  percentage =
                                    (count_answer / total_question) * 100
                                }

                                return (
                                  <li
                                    className="d-flex justify-content-between"
                                    key={index1}
                                  >
                                    {/* <li> */}

                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="hw-icon">
                                        <img
                                          width={24}
                                          className=""
                                          src={hw5}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p className="text-warning mb-2 fw-bold font-size-12">
                                          DIAGNOSTICS
                                        </p>
                                        <h4 className="fw-bold font-size-24">
                                          {activity.topics &&
                                          activity.topics.length > 0
                                            ? getUniqueTopicsNames(
                                                activity.topics
                                              )
                                            : null}
                                        </h4>
                                        <div className="ft-util d-flex">
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={duration}
                                              alt=""
                                            />
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            Minutes
                                          </div>
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins} Coins
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {percentage !== 0 ? (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            diagnosticActivity(activity)
                                          }
                                        >
                                          RESUME
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            diagnosticActivity(activity)
                                          }
                                        >
                                          START
                                        </Button>
                                      </div>
                                    )}
                                    <div className="ph_list-progressbar">
                                      <Progress
                                        color="success"
                                        value={
                                          percentage !== 0 ? percentage : 0
                                        }
                                      />
                                    </div>

                                    {/* </li> */}
                                  </li>
                                )
                              } else if (activity.activity_type === 3) {
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                let percentage = 0
                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                if (
                                  total_question >= count_answer &&
                                  total_question != 0
                                ) {
                                  percentage =
                                    (count_answer / total_question) * 100
                                }

                                return (
                                  <li
                                    className="d-flex justify-content-between"
                                    key={index1}
                                  >
                                    {/* <li> */}

                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="hw-icon">
                                        <img
                                          width={24}
                                          className=""
                                          src={hw2}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p className="text-warning mb-2 fw-bold font-size-12">
                                          LINK
                                        </p>
                                        <h4 className="fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h4>
                                        <div className="ft-util d-flex">
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={duration}
                                              alt=""
                                            />
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            Minutes
                                          </div>
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins} Coins
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {percentage !== 0 ? (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() => LinkActivity(activity)}
                                        >
                                          RESUME
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() => LinkActivity(activity)}
                                        >
                                          START
                                        </Button>
                                      </div>
                                    )}
                                    <div className="ph_list-progressbar">
                                      <Progress
                                        color="success"
                                        value={
                                          percentage !== 0 ? percentage : 0
                                        }
                                      />
                                    </div>

                                    {/* </li> */}
                                  </li>
                                )
                              } else if (activity.activity_type === 4) {
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                let percentage = 0
                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                if (
                                  total_question >= count_answer &&
                                  total_question != 0
                                ) {
                                  percentage =
                                    (count_answer / total_question) * 100
                                }
                                return (
                                  <li
                                    className="d-flex justify-content-between"
                                    key={index1}
                                  >
                                    {/* <li> */}

                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="hw-icon">
                                        <img
                                          width={24}
                                          className=""
                                          src={hw2}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p className="text-warning mb-2 fw-bold font-size-12">
                                          TASK
                                        </p>
                                        <h4 className="fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h4>
                                        <div className="ft-util d-flex">
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={duration}
                                              alt=""
                                            />
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            Minutes
                                          </div>
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins} Coins
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {percentage !== 0 ? (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() => TaskActivity(activity)}
                                        >
                                          RESUME
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() => TaskActivity(activity)}
                                        >
                                          START
                                        </Button>
                                      </div>
                                    )}
                                    <div className="ph_list-progressbar">
                                      <Progress
                                        color="success"
                                        value={
                                          percentage !== 0 ? percentage : 0
                                        }
                                      />
                                    </div>

                                    {/* </li> */}
                                  </li>
                                )
                              } else if (activity.activity_type === 5) {
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                let percentage = 0
                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      total_question += 1
                                      if (
                                        activityQuestion.StudentAnsAttempts &&
                                        activityQuestion.StudentAnsAttempts
                                          .length > 0
                                      ) {
                                        activityQuestion.StudentAnsAttempts.forEach(
                                          stuAnswer => {
                                            total_time += parseInt(
                                              stuAnswer.submitted_on
                                            )
                                          }
                                        )
                                        count_answer += 1 // Increment count_answer by 1 for each occurrence
                                      }
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_coins += 2
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                if (
                                  total_question >= count_answer &&
                                  total_question != 0
                                ) {
                                  percentage =
                                    (count_answer / total_question) * 100
                                }
                                return (
                                  <li
                                    className="d-flex justify-content-between"
                                    key={index1}
                                  >
                                    {/* <li> */}

                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="hw-icon">
                                        <img
                                          width={24}
                                          className=""
                                          src={hw2}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p className="text-warning mb-2 fw-bold font-size-12">
                                          WORKSHEET
                                        </p>
                                        <h4 className="fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h4>
                                        <div className="ft-util d-flex">
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={duration}
                                              alt=""
                                            />
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            Minutes
                                          </div>
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins} Coins
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {percentage !== 0 ? (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            WorksheetActivity(activity)
                                          }
                                        >
                                          RESUME
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            WorksheetActivity(activity)
                                          }
                                        >
                                          START
                                        </Button>
                                      </div>
                                    )}
                                    <div className="ph_list-progressbar">
                                      <Progress
                                        color="success"
                                        value={
                                          percentage !== 0 ? percentage : 0
                                        }
                                      />
                                    </div>

                                    {/* </li> */}
                                  </li>
                                )
                              } else if (
                                activity.activity_type === 6 &&
                                (activity.fk_sub_id === 1 ||
                                  activity.fk_sub_id === 2)
                              ) {
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                let percentage = 0
                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                if (
                                  total_question >= count_answer &&
                                  total_question != 0
                                ) {
                                  percentage =
                                    (count_answer / total_question) * 100
                                }

                                return (
                                  <li
                                    className="d-flex justify-content-between"
                                    key={index1}
                                  >
                                    {/* <li> */}

                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="hw-icon">
                                        <img
                                          width={24}
                                          className=""
                                          src={hw2}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p className="text-warning mb-2 fw-bold font-size-12">
                                          VIDEO
                                        </p>
                                        <h4 className="fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0]
                                            .video_name != ""
                                            ? activity.activity_node[0]
                                                .video_name
                                            : null}
                                        </h4>
                                        <div className="ft-util d-flex">
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={duration}
                                              alt=""
                                            />
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            Minutes
                                          </div>
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins} Coins
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {percentage !== 0 ? (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            VideoActivity(activity)
                                          }
                                        >
                                          RESUME
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            VideoActivity(activity)
                                          }
                                        >
                                          START
                                        </Button>
                                      </div>
                                    )}
                                    <div className="ph_list-progressbar">
                                      <Progress
                                        color="success"
                                        value={
                                          percentage !== 0 ? percentage : 0
                                        }
                                      />
                                    </div>

                                    {/* </li> */}
                                  </li>
                                )
                              } else if (
                                activity.activity_type === 7 &&
                                (activity.fk_sub_id === 1 ||
                                  activity.fk_sub_id === 2)
                              ) {
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                let percentage = 0
                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      total_question += 1
                                      if (
                                        activityQuestion.StudentAnsAttempts &&
                                        activityQuestion.StudentAnsAttempts
                                          .length > 0
                                      ) {
                                        activityQuestion.StudentAnsAttempts.forEach(
                                          stuAnswer => {
                                            total_time += parseInt(
                                              stuAnswer.submitted_on
                                            )
                                          }
                                        )
                                        count_answer += 1 // Increment count_answer by 1 for each occurrence
                                      }

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_coins += 2
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                if (
                                  total_question >= count_answer &&
                                  total_question != 0
                                ) {
                                  percentage =
                                    (count_answer / total_question) * 100
                                }

                                return (
                                  <li
                                    className="d-flex justify-content-between"
                                    key={index1}
                                  >
                                    {/* <li> */}

                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="hw-icon">
                                        <img
                                          width={24}
                                          className=""
                                          src={hw5}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p className="text-warning mb-2 fw-bold font-size-12">
                                          WORKSHEET
                                        </p>
                                        <h4 className="fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0]
                                                .node_name
                                            : null}
                                        </h4>
                                        <div className="ft-util d-flex">
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={duration}
                                              alt=""
                                            />
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            Minutes
                                          </div>
                                          <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {" "}
                                            <img
                                              width={24}
                                              className="me-2"
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins} Coins
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {percentage !== 0 ? (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            WorksheetActivity(activity)
                                          }
                                        >
                                          RESUME
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="hw-status">
                                        <Button
                                          className="btn btn-danger cs-btn-danger"
                                          onClick={() =>
                                            WorksheetActivity(activity)
                                          }
                                        >
                                          START
                                        </Button>
                                      </div>
                                    )}
                                    <div className="ph_list-progressbar">
                                      <Progress
                                        color="success"
                                        value={
                                          percentage !== 0 ? percentage : 0
                                        }
                                      />
                                    </div>

                                    {/* </li> */}
                                  </li>
                                )
                              } else {
                                return null
                              }
                            } else {
                              return null
                            }
                          })
                        ) : (
                          <h5 className="text-dark opacity-75 my-3 ms-3">
                            No Pending Homework
                          </h5>
                        )}
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="page-header mb-5">
              <h5 className="mb-4 text-dark fw-bold font-size-24">Overview</h5>
            </div>
            <div className="db-utlities">
              <Row className="g-4 pb-2">
                <Col xl={6}>
                  <Card>
                    <CardBody>
                      <div className="card-heading">
                        <div>
                          <h4>Practice these Topics More</h4>
                          <p>Get Higher Mastery Scores!</p>
                        </div>
                        <div>
                          <Link
                            to={"/mastery-scores"}
                            className="text-blue fw-semibold"
                          >
                            VIEW ALL MASTERY SCORES
                          </Link>
                        </div>
                      </div>
                      <small className="d-block text-end mb-1 font-size-12">
                        Mastery Score
                      </small>

                      <div className="section-progress border-top">
                        {practiceTopicsMoreData &&
                        practiceTopicsMoreData.length > 0 ? (
                          practiceTopicsMoreData.map(topicData => (
                            <div className="progress-child position-relative border-bottom ">
                              <Progress
                                value={
                                  topicData.adjusted_mastery_score &&
                                  topicData.adjusted_mastery_score !== null
                                    ? parseInt(topicData.adjusted_mastery_score)
                                    : 0
                                }
                                color={
                                  parseInt(topicData.adjusted_mastery_score) >
                                  70
                                    ? "pgb-success"
                                    : parseInt(
                                        topicData.adjusted_mastery_score
                                      ) <= 70 &&
                                      parseInt(
                                        topicData.adjusted_mastery_score
                                      ) > 0
                                    ? "pgb-warning"
                                    : "pgb-unset"
                                }
                                className="custom-pb"
                              ></Progress>
                              <div className="d-flex text-dark justify-content-between pg-status font-size-16">
                                <span className="fw-bold">
                                  {topicData &&
                                  topicData.topic_name &&
                                  topicData.topic_name != ""
                                    ? topicData.topic_name
                                    : null}
                                </span>
                                <span className="fw-bold">
                                  {/* <img className="rounded-circle obj-fit-cover" width={14} src={trophy} alt="" />{" "} */}
                                  {topicData.adjusted_mastery_score &&
                                  parseInt(topicData.adjusted_mastery_score) >
                                    70 ? (
                                    <img
                                      className="rounded-circle obj-fit-cover"
                                      width={14}
                                      src={trophy}
                                      alt=""
                                    />
                                  ) : parseInt(
                                      topicData.adjusted_mastery_score
                                    ) <= 70 &&
                                    parseInt(topicData.adjusted_mastery_score) >
                                      50 ? null : null}{" "}
                                  {topicData &&
                                  topicData.adjusted_mastery_score &&
                                  topicData.adjusted_mastery_score != ""
                                    ? parseInt(topicData.adjusted_mastery_score)
                                    : 0}
                                </span>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="text-center">No data yet</p>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={6}>
                  <Card>
                    <CardBody>
                      <div className="card-heading">
                        <div>
                          <h4>Topics I'm Strongest In</h4>
                          <p>All Time</p>
                        </div>
                        <div>
                          <Link
                            to={"/mastery-scores"}
                            className="text-blue fw-semibold"
                          >
                            VIEW ALL MASTERY SCORES
                          </Link>
                        </div>
                      </div>
                      <small className="d-block text-end mb-1 font-size-12">
                        Mastery Score
                      </small>

                      <div className="section-progress border-top">
                        {topicsStrongestData &&
                        topicsStrongestData.length > 0 ? (
                          topicsStrongestData.map(topicData => (
                            <div className="progress-child position-relative border-bottom ">
                              <Progress
                                value={
                                  topicData.adjusted_mastery_score &&
                                  topicData.adjusted_mastery_score !== null
                                    ? parseInt(topicData.adjusted_mastery_score)
                                    : 0
                                }
                                color={
                                  parseInt(topicData.adjusted_mastery_score) >
                                  70
                                    ? "pgb-success"
                                    : parseInt(
                                        topicData.adjusted_mastery_score
                                      ) <= 70 &&
                                      parseInt(
                                        topicData.adjusted_mastery_score
                                      ) > 0
                                    ? "pgb-warning"
                                    : "pgb-unset"
                                }
                                className="custom-pb"
                              ></Progress>
                              <div className="d-flex text-dark justify-content-between pg-status font-size-16">
                                <span className="fw-bold">
                                  {topicData &&
                                  topicData.topic_name &&
                                  topicData.topic_name != ""
                                    ? topicData.topic_name
                                    : null}
                                </span>
                                <span className="fw-bold">
                                  {/* <img className="rounded-circle obj-fit-cover" width={14} src={trophy} alt="" />{" "} */}
                                  {topicData.adjusted_mastery_score &&
                                  parseInt(topicData.adjusted_mastery_score) >
                                    70 ? (
                                    <img
                                      className="rounded-circle obj-fit-cover"
                                      width={14}
                                      src={trophy}
                                      alt=""
                                    />
                                  ) : parseInt(
                                      topicData.adjusted_mastery_score
                                    ) <= 70 &&
                                    parseInt(topicData.adjusted_mastery_score) >
                                      50 ? null : null}{" "}
                                  {topicData &&
                                  topicData.adjusted_mastery_score &&
                                  topicData.adjusted_mastery_score != ""
                                    ? parseInt(topicData.adjusted_mastery_score)
                                    : 0}
                                </span>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="text-center">No data yet</p>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={3}>
                  <Card className="cg-height">
                    <CardBody>
                      <div className="card-heading mb-3">
                        <div>
                          <h4>Most Practiced Topics</h4>
                          <p>
                            In the <strong>Past Month</strong>
                          </p>
                        </div>
                      </div>
                      <div className="section-progress">
                        {MostpracticedTopicsData &&
                        MostpracticedTopicsData.length > 0 ? (
                          MostpracticedTopicsData.map(
                            (MosttopicData, index) => {
                              const first_value =
                                MostpracticedTopicsData[0].total_question
                              const percent =
                                (MosttopicData.total_question / first_value) *
                                100 // percentage

                              let color_data = ""
                              if (index === 0) {
                                color_data = "bg-primary"
                              } else if (index === 1) {
                                color_data = "pgb-dk-purple"
                              } else if (index === 2) {
                                color_data = "bg-warning"
                              } else if (index === 3) {
                                color_data = "bg-success"
                              } else if (index === 4) {
                                color_data = "bg-info"
                              } else if (index === 5) {
                                color_data = "bg-secondary"
                              }
                              return (
                                <div
                                  key={index}
                                  className="progress-child position-relative mb-2"
                                >
                                  <Progress
                                    value={parseInt(percent)}
                                    color={color_data}
                                    className="custom-pb-child"
                                  ></Progress>
                                  <div className="d-flex text-dark justify-content-end pg-status-ft-2 font-size-12">
                                    <span className="fw-bold">
                                      {MosttopicData.total_question} Qns
                                    </span>{" "}
                                    {/* Displaying percentage */}
                                  </div>
                                </div>
                              )
                            }
                          )
                        ) : (
                          <p className="text-center">No data yet</p>
                        )}
                      </div>

                      <div className="status-denoted mt-3">
                        {MostpracticedTopicsData &&
                        MostpracticedTopicsData.length > 0
                          ? MostpracticedTopicsData.map(
                              (MosttopicData, index) => {
                                const first_value =
                                  MostpracticedTopicsData[0].total_question
                                const percent =
                                  (MosttopicData.total_question / first_value) *
                                  100 // percentage

                                let color_data = ""
                                if (index === 0) {
                                  color_data = "bg-primary me-2 flex-shrink-0"
                                } else if (index === 1) {
                                  color_data =
                                    "pgb-dk-purple me-2 flex-shrink-0"
                                } else if (index === 2) {
                                  color_data = "bg-warning me-2 flex-shrink-0"
                                } else if (index === 3) {
                                  color_data = "bg-success me-2 flex-shrink-0"
                                } else if (index === 4) {
                                  color_data = "bg-info me-2 flex-shrink-0"
                                } else if (index === 5) {
                                  color_data = "bg-secondary me-2 flex-shrink-0"
                                }
                                return (
                                  <div
                                    key={index}
                                    className="d-flex align-items-center"
                                  >
                                    {" "}
                                    <span
                                      style={{
                                        display: "block",
                                        width: "15px",
                                        height: "15px",
                                        borderRadius: "50%",
                                      }}
                                      className={color_data}
                                    ></span>
                                    {MosttopicData.topic_name}
                                  </div>
                                )
                              }
                            )
                          : null}

                        {/* <div className="d-flex align-items-center">
                    {" "}
                    <span className="circle-ppl"></span> Grammar (Nouns)
                  </div> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3}>
                  <Card className="cg-height">
                    <CardBody>
                      <div className="card-heading mb-3">
                        <div>
                          <h4>Accuracy</h4>
                          <p>
                            for the <strong>Past Month</strong>
                          </p>
                        </div>
                      </div>
                      {accuracyData.length > 0 &&
                      parseInt(accuracyData[0].total_question) !== 0 ? (
                        <>
                          <div id="doughnut-chart" className="e-chart">
                            <ReactEcharts
                              style={{ height: "280px" }}
                              option={getOption(accuracyData)}
                            />
                          </div>

                          <div className="the-charter">
                            <h4 className="">{accuracyCorrectPercentData}%</h4>
                          </div>
                        </>
                      ) : (
                        <p className="text-center">No data yet</p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3}>
                  <Card className="cg-height">
                    <CardBody>
                      <div className="card-heading mb-3">
                        <div>
                          <h4>Questions Completed</h4>
                          <p>
                            for the <strong>Past Month</strong>
                          </p>
                        </div>
                      </div>
                      {questionsCompletedData.length > 0 &&
                      parseInt(questionsCompletedData[0].total_questions) !==
                        0 ? (
                        <>
                          {" "}
                          <div id="" className="e-chart">
                            <ReactEcharts
                              style={{ height: "280px" }}
                              option={getquestionOption(questionsCompletedData)}
                            />
                            {/* <Doughnutone /> */}
                          </div>
                          <div className="the-charter sec">
                            <h4 className="">
                              {questionsComCorrectPercentData || 0}%
                            </h4>
                            <p>
                              Qns:{" "}
                              {questionsCompletedData &&
                              questionsCompletedData.length > 0
                                ? questionsCompletedData[0].completed_questions
                                : 0}
                            </p>
                          </div>
                        </>
                      ) : (
                        <p className="text-center">No data yet</p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3}>
                  <Card className="cg-height">
                    <CardBody>
                      <div className="card-heading mb-3">
                        <div>
                          <h4>Activities Completed</h4>
                          <p>
                            for the <strong>Past Month</strong>
                          </p>
                        </div>
                      </div>
                      {activityCompletedData.length > 0 &&
                      parseInt(activityCompletedData[0].total_activity) !==
                        0 ? (
                        <>
                          {" "}
                          <div id="" className="e-chart">
                            {/* <Doughnuttwo /> */}
                            <ReactEcharts
                              style={{ height: "280px" }}
                              option={getactivityOption(activityCompletedData)}
                            />
                          </div>
                          <div className="the-charter sec">
                            <h4 className="">
                              {activityComCorrectPercentData || 0}%
                            </h4>
                            <p>
                              Acts:{" "}
                              {activityCompletedData &&
                              activityCompletedData.length > 0
                                ? activityCompletedData[0].completed_activity
                                : 0}
                            </p>
                          </div>
                        </>
                      ) : (
                        <p className="text-center">No data yet</p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
