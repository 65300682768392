import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Container, Progress, Button } from "reactstrap"

import "react-multi-carousel/lib/styles.css"

import { Link, useNavigate, useLocation } from "react-router-dom"
import classnames from "classnames"
import { SECURITY_KEY, BASE_URL } from "../../global"
// import images

import hw1 from "../../assets/images/services-icon/hw1.svg"
import hw2 from "../../assets/images/services-icon/hw2.svg"
import hw4 from "../../assets/images/services-icon/hw4.svg"
import hw5 from "../../assets/images/services-icon/meter.svg"
import awicon from "../../assets/images/services-icon/coin.png"
import duration from "../../assets/images/services-icon/duration.png"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//i18n
import { withTranslation } from "react-i18next"
//pass request back end
import axios from "axios"
import moment from "moment"
import Loader from "react-spinner-loader"
const HomeworkList = props => {

  const location = useLocation();
 
  const [loader, setLoader] = useState(false)
  const [selectedSubject, setSelectedSubject] = useState([2])

  const [selectedStudentStatus, setSelectedStuStatus] = useState([0])

  //Session start
  const [SessionData, setSessionData] = useState([])
  //Session end

  // const subjectOptions = [
  //   { subject_name: "Mathematics", subject_id: 2 },
  //   { subject_name: "English", subject_id: 1 },
  //   // { subject_name: 'Others', subject_id: 3 }
  // ]

  const [subjectOptions, setSubjectOption] = useState([
    { subject_name: "Mathematics", subject_id: 2 },
    { subject_name: "English", subject_id: 1 },
    // { subject_name: 'Others', subject_id: 3 }
  ])
  useEffect(() => {
    fetchStudEnrollsubject()
  }, [])
   const [isenrolsubjectreceived, setisenrolsubjectreceived] = useState(false)
  const fetchStudEnrollsubject = async () => {
    if (authUser && authUser != "" && authUser != null) {
       setLoader(true)
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "studentHomeWork/GetStudentEnrolledSubjects")
        .then(responseSession => {
          if (responseSession.data.length > 0) {
            setSubjectOption(responseSession.data)
           

            const allSubjectIds = responseSession.data.map(subject => subject.subject_id);
            const subjectFromState = location?.state?.filterState?.subject_id || allSubjectIds || [2];
            setSelectedSubject(subjectFromState);
            const statusFronstate = location?.state?.filterState?.status_id || [0];
            setSelectedStuStatus(statusFronstate);

            //  setSelectedSubject([responseSession.data[0]?.subject_id] || [2]);
             setisenrolsubjectreceived(true);
          } else {
            setSelectedSubject([2]);
            setisenrolsubjectreceived(true);
          }

          // setLoader(false)
        })
        .catch(error => {
          // setLoader(false)
          setisenrolsubjectreceived(true);
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      setisenrolsubjectreceived(true);
      // setLoader(false)
      navigate("/login")
    }
  }
  const authUserSubject = JSON.parse(localStorage.getItem("subject")) || null
  // useEffect(() => {
  //   if (!authUserSubject || authUserSubject.trim() === "") {
  //     setSubjectOption([
  //       { subject_name: "Mathematics", subject_id: 2 },
  //       { subject_name: "English", subject_id: 1 },
  //     ])
  //     if (selectedSubject !== 2) {
  //       setSelectedSubject([2])
  //     }
  //   } else {
  //     let subjectIdsArray
  //     if (authUserSubject.includes(",")) {
  //       subjectIdsArray = authUserSubject.split(",")
  //     } else {
  //       // Handle case when authUserSubject is a single ID without comma
  //       subjectIdsArray = [authUserSubject]
  //     }

  //     if (
  //       subjectIdsArray.length === 2 &&
  //       subjectIdsArray.includes("1") &&
  //       subjectIdsArray.includes("2")
  //     ) {
  //       setSubjectOption([
  //         { subject_name: "Mathematics", subject_id: 2 },
  //         { subject_name: "English", subject_id: 1 },
  //       ])
  //       if (selectedSubject !== 2) {
  //         setSelectedSubject([2])
  //       }
  //     } else if (
  //       subjectIdsArray.length === 1 &&
  //       subjectIdsArray.includes("1")
  //     ) {
  //       setSubjectOption([{ subject_name: "English", subject_id: 1 }])
  //       if (selectedSubject !== 1) {
  //         setSelectedSubject([1])
  //       }
  //     } else if (
  //       subjectIdsArray.length === 1 &&
  //       subjectIdsArray.includes("2")
  //     ) {
  //       setSubjectOption([{ subject_name: "Mathematics", subject_id: 2 }])
  //       if (selectedSubject !== 2) {
  //         setSelectedSubject([2])
  //       }
  //     } else {
  //       setSubjectOption([
  //         { subject_name: "Mathematics", subject_id: 2 },
  //         { subject_name: "English", subject_id: 1 },
  //       ])
  //       if (selectedSubject !== 2) {
  //         setSelectedSubject([2])
  //       }
  //     }
  //   }
  // }, [authUserSubject])

  
  const studentAttenStatusOptions = [
    { attenStatus: "Completed", status_id: 1 },
    { attenStatus: "Incomplete", status_id: 0 },
    // { subject_name: 'Others', subject_id: 3 }
  ]

  const handleSelectSubject = subject => {
    setSelectedSubject(subject)
  }
  // const handleSelectStuStatus = (status) => {
  //   setSelectedStuStatus(status);
  // };

  const handleToggleSelectSubject = subject_id => {
    setSelectedSubject(prevSubject => {
      if (prevSubject.includes(subject_id)) {
        // Remove the status_id from the array if already present
        return prevSubject.filter(id => id !== subject_id)
      } else {
        // Add the status_id to the array if not already present
        return [...prevSubject, subject_id]
      }
    })
    setCurrentPage(1);
  }
  const handleToggleStuStatus = status_id => {
    setSelectedStuStatus(prevStatuses => {

      if (prevStatuses.includes(status_id)) {
        // Remove the status_id from the array if already present
        return prevStatuses.filter(id => id !== status_id)
      } else {
        // Add the status_id to the array if not already present
        return [...prevStatuses, status_id]
      }
    })
    setCurrentPage(1);
   
  }
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    if (isenrolsubjectreceived){
    fetchHomeWorkListData();
  }
  }, [currentPage, selectedSubject, selectedStudentStatus])


  




  const authUser = JSON.parse(localStorage.getItem("authStudentUser")) || null

  const navigate = useNavigate()

  //subject end

  
  const fetchHomeWorkListData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      setLoader(true)
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "studentHomeWork/StudentHomeWorkDeatils", {
          subject_id: selectedSubject,
          status_id: selectedStudentStatus,
          cent_timezone_identifier: localStorage.getItem("cent_timezone_identifier") || "",
          items_per_page: 4,
          page: currentPage
        })
        .then(responseSession => {
          // Assuming the API response is an array of objects with 'id' and 'value' properties
          //console.log(responseMastryScore);

          if (responseSession.data.length > 0) {
            setSessionData(responseSession.data)
          } else {
            setSessionData([])
          }

          setLoader(false)
        })
        .catch(error => {
          setLoader(false)
          // console.log(error.response.data.error)
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      setLoader(false)
      navigate("/login")
    }
  }

  const getUniqueTopicsNames = jsonData => {
    const uniqueTopicsNames = new Set()

    //console.log(jsonData);

    // Check if the necessary data is available
    if (jsonData) {
      // Iterate through student enrollments
      // jsonData.forEach((enrollment) => {
      // Check if the necessary data is available and is an array
      if (jsonData && Array.isArray(jsonData)) {
        // Iterate through enrollment subjects
        jsonData.forEach(subject => {
          subject.forEach(data => {
            if (data && data.topic_name != null) {
              uniqueTopicsNames.add(data.topic_name)
            }
          })
        })
      }
      //});
    }

    // Convert Set to array if needed
    return Array.from(uniqueTopicsNames)
  }

  const filterState = {
    subject_id: selectedSubject || "2", 
    status_id: selectedStudentStatus || "0"
  };
  //redirect Link
  const assessementActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {



        navigate(
          "/assessment/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1",
            {
              state: {
                ...filterState
              },
            }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  const assessementActivitySummary = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
       
        
        navigate(
          "/summary/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1", 
            {
              state: {
                ...filterState
              },
            }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  const diagnosticActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
        navigate(
          "/diagnostics/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1",
            {
              state: {
                ...filterState
              },
            }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  const diagnosticActivitySummary = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
       

        
        navigate(
          "/diagnostic-summary/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1",
            {
              state: {
                ...filterState
              },
            }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  const VideoActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
        navigate(
          "/video/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1",
            {
              state: {
                ...filterState
              },
            }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  const LinkActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
        navigate(
          "/link/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1",
            {
              state: {
                ...filterState
              },
            }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  const TaskActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
        navigate(
          "/task/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1",
            {
              state: {
                ...filterState
              },
            }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }
  const WorksheetActivity = activity => {
    if (
      activity.pk_lesson_activity_key &&
      activity.pk_lesson_activity_key != ""
    ) {
      if (activity.fk_sub_id && activity.fk_sub_id != "") {
        navigate(
          "/worksheet/" +
            activity.pk_lesson_activity_key +
            "?type=" +
            activity.fk_sub_id +
            "&homework=1",
            {
              state: {
                ...filterState
              },
            }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }
  const connectLost = () => {
    toast.error("Connection to server lost! Kindly inform admin", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
  const formatTime = totalSeconds => {
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60
    const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
    return formattedTime
  }


//yogesh

  const handlePageChange = page => {
    setCurrentPage(page);
  
  }


  return (
    <React.Fragment>
      <Loader
        show={loader}
        type="body"
        // stack="vertical"
        // message="Loading Data"
      />
      <ToastContainer autoClose={3000} />
      <div className="page-wrapper list-bg">
        <div className="cs-pg-header bg-dark-blue py-3 pb-5">
          <Container className="custom-wt">
            <div className="">
              <div className="mt-0">
                <Link className="text-white font-size-14" to={"/"}>
                  {" "}
                  <span>
                    <i className=" fas fa-chevron-left me-1"></i>
                  </span>
                  BACK TO HOME
                </Link>
              </div>
              <div className="pg-title">My Homework</div>
            </div>
          </Container>
        </div>
        <Container className="custom-wt">
          <div className="page-inner_section cs-mt p-5 pt-0">
            <div className="page-header mb-5">
              <div className="pg-info d-flex align-items-center">
                <ul className="pg-tab list-unstyled mb-0 d-flex align-items-center">
                  <li>SUBJECT</li>
                  {subjectOptions.map(
                    (
                      subject // Map over each subject in subjectOptions
                    ) => (
                      <li
                        key={subject.subject_id}
                        className={
                          selectedSubject.includes(subject.subject_id)
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handleToggleSelectSubject(subject.subject_id)
                        }
                      >
                        {selectedSubject.includes(subject.subject_id) && (
                          <span>
                            <i className="fas fa-check"></i>
                          </span>
                        )}{" "}
                        {subject.subject_name}
                      </li>
                    )
                  )}
                  {/* {subjectOptions.map((subject) => (// Map over each subject in subjectOptions 
                    <li
                      key={subject} // Set a unique key for each list item
                      className={selectedSubject === subject.subject_id ? "active" : ""} // Set class to "active" if selectedSubject matches the current subject 
                      onClick={() => handleSelectSubject(subject.subject_id)} // Set up click event handler to call handleSelectSubject with the current subject 
                    >
                      {selectedSubject === subject.subject_id && ( // Conditionally render a checkmark if selectedSubject matches the current subject 
                        <span>
                          <i className="fas fa-check"></i>
                        </span>
                      )} {" "} {subject.subject_name}
                    </li>
                  ))} */}
                </ul>
                <ul className="pg-tab list-unstyled mb-0 d-flex align-items-center">
                  <li>STATUS</li>
                  {studentAttenStatusOptions.map(
                    (
                      stuAttend // Map over each subject in subjectOptions
                    ) => (
                      <li
                        key={stuAttend.status_id}
                        className={
                          selectedStudentStatus.includes(stuAttend.status_id)
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handleToggleStuStatus(stuAttend.status_id)
                        }
                      >
                        {selectedStudentStatus.includes(
                          stuAttend.status_id
                        ) && (
                          <span>
                            <i className="fas fa-check"></i>
                          </span>
                        )}{" "}
                        {stuAttend.attenStatus}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>

            <div className="pd-homework-section pb-100">
              <div className="hw-list mt-4 my-homework">
                {SessionData && SessionData.length > 0 ? (
                  SessionData.map((Session, index) => {
                    // const first_value = MostpracticedTopicsData[0].total_question;

                    return (
                      <>
                        <div className="date-wrapper" key={index}>
                          <ul className="list-unstyled mb-0">
                            <li className="d-flex justify-content-between py-3 px-3">
                              <div className="d-flex align-items-center">
                                <div className="">
                                  {Session &&
                                  Session.subject_id &&
                                  Session.subject_name
                                    ? Session.subject_id
                                        .split(",")
                                        .map((subjectId, index) => (
                                          <div
                                            key={subjectId}
                                            className={
                                              parseInt(subjectId) === 1
                                                ? "badge bg-info font-size-16 px-2 py-2 me-3"
                                                : "badge bg-success font-size-16 px-2 py-2 me-3"
                                            }
                                          >
                                            {
                                              Session.subject_name.split(",")[
                                                index
                                              ]
                                            }
                                          </div>
                                        ))
                                    : null}
                                  {/* {subjectOptions.map((subject) => (// Map over each subject in subjectOptions 
                                
                                  selectedSubject.includes(subject.subject_id) && ( parseInt(subject.subject_id) === 2 ?
                                    <div className="badge ms-success font-size-16 px-3 py-2">
                                     {subject.subject_name}
                                    </div> : parseInt(subject.subject_id) === 1 ?
                                    <div className="badge bg-info font-size-16 px-2 py-2">
                                    {subject.subject_name}
                                    </div> :
                                    <div className="badge bg-info font-size-16 px-2 py-2">
                                    {subject.subject_name}
                                    </div>
                                    ) 
                                
                                ))} */}
                                </div>
                                <div className="d-flex align-items-center">
                                  <p className="mb-0 me-3 fw-bold font-size-18 text-dark">
                                    {Session && Session.ses_date
                                      ? moment(
                                          Session.ses_date,
                                          moment.ISO_8601
                                        ).format("D MMM")
                                      : "-"}
                                  </p>
                                  <p className="mb-0 me-3 font-size-18 text-dark">
                                    {Session && Session.ses_start_time
                                      ? moment(
                                          Session.ses_start_time,
                                          "HH:mm:ss"
                                        ).format("hh:mm A")
                                      : "-"}{" "}
                                    -{" "}
                                    {Session && Session.ses_end_time
                                      ? moment(
                                          Session.ses_end_time,
                                          "HH:mm:ss"
                                        ).format("hh:mm A")
                                      : "-"}
                                  </p>
                                  <p className="mb-0 font-size-18 text-dark">
                                    {Session &&
                                    Session.tut_fname &&
                                    Session.tut_fname != ""
                                      ? Session.tut_fname +
                                        " " +
                                        Session.tut_surname
                                      : null}
                                  </p>

                                  {Session &&
                                  Session.ses_is_flexi &&
                                  Session.ses_is_flexi == 1 ? (
                                    <span className="badge bg-danger font-size-14 mt-auto mb-2 ms-1">
                                      Flexi
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="hw-status text-end">
                                {/* {Session && parseInt(Session.ses_is_completed) === 1 ?
                                <div className="d-flex align-items-center text-success fw-bold font-size-18">
                                   Completed
                                </div> : 
                                 null
                              } */}
                              </div>
                            </li>
                            {Session &&
                            Session.Lesson &&
                            Session.Lesson.LessonActivities.length > 0 ? (
                              Session.Lesson.LessonActivities.map(
                                (activity, index1) => {
                                  {
                                    // console.log(activity)
                                  }
                                  if (
                                    parseInt(activity.marked_for_homework) === 1
                                  ) {
                                    // console.log(activity)
                                    if (
                                      activity.activity_type === 0 &&
                                      activity.fk_sub_id === 2
                                    ) {
                                      let total_coins = 0
                                      let total_time = 0

                                      let total_question = 0
                                      let count_answer = 0
                                      let percentage = 0
                                      //let manual_mark = 0;

                                      if (
                                        activity.LessonActivitiesQuestions &&
                                        activity.LessonActivitiesQuestions
                                          .length > 0
                                      ) {
                                        //total_question += activity.LessonActivitiesQuestions.length;

                                        activity.LessonActivitiesQuestions.forEach(
                                          (activityQuestion, index2) => {
                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              let filteredManualMark = []

                                              // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                              // if(filteredManualMark.length > 0){
                                              //   manual_mark += 1;
                                              // }
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  total_question += 1
                                                  total_coins += 2
                                                  if (
                                                    question.StudentAnsAttempts &&
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      stuAnswer => {
                                                        total_time += parseInt(
                                                          stuAnswer.submitted_on
                                                        )
                                                      }
                                                    )
                                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                      if (
                                        total_question >= count_answer &&
                                        total_question != 0
                                      ) {
                                        percentage =
                                          (count_answer / total_question) * 100
                                      }

                                      return (
                                        <li className="d-flex justify-content-between">
                                          <div className="d-flex align-items-start w-75 me-5">
                                            {activity &&
                                            activity.status &&
                                            parseInt(activity.status) === 1 ? (
                                              <div className="hw-icon me-3 bg-success text-white font-size-24 me-3">
                                                <i className="fas fa-check-circle"></i>
                                              </div>
                                            ) : (
                                              <div className="hw-icon me-3">
                                                <img
                                                  width={24}
                                                  className=""
                                                  src={hw1}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                            <div>
                                              <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                                Assessment
                                              </p>
                                              <h4 className=" fw-bold font-size-24">
                                                {activity.activity_node[0] &&
                                                activity.activity_node[0]
                                                  .node_name != ""
                                                  ? activity.activity_node[0]
                                                      .node_name
                                                  : null}
                                              </h4>
                                              <div className="ft-util d-flex align-items-center">
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={duration}
                                                    alt=""
                                                  />
                                                  {activity.total_activity_time
                                                    ? activity.total_activity_time
                                                    : 0}{" "}
                                                  Minutes
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={awicon}
                                                    alt=""
                                                  />
                                                  {activity.coins_earned
                                                    ? activity.coins_earned
                                                    : 0}
                                                  /{total_coins} Coins
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {activity &&
                                          activity.status &&
                                          parseInt(activity.status) === 1 ? (
                                            <div
                                              className="hw-status text-end"
                                              onClick={() =>
                                                assessementActivitySummary(
                                                  activity
                                                )
                                              }
                                            >
                                              <div className="d-flex align-items-center text-success font-size-18 fw-bold text-uppercase cursor-pointer">
                                                <span className="mcr-success me-2"></span>
                                                Completed
                                              </div>
                                              <p className="mb-0 text-uppercase font-size-12">
                                                {formatTime(total_time)} Mins
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="hw-status text-end w-25">
                                              {parseInt(percentage) !== 0 ? (
                                                <>
                                                  <div>
                                                    <Button
                                                      className="btn btn-danger cs-btn-danger text-uppercase mb-2"
                                                      onClick={() =>
                                                        assessementActivity(
                                                          activity
                                                        )
                                                      }
                                                    >
                                                      Resume
                                                    </Button>
                                                  </div>

                                                  <div className="d-flex align-items-center">
                                                    <span className="me-2">
                                                      {percentage !== 0
                                                        ? percentage.toFixed(1)
                                                        : 0}
                                                      %
                                                    </span>
                                                    <div className="flex-grow-1">
                                                      <Progress
                                                        color="success"
                                                        value={
                                                          percentage !== 0
                                                            ? percentage
                                                            : 0
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <Button
                                                  className="btn btn-danger cs-btn-danger text-uppercase"
                                                  onClick={() =>
                                                    assessementActivity(
                                                      activity
                                                    )
                                                  }
                                                >
                                                  START
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </li>
                                      )
                                    } else if (
                                      activity.activity_type === 0 &&
                                      activity.fk_sub_id === 1
                                    ) {
                                      let total_coins = 0
                                      let total_time = 0

                                      let total_question = 0
                                      let count_answer = 0
                                      let percentage = 0
                                      let manual_mark = 0
                                      let manual_mark_review = 0
                                      let time_editing_category = 0
                                      if (
                                        activity.LessonActivitiesQuestions &&
                                        activity.LessonActivitiesQuestions
                                          .length > 0
                                      ) {
                                        //total_question += activity.LessonActivitiesQuestions.length;

                                        activity.LessonActivitiesQuestions.forEach(
                                          (activityQuestion, index2) => {
                                            manual_mark_review += parseInt(
                                              activityQuestion.marked_for_manual_marking
                                            )

                                            if (
                                              activityQuestion.english_question &&
                                              activityQuestion.english_question
                                                .length > 0
                                            ) {
                                              let filteredManualMark =
                                                activityQuestion.english_question.filter(
                                                  manualMark =>
                                                    parseInt(
                                                      manualMark.english_question_part_automark
                                                    ) === 0
                                                )
                                              if (
                                                filteredManualMark.length > 0
                                              ) {
                                                manual_mark += 1
                                              }

                                              activityQuestion.english_question.forEach(
                                                (question, index3) => {
                                                  if (
                                                    parseInt(
                                                      question.english_question_category_id
                                                    ) === 10 ||
                                                    parseInt(
                                                      question.english_question_category_id
                                                    ) === 13 ||
                                                    parseInt(
                                                      question.english_question_category_id
                                                    ) === 12 ||
                                                    parseInt(
                                                      question.english_question_category_id
                                                    ) === 8
                                                  ) {
                                                    if (
                                                      index3 === 0 &&
                                                      question.english_question_time_needed ===
                                                        "Less than 2 minutes"
                                                    ) {
                                                      time_editing_category += 2
                                                    } else if (
                                                      index3 === 0 &&
                                                      question.english_question_time_needed ===
                                                        "2-5 minutes"
                                                    ) {
                                                      time_editing_category += 5
                                                    } else if (
                                                      index3 === 0 &&
                                                      question.english_question_time_needed ===
                                                        "5-10 minutes"
                                                    ) {
                                                      time_editing_category += 10
                                                    } else if (
                                                      index3 === 0 &&
                                                      question.english_question_time_needed ===
                                                        "10-15 minutes"
                                                    ) {
                                                      time_editing_category += 15
                                                    } else if (
                                                      index3 === 0 &&
                                                      question.english_question_time_needed ===
                                                        "15-20 minutes"
                                                    ) {
                                                      time_editing_category += 20
                                                    } else if (
                                                      index3 === 0 &&
                                                      question.english_question_time_needed ===
                                                        "20-30 minutes"
                                                    ) {
                                                      time_editing_category += 30
                                                    } else if (
                                                      index3 === 0 &&
                                                      question.english_question_time_needed ===
                                                        "30-60 minutes"
                                                    ) {
                                                      time_editing_category += 60
                                                    }
                                                  } else {
                                                    time_editing_category =
                                                      activity.total_activity_time
                                                  }

                                                  if (
                                                    parseInt(
                                                      question.english_question_category_id
                                                    ) === 10 ||
                                                    parseInt(
                                                      question.english_question_category_id
                                                    ) === 13
                                                  ) {
                                                    question
                                                      .english_question_answers
                                                      .length > 0 &&
                                                    question
                                                      .english_question_answers[0]
                                                      .english_answer_text &&
                                                    question
                                                      .english_question_answers[0]
                                                      .english_answer_text != ""
                                                      ? ((total_question += 1),
                                                        (total_coins += 2))
                                                      : null
                                                  } else {
                                                    total_question += 1
                                                    total_coins += 2
                                                  }
                                                  // total_question += 1
                                                  // total_coins += 2
                                                  if (
                                                    question.StudentAnsAttempts &&
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      stuAnswer => {
                                                        total_time += parseInt(
                                                          stuAnswer.submitted_on
                                                        )
                                                      }
                                                    )
                                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                      if (
                                        total_question >= count_answer &&
                                        total_question != 0
                                      ) {
                                        percentage =
                                          (count_answer / total_question) * 100
                                      }

                                      return (
                                        <li className="d-flex justify-content-between">
                                          <div className="d-flex align-items-start w-75 me-5">
                                            {activity &&
                                            activity.status &&
                                            parseInt(activity.status) === 1 ? (
                                              <div className="hw-icon me-3 bg-success text-white font-size-24 me-3">
                                                <i className="fas fa-check-circle"></i>
                                              </div>
                                            ) : (
                                              <div className="hw-icon me-3">
                                                <img
                                                  width={24}
                                                  className=""
                                                  src={hw1}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                            <div>
                                              <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                                Assessment
                                              </p>
                                              <h4 className=" fw-bold font-size-24">
                                                {activity.activity_node[0] &&
                                                activity.activity_node[0]
                                                  .node_name != ""
                                                  ? activity.activity_node[0]
                                                      .node_name
                                                  : null}
                                              </h4>
                                              <div className="ft-util d-flex align-items-center">
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={duration}
                                                    alt=""
                                                  />
                                                  {time_editing_category
                                                    ? time_editing_category
                                                    : 0}{" "}
                                                  Minutes
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={awicon}
                                                    alt=""
                                                  />
                                                  {activity.coins_earned
                                                    ? activity.coins_earned
                                                    : 0}
                                                  /{total_coins} Coins
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  {activity &&
                                                  activity.status &&
                                                  parseInt(activity.status) ===
                                                    1 &&
                                                  manual_mark > 0 ? (
                                                    <span className="badge bg-primary font-size-14 mt-auto">
                                                      Manual Marking
                                                    </span>
                                                  ) : null}
                                                </div>

                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  {activity &&
                                                  activity.status &&
                                                  parseInt(activity.status) ===
                                                    1 &&
                                                  manual_mark > 0 &&
                                                  parseInt(
                                                    manual_mark_review
                                                  ) <= 0 ? (
                                                    <span className="badge bg-primary font-size-14 mt-auto">
                                                      In Review
                                                    </span>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {activity &&
                                          activity.status &&
                                          parseInt(activity.status) === 1 ? (
                                            <div className="hw-status text-end">
                                              <div
                                                className="d-flex align-items-center text-success font-size-18 fw-bold text-uppercase cursor-pointer"
                                                onClick={() =>
                                                  assessementActivitySummary(
                                                    activity
                                                  )
                                                }
                                              >
                                                <span className="mcr-success me-2"></span>
                                                Completed
                                              </div>
                                              <p className="mb-0 text-uppercase font-size-12">
                                                {formatTime(total_time)} Mins
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="hw-status text-end w-25">
                                              {parseInt(percentage) !== 0 ? (
                                                <>
                                                  <div>
                                                    <Button
                                                      className="btn btn-danger cs-btn-danger text-uppercase mb-2"
                                                      onClick={() =>
                                                        assessementActivity(
                                                          activity
                                                        )
                                                      }
                                                    >
                                                      Resume
                                                    </Button>
                                                  </div>

                                                  <div className="d-flex align-items-center">
                                                    <span className="me-2">
                                                      {percentage !== 0
                                                        ? percentage.toFixed(1)
                                                        : 0}
                                                      %
                                                    </span>
                                                    <div className="flex-grow-1">
                                                      <Progress
                                                        color="success"
                                                        value={
                                                          percentage !== 0
                                                            ? percentage
                                                            : 0
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <Button
                                                  className="btn btn-danger cs-btn-danger text-uppercase cursor-pointer"
                                                  onClick={() =>
                                                    assessementActivity(
                                                      activity
                                                    )
                                                  }
                                                >
                                                  START
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </li>
                                      )
                                    } else if (activity.activity_type === 1) {
                                      let total_coins = 0
                                      let total_time = 0

                                      let total_question = 0
                                      let count_answer = 0
                                      let percentage = 0
                                      //let manual_mark = 0;

                                      if (
                                        activity.LessonActivitiesQuestions &&
                                        activity.LessonActivitiesQuestions
                                          .length > 0
                                      ) {
                                        //total_question += activity.LessonActivitiesQuestions.length;

                                        activity.LessonActivitiesQuestions.forEach(
                                          (activityQuestion, index2) => {
                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              let filteredManualMark = []

                                              // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                              // if(filteredManualMark.length > 0){
                                              //   manual_mark += 1;
                                              // }
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  total_question += 1
                                                  total_coins += 2
                                                  if (
                                                    question.StudentAnsAttempts &&
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      stuAnswer => {
                                                        total_time += parseInt(
                                                          stuAnswer.submitted_on
                                                        )
                                                      }
                                                    )
                                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                      if (
                                        total_question >= count_answer &&
                                        total_question != 0
                                      ) {
                                        percentage =
                                          (count_answer / total_question) * 100
                                      }

                                      return (
                                        <li className="d-flex justify-content-between">
                                          <div className="d-flex align-items-start w-75 me-5">
                                            {activity &&
                                            activity.status &&
                                            parseInt(activity.status) === 1 ? (
                                              <div className="hw-icon me-3 bg-success text-white font-size-24 me-3">
                                                <i className="fas fa-check-circle"></i>
                                              </div>
                                            ) : (
                                              <div className="hw-icon me-3">
                                                <img
                                                  width={24}
                                                  className=""
                                                  src={hw2}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                            <div>
                                              <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                                Video
                                              </p>
                                              <h4 className=" fw-bold font-size-24">
                                                {activity.activity_node[0] &&
                                                activity.activity_node[0]
                                                  .name != ""
                                                  ? activity.activity_node[0]
                                                      .name
                                                  : null}
                                              </h4>
                                              <div className="ft-util d-flex align-items-center">
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={duration}
                                                    alt=""
                                                  />
                                                  {activity.total_activity_time
                                                    ? activity.total_activity_time
                                                    : 0}{" "}
                                                  Minutes
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={awicon}
                                                    alt=""
                                                  />
                                                  {activity.coins_earned
                                                    ? activity.coins_earned
                                                    : 0}
                                                  /{total_coins} Coins
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {activity &&
                                          activity.status &&
                                          parseInt(activity.status) === 1 ? (
                                            <div className="hw-status text-end">
                                              <div
                                                className="d-flex align-items-center text-success font-size-18 fw-bold text-uppercase"
                                                onClick={() =>
                                                  VideoActivity(activity)
                                                }
                                              >
                                                <span className="mcr-success me-2"></span>
                                                Completed
                                              </div>
                                              <p className="mb-0 text-uppercase font-size-12">
                                                {formatTime(total_time)} Mins
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="hw-status text-end w-25">
                                              {parseInt(percentage) !== 0 ? (
                                                <>
                                                  <div>
                                                    <Button
                                                      className="btn btn-danger cs-btn-danger text-uppercase mb-2"
                                                      onClick={() =>
                                                        VideoActivity(activity)
                                                      }
                                                    >
                                                      Resume
                                                    </Button>
                                                  </div>

                                                  <div className="d-flex align-items-center">
                                                    <span className="me-2">
                                                      {percentage !== 0
                                                        ? percentage.toFixed(1)
                                                        : 0}
                                                      %
                                                    </span>
                                                    <div className="flex-grow-1">
                                                      <Progress
                                                        color="success"
                                                        value={
                                                          percentage !== 0
                                                            ? percentage
                                                            : 0
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <Button
                                                  className="btn btn-danger cs-btn-danger text-uppercase"
                                                  onClick={() =>
                                                    VideoActivity(activity)
                                                  }
                                                >
                                                  START
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </li>
                                      )
                                    } else if (
                                      activity.activity_type === 2 &&
                                      activity.fk_sub_id === 1
                                    ) {
                                      let total_coins = 0
                                      let total_time = 0

                                      let total_question = 0
                                      let count_answer = 0
                                      let percentage = 0
                                      let manual_mark = 0
                                      if (
                                        activity.LessonActivitiesQuestions &&
                                        activity.LessonActivitiesQuestions
                                          .length > 0
                                      ) {
                                        //total_question += activity.LessonActivitiesQuestions.length;

                                        activity.LessonActivitiesQuestions.forEach(
                                          (activityQuestion, index2) => {
                                            if (
                                              activityQuestion.english_question &&
                                              activityQuestion.english_question
                                                .length > 0
                                            ) {
                                              let filteredManualMark = []
                                              // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                              //   if(filteredManualMark.length > 0){
                                              //     manual_mark += 1;
                                              //   }
                                              activityQuestion.english_question.forEach(
                                                (question, index3) => {
                                                  total_question += 1
                                                  total_coins += 2
                                                  if (
                                                    question.StudentAnsAttempts &&
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      stuAnswer => {
                                                        total_time += parseInt(
                                                          stuAnswer.submitted_on
                                                        )
                                                      }
                                                    )
                                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                      if (
                                        total_question >= count_answer &&
                                        total_question != 0
                                      ) {
                                        percentage =
                                          (count_answer / total_question) * 100
                                      }

                                      return (
                                        <li className="d-flex justify-content-between">
                                          <div className="d-flex align-items-start w-75 me-5">
                                            {activity &&
                                            activity.status &&
                                            parseInt(activity.status) === 1 &&
                                            parseInt(percentage) >= 100 ? (
                                              <div className="hw-icon me-3 bg-success text-white font-size-24 me-3">
                                                <i className="fas fa-check-circle"></i>
                                              </div>
                                            ) : (
                                              <div className="hw-icon me-3">
                                                <img
                                                  width={24}
                                                  className=""
                                                  src={hw5}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                            <div>
                                              <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                                DIAGNOSTIC
                                              </p>
                                              <h4 className=" fw-bold font-size-24">
                                                {activity.topics &&
                                                activity.topics.length > 0
                                                  ? getUniqueTopicsNames(
                                                      activity.topics
                                                    )
                                                  : null}
                                              </h4>
                                              <div className="ft-util d-flex align-items-center">
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={duration}
                                                    alt=""
                                                  />
                                                  {activity.total_activity_time
                                                    ? activity.total_activity_time
                                                    : 0}{" "}
                                                  Minutes
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={awicon}
                                                    alt=""
                                                  />
                                                  {activity.coins_earned
                                                    ? activity.coins_earned
                                                    : 0}
                                                  /{total_coins} Coins
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {activity &&
                                          activity.status &&
                                          parseInt(activity.status) === 1 ? (
                                            <div className="hw-status text-end">
                                              <div
                                                className="d-flex align-items-center text-success font-size-18 fw-bold text-uppercase"
                                                onClick={() =>
                                                  diagnosticActivitySummary(
                                                    activity
                                                  )
                                                }
                                              >
                                                <span className="mcr-success me-2"></span>
                                                Completed
                                              </div>
                                              <p className="mb-0 text-uppercase font-size-12">
                                                {formatTime(total_time)} Mins
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="hw-status text-end w-25">
                                              {parseInt(percentage) !== 0 ? (
                                                <>
                                                  <div>
                                                    <Button
                                                      className="btn btn-danger cs-btn-danger text-uppercase mb-2"
                                                      onClick={() =>
                                                        diagnosticActivity(
                                                          activity
                                                        )
                                                      }
                                                    >
                                                      Resume
                                                    </Button>
                                                  </div>

                                                  <div className="d-flex align-items-center">
                                                    <span className="me-2">
                                                      {percentage !== 0
                                                        ? percentage.toFixed(1)
                                                        : 0}
                                                      %
                                                    </span>
                                                    <div className="flex-grow-1">
                                                      <Progress
                                                        color="success"
                                                        value={
                                                          percentage !== 0
                                                            ? percentage
                                                            : 0
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <Button
                                                  className="btn btn-danger cs-btn-danger text-uppercase"
                                                  onClick={() =>
                                                    diagnosticActivity(activity)
                                                  }
                                                >
                                                  START
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </li>
                                      )
                                    } else if (
                                      activity.activity_type === 2 &&
                                      activity.fk_sub_id === 2
                                    ) {
                                      let total_coins = 0
                                      let total_time = 0

                                      let total_question = 0
                                      let count_answer = 0
                                      let percentage = 0
                                      let manual_mark = 0
                                      if (
                                        activity.LessonActivitiesQuestions &&
                                        activity.LessonActivitiesQuestions
                                          .length > 0
                                      ) {
                                        //total_question += activity.LessonActivitiesQuestions.length;

                                        activity.LessonActivitiesQuestions.forEach(
                                          (activityQuestion, index2) => {
                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              let filteredManualMark = []
                                              // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                              //   if(filteredManualMark.length > 0){
                                              //     manual_mark += 1;
                                              //   }
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  total_question += 1
                                                  total_coins += 2
                                                  if (
                                                    question.StudentAnsAttempts &&
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      stuAnswer => {
                                                        total_time += parseInt(
                                                          stuAnswer.submitted_on
                                                        )
                                                      }
                                                    )
                                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                      if (
                                        total_question >= count_answer &&
                                        total_question != 0
                                      ) {
                                        percentage =
                                          (count_answer / total_question) * 100
                                      }

                                      return (
                                        <li
                                          className="d-flex justify-content-between"
                                          key={index1}
                                        >
                                          <div className="d-flex align-items-start w-75 me-5">
                                            {activity &&
                                            activity.status &&
                                            parseInt(activity.status) === 1 &&
                                            parseInt(percentage) >= 100 ? (
                                              <div className="hw-icon me-3 bg-success text-white font-size-24 me-3">
                                                <i className="fas fa-check-circle"></i>
                                              </div>
                                            ) : (
                                              <div className="hw-icon me-3">
                                                <img
                                                  width={24}
                                                  className=""
                                                  src={hw5}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                            <div>
                                              <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                                DIAGNOSTIC
                                              </p>
                                              <h4 className=" fw-bold font-size-24">
                                                {activity.topics &&
                                                activity.topics.length > 0
                                                  ? getUniqueTopicsNames(
                                                      activity.topics
                                                    )
                                                  : null}
                                              </h4>
                                              <div className="ft-util d-flex align-items-center">
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={duration}
                                                    alt=""
                                                  />
                                                  {activity.total_activity_time
                                                    ? activity.total_activity_time
                                                    : 0}{" "}
                                                  Minutes
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={awicon}
                                                    alt=""
                                                  />
                                                  {activity.coins_earned
                                                    ? activity.coins_earned
                                                    : 0}
                                                  /{total_coins} Coins
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {activity &&
                                          activity.status &&
                                          parseInt(activity.status) === 1 ? (
                                            <div className="hw-status text-end">
                                              <div
                                                className="d-flex align-items-center text-success font-size-18 fw-bold text-uppercase"
                                                onClick={() =>
                                                  diagnosticActivitySummary(
                                                    activity
                                                  )
                                                }
                                              >
                                                <span className="mcr-success me-2"></span>
                                                Completed
                                              </div>
                                              <p className="mb-0 text-uppercase font-size-12">
                                                {formatTime(total_time)} Mins
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="hw-status text-end w-25">
                                              {parseInt(percentage) !== 0 ? (
                                                <>
                                                  <div>
                                                    <Button
                                                      className="btn btn-danger cs-btn-danger text-uppercase mb-2"
                                                      onClick={() =>
                                                        diagnosticActivity(
                                                          activity
                                                        )
                                                      }
                                                    >
                                                      Resume
                                                    </Button>
                                                  </div>

                                                  <div className="d-flex align-items-center">
                                                    <span className="me-2">
                                                      {percentage !== 0
                                                        ? percentage.toFixed(1)
                                                        : 0}
                                                      %
                                                    </span>
                                                    <div className="flex-grow-1">
                                                      <Progress
                                                        color="success"
                                                        value={
                                                          percentage !== 0
                                                            ? percentage
                                                            : 0
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <Button
                                                  className="btn btn-danger cs-btn-danger text-uppercase"
                                                  onClick={() =>
                                                    diagnosticActivity(activity)
                                                  }
                                                >
                                                  START
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </li>
                                      )
                                    } else if (activity.activity_type === 3) {
                                      let total_coins = 0
                                      let total_time = 0

                                      let total_question = 0
                                      let count_answer = 0
                                      let percentage = 0
                                      //let manual_mark = 0;

                                      if (
                                        activity.LessonActivitiesQuestions &&
                                        activity.LessonActivitiesQuestions
                                          .length > 0
                                      ) {
                                        //total_question += activity.LessonActivitiesQuestions.length;

                                        activity.LessonActivitiesQuestions.forEach(
                                          (activityQuestion, index2) => {
                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              let filteredManualMark = []

                                              // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                              // if(filteredManualMark.length > 0){
                                              //   manual_mark += 1;
                                              // }
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  total_question += 1
                                                  total_coins += 2
                                                  if (
                                                    question.StudentAnsAttempts &&
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      stuAnswer => {
                                                        total_time += parseInt(
                                                          stuAnswer.submitted_on
                                                        )
                                                      }
                                                    )
                                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                      if (
                                        total_question >= count_answer &&
                                        total_question != 0
                                      ) {
                                        percentage =
                                          (count_answer / total_question) * 100
                                      }

                                      return (
                                        <li className="d-flex justify-content-between">
                                          <div className="d-flex align-items-start w-75 me-5">
                                            {activity &&
                                            activity.status &&
                                            parseInt(activity.status) === 1 ? (
                                              <div className="hw-icon me-3 bg-success text-white font-size-24 me-3">
                                                <i className="fas fa-check-circle"></i>
                                              </div>
                                            ) : (
                                              <div className="hw-icon me-3">
                                                <img
                                                  width={24}
                                                  className=""
                                                  src={hw2}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                            <div>
                                              <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                                Link
                                              </p>
                                              <h4 className=" fw-bold font-size-24">
                                                {activity.activity_node[0] &&
                                                activity.activity_node[0]
                                                  .name != ""
                                                  ? activity.activity_node[0]
                                                      .name
                                                  : null}
                                              </h4>
                                              <div className="ft-util d-flex align-items-center">
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={duration}
                                                    alt=""
                                                  />
                                                  {activity.total_activity_time
                                                    ? activity.total_activity_time
                                                    : 0}{" "}
                                                  Minutes
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={awicon}
                                                    alt=""
                                                  />
                                                  {activity.coins_earned
                                                    ? activity.coins_earned
                                                    : 0}
                                                  /{total_coins} Coins
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {activity &&
                                          activity.status &&
                                          parseInt(activity.status) === 1 ? (
                                            <div className="hw-status text-end">
                                              <div
                                                className="d-flex align-items-center text-success font-size-18 fw-bold text-uppercase"
                                                onClick={() =>
                                                  LinkActivity(activity)
                                                }
                                              >
                                                <span className="mcr-success me-2"></span>
                                                Completed
                                              </div>
                                              <p className="mb-0 text-uppercase font-size-12">
                                                {formatTime(total_time)} Mins
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="hw-status text-end w-25">
                                              {parseInt(percentage) !== 0 ? (
                                                <>
                                                  <div>
                                                    <Button
                                                      className="btn btn-danger cs-btn-danger text-uppercase mb-2"
                                                      onClick={() =>
                                                        LinkActivity(activity)
                                                      }
                                                    >
                                                      Resume
                                                    </Button>
                                                  </div>

                                                  <div className="d-flex align-items-center">
                                                    <span className="me-2">
                                                      {percentage !== 0
                                                        ? percentage.toFixed(1)
                                                        : 0}
                                                      %
                                                    </span>
                                                    <div className="flex-grow-1">
                                                      <Progress
                                                        color="success"
                                                        value={
                                                          percentage !== 0
                                                            ? percentage
                                                            : 0
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <Button
                                                  className="btn btn-danger cs-btn-danger text-uppercase"
                                                  onClick={() =>
                                                    LinkActivity(activity)
                                                  }
                                                >
                                                  START
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </li>
                                      )
                                    } else if (activity.activity_type === 4) {
                                      let total_coins = 0
                                      let total_time = 0

                                      let total_question = 0
                                      let count_answer = 0
                                      let percentage = 0
                                      //let manual_mark = 0;

                                      if (
                                        activity.LessonActivitiesQuestions &&
                                        activity.LessonActivitiesQuestions
                                          .length > 0
                                      ) {
                                        //total_question += activity.LessonActivitiesQuestions.length;

                                        activity.LessonActivitiesQuestions.forEach(
                                          (activityQuestion, index2) => {
                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              let filteredManualMark = []

                                              // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                              // if(filteredManualMark.length > 0){
                                              //   manual_mark += 1;
                                              // }
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  total_question += 1
                                                  total_coins += 2
                                                  if (
                                                    question.StudentAnsAttempts &&
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      stuAnswer => {
                                                        total_time += parseInt(
                                                          stuAnswer.submitted_on
                                                        )
                                                      }
                                                    )
                                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                      if (
                                        total_question >= count_answer &&
                                        total_question != 0
                                      ) {
                                        percentage =
                                          (count_answer / total_question) * 100
                                      }
                                      return (
                                        <li className="d-flex justify-content-between">
                                          <div className="d-flex align-items-start w-75 me-5">
                                            {activity &&
                                            activity.status &&
                                            parseInt(activity.status) === 1 ? (
                                              <div className="hw-icon me-3 bg-success text-white font-size-24 me-3">
                                                <i className="fas fa-check-circle"></i>
                                              </div>
                                            ) : (
                                              <div className="hw-icon me-3">
                                                <img
                                                  width={24}
                                                  className=""
                                                  src={hw2}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                            <div>
                                              <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                                Task
                                              </p>
                                              <h4 className=" fw-bold font-size-24">
                                                {activity.activity_node[0] &&
                                                activity.activity_node[0]
                                                  .name != ""
                                                  ? activity.activity_node[0]
                                                      .name
                                                  : null}
                                              </h4>
                                              <div className="ft-util d-flex align-items-center">
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={duration}
                                                    alt=""
                                                  />
                                                  {activity.total_activity_time
                                                    ? activity.total_activity_time
                                                    : 0}{" "}
                                                  Minutes
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={awicon}
                                                    alt=""
                                                  />
                                                  {activity.coins_earned
                                                    ? activity.coins_earned
                                                    : 0}
                                                  /{total_coins} Coins
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {activity &&
                                          activity.status &&
                                          parseInt(activity.status) === 1 ? (
                                            <div className="hw-status text-end">
                                              <div
                                                className="d-flex align-items-center text-success font-size-18 fw-bold text-uppercase"
                                                onClick={() =>
                                                  TaskActivity(activity)
                                                }
                                              >
                                                <span className="mcr-success me-2"></span>
                                                Completed
                                              </div>
                                              <p className="mb-0 text-uppercase font-size-12">
                                                {formatTime(total_time)} Mins
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="hw-status text-end w-25">
                                              {parseInt(percentage) !== 0 ? (
                                                <>
                                                  <div>
                                                    <Button
                                                      className="btn btn-danger cs-btn-danger text-uppercase mb-2"
                                                      onClick={() =>
                                                        TaskActivity(activity)
                                                      }
                                                    >
                                                      Resume
                                                    </Button>
                                                  </div>

                                                  <div className="d-flex align-items-center">
                                                    <span className="me-2">
                                                      {percentage !== 0
                                                        ? percentage.toFixed(1)
                                                        : 0}
                                                      %
                                                    </span>
                                                    <div className="flex-grow-1">
                                                      <Progress
                                                        color="success"
                                                        value={
                                                          percentage !== 0
                                                            ? percentage
                                                            : 0
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <Button
                                                  className="btn btn-danger cs-btn-danger text-uppercase"
                                                  onClick={() =>
                                                    TaskActivity(activity)
                                                  }
                                                >
                                                  START
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </li>
                                      )
                                    } else if (activity.activity_type === 5) {
                                      let total_coins = 0
                                      let total_time = 0

                                      let total_question = 0
                                      let count_answer = 0
                                      let percentage = 0
                                      //let manual_mark = 0;
                                      let manual_mark_review = 0
                                      if (
                                        activity.LessonActivitiesQuestions &&
                                        activity.LessonActivitiesQuestions
                                          .length > 0
                                      ) {
                                        //total_question += activity.LessonActivitiesQuestions.length;

                                        activity.LessonActivitiesQuestions.forEach(
                                          (activityQuestion, index2) => {
                                            total_question += 1
                                            manual_mark_review += parseInt(
                                              activityQuestion.marked_for_manual_marking
                                            )
                                            if (
                                              activityQuestion.StudentAnsAttempts &&
                                              activityQuestion
                                                .StudentAnsAttempts.length > 0
                                            ) {
                                              activityQuestion.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              let filteredManualMark = []

                                              // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                              // if(filteredManualMark.length > 0){
                                              //   manual_mark += 1;
                                              // }
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  total_coins += 2
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                      if (
                                        total_question >= count_answer &&
                                        total_question != 0
                                      ) {
                                        percentage =
                                          (count_answer / total_question) * 100
                                      }

                                      return (
                                        <li className="d-flex justify-content-between">
                                          <div className="d-flex align-items-start w-75 me-5">
                                            {activity &&
                                            activity.status &&
                                            parseInt(activity.status) === 1 ? (
                                              <div className="hw-icon me-3 bg-success text-white font-size-24 me-3">
                                                <i className="fas fa-check-circle"></i>
                                              </div>
                                            ) : (
                                              <div className="hw-icon me-3">
                                                <img
                                                  width={24}
                                                  className=""
                                                  src={hw2}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                            <div>
                                              <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                                Worksheet
                                              </p>
                                              <h4 className=" fw-bold font-size-24">
                                                {activity.activity_node[0] &&
                                                activity.activity_node[0]
                                                  .name != ""
                                                  ? activity.activity_node[0]
                                                      .name
                                                  : null}
                                              </h4>
                                              <div className="ft-util d-flex align-items-center">
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={duration}
                                                    alt=""
                                                  />
                                                  {activity.total_activity_time
                                                    ? activity.total_activity_time
                                                    : 0}{" "}
                                                  Minutes
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={awicon}
                                                    alt=""
                                                  />
                                                  {activity.coins_earned
                                                    ? activity.coins_earned
                                                    : 0}
                                                  /{total_coins} Coins
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  {activity &&
                                                  activity.status &&
                                                  parseInt(activity.status) ===
                                                    1 ? (
                                                    <span className="badge bg-primary font-size-14 mt-auto">
                                                      Manual Marking
                                                    </span>
                                                  ) : null}
                                                </div>

                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  {activity &&
                                                  activity.status &&
                                                  parseInt(activity.status) ===
                                                    1 &&
                                                  parseInt(
                                                    manual_mark_review
                                                  ) <= 0 ? (
                                                    <span className="badge bg-primary font-size-14 mt-auto">
                                                      In Review
                                                    </span>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {activity &&
                                          activity.status &&
                                          parseInt(activity.status) === 1 ? (
                                            <div className="hw-status text-end">
                                              <div
                                                className="d-flex align-items-center text-success font-size-18 fw-bold text-uppercase"
                                                onClick={() =>
                                                  WorksheetActivity(activity)
                                                }
                                              >
                                                <span className="mcr-success me-2"></span>
                                                Completed
                                              </div>
                                              <p className="mb-0 text-uppercase font-size-12">
                                                {formatTime(total_time)} Mins
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="hw-status text-end w-25">
                                              {parseInt(percentage) !== 0 ? (
                                                <>
                                                  <div>
                                                    <Button
                                                      className="btn btn-danger cs-btn-danger text-uppercase mb-2"
                                                      onClick={() =>
                                                        WorksheetActivity(
                                                          activity
                                                        )
                                                      }
                                                    >
                                                      Resume
                                                    </Button>
                                                  </div>

                                                  <div className="d-flex align-items-center">
                                                    <span className="me-2">
                                                      {percentage !== 0
                                                        ? percentage.toFixed(1)
                                                        : 0}
                                                      %
                                                    </span>
                                                    <div className="flex-grow-1">
                                                      <Progress
                                                        color="success"
                                                        value={
                                                          percentage !== 0
                                                            ? percentage
                                                            : 0
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <Button
                                                  className="btn btn-danger cs-btn-danger text-uppercase"
                                                  onClick={() =>
                                                    WorksheetActivity(activity)
                                                  }
                                                >
                                                  START
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </li>
                                      )
                                    } else if (
                                      activity.activity_type === 6 &&
                                      (activity.fk_sub_id === 1 ||
                                        activity.fk_sub_id === 2)
                                    ) {
                                      let total_coins = 0
                                      let total_time = 0

                                      let total_question = 0
                                      let count_answer = 0
                                      let percentage = 0
                                      //let manual_mark = 0;

                                      if (
                                        activity.LessonActivitiesQuestions &&
                                        activity.LessonActivitiesQuestions
                                          .length > 0
                                      ) {
                                        //total_question += activity.LessonActivitiesQuestions.length;

                                        activity.LessonActivitiesQuestions.forEach(
                                          (activityQuestion, index2) => {
                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              let filteredManualMark = []

                                              // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                              // if(filteredManualMark.length > 0){
                                              //   manual_mark += 1;
                                              // }
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  total_question += 1
                                                  total_coins += 2
                                                  if (
                                                    question.StudentAnsAttempts &&
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      stuAnswer => {
                                                        total_time += parseInt(
                                                          stuAnswer.submitted_on
                                                        )
                                                      }
                                                    )
                                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                      if (
                                        total_question >= count_answer &&
                                        total_question != 0
                                      ) {
                                        percentage =
                                          (count_answer / total_question) * 100
                                      }
                                      return (
                                        <li className="d-flex justify-content-between">
                                          <div className="d-flex align-items-start w-75 me-5">
                                            {activity &&
                                            activity.status &&
                                            parseInt(activity.status) === 1 ? (
                                              <div className="hw-icon me-3 bg-success text-white font-size-24 me-3">
                                                <i className="fas fa-check-circle"></i>
                                              </div>
                                            ) : (
                                              <div className="hw-icon me-3">
                                                <img
                                                  width={24}
                                                  className=""
                                                  src={hw2}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                            <div>
                                              <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                                Video
                                              </p>
                                              <h4 className=" fw-bold font-size-24">
                                                {activity.activity_node &&
                                                activity.activity_node[0] &&
                                                activity.activity_node[0]
                                                  .video_name != ""
                                                  ? activity.activity_node[0]
                                                      .video_name
                                                  : null}
                                              </h4>
                                              <div className="ft-util d-flex align-items-center">
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={duration}
                                                    alt=""
                                                  />
                                                  {activity.total_activity_time
                                                    ? activity.total_activity_time
                                                    : 0}{" "}
                                                  Minutes
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={awicon}
                                                    alt=""
                                                  />
                                                  {activity.coins_earned
                                                    ? activity.coins_earned
                                                    : 0}
                                                  /{total_coins} Coins
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {activity &&
                                          activity.status &&
                                          parseInt(activity.status) === 1 ? (
                                            <div className="hw-status text-end">
                                              <div
                                                className="d-flex align-items-center text-success font-size-18 fw-bold text-uppercase"
                                                onClick={() =>
                                                  VideoActivity(activity)
                                                }
                                              >
                                                <span className="mcr-success me-2"></span>
                                                Completed
                                              </div>
                                              <p className="mb-0 text-uppercase font-size-12">
                                                {formatTime(total_time)} Mins
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="hw-status text-end w-25">
                                              {parseInt(percentage) !== 0 ? (
                                                <>
                                                  <div>
                                                    <Button
                                                      className="btn btn-danger cs-btn-danger text-uppercase mb-2"
                                                      onClick={() =>
                                                        VideoActivity(activity)
                                                      }
                                                    >
                                                      Resume
                                                    </Button>
                                                  </div>

                                                  <div className="d-flex align-items-center">
                                                    <span className="me-2">
                                                      {percentage !== 0
                                                        ? percentage.toFixed(1)
                                                        : 0}
                                                      %
                                                    </span>
                                                    <div className="flex-grow-1">
                                                      <Progress
                                                        color="success"
                                                        value={
                                                          percentage !== 0
                                                            ? percentage
                                                            : 0
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <Button
                                                  className="btn btn-danger cs-btn-danger text-uppercase"
                                                  onClick={() =>
                                                    VideoActivity(activity)
                                                  }
                                                >
                                                  START
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </li>
                                      )
                                    } else if (
                                      activity.activity_type === 7 &&
                                      (activity.fk_sub_id === 1 ||
                                        activity.fk_sub_id === 2)
                                    ) {
                                      let total_coins = 0
                                      let total_time = 0

                                      let total_question = 0
                                      let count_answer = 0
                                      let percentage = 0
                                      //let manual_mark = 0;
                                      let manual_mark_review = 0
                                      if (
                                        activity.LessonActivitiesQuestions &&
                                        activity.LessonActivitiesQuestions
                                          .length > 0
                                      ) {
                                        //total_question += activity.LessonActivitiesQuestions.length;

                                        activity.LessonActivitiesQuestions.forEach(
                                          (activityQuestion, index2) => {
                                            total_question += 1
                                            manual_mark_review += parseInt(
                                              activityQuestion.marked_for_manual_marking
                                            )
                                            if (
                                              activityQuestion.StudentAnsAttempts &&
                                              activityQuestion
                                                .StudentAnsAttempts.length > 0
                                            ) {
                                              activityQuestion.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }

                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              let filteredManualMark = []

                                              // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                              // if(filteredManualMark.length > 0){
                                              //   manual_mark += 1;
                                              // }
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  total_coins += 2
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                      if (
                                        total_question >= count_answer &&
                                        total_question != 0
                                      ) {
                                        percentage =
                                          (count_answer / total_question) * 100
                                      }

                                      return (
                                        <li className="d-flex justify-content-between">
                                          <div className="d-flex align-items-start w-75 me-5">
                                            {activity &&
                                            activity.status &&
                                            parseInt(activity.status) === 1 ? (
                                              <div className="hw-icon me-3 bg-success text-white font-size-24 me-3">
                                                <i className="fas fa-check-circle"></i>
                                              </div>
                                            ) : (
                                              <div className="hw-icon me-3">
                                                <img
                                                  width={24}
                                                  className=""
                                                  src={hw2}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                            <div>
                                              <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                                Worksheet
                                              </p>
                                              <h4 className=" fw-bold font-size-24">
                                                {activity.activity_node[0] &&
                                                activity.activity_node[0]
                                                  .node_name != ""
                                                  ? activity.activity_node[0]
                                                      .node_name
                                                  : null}
                                              </h4>
                                              <div className="ft-util d-flex align-items-center">
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={duration}
                                                    alt=""
                                                  />
                                                  {activity.total_activity_time
                                                    ? activity.total_activity_time
                                                    : 0}{" "}
                                                  Minutes
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  <img
                                                    width={24}
                                                    className="me-2"
                                                    src={awicon}
                                                    alt=""
                                                  />
                                                  {activity.coins_earned
                                                    ? activity.coins_earned
                                                    : 0}
                                                  /{total_coins} Coins
                                                </div>
                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  {activity &&
                                                  activity.status &&
                                                  parseInt(activity.status) ===
                                                    1 ? (
                                                    <span className="badge bg-primary font-size-14 mt-auto">
                                                      Manual Marking
                                                    </span>
                                                  ) : null}
                                                </div>

                                                <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                                  {activity &&
                                                  activity.status &&
                                                  parseInt(activity.status) ===
                                                    1 &&
                                                  parseInt(
                                                    manual_mark_review
                                                  ) <= 0 ? (
                                                    <span className="badge bg-primary font-size-14 mt-auto">
                                                      In Review
                                                    </span>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {activity &&
                                          activity.status &&
                                          parseInt(activity.status) === 1 ? (
                                            <div className="hw-status text-end">
                                              <div
                                                className="d-flex align-items-center text-success font-size-18 fw-bold text-uppercase"
                                                onClick={() =>
                                                  WorksheetActivity(activity)
                                                }
                                              >
                                                <span className="mcr-success me-2"></span>
                                                Completed
                                              </div>
                                              <p className="mb-0 text-uppercase font-size-12">
                                                {formatTime(total_time)} Mins
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="hw-status text-end w-25">
                                              {parseInt(percentage) !== 0 ? (
                                                <>
                                                  <div>
                                                    <Button
                                                      className="btn btn-danger cs-btn-danger text-uppercase mb-2"
                                                      onClick={() =>
                                                        WorksheetActivity(
                                                          activity
                                                        )
                                                      }
                                                    >
                                                      Resume
                                                    </Button>
                                                  </div>

                                                  <div className="d-flex align-items-center">
                                                    <span className="me-2">
                                                      {percentage !== 0
                                                        ? percentage.toFixed(1)
                                                        : 0}
                                                      %
                                                    </span>
                                                    <div className="flex-grow-1">
                                                      <Progress
                                                        color="success"
                                                        value={
                                                          percentage !== 0
                                                            ? percentage
                                                            : 0
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <Button
                                                  className="btn btn-danger cs-btn-danger text-uppercase"
                                                  onClick={() =>
                                                    WorksheetActivity(activity)
                                                  }
                                                >
                                                  START
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </li>
                                      )
                                    } else {
                                      return null
                                    }
                                  } else {
                                    return null
                                  }
                                }
                              )
                            ) : (
                              <p>No Activity</p>
                            )}
                          </ul>
                        </div>
                      </>
                    )
                  })
                ) : (
                  <div className="text-center">No Homework found </div>
                )}
               <div className="d-flex justify-content-center mt-4">
                <button
                  className="btn btn-primary-secondary me-2"
                  onClick={() => {handlePageChange(currentPage - 1);
                   
                  }}
                  disabled={currentPage === 1}
                >
                  <i className="fas fa-chevron-left"></i> Previous
                </button>

                <span className="pagination-info  mt-2">
                  {/* Page {currentPage} of {executeQuery} */}
                </span>

                <button
                  className="btn btn-primary-secondary ms-2"
                  onClick={() => {handlePageChange(currentPage + 1);
                    setTotalSessions(currentPage + 1)
                   }}
                    disabled={SessionData && SessionData.length === 0}
                >
                  Next <i className="fas fa-chevron-right"></i>
                </button>
              </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

HomeworkList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(HomeworkList)
