import React, { useState, useEffect, useCallback, useRef } from "react"
import {
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
  Form,
  FormFeedback,
} from "reactstrap"

import "react-multi-carousel/lib/styles.css"

import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"

import awicon from "../../../assets/images/services-icon/coin.png"
import Select from "react-select"

import { SECURITY_KEY, BASE_URL } from "../../../global"
import dsmile from "../../../assets/images/services-icon/dog_smile.png"

import piratemale from "../../../assets/images/profile-avatars/avatar1/piratemale.png"

import piratefemale from "../../../assets/images/profile-avatars/avatar2/piratefemale.png"

//i18n
import axios from "axios"
// import { BASE_URL } from "../../global";

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import moment from "moment"

import Loader from "react-spinner-loader"

import { MathJax, MathJaxContext } from "better-react-mathjax"

const Comprehension = React.memo(
  ({
    triggerFunction,
    data,
    part,
    triggerSubmitFunction,
    responceData,
    key,
  }) => {
    const authUser = JSON.parse(localStorage.getItem("authStudentUser")) || null

    const navigate = useNavigate()

    const CountTotalCoins = question => {
      let total_coins = 0

      if (question && question.length > 0) {
        question.forEach(activity => {
          if (activity.fk_sub_id === "1") {
            total_coins += activity.english_question.reduce(
              (acc, question) =>
                acc + question.english_question_parts.length * 2,
              0
            )
          } else if (activity.fk_sub_id === "2" || activity.fk_sub_id === "3") {
            total_coins += activity.question.reduce(
              (acc, question) => acc + question.question_parts.length * 2,
              0
            )
          }
        })
      }
      // setTotalCoins(total_coins);
      return total_coins
    }

    // useEffect(useCallback(() => {

    // if(data && data!=''){
    //     setActivityData(data);
    // }else{
    //     setActivityData([]);
    // }

    //}, [data])
    const [isValuefield, setValuefield] = useState(false)

    const [selectedAnswer, setselectedAnswer] = useState(null)
    const [selectedAnswerText, setselectedAnswerText] = useState([])

    const [nextQuestion, setnextQuestion] = useState(true)
    const [resultData, setresultData] = useState(null)
    const [IsSubmited, setIsSubmited] = useState(false)

    const [modal_standard, setmodal_standard] = useState(false)
    const [modal_standard1, setmodal_standard1] = useState(false)

    const [isFlagReport, setFlagReport] = useState(false)
    const [isQuestionReport, setQuestionReport] = useState(false)

    const [selectedGroup, setselectedGroup] = useState(null)

    //const ActivePartData = part;
    const [ActivePartData, setActivePartData] = useState([])
    const [ActivityData1, setActivityData1] = useState(null)

    useEffect(() => {
      if (part && part != "" && data && data != "") {
        setActivityData1(data)
        setActivePartData(part)
        setValuefield(false)
        setselectedAnswer(null)
        setselectedAnswerText([])

        setnextQuestion(true)
        setresultData(null)
        setIsSubmited(false)

        setmodal_standard(false)
        setmodal_standard1(false)
        setFlagReport(false)
        setQuestionReport(false)
        setselectedGroup(null)
      }
    }, [part, data])

    function handleSelectGroup(selectedGroup) {
      setselectedGroup(selectedGroup)
    }

    const tog_standard1 = () => {
      setmodal_standard1(!modal_standard1)
    }

    const optionGroup = [
      // {
      // label: "Select Subject",
      // options: [
      { label: "Spelling error / Typo", value: "Spelling error / Typo" },
      { label: "The solution is wrong", value: "The solution is wrong" },
      {
        label: "The question is not relevant to the topic",
        value: "The question is not relevant to the topic",
      },
      {
        label: "The question cannot be solved",
        value: "The question cannot be solved",
      },
      {
        label: "The question is incomplete",
        value: "The question is incomplete",
      },
      { label: "Others", value: "Others" },
      // ],
      // },
    ]
    const [ResonTextData, setResonText] = useState("")
    function textareachange(event) {
      const text = event.target.value
      // if (count > 0) {
      setResonText(text)
      // }
      //settextcount(event.target.value.length)
    }

    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
      initialValues: {
        reson: "",
        reson_text: "",
      },
      validationSchema: Yup.object({
        reson: Yup.mixed().required("Select a reason is required."),
        reson_text: Yup.string()
          .min(2, "Minimum 2 characters is required.")
          .max(250, "Maximum 250 characters is required.")
          .required("Reason is required.")
          .matches(
            /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
            "Avoid start & end with a space or comma"
          ),
      }),
      onSubmit: async values => {
        // console.log(values);

        if (authUser && authUser != "" && authUser != null) {
          setFlagReport(true)
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          axios
            .post(BASE_URL + "studentSession/flagReport", {
              fk_lesson_activity_key: ActivityData1.fk_lesson_activity_key,
              fk_lesson_activity_question_key:
                ActivityData1.pk_lesson_activity_question_key,
              fk_sub_id: ActivityData1.fk_sub_id,
              question_id: ActivityData1.fk_question_id,
              // reson_text: ResonTextData,
              // reson: selectedGroup
              ...values,
            })
            .then(responseFlag => {
              //console.log(responseSessionEnd.data);
              // Assuming the API response is an array of objects with 'id' and 'value' properties
              if (parseInt(responseFlag.data) === 1) {
                tog_standard()
                setFlagReport(false)
                toast.success(
                  "Thank you! Our Curriculum team will review and add 5 coins if the issue reported is correct.",
                  {
                    autoClose: 3000, // 3 seconds in this example
                  }
                )
              } else if (parseInt(responseFlag.data) === 2) {
                tog_standard()
                setFlagReport(false)
                toast.error("Already submitted a report on this question", {
                  autoClose: 3000, // 3 seconds in this example
                })
              } else {
                tog_standard()
                setFlagReport(false)
                toast.error("Failure! Unable to insert data. Try again later", {
                  autoClose: 3000, // 3 seconds in this example
                })
              }
            })
            .catch(error => {
              //console.log(error)
              tog_standard()
              setFlagReport(false)
              if (
                error.response.status === 403 &&
                error.response.data.error === "jwt expired"
              ) {
                localStorage.clear()
                navigate("/login")
              }
            })
        } else {
          setFlagReport(false)
          navigate("/login")
        }

        //dispatch(loginUser(values, props.router.navigate));
      },
    })

    const tog_standard = () => {
      setmodal_standard(!modal_standard)
      validation.resetForm()
      setselectedGroup(null)
      setResonText("")
    }

    // function submitFlagReport(){
    //   if (authUser && authUser != "" && authUser != null) {

    //     setFlagReport(true);
    //       axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
    //       axios.post(BASE_URL+'studentSession/flagReport', {
    //         fk_lesson_activity_key: ActivityData1.fk_lesson_activity_key,
    //         fk_lesson_activity_question_key : ActivityData1.pk_lesson_activity_question_key,
    //         fk_sub_id: ActivityData1.fk_sub_id,
    //         question_id: ActivityData1.fk_question_id,
    //         reson_text: ResonTextData,
    //         reson: selectedGroup
    //       }).then(responseFlag => {
    //         //console.log(responseSessionEnd.data);
    //         // Assuming the API response is an array of objects with 'id' and 'value' properties
    //         if (parseInt(responseFlag.data) === 1) {
    //           tog_standard();
    //           setFlagReport(false);
    //           toast.success("Thank you! Our Curriculum team will review and add 5 coins if the issue reported is correct.", {
    //             autoClose: 3000, // 3 seconds in this example
    //           });
    //         } else {
    //           tog_standard();
    //           setFlagReport(false);
    //           toast.error("Failure! Unable to insert data. Try again later", {
    //             autoClose: 3000, // 3 seconds in this example
    //           });
    //         }

    //       })
    //         .catch(error => {
    //           //console.log(error)
    //           tog_standard();
    //           setFlagReport(false);
    //           if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
    //             localStorage.clear();
    //             navigate("/login");
    //           }
    //         });

    //   }
    //   else {
    //     setFlagReport(false);
    //     navigate("/login");
    //   }

    // }
    function submitQuestionReport() {
      if (authUser && authUser != "" && authUser != null) {
        setQuestionReport(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "studentSession/questionReport", {
            fk_lesson_activity_key: ActivityData1.fk_lesson_activity_key,
            fk_lesson_activity_question_key:
              ActivityData1.pk_lesson_activity_question_key,
            fk_sub_id: ActivityData1.fk_sub_id,
            question_id: ActivityData1.fk_question_id,
          })
          .then(responseQus => {
            //console.log(responseSessionEnd.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseQus.data) === 1) {
              tog_standard1()
              setQuestionReport(false)
              toast.success(
                "Thank you! Our Curriculum team will review and add 5 coins if the issue reported is correct.",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )
            } else if (parseInt(responseQus.data) === 2) {
              tog_standard1()
              setQuestionReport(false)
              toast.error("Already submitted a report on this question", {
                autoClose: 3000, // 3 seconds in this example
              })
            } else {
              tog_standard1()
              setQuestionReport(false)
              toast.error("Failure! Unable to insert data. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            //console.log(error)
            tog_standard1()
            setQuestionReport(false)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        tog_standard1()
        setQuestionReport(false)
        navigate("/login")
      }
    }

    //caregory 1
    const selectedAnsOption = answer => {
      //console.log(answer);

      if (parseInt(ActivityData1.fk_sub_id) === 1) {
        setselectedAnswer(answer.english_answer_id)
        let result = [
          {
            part_id: answer.english_answer_part_id,
            answer_id: answer.english_answer_id,
            text: answer.english_answer_text,
          },
        ]
        setselectedAnswerText(result)
      } else {
        setselectedAnswer(answer.answer_id)
        let result = [
          {
            part_id: answer.answer_part_id,
            answer_id: answer.answer_id,
            text: answer.answer_text,
          },
        ]
        setselectedAnswerText(result)
      }

      //  const jsonString = JSON.stringify(result).replace(/\"/g, '\\"');
      //console.log(result);
    }

    //category 8

    // // Initialize state for answerText with initial data
    // const [answerText, setAnswerText] = useState([]);

    function handletext(e, answer_id, part_id) {
      //console.log(e)
      var answer_text = e.target.value
      //CountAnswerQuestion(part_id,answer_id, e.target.value);
      setselectedAnswerText(prevAnswerText => {
        // console.log(prevAnswerText);
        if (!answer_text.trim()) {
          // If text is empty or contains only whitespace, remove the corresponding entry
          return prevAnswerText.filter(item => item.answer_id !== answer_id)
        } else {
          const existingAnswerIndex = prevAnswerText.findIndex(
            item => item.answer_id === answer_id
          )

          if (existingAnswerIndex !== -1) {
            // If answer_id already exists, update the text
            const updatedAnswerText = [...prevAnswerText]
            updatedAnswerText[existingAnswerIndex] = {
              part_id: part_id,
              answer_id: answer_id,
              text: answer_text,
            }
            return updatedAnswerText
          } else {
            // If answer_id does not exist, add the new answer
            return [
              ...prevAnswerText,
              { part_id: part_id, answer_id: answer_id, text: answer_text },
            ]
          }
        }
      })
      setValuefield(true)
    }

    function handleAllowSpacetext(e, answer_id, part_id) {
      //console.log(e)
      var answer_text = e.target.value
      //CountAnswerQuestion(part_id,answer_id, e.target.value);
      setselectedAnswerText(prevAnswerText => {
        if (!answer_text) {
          // If text is empty or contains only whitespace, remove the corresponding entry
          return prevAnswerText.filter(item => item.answer_id !== answer_id)
        } else {
          const existingAnswerIndex = prevAnswerText.findIndex(
            item => item.answer_id === answer_id
          )

          if (existingAnswerIndex !== -1) {
            // If answer_id already exists, update the text
            const updatedAnswerText = [...prevAnswerText]
            updatedAnswerText[existingAnswerIndex] = {
              part_id: part_id,
              answer_id: answer_id,
              text: answer_text,
            }
            return updatedAnswerText
          } else {
            // If answer_id does not exist, add the new answer
            return [
              ...prevAnswerText,
              { part_id: part_id, answer_id: answer_id, text: answer_text },
            ]
          }
        }
      })
      setValuefield(true)
    }
    function handletextCheck(e, answer_id, part_id) {
      //console.log(e)
      var answer_text = e
      //CountAnswerQuestion(part_id,answer_id, e.target.value);
      setselectedAnswerText(prevAnswerText => {
        if (!answer_text.trim()) {
          // If text is empty or contains only whitespace, remove the corresponding entry
          return prevAnswerText.filter(item => item.answer_id !== answer_id)
        } else {
          const existingAnswerIndex = prevAnswerText.findIndex(
            item => item.answer_id === answer_id
          )

          if (existingAnswerIndex !== -1) {
            // If answer_id already exists, update the text
            const updatedAnswerText = [...prevAnswerText]
            updatedAnswerText[existingAnswerIndex] = {
              part_id: part_id,
              answer_id: answer_id,
              text: answer_text,
            }
            return updatedAnswerText
          } else {
            // If answer_id does not exist, add the new answer
            return [
              ...prevAnswerText,
              { part_id: part_id, answer_id: answer_id, text: answer_text },
            ]
          }
        }
      })
      setValuefield(true)
    }
    const textAreaRefs = useRef([])
    useEffect(() => {
      // Set focus to the first textarea when the component mounts
      //console.log(textAreaRefs.current[1])
      if (textAreaRefs.current[1]) {
        textAreaRefs.current[1].focus()
        // console.log('focused')
      }
    }, [ActivePartData, textAreaRefs.current[1]])

    useEffect(() => {
      //console.log(responceData);

      if (responceData && responceData != null) {
        setIsSubmited(false)
        setselectedAnswer(null)
        setresultData(responceData)
        let ansStatus = responceData.is_correct || null
        //setresultData(responceData);
        if (parseInt(ansStatus) === 1) {
          //green color
          if (parseInt(ActivityData1.fk_sub_id) === 1) {
            //english functionality correct answer start
            if (responceData.answerArray.length > 0) {
              //console.log(responceData.answerArray);
              // Create a copy of ActiveData
              const updatedData = [...ActivePartData]

              // Update the copied data
              updatedData.forEach(quspart => {
                //console.log(quspart)
                // quspart.english_question_answers.forEach((qusAns) => {
                // Assign answerArray directly to StudentAnsAttempts
                quspart.StudentAnsAttempts = responceData.answerArray
                // });
              })
              // Update the state with the modified data
              setActivePartData(updatedData)

              setselectedAnswerText([])

              if (
                ActivityData1 &&
                ActivityData1.english_question &&
                ActivityData1.english_question.length > 0 &&
                ActivityData1.english_question[0]
                  .english_question_category_id &&
                (ActivityData1.english_question[0]
                  .english_question_category_id === 8 ||
                  ActivityData1.english_question[0]
                    .english_question_category_id === 2 ||
                  ActivityData1.english_question[0]
                    .english_question_category_id === 12)
              ) {
                if (
                  (responceData && responceData.LastQuestion) ||
                  ActivityData1.is_current_tab_current_part ===
                    ActivityData1.is_current_tab_total_part
                ) {
                  setnextQuestion(false)
                } else {
                  // window.location.reload();
                  triggerFunction()
                }
              } else if (
                ActivityData1.english_question[0]
                  .english_question_category_id === 13 ||
                ActivityData1.english_question[0]
                  .english_question_category_id === 10
              ) {
                if (
                  (responceData && responceData.LastQuestion) ||
                  ActivityData1.is_first_question ===
                    ActivityData1.is_last_question
                ) {
                  setnextQuestion(false)
                } else {
                  // window.location.reload();
                  triggerFunction()
                }
              } else {
                setnextQuestion(false)
              }
            }
            //english functionality correct answer end
          } else {
            if (responceData.answerArray.length > 0) {
              //console.log(responceData.answerArray);
              // Create a copy of ActiveData
              const updatedData = [...ActivePartData]

              // Update the copied data
              updatedData.forEach(quspart => {
                //console.log(quspart)
                // quspart.english_question_answers.forEach((qusAns) => {
                // Assign answerArray directly to StudentAnsAttempts
                quspart.StudentAnsAttempts = responceData.answerArray
                // });
              })
              // Update the state with the modified data
              setActivePartData(updatedData)

              setselectedAnswerText([])

              if (
                parseInt(ActivityData1.is_current_tab_total_part) ===
                parseInt(ActivityData1.is_current_tab_current_part)
              ) {
                setnextQuestion(false)
              } else {
                // window.location.reload();
                triggerFunction()
              }
            }
          }
        } else {
          //console.log(responceData.answerArray);
          //red color

          if (parseInt(ActivityData1.fk_sub_id) === 1) {
            if (responceData.answerArray.length > 0) {
              // console.log(responceData.answerArray);
              const updatedData = [...ActivePartData]

              // Update the copied data
              updatedData.forEach(quspart => {
                //console.log(quspart)
                // quspart.english_question_answers.forEach((qusAns) => {
                // Assign answerArray directly to StudentAnsAttempts
                quspart.StudentAnsAttempts = responceData.answerArray
                // });
              })

              // Update the state with the modified data
              setActivePartData(updatedData)
            }

            if (responceData.attempCount === 2) {
              setselectedAnswerText([])
              setValuefield(false)
              if (
                (ActivityData1 &&
                  ActivityData1.english_question &&
                  ActivityData1.english_question.length > 0 &&
                  ActivityData1.english_question[0]
                    .english_question_category_id &&
                  (ActivityData1.english_question[0]
                    .english_question_category_id === 8 ||
                    ActivityData1.english_question[0]
                      .english_question_category_id === 2 ||
                    ActivityData1.english_question[0]
                      .english_question_category_id === 12)) ||
                parseInt(responceData.is_auto_mark) <= 0
              ) {
                if (
                  (responceData && responceData.LastQuestion === true) ||
                  ActivityData1.is_current_tab_current_part ===
                    ActivityData1.is_current_tab_total_part
                ) {
                  setnextQuestion(false)
                } else {
                  //window.location.reload();
                  triggerFunction()
                }
              } else if (
                ActivityData1.english_question[0]
                  .english_question_category_id === 13 ||
                ActivityData1.english_question[0]
                  .english_question_category_id === 10 ||
                parseInt(responceData.is_auto_mark) <= 0
              ) {
                if (
                  (responceData && responceData.LastQuestion) ||
                  ActivityData1.is_first_question ===
                    ActivityData1.is_last_question
                ) {
                  setnextQuestion(false)
                } else {
                  // window.location.reload();
                  triggerFunction()
                }
              } else {
                setnextQuestion(false)
              }
            } else {
              if (
                textAreaRefs &&
                textAreaRefs.current &&
                textAreaRefs.current
              ) {
                for (let i = 0; i < textAreaRefs.current.length; i++) {
                  if (
                    textAreaRefs.current[i] &&
                    textAreaRefs.current[i] instanceof HTMLTextAreaElement
                  ) {
                    textAreaRefs.current[i].focus()
                    break // Stop once the first valid textarea is found and focused
                  }
                }
                //textAreaRefs.current.focus();
              }
              setselectedAnswerText([])
              setValuefield(false)
              if (
                ActivityData1 &&
                ActivityData1.english_question &&
                ActivityData1.english_question.length > 0 &&
                ActivityData1.english_question[0]
                  .english_question_category_id &&
                (ActivityData1.english_question[0]
                  .english_question_category_id === 8 ||
                  ActivityData1.english_question[0]
                    .english_question_category_id === 2 ||
                  ActivityData1.english_question[0]
                    .english_question_category_id === 12) &&
                parseInt(responceData.is_auto_mark) <= 0
              ) {
                if (
                  (responceData && responceData.LastQuestion) ||
                  ActivityData1.is_current_tab_current_part ===
                    ActivityData1.is_current_tab_total_part
                ) {
                  setnextQuestion(false)
                } else {
                  //window.location.reload();
                  triggerFunction()
                }
              } else if (
                (ActivityData1.english_question[0]
                  .english_question_category_id === 13 ||
                  ActivityData1.english_question[0]
                    .english_question_category_id === 10) &&
                parseInt(responceData.is_auto_mark) <= 0
              ) {
                if (
                  (responceData && responceData.LastQuestion) ||
                  ActivityData1.is_first_question ===
                    ActivityData1.is_last_question
                ) {
                  setnextQuestion(false)
                } else {
                  // window.location.reload();
                  triggerFunction()
                }
              }
            }
          } else {
            if (responceData.answerArray.length > 0) {
              // console.log(responceData.answerArray);
              const updatedData = [...ActivePartData]

              // Update the copied data
              updatedData.forEach(quspart => {
                //console.log(quspart)
                // quspart.english_question_answers.forEach((qusAns) => {
                // Assign answerArray directly to StudentAnsAttempts
                quspart.StudentAnsAttempts = responceData.answerArray
                // });
              })

              // Update the state with the modified data
              setActivePartData(updatedData)
            }

            if (responceData.attempCount === 2) {
              setselectedAnswerText([])

              if (
                (responceData && responceData.LastQuestion === true) ||
                ActivityData1.is_current_tab_current_part ===
                  ActivityData1.is_current_tab_total_part
              ) {
                setnextQuestion(false)
              } else {
                //window.location.reload();
                triggerFunction()
              }
            } else {
              setselectedAnswerText([])
            }
          }
        }
      }
    }, [responceData])

    // console.log(ActivityData1);
    // console.log(responceData);
    // console.log(ActivePartData);

    // console.log(selectedAnswerText);
    //redirect Link
    const assessementSummary = () => {
      if (
        ActivityData1 &&
        ActivityData1.fk_lesson_activity_key &&
        ActivityData1.fk_lesson_activity_key != ""
      ) {
        if (ActivityData1.fk_sub_id && ActivityData1.fk_sub_id != "") {
          navigate(
            "/summary/" +
              ActivityData1.fk_lesson_activity_key +
              "?type=" +
              ActivityData1.fk_sub_id +
              "&homework=" +
              ActivityData1.IsHomework
          )
        } else {
          toast.error("Sorry, Activity Key Missing", {
            autoClose: 3000, // 3 seconds in this example
          })
        }
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    }

    // console.log(selectedGroup);
    // console.log(ResonTextData);
    function rearrangeEvenOddSecond(array) {
      const even = [] // To store even-indexed elements
      const odd = [] // To store odd-indexed elements

      array.forEach((element, index) => {
        if (index % 2 === 0) {
          even.push(element)
        } else {
          odd.push(element)
        }
      })

      // Combine odd and even arrays
      const rearranged = [...odd, ...even]

      return rearranged // Return the final rearranged array
    }

    function rearrangeEvenOdd(array) {
      const even = [] // To store even-indexed elements
      const odd = [] // To store odd-indexed elements

      array.forEach((element, index) => {
        if (index % 2 === 0) {
          even.push(element)
        } else {
          odd.push(element)
        }
      })

      // Combine odd and even arrays
      const rearranged = [...odd, ...even]

      // Check if further rearrangement is needed
      if (rearranged.length > 1) {
        return rearrangeEvenOddSecond(rearranged) // Call recursively for further rearrangement
      }

      return rearranged // Return the final rearranged array
    }

    // const shuffleArray = (array) => {
    //   for (let i = array.length - 1; i > 0; i--) {
    //     const j = Math.floor(Math.random() * (i + 1));
    //     [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    //   }
    //   return array;
    // }

    const handleKeyDown = e => {
      // Check if Enter key (keyCode 13) is pressed
      if (e.key === "Enter") {
        if (e.target.tagName === "TEXTAREA") {
          return // Do nothing if the target is an input or textarea
        }
        const submitButton = document.querySelector(".submit-btn-question")
        if (submitButton && !IsSubmited) {
          submitButton.click() // Trigger the click event on the button
        }
      }
    }

    // const handleKeyPress = e => {


    
    //   if (e.key === "Enter") {
    //     if (resultData && !resultData.LastQuestion) {

    //       const nextquestionbutton =
    //         document.querySelector(".next-btn-question");
           
    //       nextquestionbutton.click()
    //     } else if (resultData && !resultData.LastQuestion) {
    //       const summarybutton =
    //       document.querySelector(".summary-btn-question");
         
    //       summarybutton.click()
    //     }
    //   }
    // }
    const handleKeyPress = e => {
    
    
      if (e.key === "Enter") {
       
        if (resultData && !resultData.LastQuestion) {
          const nextquestionbutton = document.querySelector(".next-btn-question");
          if (nextquestionbutton) {
            nextquestionbutton.click();
          }
        } else if (resultData && resultData.LastQuestion) {
         
          const summarybutton = document.querySelector(".summary-btn-question");
          if (summarybutton) {
            summarybutton.click(); 
          }
        }
      }
    };

    const navigatorRef = useRef(null);
    useEffect(() => {
      if (navigatorRef.current) {
        navigatorRef.current.scrollIntoView({
          behavior: "smooth",  
          block: "start", 
        });
      }
    }, [resultData]);

    useEffect(() => {
      document.addEventListener("keydown", handleKeyPress)
      return () => {
        document.removeEventListener("keydown", handleKeyPress)
      }
    }, [resultData])

    //Avathr image functionality
    //useEffect
    const [userGender, setUserGender] = useState(
      localStorage.getItem("usergender") || null
    )
    //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'
    const [maleAvatarData, setMaleAvatarData] = useState([])
    const [femaleAvatarData, setFemaleAvatarData] = useState([])

    const [AvatarGendar, setAvatarGendar] = useState(
      localStorage.getItem("usergender") === "Female" ? true : false
    )
    useEffect(() => {
      if (userGender && userGender == "Male") {
        setAvatarGendar(false)
      } else if (userGender && userGender == "Female") {
        setAvatarGendar(true)
      } else {
        setAvatarGendar(false)
      }
    }, [userGender])
    useEffect(() => {
      fetchStudentAvatarData()
    }, [])
    const togAvatarGendar = () => {
      setAvatarGendar(!AvatarGendar)
    }

    const properties = {
      prevArrow: (
        <button className="prev-btn">
          <i className="fas fa-arrow-circle-left"></i>
        </button>
      ),
      nextArrow: (
        <button className="next-btn">
          <i className="fas fa-arrow-circle-right"></i>
        </button>
      ),
    }
    //resource male
    const [maleAvatarHeadData, setMaleAvatarHeadData] = useState({})
    const [maleAvatarArmourData, setMaleAvatarArmourData] = useState({})
    const [maleAvatarHandData, setMaleAvatarHandData] = useState({})
    const [maleAvatarWeaponData, setMaleAvatarWeaponData] = useState({})
    const [maleAvatarPetData, setMaleAvatarPetData] = useState({})

    //resource female
    const [femaleAvatarHeadData, setFemaleAvatarHeadData] = useState({})
    const [femaleAvatarArmourData, setFemaleAvatarArmourData] = useState({})
    const [femaleAvatarHandData, setFemaleAvatarHandData] = useState({})
    const [femaleAvatarWeaponData, setFemaleAvatarWeaponData] = useState({})
    const [femaleAvatarPetData, setFemaleAvatarPetData] = useState({})

    const fetchStudentAvatarData = async () => {
      if (authUser && authUser != "" && authUser != null) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "studentAvatar/StudentAvatarDeatils", {})
          .then(responseAvatar => {
            if (responseAvatar.data != "") {
              if (responseAvatar.data.maleInventory.length > 0) {
                setMaleAvatarData(responseAvatar.data.maleInventory)

                //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'

                const filteredMaleHeadArray =
                  responseAvatar.data.maleInventory.filter(
                    item => parseInt(item.resource_type) === 0
                  )
                if (filteredMaleHeadArray.length > 0) {
                  setMaleAvatarHeadData(filteredMaleHeadArray[0])
                } else {
                  setMaleAvatarHeadData({})
                }

                const filteredMaleArmourArray =
                  responseAvatar.data.maleInventory.filter(
                    item => parseInt(item.resource_type) === 1
                  )
                if (filteredMaleArmourArray.length > 0) {
                  setMaleAvatarArmourData(filteredMaleArmourArray[0])
                } else {
                  setMaleAvatarArmourData({})
                }

                const filteredMaleHandArray =
                  responseAvatar.data.maleInventory.filter(
                    item => parseInt(item.resource_type) === 2
                  )
                if (filteredMaleHandArray.length > 0) {
                  setMaleAvatarHandData(filteredMaleHandArray[0])
                } else {
                  setMaleAvatarHandData({})
                }

                const filteredMaleWeaponArray =
                  responseAvatar.data.maleInventory.filter(
                    item => parseInt(item.resource_type) === 3
                  )
                if (filteredMaleWeaponArray.length > 0) {
                  setMaleAvatarWeaponData(filteredMaleWeaponArray[0])
                } else {
                  setMaleAvatarWeaponData({})
                }

                const filteredMalePetArray =
                  responseAvatar.data.maleInventory.filter(
                    item => parseInt(item.resource_type) === 4
                  )
                if (filteredMalePetArray.length > 0) {
                  setMaleAvatarPetData(filteredMalePetArray[0])
                } else {
                  setMaleAvatarPetData({})
                }
              } else {
                setMaleAvatarData([])
                setMaleAvatarHeadData({})
                setMaleAvatarArmourData({})
                setMaleAvatarHandData({})
                setMaleAvatarWeaponData({})
                setMaleAvatarPetData({})
              }

              if (responseAvatar.data.femaleInventory.length > 0) {
                setFemaleAvatarData(responseAvatar.data.femaleInventory)
                //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'

                const filteredFeMaleHeadArray =
                  responseAvatar.data.femaleInventory.filter(
                    item => parseInt(item.resource_type) === 0
                  )
                if (filteredFeMaleHeadArray.length > 0) {
                  setFemaleAvatarHeadData(filteredFeMaleHeadArray[0])
                } else {
                  setFemaleAvatarHeadData({})
                }

                const filteredFeMaleArmourArray =
                  responseAvatar.data.femaleInventory.filter(
                    item => parseInt(item.resource_type) === 1
                  )
                if (filteredFeMaleArmourArray.length > 0) {
                  setFemaleAvatarArmourData(filteredFeMaleArmourArray[0])
                } else {
                  setFemaleAvatarArmourData({})
                }

                const filteredFeMaleHandArray =
                  responseAvatar.data.femaleInventory.filter(
                    item => parseInt(item.resource_type) === 2
                  )
                if (filteredFeMaleHandArray.length > 0) {
                  setFemaleAvatarHandData(filteredFeMaleHandArray[0])
                } else {
                  setFemaleAvatarHandData({})
                }

                const filteredFeMaleWeaponArray =
                  responseAvatar.data.femaleInventory.filter(
                    item => parseInt(item.resource_type) === 3
                  )
                if (filteredFeMaleWeaponArray.length > 0) {
                  setFemaleAvatarWeaponData(filteredFeMaleWeaponArray[0])
                } else {
                  setFemaleAvatarWeaponData({})
                }

                const filteredFeMalePetArray =
                  responseAvatar.data.femaleInventory.filter(
                    item => parseInt(item.resource_type) === 4
                  )
                if (filteredFeMalePetArray.length > 0) {
                  setFemaleAvatarPetData(filteredFeMalePetArray[0])
                } else {
                  setFemaleAvatarPetData({})
                }
              } else {
                setFemaleAvatarData([])
                setFemaleAvatarHeadData({})
                setFemaleAvatarArmourData({})
                setFemaleAvatarHandData({})
                setFemaleAvatarWeaponData({})
                setFemaleAvatarPetData({})
              }
            } else {
              setMaleAvatarData([])
              setMaleAvatarHeadData({})
              setMaleAvatarArmourData({})
              setMaleAvatarHandData({})
              setMaleAvatarWeaponData({})
              setMaleAvatarPetData({})

              setFemaleAvatarData([])
              setFemaleAvatarHeadData({})
              setFemaleAvatarArmourData({})
              setFemaleAvatarHandData({})
              setFemaleAvatarWeaponData({})
              setFemaleAvatarPetData({})
            }
          })
          .catch(error => {
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        navigate("/login")
      }
    }

    // Function to add "character_" prefix to filename
    const modifyUrl = url => {
      const parts = url.split("/")
      const filename = parts[parts.length - 1]
      const modifiedFilename = `character_${filename}`
      parts[parts.length - 1] = modifiedFilename
      return parts.join("/")
    }
    const ReDirectProfile = async () => {
      navigate("/profile")
    }
    // student avatar system



   

    return ActivityData1 &&
      ActivityData1.fk_sub_id &&
      parseInt(ActivityData1.fk_sub_id) === 1 ? (
      ActivityData1.english_question.map((ActivityData, index) => {
        if (index === 0) {
          if (
            ActivityData &&
            ActivityData.english_question_category_id &&
            parseInt(ActivityData.english_question_category_id) === 1
          ) {
            return (
              <div className="hw-list mt-4 pb-100">
                {/* <ToastContainer autoClose={3000} /> */}
                <Modal isOpen={modal_standard1} toggle={tog_standard1}>
                  <ModalHeader toggle={tog_standard1}></ModalHeader>
                  <ModalBody>
                    <p className="text-center fw-bold text-dark mb-0 font-size-18">
                      Report this question to your tutor as being incorrectly
                      marked?
                    </p>
                  </ModalBody>
                  <ModalFooter className="justify-content-center">
                    <Button
                      className="btn-cst-success-md"
                      disabled={isFlagReport}
                      onClick={() => {
                        submitQuestionReport()
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn-cst-danger-md"
                      onClick={() => {
                        tog_standard1()
                      }}
                    >
                      No
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal isOpen={modal_standard} toggle={tog_standard}>
                  <Form
                    className="mt-4"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    action="#"
                  >
                    <ModalHeader toggle={tog_standard}>
                      Flag this Question
                    </ModalHeader>
                    <ModalBody>
                      <div className="flex-grow-1 mb-3 cs-rsm">
                        <Label>Select a Reason</Label>
                        <Select
                          name="reson"
                          id="reson"
                          className="cs-style bg-mutant"
                          value={optionGroup.find(
                            option =>
                              option.value &&
                              validation.values.reson &&
                              option.value.toString() ==
                                validation.values.reson.toString()
                          )}
                          placeholder="Select"
                          onChange={selectedOption => {
                            const event = {
                              target: {
                                name: "reson",
                                value: selectedOption["value"],
                              },
                            }
                            validation.handleChange(event)
                          }}
                          onBlur={event => {
                            validation.handleBlur(event)
                          }}
                          // invalid={
                          //   validation.touched.reson &&
                          //   validation.errors.reson
                          //     ? true
                          //     : false
                          // }
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                        {validation.touched.reson && validation.errors.reson ? (
                          <div className="invalid-feedback-all">
                            {validation.errors.reson}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex-grow-1 cs-rsm">
                        <Label>Reason:</Label>
                        <Input
                          style={{ height: "200px" }}
                          type="textarea"
                          name="reson_text"
                          id="reson_text"
                          // onChange={e=> {
                          // textareachange(e)
                          // }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.reson_text || ""}
                          // invalid={
                          //   validation.touched.reson_text &&
                          //   validation.errors.reson_text
                          //     ? true
                          //     : false
                          // }
                          maxLength="225"
                          rows="3"
                          placeholder="Write your reason here..."
                        />
                        {validation.touched.reson_text &&
                        validation.errors.reson_text ? (
                          <div className="invalid-feedback-all">
                            {validation.errors.reson_text}
                          </div>
                        ) : null}
                      </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                      <Button
                        type="submit"
                        className="btn btn-danger cs-btn-danger"
                        disabled={isFlagReport}
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </Form>
                </Modal>
                <ul className="list-unstyled mb-0 question_container">
                  <li
                    className="justify-content-between"
                    onKeyDown={handleKeyDown}
                    tabIndex={index}
                  >
                    <div className="d-flex align-items-start gap-4 justify-content-end">
                      {/* <p className="mb-0 text-uppercase font-size-16">
                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic.level_name
                          ? ActivityData.english_question_has_sub_topic
                              .level_name + " /"
                          : null}

                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic.strand_name
                          ? ActivityData.english_question_has_sub_topic
                              .strand_name + " /"
                          : null}

                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic.topic_name
                          ? ActivityData.english_question_has_sub_topic
                              .topic_name + " /"
                          : null}

                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic
                          .sub_topic_name
                          ? ActivityData.english_question_has_sub_topic
                              .sub_topic_name
                          : null}
                      </p> */}
                      <div className="mb-0 d-flex align-items-center gap-2">
                        <p className="mb-0">
                          #
                          {ActivityData.english_question_id
                            ? ActivityData.english_question_id
                            : null}
                        </p>
                        <span onClick={tog_standard}>
                          <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                        </span>
                      </div>
                    </div>

                    <div
                      className="mt-4"
                      dangerouslySetInnerHTML={{
                        __html: ActivityData.english_question_text
                          ? ActivityData.english_question_text
                              .replace(/\[(\d+)Answer char=\d+\]/g, "")
                              .replace(/\[Answer char=\d+\]/g, "")
                          : null,
                      }}
                    ></div>

                    <ul className="dg-selector width-controll list-unstyled mb-0">
                      {ActivePartData &&
                        ActivePartData.map((quspart, index) => {
                          //const stuAnswersarray = quspart.StudentAnsAttempts.map(stuAns => stuAns.student_answer);

                          const stuAnswersarray =
                            quspart.StudentAnsAttempts.map(stuAns => {
                              try {
                                return JSON.parse(stuAns.student_answer)
                              } catch (error) {
                                console.error("Error parsing JSON:", error)
                                return null // or handle the error in an appropriate way
                              }
                            }) || [] // Extracting session keys

                          // Assuming stuAnswersarray is a nested array
                          const flattenedStuAnswers = [].concat(
                            ...stuAnswersarray
                          )
                          let correct_count = 0
                          let serialNumber = 0
                          //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                          return (
                            quspart.english_question_answers &&
                            rearrangeEvenOdd(
                              quspart.english_question_answers
                            ).map((qusAns, index1) => {
                              //   if ((!qusAns.english_answer_text.replace(/&nbsp;/g, '').replace(/<[^>]*>/g, '') || qusAns.english_answer_text.replace(/&nbsp;/g, '').replace(/<[^>]*>/g, '').trim() === '')
                              //   && !(/<img\s+[^>]*>/gi.test(qusAns.english_answer_text))) {
                              //     return null; // Skip rendering if english_answer_text is null
                              // }
                              const cleanText = qusAns.english_answer_text
                                .replace(/&nbsp;/g, "")
                                .replace(/`/g, "")
                                .replace(/<[^>]*>/g, "")
                                .trim()

                              const hasImage = /<img\s+[^>]*>/gi.test(
                                qusAns.english_answer_text
                              )

                              if (!cleanText && !hasImage) {
                                return null // Skip rendering if english_answer_text is effectively empty and no image is present
                              }
                              serialNumber++
                              let filteredStuAns =
                                flattenedStuAnswers.filter(
                                  stuAns =>
                                    parseInt(stuAns.part_id) ===
                                      parseInt(qusAns.english_answer_part_id) &&
                                    parseInt(stuAns.answer_id) ===
                                      parseInt(qusAns.english_answer_id)
                                ) || []

                              var status_color =
                                "selector-general cursor-pointer"
                              var border_color = "border-start-secondary"

                              if (filteredStuAns.length > 0) {
                                if (
                                  parseInt(qusAns.english_answer_is_correct) ===
                                  1
                                ) {
                                  status_color = "selector-success"
                                  border_color = "border-start-success"

                                  //if(selectedAnswer && selectedAnswer === qusAns.english_answer_id){
                                  correct_count += 1
                                  // }
                                } else {
                                  status_color = "selector-wrong "
                                  border_color = "border-start-danger"
                                }
                              }

                              //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                              return (
                                <>
                                  <li
                                    className={
                                      selectedAnswer &&
                                      selectedAnswer ===
                                        qusAns.english_answer_id
                                        ? status_color +
                                          " active choose e_choose"
                                        : status_color + " choose e_choose"
                                    }
                                    key={index1}
                                    onClick={() => selectedAnsOption(qusAns)}
                                  >
                                    <div className="p-2 px-3 font-size-18">
                                      {serialNumber}
                                    </div>
                                    <div
                                      className={
                                        "p-2 dg-media " + { border_color }
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: qusAns.english_answer_text
                                          ? qusAns.english_answer_text
                                          : null,
                                      }}
                                    ></div>
                                  </li>
                                  {index1 + 1 >=
                                    quspart.english_question_answers.length &&
                                  quspart.StudentAnsAttempts.length === 1 &&
                                  parseInt(
                                    quspart.english_question_part_automark
                                  ) === 1 ? (
                                    correct_count > 0 ? null : (
                                      <div className="d-flex justify-content-end">
                                        <span className="text-danger font-size-14 mt-auto mb-2">
                                          Try again with a different answer.
                                        </span>
                                      </div>
                                    )
                                  ) : null}
                                </>
                              )
                            })
                          )
                        })}
                    </ul>

                    {!nextQuestion ? (
                      <>
                        <h4 className="font-size-16 fw-bold mb-2 mt-3">
                          Solution
                        </h4>
                        <div
                          className="e_feedBack"
                          style={{
                            border: "1px solid #1a1a1a",
                            borderRadius: "4px",
                            fontSize: "16px",
                          }}
                        >
                          {ActivityData.english_question_parts &&
                            ActivityData.english_question_parts.map(
                              (quspart, index) => {
                                let filteredAns =
                                  quspart.english_question_answers.filter(
                                    CorrAns =>
                                      parseInt(
                                        CorrAns.english_answer_is_correct
                                      ) === 1
                                  )
                                return filteredAns.map((qusAns, index1) => {
                                  return (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: qusAns.english_answer_text
                                          ? qusAns.english_answer_text
                                          : null,
                                      }}
                                    ></div>
                                  )
                                })
                              }
                            )}
                        </div>
                      </>
                    ) : null}

                    <div className="text-end mt-3 mb-5">
                      {selectedAnswerText &&
                      selectedAnswerText != null &&
                      selectedAnswerText.length > 0 &&
                      nextQuestion ? (
                        <Button
                          className="submit-btn-question btn btn-danger cs-btn-danger mt-1"
                          disabled={IsSubmited}
                          onClick={() => {
                            triggerSubmitFunction(selectedAnswerText)
                            setIsSubmited(true)
                          }}
                        >
                          Submit
                        </Button>
                      ) : null}
                    </div>
                  </li>
                </ul>

                {!nextQuestion ? (
                  <div className="navigator-wrapper position-relative">
                    {/* <div className="nv-dg-smile">
                      <img width={400} src={dsmile} alt="" /> 
                  </div> */}
                    <div className="nv-dg-smile-avatar">
                      {/* <img width={400} src={dsmile} alt="" /> */}
                      <div className="avatar-wrapper">
                        {/* student avatar start */}
                        <div className="super-profile_wrapper img-consolidate-display">
                          <div className="content-left">
                            <div className="super-avatar-display">
                              {/* <Slide
                              autoplay={false}
                              infinite={false}
                              onStartChange={togAvatarGendar}
                              {...properties}
                            > */}
                              {/* male functionality start */}
                              {!AvatarGendar ? (
                                <div className="each-slide-effect cursor-pointer">
                                  <div className="pirate-media male position-relative">
                                    <div className="avatar-display text-center position-relative">
                                      <div className="avatar-default">
                                        <img
                                          width={450}
                                          className=""
                                          src={piratemale}
                                          alt=""
                                        />
                                      </div>
                                      <div className="avatar-attachments">
                                        {maleAvatarHeadData &&
                                        maleAvatarHeadData != "" &&
                                        maleAvatarHeadData.image ? (
                                          <div className="head-float position-absolute">
                                            <img
                                              className=""
                                              src={modifyUrl(
                                                maleAvatarHeadData.image
                                              )}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarArmourData &&
                                        maleAvatarArmourData != "" &&
                                        maleAvatarArmourData.image ? (
                                          <div className="dress-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarArmourData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarPetData &&
                                        maleAvatarPetData != "" &&
                                        maleAvatarPetData.image ? (
                                          <div className="companion-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarPetData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarHandData &&
                                        maleAvatarHandData != "" &&
                                        maleAvatarHandData.image ? (
                                          <>
                                            <div className="hand-float position-absolute">
                                              <img
                                                className=""
                                                src={maleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>

                                            <div className="hand-float-revert position-absolute">
                                              <img
                                                className=""
                                                src={maleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                          </>
                                        ) : null}

                                        {maleAvatarWeaponData &&
                                        maleAvatarWeaponData != "" &&
                                        maleAvatarWeaponData.image ? (
                                          <div className="weapon-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarWeaponData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="each-slide-effect cursor-pointer">
                                  <div className="pirate-media female position-relative">
                                    <div className="avatar-display text-center position-relative">
                                      <div className="avatar-default">
                                        <img
                                          width={450}
                                          className=""
                                          src={piratefemale}
                                          alt=""
                                        />
                                      </div>
                                      <div className="avatar-attachments">
                                        {femaleAvatarHeadData &&
                                        femaleAvatarHeadData != "" &&
                                        femaleAvatarHeadData.image ? (
                                          <div className="head-float female-hd position-absolute">
                                            <img
                                              className=""
                                              src={modifyUrl(
                                                femaleAvatarHeadData.image
                                              )}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarArmourData &&
                                        femaleAvatarArmourData != "" &&
                                        femaleAvatarArmourData.image ? (
                                          <div className="dress-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarArmourData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarPetData &&
                                        femaleAvatarPetData != "" &&
                                        femaleAvatarPetData.image ? (
                                          <div className="companion-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarPetData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarHandData &&
                                        femaleAvatarHandData != "" &&
                                        femaleAvatarHandData.image ? (
                                          <>
                                            <div className="hand-float position-absolute">
                                              <img
                                                className=""
                                                src={femaleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                            <div className="hand-float-revert position-absolute">
                                              <img
                                                className=""
                                                src={femaleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                          </>
                                        ) : null}

                                        {femaleAvatarWeaponData &&
                                        femaleAvatarWeaponData != "" &&
                                        femaleAvatarWeaponData.image ? (
                                          <div className="weapon-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarWeaponData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Female functionality end */}
                              {/* </Slide> */}
                            </div>
                          </div>
                        </div>

                        {/* student avatar end */}
                      </div>
                    </div>
                    <div className="navigator-nxt" ref={navigatorRef}>
                      <p className="opacity-75">
                        SUGGESTED TIME:{" "}
                        {ActivityData.english_question_time_needed}
                      </p>
                      <p className="font-size-16 d-flex align-items-center gap-2">
                        <img width={24} src={awicon} />{" "}
                        {resultData ? resultData.latestScore : 0} /{" "}
                        {resultData && resultData.question_length
                          ? resultData.question_length * 2
                          : 0}{" "}
                        coins earned
                      </p>
                      <p className="opacity-75">
                        {resultData
                          ? parseInt(resultData.accuracyOfOtherStudents)
                          : 0}
                        % OF STUDENTS GOT IT RIGHT
                      </p>
                      {resultData ? (
                        !resultData.LastQuestion ? (
                          <Button
                            className="next-btn-question btn btn-danger cs-btn-danger mt-1"
                            onClick={() => {
                              //window.location.reload();
                              triggerFunction()
                              setnextQuestion(false)
                              setActivePartData([])
                              setIsSubmited(false)
                              setresultData(null)
                            }}
                          >
                            Next Question
                          </Button>
                        ) : (
                          <Button
                            className="summary-btn-question btn btn-danger cs-btn-danger mt-1"
                            onClick={() => assessementSummary()}
                          >
                            Go to summary
                          </Button>
                        )
                      ) : null}

                      {resultData &&
                      resultData.attempCount &&
                      parseInt(resultData.attempCount) >= 2 ? (
                        <span
                          className="font-size-15 mt-3 text-dark fw-bold"
                          onClick={tog_standard1}
                          style={{
                            fontStyle: "italic",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          It was marked incorrectly
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            )
          } else if (
            ActivityData &&
            ActivityData.english_question_category_id &&
            (parseInt(ActivityData.english_question_category_id) === 8 ||
              parseInt(ActivityData.english_question_category_id) === 2 ||
              parseInt(ActivityData.english_question_category_id) === 12)
          ) {
            return (
              <div className="hw-list mt-4 pb-100 not_scroll">
                {/* <ToastContainer autoClose={3000} /> */}
                <Modal isOpen={modal_standard1} toggle={tog_standard1}>
                  <ModalHeader toggle={tog_standard1}></ModalHeader>
                  <ModalBody>
                    <p className="text-center fw-bold text-dark mb-0 font-size-18">
                      Report this question to your tutor as being incorrectly
                      marked?
                    </p>
                  </ModalBody>
                  <ModalFooter className="justify-content-center">
                    <Button
                      className="btn-cst-success-md"
                      disabled={isFlagReport}
                      onClick={() => {
                        submitQuestionReport()
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn-cst-danger-md"
                      onClick={() => {
                        tog_standard1()
                      }}
                    >
                      No
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal isOpen={modal_standard} toggle={tog_standard}>
                  <Form
                    className="mt-4"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    action="#"
                  >
                    <ModalHeader toggle={tog_standard}>
                      Flag this Question
                    </ModalHeader>
                    <ModalBody>
                      <div className="flex-grow-1 mb-3 cs-rsm">
                        <Label>Select a Reason</Label>
                        <Select
                          name="reson"
                          id="reson"
                          className="cs-style bg-mutant"
                          value={optionGroup.find(
                            option =>
                              option.value &&
                              validation.values.reson &&
                              option.value.toString() ==
                                validation.values.reson.toString()
                          )}
                          placeholder="Select"
                          onChange={selectedOption => {
                            const event = {
                              target: {
                                name: "reson",
                                value: selectedOption["value"],
                              },
                            }
                            validation.handleChange(event)
                          }}
                          onBlur={event => {
                            validation.handleBlur(event)
                          }}
                          // invalid={
                          //   validation.touched.reson &&
                          //   validation.errors.reson
                          //     ? true
                          //     : false
                          // }
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                        {validation.touched.reson && validation.errors.reson ? (
                          <div className="invalid-feedback-all">
                            {validation.errors.reson}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex-grow-1 cs-rsm">
                        <Label>Reason:</Label>
                        <Input
                          style={{ height: "200px" }}
                          type="textarea"
                          name="reson_text"
                          id="reson_text"
                          // onChange={e=> {
                          // textareachange(e)
                          // }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.reson_text || ""}
                          // invalid={
                          //   validation.touched.reson_text &&
                          //   validation.errors.reson_text
                          //     ? true
                          //     : false
                          // }
                          maxLength="225"
                          rows="3"
                          placeholder="Write your reason here..."
                        />
                        {validation.touched.reson_text &&
                        validation.errors.reson_text ? (
                          <div className="invalid-feedback-all">
                            {validation.errors.reson_text}
                          </div>
                        ) : null}
                      </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                      <Button
                        type="submit"
                        className="btn btn-danger cs-btn-danger"
                        disabled={isFlagReport}
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </Form>
                </Modal>
                <ul className="list-unstyled mb-0 question_container">
                  <li
                    className="justify-content-between"
                    onKeyDown={handleKeyDown}
                    tabIndex={index}
                  >
                    <div className="d-flex align-items-start gap-4 justify-content-end">
                      {/* <p className="mb-0 text-uppercase font-size-16">
                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic.level_name
                          ? ActivityData.english_question_has_sub_topic
                              .level_name + " /"
                          : null}

                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic.strand_name
                          ? ActivityData.english_question_has_sub_topic
                              .strand_name + " /"
                          : null}

                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic.topic_name
                          ? ActivityData.english_question_has_sub_topic
                              .topic_name + " /"
                          : null}

                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic
                          .sub_topic_name
                          ? ActivityData.english_question_has_sub_topic
                              .sub_topic_name
                          : null}
                      </p> */}
                      <div className="mb-0 d-flex align-items-center gap-2">
                        <p className="mb-0">
                          #
                          {ActivityData.english_question_id
                            ? ActivityData.english_question_id
                            : null}
                        </p>
                        <span onClick={tog_standard}>
                          <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                        </span>
                      </div>
                    </div>
                    <div
                      className="mt-3"
                      dangerouslySetInnerHTML={{
                        __html: ActivityData.english_question_instruction
                          ? ActivityData.english_question_instruction
                              .replace(/\[(\d+)Answer char=\d+\]/g, "")
                              .replace(/\[Answer char=\d+\]/g, "")
                          : null,
                      }}
                    ></div>

                    {/* Choice */}
                    {ActivityData &&
                    ActivityData.english_question_choices &&
                    ActivityData.english_question_choices.length > 0 ? (
                      <div className="mt-4 my-2 border border-dark choice-options_wrapper">
                        {ActivityData.english_question_choices.map(
                          (qusChoice, Cindex) => (
                            <div class="choice-options_child" key={Cindex}>
                              <div>{qusChoice.choice_no}</div>
                              <div>{qusChoice.choice_text}</div>
                            </div>
                          )
                        )}
                      </div>
                    ) : null}

                    {ActivityData.english_question_text &&
                    parseInt(ActivityData.english_question_category_id) ===
                      8 ? (
                      <div
                        className="mt-4"
                        dangerouslySetInnerHTML={{
                          __html: ActivityData.english_question_text
                            ? ActivityData.english_question_text
                                .replace(/\[(\d+)Answer char=\d+\]/g, "")
                                .replace(/\[Answer char=\d+\]/g, "")
                                .replace(/&nbsp;/g, "")
                                .split("</p>")
                                .map((paragraph, index) =>
                                  paragraph
                                    ? `<div class="d-flex"><p class="me-2">${
                                        index + 1
                                      } ${paragraph}</p></div>`
                                    : ""
                                )
                                .filter(Boolean)
                                .join("")
                            : null,
                        }}
                      />
                    ) : (
                      <div
                        className="mt-4"
                        dangerouslySetInnerHTML={{
                          __html: ActivityData.english_question_text
                            ? ActivityData.english_question_text
                                .replace(/\[(\d+)Answer char=\d+\]/g, "")
                                .replace(/\[Answer char=\d+\]/g, "")
                            : null,
                        }}
                      ></div>
                    )}
                    {ActivityData &&
                    ActivityData.part_heading &&
                    ActivityData.part_heading != "" ? (
                      <div
                        className="mt-4"
                        dangerouslySetInnerHTML={{
                          __html: ActivityData.part_heading
                            .replace(/\[(\d+)Answer char=\d+\]/g, "")
                            .replace(/\[Answer char=\d+\]/g, ""),
                        }}
                      ></div>
                    ) : null}
                    {/* File option */}
                    <div className="mt-4 my-2">
                      {ActivityData &&
                      ActivityData.english_question_files &&
                      ActivityData.english_question_files.length > 0
                        ? ActivityData.english_question_files.map(
                            (qusFile, index) => (
                              <img
                                key={index}
                                alt=""
                                style={{ height: "auto", maxWidth: "100%" }}
                                src={`https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/question_files/${qusFile.file_name}`}
                              />
                            )
                          )
                        : null}
                    </div>

                    {/* Past question */}
                    {ActivityData.english_question_parts &&
                      ActivityData.english_question_parts.map(
                        (quspart, index) => {
                          const stuAnswersarray =
                            quspart.StudentAnsAttempts.map(stuAns => {
                              try {
                                return JSON.parse(stuAns.student_answer)
                              } catch (error) {
                                console.error("Error parsing JSON:", error)
                                return null // or handle the error in an appropriate way
                              }
                            }) || [] // Extracting session keys

                          // Assuming stuAnswersarray is a nested array
                          const flattenedStuAnswers = [].concat(
                            ...stuAnswersarray
                          )
                          let serialNumber = 0
                          return index + 1 <
                            ActivityData1.is_current_tab_current_part ? (
                            <div key={index}>
                              <div className="d-flex gap-2 flex-row ">
                                <div className="mr-3">
                                  {index + 1}
                                  {")"}
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: quspart.english_question_part_text
                                      ? quspart.english_question_part_text
                                          .replace(
                                            /\[(\d+)Answer char=\d+\]/g,
                                            ""
                                          )
                                          .replace(/\[Answer char=\d+\]/g, "")
                                      : null,
                                  }}
                                ></div>
                              </div>
                              {/* Anse category statement Star */}
                              {quspart.english_question_part_answer_type ===
                                8 ||
                              quspart.english_question_part_answer_type === 1 ||
                              quspart.english_question_part_answer_type ===
                                7 ? (
                                <ul className="dg-selector width-controll list-unstyled mb-0">
                                  {" "}
                                  {parseInt(
                                    quspart.english_question_part_automark
                                  ) === 1 ? (
                                    ""
                                  ) : (
                                    <div className="d-flex justify-content-end">
                                      <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                        Manual Marking
                                      </span>
                                    </div>
                                  )}
                                  {quspart.english_question_answers &&
                                    quspart.english_question_answers.map(
                                      (qusAns, index1) => {
                                        // Your logic for filtering student answers and determining status_color and border_color
                                        const stuAnswersarray =
                                          quspart.StudentAnsAttempts.map(
                                            stuAns => {
                                              try {
                                                return JSON.parse(
                                                  stuAns.student_answer
                                                )
                                              } catch (error) {
                                                console.error(
                                                  "Error parsing JSON:",
                                                  error
                                                )
                                                return null // or handle the error in an appropriate way
                                              }
                                            }
                                          ) || [] // Extracting session keys
                                        //console.log(stuAnswersarray);
                                        // Assuming stuAnswersarray is a nested array
                                        const flattenedStuAnswers = [].concat(
                                          ...stuAnswersarray
                                        )
                                        let cleanedText =
                                          qusAns.english_answer_text
                                            .replace(/&nbsp;/g, "")
                                            .replace(/`/g, "")
                                            .replace(/<[^>]*>/g, "") || ""
                                        let resultTest = cleanedText
                                          .toLowerCase()
                                          .trim()
                                        let answers =
                                          resultTest.split("|") || []
                                        let firstAnswer = answers[0]
                                          ? answers[0].trim()
                                          : ""
                                        let secondAnswer = answers[1]
                                          ? answers[1].trim()
                                          : ""
                                        if (
                                          qusAns.english_answer_is_correct === 1
                                        ) {
                                          let filteredStuAns =
                                            flattenedStuAnswers.filter(
                                              stuAns =>
                                                parseInt(stuAns.part_id) ===
                                                  parseInt(
                                                    qusAns.english_answer_part_id
                                                  ) &&
                                                (stuAns.text
                                                  .toLowerCase()
                                                  .trim() == resultTest ||
                                                  stuAns.text
                                                    .toLowerCase()
                                                    .trim() == firstAnswer ||
                                                  stuAns.text
                                                    .toLowerCase()
                                                    .trim() == secondAnswer) &&
                                                parseInt(stuAns.answer_id) ===
                                                  parseInt(
                                                    qusAns.english_answer_id
                                                  )
                                            ) || []

                                          let filteredAllStuAns =
                                            flattenedStuAnswers.filter(
                                              stuAns =>
                                                parseInt(stuAns.part_id) ===
                                                  parseInt(
                                                    qusAns.english_answer_part_id
                                                  ) &&
                                                parseInt(stuAns.answer_id) ===
                                                  parseInt(
                                                    qusAns.english_answer_id
                                                  )
                                            ) || []

                                          return (
                                            <li
                                              key={index1}
                                              className={
                                                parseInt(
                                                  quspart.english_question_part_automark
                                                ) === 1
                                                  ? filteredStuAns.length > 0
                                                    ? "input_box e_input_box"
                                                    : "input_box e_input_box"
                                                  : " input_box e_input_box"
                                              }
                                            >
                                              <textarea
                                                id={
                                                  index1 +
                                                  Math.floor(Math.random() * 5)
                                                }
                                                type="text"
                                                className={
                                                  parseInt(
                                                    quspart.english_question_part_automark
                                                  ) === 1
                                                    ? filteredStuAns.length > 0
                                                      ? "answersuccess "
                                                      : "answererror"
                                                    : "answereditor "
                                                }
                                                value={
                                                  filteredAllStuAns
                                                    .filter(
                                                      item =>
                                                        parseInt(
                                                          item.answer_id
                                                        ) ===
                                                        parseInt(
                                                          qusAns.english_answer_id
                                                        )
                                                    )
                                                    .reverse()
                                                    .map(item => item.text)
                                                    .find(
                                                      text => text !== undefined
                                                    ) || "" // Find the first non-undefined text value
                                                }
                                                disabled={true}
                                                //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                onChange={e =>
                                                  handletext(
                                                    e,
                                                    qusAns.english_answer_id,
                                                    qusAns.english_answer_part_id
                                                  )
                                                }
                                                // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                              />
                                            </li>
                                          )
                                        }
                                      }
                                    )}
                                </ul>
                              ) : quspart.english_question_part_answer_type ===
                                4 ? (
                                //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                                <ul className="dg-selector width-controll list-unstyled mb-0">
                                  {parseInt(
                                    quspart.english_question_part_automark
                                  ) === 1 ? (
                                    ""
                                  ) : (
                                    <div className="d-flex justify-content-end">
                                      <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                        Manual Marking
                                      </span>
                                    </div>
                                  )}
                                  {quspart.english_question_answers &&
                                    rearrangeEvenOdd(
                                      quspart.english_question_answers
                                    ).map((qusAns, index1) => {
                                      const cleanText =
                                        qusAns.english_answer_text
                                          .replace(/&nbsp;/g, "")
                                          .replace(/`/g, "")
                                          .replace(/<[^>]*>/g, "")
                                          .trim()

                                      const hasImage = /<img\s+[^>]*>/gi.test(
                                        qusAns.english_answer_text
                                      )

                                      if (!cleanText && !hasImage) {
                                        return null // Skip rendering if english_answer_text is effectively empty and no image is present
                                      }
                                      serialNumber++
                                      let filteredStuAns =
                                        flattenedStuAnswers.filter(
                                          stuAns =>
                                            parseInt(stuAns.part_id) ===
                                              parseInt(
                                                qusAns.english_answer_part_id
                                              ) &&
                                            parseInt(stuAns.answer_id) ===
                                              parseInt(qusAns.english_answer_id)
                                        ) || []

                                      var status_color =
                                        "selector-general cursor-pointer"
                                      var border_color =
                                        "border-start-secondary"
                                      if (
                                        filteredStuAns.length > 0 &&
                                        parseInt(
                                          quspart.english_question_part_automark
                                        ) === 1
                                      ) {
                                        if (
                                          parseInt(
                                            qusAns.english_answer_is_correct
                                          ) === 1
                                        ) {
                                          status_color = "selector-success"
                                          border_color = "border-start-success"
                                        } else {
                                          status_color = "selector-wrong "
                                          border_color = "border-start-danger"
                                        }
                                      }
                                      //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                      return (
                                        <li
                                          className={
                                            selectedAnswer &&
                                            selectedAnswer ===
                                              qusAns.english_answer_id
                                              ? status_color +
                                                " active choose e_choose"
                                              : status_color +
                                                " choose e_choose"
                                          }
                                          key={index1}
                                        >
                                          <div className="p-2 px-3 font-size-18">
                                            {serialNumber}
                                          </div>
                                          <div
                                            className={
                                              "p-2 dg-media " + { border_color }
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html: qusAns.english_answer_text
                                                ? qusAns.english_answer_text
                                                : null,
                                            }}
                                          ></div>
                                        </li>
                                      )
                                    })}
                                </ul>
                              ) : (
                                <p className="text-center">No option</p>
                              )}
                              {/* Anse category statement end */}
                            </div>
                          ) : null
                        }
                      )}

                    {/* past question End */}
                    {ActivePartData &&
                      ActivePartData.map((quspart, index) => {
                        const stuAnswersarray =
                          quspart.StudentAnsAttempts.map(stuAns => {
                            try {
                              return JSON.parse(stuAns.student_answer)
                            } catch (error) {
                              console.error("Error parsing JSON:", error)
                              return null // or handle the error in an appropriate way
                            }
                          }) || [] // Extracting session keys

                        // Assuming stuAnswersarray is a nested array
                        const flattenedStuAnswers = [].concat(
                          ...stuAnswersarray
                        )
                        let correct_count = 0
                        let serialNumber = 0
                        let initial_question = 0
                        return (
                          <div key={1001}>
                            <div className="d-flex gap-2 flex-row ">
                              <div className="mr-3">
                                {ActivityData1.is_first_question}
                                {")"}
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: quspart.english_question_part_text
                                    ? quspart.english_question_part_text
                                        .replace(
                                          /\[(\d+)Answer char=\d+\]/g,
                                          ""
                                        )
                                        .replace(/\[Answer char=\d+\]/g, "")
                                    : null,
                                }}
                              ></div>
                            </div>
                            {/* Anse category statement Star */}
                            {quspart.english_question_part_answer_type === 8 ||
                            quspart.english_question_part_answer_type === 1 ||
                            quspart.english_question_part_answer_type === 7 ? (
                              <ul className="dg-selector width-controll list-unstyled mb-0">
                                {resultData &&
                                resultData.is_auto_mark &&
                                resultData.is_auto_mark == "0" &&
                                parseInt(
                                  quspart.english_question_part_automark
                                ) <= 0 ? (
                                  <div className="d-flex justify-content-end">
                                    <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                      Manual Marking
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {quspart.english_question_answers &&
                                  quspart.english_question_answers.map(
                                    (qusAns, index1) => {
                                      // Your logic for filtering student answers and determining status_color and border_color
                                      const stuAnswersarray =
                                        quspart.StudentAnsAttempts.map(
                                          stuAns => {
                                            try {
                                              return JSON.parse(
                                                stuAns.student_answer
                                              )
                                            } catch (error) {
                                              console.error(
                                                "Error parsing JSON:",
                                                error
                                              )
                                              return null // or handle the error in an appropriate way
                                            }
                                          }
                                        ) || [] // Extracting session keys
                                      // console.log(stuAnswersarray);
                                      // Assuming stuAnswersarray is a nested array
                                      const flattenedStuAnswers = [].concat(
                                        ...stuAnswersarray
                                      )
                                      let cleanedText =
                                        qusAns.english_answer_text
                                          .replace(/&nbsp;/g, "")
                                          .replace(/`/g, "")
                                          .replace(/<[^>]*>/g, "") || ""
                                      let resultTest = cleanedText
                                        .toLowerCase()
                                        .trim()
                                      let answers = resultTest.split("|") || []
                                      let firstAnswer = answers[0]
                                        ? answers[0].trim()
                                        : ""
                                      let secondAnswer = answers[1]
                                        ? answers[1].trim()
                                        : ""

                                      if (
                                        qusAns.english_answer_is_correct === 1
                                      ) {
                                        initial_question++

                                        let filteredStuAns =
                                          flattenedStuAnswers.filter(
                                            stuAns =>
                                              parseInt(stuAns.part_id) ===
                                                parseInt(
                                                  qusAns.english_answer_part_id
                                                ) &&
                                              (stuAns.text
                                                .toLowerCase()
                                                .trim() == resultTest ||
                                                stuAns.text
                                                  .toLowerCase()
                                                  .trim() == firstAnswer ||
                                                stuAns.text
                                                  .toLowerCase()
                                                  .trim() == secondAnswer) &&
                                              parseInt(stuAns.answer_id) ===
                                                parseInt(
                                                  qusAns.english_answer_id
                                                )
                                          ) || []

                                        let filteredAllStuAns =
                                          flattenedStuAnswers.filter(
                                            stuAns =>
                                              parseInt(stuAns.part_id) ===
                                                parseInt(
                                                  qusAns.english_answer_part_id
                                                ) &&
                                              parseInt(stuAns.answer_id) ===
                                                parseInt(
                                                  qusAns.english_answer_id
                                                )
                                          ) || []

                                        return (
                                          <>
                                            <li
                                              key={index1}
                                              className={
                                                parseInt(
                                                  quspart.english_question_part_automark
                                                ) === 1
                                                  ? quspart.StudentAnsAttempts
                                                      .length > 0 &&
                                                    filteredStuAns.length > 0
                                                    ? "input_box e_input_box"
                                                    : "input_box e_input_box"
                                                  : "input_box e_input_box"
                                              }
                                            >
                                              <textarea
                                                id={
                                                  index1 +
                                                  Math.floor(Math.random() * 5)
                                                }
                                                type="text"
                                                autoFocus={
                                                  initial_question === 1
                                                }
                                                ref={el =>
                                                  (textAreaRefs.current[
                                                    initial_question
                                                  ] = el)
                                                }
                                                className={
                                                  quspart.StudentAnsAttempts
                                                    .length > 0 &&
                                                  parseInt(
                                                    quspart.english_question_part_automark
                                                  ) === 1
                                                    ? filteredStuAns.length > 0
                                                      ? "answersuccess initial_question"
                                                      : "answererror initial_question"
                                                    : "answereditor initial_question"
                                                }
                                                value={
                                                  resultData &&
                                                  resultData.is_correct &&
                                                  resultData.is_correct === 1
                                                    ? filteredAllStuAns
                                                        .filter(
                                                          item =>
                                                            parseInt(
                                                              item.answer_id
                                                            ) ===
                                                            parseInt(
                                                              qusAns.english_answer_id
                                                            )
                                                        )
                                                        .reverse()
                                                        .map(item => item.text)
                                                        .find(
                                                          text =>
                                                            text !== undefined
                                                        ) ||
                                                      selectedAnswerText
                                                        .filter(
                                                          item =>
                                                            parseInt(
                                                              item.answer_id
                                                            ) ===
                                                            parseInt(
                                                              qusAns.english_answer_id
                                                            )
                                                        )
                                                        .map(item => item.text)
                                                        .find(
                                                          text =>
                                                            text !== undefined
                                                        ) ||
                                                      "" // Find the first non-undefined text value
                                                    : isValuefield &&
                                                      filteredStuAns.length <= 0
                                                    ? selectedAnswerText
                                                        .filter(
                                                          item =>
                                                            parseInt(
                                                              item.answer_id
                                                            ) ===
                                                            parseInt(
                                                              qusAns.english_answer_id
                                                            )
                                                        )
                                                        .map(item => item.text)
                                                        .find(
                                                          text =>
                                                            text !== undefined
                                                        ) || ""
                                                    : filteredAllStuAns
                                                        .filter(
                                                          item =>
                                                            parseInt(
                                                              item.answer_id
                                                            ) ===
                                                            parseInt(
                                                              qusAns.english_answer_id
                                                            )
                                                        )
                                                        .reverse()
                                                        .map(item => item.text)
                                                        .find(
                                                          text =>
                                                            text !== undefined
                                                        ) || ""
                                                }
                                                disabled={
                                                  filteredStuAns.length > 0
                                                    ? true
                                                    : false
                                                }
                                                //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                onChange={e =>
                                                  handletext(
                                                    e,
                                                    qusAns.english_answer_id,
                                                    qusAns.english_answer_part_id
                                                  )
                                                }
                                                // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                              />
                                            </li>
                                            {quspart.StudentAnsAttempts
                                              .length === 1 &&
                                            parseInt(
                                              quspart.english_question_part_automark
                                            ) === 1 ? (
                                              filteredStuAns.length >
                                              0 ? null : (
                                                <div className="d-flex justify-content-end">
                                                  <span className="text-danger font-size-14 mt-auto mb-2">
                                                    Try again with a different
                                                    answer.
                                                  </span>
                                                </div>
                                              )
                                            ) : null}
                                          </>
                                        )
                                      }
                                    }
                                  )}
                              </ul>
                            ) : quspart.english_question_part_answer_type ===
                              4 ? (
                              //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                              <ul className="dg-selector width-controll list-unstyled mb-0">
                                {resultData &&
                                resultData.is_auto_mark &&
                                resultData.is_auto_mark == "0" &&
                                parseInt(
                                  quspart.english_question_part_automark
                                ) <= 1 ? (
                                  <div className="d-flex justify-content-end">
                                    <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                      Manual Marking
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {quspart.english_question_answers &&
                                  rearrangeEvenOdd(
                                    quspart.english_question_answers
                                  ).map((qusAns, index1) => {
                                    //   if ((!qusAns.english_answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '')
                                    //     || qusAns.english_answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '').trim() === '')
                                    //   && !(/<img\s+[^>]*>/gi.test(qusAns.english_answer_text))) {
                                    //     return null; // Skip rendering if english_answer_text is null
                                    // }

                                    const cleanText = qusAns.english_answer_text
                                      .replace(/&nbsp;/g, "")
                                      .replace(/`/g, "")
                                      .replace(/<[^>]*>/g, "")
                                      .trim()

                                    const hasImage = /<img\s+[^>]*>/gi.test(
                                      qusAns.english_answer_text
                                    )

                                    if (!cleanText && !hasImage) {
                                      return null // Skip rendering if english_answer_text is effectively empty and no image is present
                                    }

                                    serialNumber++

                                    let filteredStuAns =
                                      flattenedStuAnswers.filter(
                                        stuAns =>
                                          parseInt(stuAns.part_id) ===
                                            parseInt(
                                              qusAns.english_answer_part_id
                                            ) &&
                                          parseInt(stuAns.answer_id) ===
                                            parseInt(qusAns.english_answer_id)
                                      ) || []

                                    var status_color =
                                      "selector-general cursor-pointer"
                                    var border_color = "border-start-secondary"
                                    if (filteredStuAns.length > 0) {
                                      if (
                                        parseInt(
                                          qusAns.english_answer_is_correct
                                        ) === 1
                                      ) {
                                        status_color = "selector-success"
                                        border_color = "border-start-success"
                                        correct_count += 1
                                      } else {
                                        status_color = "selector-wrong "
                                        border_color = "border-start-danger"
                                      }
                                    }
                                    //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                    return (
                                      <>
                                        <li
                                          className={
                                            selectedAnswer &&
                                            selectedAnswer ===
                                              qusAns.english_answer_id
                                              ? status_color +
                                                " active choose e_choose"
                                              : status_color +
                                                " choose e_choose"
                                          }
                                          key={index1}
                                          onClick={() =>
                                            selectedAnsOption(qusAns)
                                          }
                                        >
                                          <div className="p-2 px-3 font-size-18">
                                            {serialNumber}
                                          </div>
                                          <div
                                            className={
                                              "p-2 dg-media " + { border_color }
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html: qusAns.english_answer_text
                                                ? qusAns.english_answer_text
                                                : null,
                                            }}
                                          ></div>
                                        </li>
                                        {index1 + 1 >=
                                          quspart.english_question_answers
                                            .length &&
                                        quspart.StudentAnsAttempts.length ===
                                          1 &&
                                        parseInt(
                                          quspart.english_question_part_automark
                                        ) === 1 ? (
                                          correct_count > 0 ? null : (
                                            <div className="d-flex justify-content-end">
                                              <span className="text-danger font-size-14 mt-auto mb-2">
                                                Try again with a different
                                                answer.
                                              </span>
                                            </div>
                                          )
                                        ) : null}
                                      </>
                                    )
                                  })}
                              </ul>
                            ) : (
                              <p className="text-center">No option</p>
                            )}
                            {/* Anse category statement end */}
                          </div>
                        )
                      })}

                    <div className="text-end mt-3 mb-5">
                      {resultData && resultData.is_auto_mark == "0" ? (
                        ""
                      ) : selectedAnswerText &&
                        selectedAnswerText.length > 0 &&
                        nextQuestion ? (
                        <Button
                          className="submit-btn-question btn btn-danger cs-btn-danger mt-1"
                          disabled={IsSubmited}
                          onClick={() => {
                            triggerSubmitFunction(selectedAnswerText)
                            setIsSubmited(true)
                          }}
                        >
                          Submit
                        </Button>
                      ) : null}
                    </div>
                  </li>
                </ul>

                {!nextQuestion ? (
                  <div className="navigator-wrapper position-relative">
                    {/* <div className="nv-dg-smile">
                      <img width={400} src={dsmile} alt="" /> 
                  </div> */}
                    <div className="nv-dg-smile-avatar">
                      {/* <img width={400} src={dsmile} alt="" /> */}
                      <div className="avatar-wrapper">
                        {/* student avatar start */}
                        <div className="super-profile_wrapper img-consolidate-display">
                          <div className="content-left">
                            <div className="super-avatar-display">
                              {/* <Slide
                              autoplay={false}
                              infinite={false}
                              onStartChange={togAvatarGendar}
                              {...properties}
                            > */}
                              {/* male functionality start */}
                              {!AvatarGendar ? (
                                <div className="each-slide-effect cursor-pointer">
                                  <div className="pirate-media male position-relative">
                                    <div className="avatar-display text-center position-relative">
                                      <div className="avatar-default">
                                        <img
                                          width={450}
                                          className=""
                                          src={piratemale}
                                          alt=""
                                        />
                                      </div>
                                      <div className="avatar-attachments">
                                        {maleAvatarHeadData &&
                                        maleAvatarHeadData != "" &&
                                        maleAvatarHeadData.image ? (
                                          <div className="head-float position-absolute">
                                            <img
                                              className=""
                                              src={modifyUrl(
                                                maleAvatarHeadData.image
                                              )}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarArmourData &&
                                        maleAvatarArmourData != "" &&
                                        maleAvatarArmourData.image ? (
                                          <div className="dress-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarArmourData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarPetData &&
                                        maleAvatarPetData != "" &&
                                        maleAvatarPetData.image ? (
                                          <div className="companion-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarPetData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarHandData &&
                                        maleAvatarHandData != "" &&
                                        maleAvatarHandData.image ? (
                                          <>
                                            <div className="hand-float position-absolute">
                                              <img
                                                className=""
                                                src={maleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>

                                            <div className="hand-float-revert position-absolute">
                                              <img
                                                className=""
                                                src={maleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                          </>
                                        ) : null}

                                        {maleAvatarWeaponData &&
                                        maleAvatarWeaponData != "" &&
                                        maleAvatarWeaponData.image ? (
                                          <div className="weapon-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarWeaponData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="each-slide-effect cursor-pointer">
                                  <div className="pirate-media female position-relative">
                                    <div className="avatar-display text-center position-relative">
                                      <div className="avatar-default">
                                        <img
                                          width={450}
                                          className=""
                                          src={piratefemale}
                                          alt=""
                                        />
                                      </div>
                                      <div className="avatar-attachments">
                                        {femaleAvatarHeadData &&
                                        femaleAvatarHeadData != "" &&
                                        femaleAvatarHeadData.image ? (
                                          <div className="head-float female-hd position-absolute">
                                            <img
                                              className=""
                                              src={modifyUrl(
                                                femaleAvatarHeadData.image
                                              )}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarArmourData &&
                                        femaleAvatarArmourData != "" &&
                                        femaleAvatarArmourData.image ? (
                                          <div className="dress-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarArmourData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarPetData &&
                                        femaleAvatarPetData != "" &&
                                        femaleAvatarPetData.image ? (
                                          <div className="companion-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarPetData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarHandData &&
                                        femaleAvatarHandData != "" &&
                                        femaleAvatarHandData.image ? (
                                          <>
                                            <div className="hand-float position-absolute">
                                              <img
                                                className=""
                                                src={femaleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                            <div className="hand-float-revert position-absolute">
                                              <img
                                                className=""
                                                src={femaleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                          </>
                                        ) : null}

                                        {femaleAvatarWeaponData &&
                                        femaleAvatarWeaponData != "" &&
                                        femaleAvatarWeaponData.image ? (
                                          <div className="weapon-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarWeaponData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Female functionality end */}
                              {/* </Slide> */}
                            </div>
                          </div>
                        </div>

                        {/* student avatar end */}
                      </div>
                    </div>
                    <div className="navigator-nxt" ref={navigatorRef}>
                      <p className="opacity-75">
                        SUGGESTED TIME:{" "}
                        {ActivityData.english_question_time_needed}
                      </p>
                      <p className="font-size-16 d-flex align-items-center gap-2">
                        <img width={24} src={awicon} />{" "}
                        {resultData ? resultData.latestScore : 0} /{" "}
                        {resultData && resultData.question_length
                          ? resultData.question_length * 2
                          : 0}{" "}
                        coins earned
                      </p>
                      <p className="opacity-75">
                        {resultData
                          ? parseInt(resultData.accuracyOfOtherStudents)
                          : 0}
                        % OF STUDENTS GOT IT RIGHT
                      </p>
                      {resultData ? (
                        !resultData.LastQuestion ? (
                          <Button
                            className="next-btn-question btn btn-danger cs-btn-danger mt-1"
                            onClick={() => {
                              //window.location.reload();
                              triggerFunction()
                              setnextQuestion(false)
                              setActivePartData([])
                              setIsSubmited(false)
                              setresultData(null)
                            }}
                          >
                            Next Question
                          </Button>
                        ) : (
                          <Button
                            className="summary-btn-question btn btn-danger cs-btn-danger mt-1"
                            onClick={() => assessementSummary()}
                          >
                            Go to summary
                          </Button>
                        )
                      ) : null}

                      {resultData &&
                      resultData.attempCount &&
                      parseInt(resultData.attempCount) >= 2 ? (
                        <span
                          className="font-size-15 mt-3 text-dark fw-bold"
                          onClick={tog_standard1}
                          style={{
                            fontStyle: "italic",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          It was marked incorrectly
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            )
          } else if (
            ActivityData &&
            ActivityData.english_question_category_id &&
            (parseInt(ActivityData.english_question_category_id) === 13 ||
              parseInt(ActivityData.english_question_category_id) === 10)
          ) {
            return (
              <div className="hw-list mt-4 pb-100 not_scroll">
                {/* <ToastContainer autoClose={3000} /> */}
                <Modal isOpen={modal_standard1} toggle={tog_standard1}>
                  <ModalHeader toggle={tog_standard1}></ModalHeader>
                  <ModalBody>
                    <p className="text-center fw-bold text-dark mb-0 font-size-18">
                      Report this question to your tutor as being incorrectly
                      marked?
                    </p>
                  </ModalBody>
                  <ModalFooter className="justify-content-center">
                    <Button
                      className="btn-cst-success-md"
                      disabled={isFlagReport}
                      onClick={() => {
                        submitQuestionReport()
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn-cst-danger-md"
                      onClick={() => {
                        tog_standard1()
                      }}
                    >
                      No
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal isOpen={modal_standard} toggle={tog_standard}>
                  <Form
                    className="mt-4"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    action="#"
                  >
                    <ModalHeader toggle={tog_standard}>
                      Flag this Question
                    </ModalHeader>
                    <ModalBody>
                      <div className="flex-grow-1 mb-3 cs-rsm">
                        <Label>Select a Reason</Label>
                        <Select
                          name="reson"
                          id="reson"
                          className="cs-style bg-mutant"
                          value={optionGroup.find(
                            option =>
                              option.value &&
                              validation.values.reson &&
                              option.value.toString() ==
                                validation.values.reson.toString()
                          )}
                          placeholder="Select"
                          onChange={selectedOption => {
                            const event = {
                              target: {
                                name: "reson",
                                value: selectedOption["value"],
                              },
                            }
                            validation.handleChange(event)
                          }}
                          onBlur={event => {
                            validation.handleBlur(event)
                          }}
                          // invalid={
                          //   validation.touched.reson &&
                          //   validation.errors.reson
                          //     ? true
                          //     : false
                          // }
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                        {validation.touched.reson && validation.errors.reson ? (
                          <div className="invalid-feedback-all">
                            {validation.errors.reson}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex-grow-1 cs-rsm">
                        <Label>Reason:</Label>
                        <Input
                          style={{ height: "200px" }}
                          type="textarea"
                          name="reson_text"
                          id="reson_text"
                          // onChange={e=> {
                          // textareachange(e)
                          // }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.reson_text || ""}
                          // invalid={
                          //   validation.touched.reson_text &&
                          //   validation.errors.reson_text
                          //     ? true
                          //     : false
                          // }
                          maxLength="225"
                          rows="3"
                          placeholder="Write your reason here..."
                        />
                        {validation.touched.reson_text &&
                        validation.errors.reson_text ? (
                          <div className="invalid-feedback-all">
                            {validation.errors.reson_text}
                          </div>
                        ) : null}
                      </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                      <Button
                        type="submit"
                        className="btn btn-danger cs-btn-danger"
                        disabled={isFlagReport}
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </Form>
                </Modal>
                <ul className="list-unstyled mb-0 question_container">
                  <li
                    className="justify-content-between"
                    onKeyDown={handleKeyDown}
                    tabIndex={index}
                  >
                    <div className="d-flex align-items-start gap-4 justify-content-end">
                      {/* <p className="mb-0 text-uppercase font-size-16">
                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic.level_name
                          ? ActivityData.english_question_has_sub_topic
                              .level_name + " /"
                          : null}

                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic.strand_name
                          ? ActivityData.english_question_has_sub_topic
                              .strand_name + " /"
                          : null}

                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic.topic_name
                          ? ActivityData.english_question_has_sub_topic
                              .topic_name + " /"
                          : null}

                        {ActivityData.english_question_has_sub_topic &&
                        ActivityData.english_question_has_sub_topic
                          .sub_topic_name
                          ? ActivityData.english_question_has_sub_topic
                              .sub_topic_name
                          : null}
                      </p> */}
                      <div className="mb-0 d-flex align-items-center gap-2">
                        <p className="mb-0">
                          #
                          {ActivityData.english_question_id
                            ? ActivityData.english_question_id
                            : null}
                        </p>
                        <span onClick={tog_standard}>
                          <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                        </span>
                      </div>
                    </div>
                    <div
                      className="mt-3"
                      dangerouslySetInnerHTML={{
                        __html: ActivityData.english_question_instruction
                          ? ActivityData.english_question_instruction
                              .replace(/\[(\d+)Answer char=\d+\]/g, "")
                              .replace(/\[Answer char=\d+\]/g, "")
                          : null,
                      }}
                    ></div>

                    {/* Choice */}
                    {ActivityData &&
                    ActivityData.english_question_choices &&
                    ActivityData.english_question_choices.length > 0 ? (
                      <div className="mt-4 my-2 border border-dark choice-options_wrapper">
                        {ActivityData.english_question_choices.map(
                          (qusChoice, Cindex) => (
                            <div class="choice-options_child" key={Cindex}>
                              <div>{qusChoice.choice_no}</div>
                              <div>{qusChoice.choice_text}</div>
                            </div>
                          )
                        )}
                      </div>
                    ) : null}

                    <div
                      className="mt-4"
                      dangerouslySetInnerHTML={{
                        __html: ActivityData.english_question_text
                          ? ActivityData.english_question_text
                              .replace(/\[(\d+)Answer char=\d+\]/g, "")
                              .replace(/\[Answer char=\d+\]/g, "")
                          : null,
                      }}
                    ></div>
                    {ActivityData &&
                    ActivityData.part_heading &&
                    ActivityData.part_heading != "" ? (
                      <div
                        className="mt-4"
                        dangerouslySetInnerHTML={{
                          __html: ActivityData.part_heading
                            .replace(/\[(\d+)Answer char=\d+\]/g, "")
                            .replace(/\[Answer char=\d+\]/g, ""),
                        }}
                      ></div>
                    ) : null}
                    {/* File option */}
                    <div className="mt-4 my-2">
                      {ActivityData &&
                      ActivityData.english_question_files &&
                      ActivityData.english_question_files.length > 0
                        ? ActivityData.english_question_files.map(
                            (qusFile, index) => (
                              <img
                                key={index}
                                alt=""
                                style={{ height: "auto", maxWidth: "100%" }}
                                src={`https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/question_files/${qusFile.file_name}`}
                              />
                            )
                          )
                        : null}
                    </div>

                    {/* Past question */}
                    {ActivityData.english_question_parts &&
                      ActivityData.english_question_parts.map(
                        (quspart, index) => {
                          if (
                            quspart.english_question_answers &&
                            quspart.english_question_answers.length > 0 &&
                            quspart.english_question_answers[0]
                              .english_answer_text &&
                            quspart.english_question_answers[0]
                              .english_answer_text != ""
                          ) {
                            const stuAnswersarray =
                              quspart.StudentAnsAttempts.map(stuAns => {
                                try {
                                  return JSON.parse(stuAns.student_answer)
                                } catch (error) {
                                  console.error("Error parsing JSON:", error)
                                  return null // or handle the error in an appropriate way
                                }
                              }) || [] // Extracting session keys

                            // Assuming stuAnswersarray is a nested array
                            const flattenedStuAnswers = [].concat(
                              ...stuAnswersarray
                            )
                            let serialNumber = 0
                            return index + 1 <
                              ActivityData1.is_current_tab_current_part ? (
                              <div
                                key={index}
                                className="d-flex justify-content-between align-items-center mt-3"
                              >
                                <div className="d-flex gap-2 flex-row ">
                                  {/* <div className="mr-3">{index + 1}</div> */}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: quspart.english_question_part_text
                                        ? quspart.english_question_part_text
                                            .replace(
                                              /\[\[(.+?)\]\]/g,
                                              function (match, p1) {
                                                return p1.length > 1
                                                  ? `<span class="text-bg-yellow">${p1}</span>`
                                                  : `<span class="text-bg-blue">${p1}</span>`
                                              }
                                            )
                                            .replace(/\{\{/g, "<u>")
                                            .replace(/\}\}/g, "</u>")
                                            .replace(
                                              /\[(\d+)Answer char=\d+\]/g,
                                              ""
                                            )
                                            .replace(/\[Answer char=\d+\]/g, "")
                                        : null,
                                    }}
                                  ></div>
                                </div>
                                {/* Anse category statement Star */}
                                {quspart.english_question_part_answer_type ===
                                  8 ||
                                quspart.english_question_part_answer_type ===
                                  1 ||
                                quspart.english_question_part_answer_type ===
                                  7 ? (
                                  <ul className="dg-selector width-controll list-unstyled mb-0">
                                    {" "}
                                    {parseInt(
                                      quspart.english_question_part_automark
                                    ) === 1 ? (
                                      ""
                                    ) : (
                                      <div className="d-flex justify-content-end">
                                        <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                          Manual Marking
                                        </span>
                                      </div>
                                    )}
                                    {quspart.english_question_answers &&
                                      quspart.english_question_answers.map(
                                        (qusAns, index1) => {
                                          // Your logic for filtering student answers and determining status_color and border_color
                                          const stuAnswersarray =
                                            quspart.StudentAnsAttempts.map(
                                              stuAns => {
                                                try {
                                                  return JSON.parse(
                                                    stuAns.student_answer
                                                  )
                                                } catch (error) {
                                                  console.error(
                                                    "Error parsing JSON:",
                                                    error
                                                  )
                                                  return null // or handle the error in an appropriate way
                                                }
                                              }
                                            ) || [] // Extracting session keys
                                          //console.log(stuAnswersarray);
                                          // Assuming stuAnswersarray is a nested array
                                          const flattenedStuAnswers = [].concat(
                                            ...stuAnswersarray
                                          )
                                          let cleanedText =
                                            qusAns.english_answer_text
                                              .replace(/&nbsp;/g, "")
                                              .replace(/`/g, "")
                                              .replace(/<[^>]*>/g, "") || ""
                                          let resultTest = cleanedText
                                            .toLowerCase()
                                            .trim()
                                          let answers =
                                            resultTest.split("|") || []
                                          let firstAnswer = answers[0]
                                            ? answers[0].trim()
                                            : "No_Data"
                                          let secondAnswer = answers[1]
                                            ? answers[1].trim()
                                            : "No_Data"
                                          if (
                                            qusAns.english_answer_is_correct ===
                                            1
                                          ) {
                                            let filteredStuAns =
                                              flattenedStuAnswers.filter(
                                                stuAns =>
                                                  parseInt(stuAns.part_id) ===
                                                    parseInt(
                                                      qusAns.english_answer_part_id
                                                    ) &&
                                                  (stuAns.text
                                                    .toLowerCase()
                                                    .trim() == resultTest ||
                                                    stuAns.text
                                                      .toLowerCase()
                                                      .trim() == firstAnswer ||
                                                    stuAns.text
                                                      .toLowerCase()
                                                      .trim() ==
                                                      secondAnswer) &&
                                                  parseInt(stuAns.answer_id) ===
                                                    parseInt(
                                                      qusAns.english_answer_id
                                                    )
                                              ) || []

                                            let filteredAllStuAns =
                                              flattenedStuAnswers.filter(
                                                stuAns =>
                                                  parseInt(stuAns.part_id) ===
                                                    parseInt(
                                                      qusAns.english_answer_part_id
                                                    ) &&
                                                  parseInt(stuAns.answer_id) ===
                                                    parseInt(
                                                      qusAns.english_answer_id
                                                    )
                                              ) || []

                                            return (
                                              <li
                                                key={index1}
                                                className={
                                                  parseInt(
                                                    quspart.english_question_part_automark
                                                  ) === 1
                                                    ? filteredStuAns.length > 0
                                                      ? "input_box small_input_box"
                                                      : "input_box small_input_box"
                                                    : " input_box small_input_box"
                                                }
                                              >
                                                <input
                                                  id={
                                                    index1 +
                                                    Math.floor(
                                                      Math.random() * 5
                                                    )
                                                  }
                                                  type="text"
                                                  className={
                                                    parseInt(
                                                      quspart.english_question_part_automark
                                                    ) === 1
                                                      ? filteredStuAns.length >
                                                        0
                                                        ? "answersuccess"
                                                        : "answererror"
                                                      : "answereditor"
                                                  }
                                                  value={
                                                    filteredAllStuAns
                                                      .filter(
                                                        item =>
                                                          parseInt(
                                                            item.answer_id
                                                          ) ===
                                                          parseInt(
                                                            qusAns.english_answer_id
                                                          )
                                                      )
                                                      .reverse()
                                                      .map(item => item.text)
                                                      .find(
                                                        text =>
                                                          text !== undefined
                                                      ) || "" // Find the first non-undefined text value
                                                  }
                                                  disabled={true}
                                                  autoComplete="off"  
                                                  autoCorrect="off"   
                                                  spellCheck="false"
                                                  //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                  onChange={e =>
                                                    handletext(
                                                      e,
                                                      qusAns.english_answer_id,
                                                      qusAns.english_answer_part_id
                                                    )
                                                  }
                                                  // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                />
                                              </li>
                                            )
                                          }
                                        }
                                      )}
                                  </ul>
                                ) : quspart.english_question_part_answer_type ===
                                  4 ? (
                                  //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                                  <ul className="dg-selector width-controll list-unstyled mb-0">
                                    {parseInt(
                                      quspart.english_question_part_automark
                                    ) === 1 ? (
                                      ""
                                    ) : (
                                      <div className="d-flex justify-content-end">
                                        <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                          Manual Marking
                                        </span>
                                      </div>
                                    )}
                                    {quspart.english_question_answers &&
                                      rearrangeEvenOdd(
                                        quspart.english_question_answers
                                      ).map((qusAns, index1) => {
                                        //   if ((!qusAns.english_answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '')
                                        //     || qusAns.english_answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '').trim() === '')
                                        //   && !(/<img\s+[^>]*>/gi.test(qusAns.english_answer_text))) {
                                        //     return null; // Skip rendering if english_answer_text is null
                                        // }
                                        const cleanText =
                                          qusAns.english_answer_text
                                            .replace(/&nbsp;/g, "")
                                            .replace(/`/g, "")
                                            .replace(/<[^>]*>/g, "")
                                            .trim()

                                        const hasImage = /<img\s+[^>]*>/gi.test(
                                          qusAns.english_answer_text
                                        )

                                        if (!cleanText && !hasImage) {
                                          return null // Skip rendering if english_answer_text is effectively empty and no image is present
                                        }

                                        serialNumber++

                                        let filteredStuAns =
                                          flattenedStuAnswers.filter(
                                            stuAns =>
                                              parseInt(stuAns.part_id) ===
                                                parseInt(
                                                  qusAns.english_answer_part_id
                                                ) &&
                                              parseInt(stuAns.answer_id) ===
                                                parseInt(
                                                  qusAns.english_answer_id
                                                )
                                          ) || []

                                        var status_color =
                                          "selector-general cursor-pointer"
                                        var border_color =
                                          "border-start-secondary"
                                        if (
                                          filteredStuAns.length > 0 &&
                                          parseInt(
                                            quspart.english_question_part_automark
                                          ) === 1
                                        ) {
                                          if (
                                            parseInt(
                                              qusAns.english_answer_is_correct
                                            ) === 1
                                          ) {
                                            status_color = "selector-success"
                                            border_color =
                                              "border-start-success"
                                          } else {
                                            status_color = "selector-wrong "
                                            border_color = "border-start-danger"
                                          }
                                        }
                                        //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                        return (
                                          <li
                                            className={
                                              selectedAnswer &&
                                              selectedAnswer ===
                                                qusAns.english_answer_id
                                                ? status_color +
                                                  " active choose e_choose"
                                                : status_color +
                                                  " choose e_choose"
                                            }
                                            key={index1}
                                          >
                                            <div className="p-2 px-3 font-size-18">
                                              {serialNumber}
                                            </div>
                                            <div
                                              className={
                                                "p-2 dg-media " +
                                                { border_color }
                                              }
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  qusAns.english_answer_text
                                                    ? qusAns.english_answer_text
                                                    : null,
                                              }}
                                            ></div>
                                          </li>
                                        )
                                      })}
                                  </ul>
                                ) : (
                                  <p className="text-center">No option</p>
                                )}
                                {/* Anse category statement end */}
                              </div>
                            ) : null
                          } else {
                            return index + 1 <
                              ActivityData1.is_current_tab_current_part &&
                              quspart.english_question_part_text ? (
                              <div key={index} className="mt-3">
                                <div className="d-flex gap-2 flex-row w-75">
                                  {/* <div className="mr-3">{index + 1}</div> */}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: quspart.english_question_part_text
                                        ? quspart.english_question_part_text
                                            .replace(
                                              /\[\[(.+?)\]\]/g,
                                              function (match, p1) {
                                                return p1.length > 1
                                                  ? `<span class="text-bg-yellow">${p1}</span>`
                                                  : `<span class="text-bg-blue">${p1}</span>`
                                              }
                                            )
                                            .replace(/\{\{/g, "<u>")
                                            .replace(/\}\}/g, "</u>")
                                            .replace(
                                              /\[(\d+)Answer char=\d+\]/g,
                                              ""
                                            )
                                            .replace(/\[Answer char=\d+\]/g, "")
                                        : null,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            ) : null
                          }
                        }
                      )}

                    {/* past question End */}
                    {ActivePartData &&
                      ActivePartData.map((quspart, index) => {
                        const stuAnswersarray =
                          quspart.StudentAnsAttempts.map(stuAns => {
                            try {
                              return JSON.parse(stuAns.student_answer)
                            } catch (error) {
                              console.error("Error parsing JSON:", error)
                              return null // or handle the error in an appropriate way
                            }
                          }) || [] // Extracting session keys

                        // Assuming stuAnswersarray is a nested array
                        const flattenedStuAnswers = [].concat(
                          ...stuAnswersarray
                        )
                        let correct_count = 0
                        let serialNumber = 0
                        let initial_question = 0
                        return (
                          <div
                            key={1001}
                            className="d-flex justify-content-between align-items-center mt-3"
                          >
                            <div className="d-flex gap-2 flex-row ">
                              {/* <div className="mr-3">{ActivityData1.is_first_question}</div> */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: quspart.english_question_part_text
                                    ? quspart.english_question_part_text
                                        .replace(
                                          /\[\[(.+?)\]\]/g,
                                          function (match, p1) {
                                            return p1.length > 1
                                              ? `<span class="text-bg-yellow">${p1}</span>`
                                              : `<span class="text-bg-blue">${p1}</span>`
                                          }
                                        )
                                        .replace(/\{\{/g, "<u>")
                                        .replace(/\}\}/g, "</u>")
                                        .replace(
                                          /\[(\d+)Answer char=\d+\]/g,
                                          ""
                                        )
                                        .replace(/\[Answer char=\d+\]/g, "")
                                    : null,
                                }}
                              ></div>
                            </div>
                            {/* Anse category statement Star */}
                            {quspart.english_question_part_answer_type === 8 ||
                            quspart.english_question_part_answer_type === 1 ||
                            quspart.english_question_part_answer_type === 7 ? (
                              <ul className="dg-selector width-controll list-unstyled mb-0">
                                {resultData &&
                                resultData.is_auto_mark &&
                                resultData.is_auto_mark == "0" &&
                                parseInt(
                                  quspart.english_question_part_automark
                                ) <= 0 ? (
                                  <div className="d-flex justify-content-end">
                                    <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                      Manual Marking
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {quspart.english_question_answers &&
                                  quspart.english_question_answers.map(
                                    (qusAns, index1) => {
                                      // Your logic for filtering student answers and determining status_color and border_color
                                      const stuAnswersarray =
                                        quspart.StudentAnsAttempts.map(
                                          stuAns => {
                                            try {
                                              return JSON.parse(
                                                stuAns.student_answer
                                              )
                                            } catch (error) {
                                              console.error(
                                                "Error parsing JSON:",
                                                error
                                              )
                                              return null // or handle the error in an appropriate way
                                            }
                                          }
                                        ) || [] // Extracting session keys
                                      // console.log(stuAnswersarray);
                                      // Assuming stuAnswersarray is a nested array
                                      const flattenedStuAnswers = [].concat(
                                        ...stuAnswersarray
                                      )
                                      let cleanedText =
                                        qusAns.english_answer_text
                                          .replace(/&nbsp;/g, "")
                                          .replace(/`/g, "")
                                          .replace(/<[^>]*>/g, "") || ""
                                      let resultTest = cleanedText
                                        .toLowerCase()
                                        .trim()
                                      let answers = resultTest.split("|") || []
                                      let firstAnswer = answers[0]
                                        ? answers[0].trim()
                                        : "No_Data"
                                      let secondAnswer = answers[1]
                                        ? answers[1].trim()
                                        : "No_Data"

                                      if (
                                        qusAns.english_answer_is_correct === 1
                                      ) {
                                        initial_question++
                                        let filteredStuAns =
                                          flattenedStuAnswers.filter(
                                            stuAns =>
                                              parseInt(stuAns.part_id) ===
                                                parseInt(
                                                  qusAns.english_answer_part_id
                                                ) &&
                                              (stuAns.text
                                                .toLowerCase()
                                                .trim() == resultTest ||
                                                stuAns.text
                                                  .toLowerCase()
                                                  .trim() == firstAnswer ||
                                                stuAns.text
                                                  .toLowerCase()
                                                  .trim() == secondAnswer) &&
                                              parseInt(stuAns.answer_id) ===
                                                parseInt(
                                                  qusAns.english_answer_id
                                                )
                                          ) || []

                                        let filteredAllStuAns =
                                          flattenedStuAnswers.filter(
                                            stuAns =>
                                              parseInt(stuAns.part_id) ===
                                                parseInt(
                                                  qusAns.english_answer_part_id
                                                ) &&
                                              parseInt(stuAns.answer_id) ===
                                                parseInt(
                                                  qusAns.english_answer_id
                                                )
                                          ) || []

                                        return (
                                          <>
                                            <li
                                              key={index1}
                                              className={
                                                parseInt(
                                                  quspart.english_question_part_automark
                                                ) === 1
                                                  ? quspart.StudentAnsAttempts
                                                      .length > 0 &&
                                                    filteredStuAns.length > 0
                                                    ? "input_box small_input_box"
                                                    : "input_box small_input_box"
                                                  : "input_box small_input_box"
                                              }
                                            >
                                              <input
                                                id={
                                                  index1 +
                                                  Math.floor(Math.random() * 5)
                                                }
                                                type="text"
                                                autoFocus={
                                                  initial_question === 1
                                                }
                                                ref={el =>
                                                  (textAreaRefs.current[
                                                    initial_question
                                                  ] = el)
                                                }
                                                className={
                                                  quspart.StudentAnsAttempts
                                                    .length > 0 &&
                                                  parseInt(
                                                    quspart.english_question_part_automark
                                                  ) === 1
                                                    ? filteredStuAns.length > 0
                                                      ? "answersuccess initial_question"
                                                      : "answererror initial_question"
                                                    : "answereditor initial_question"
                                                }
                                                value={
                                                  resultData &&
                                                  resultData.is_correct &&
                                                  resultData.is_correct === 1
                                                    ? filteredAllStuAns
                                                        .filter(
                                                          item =>
                                                            parseInt(
                                                              item.answer_id
                                                            ) ===
                                                            parseInt(
                                                              qusAns.english_answer_id
                                                            )
                                                        )
                                                        .reverse()
                                                        .map(item => item.text)
                                                        .find(
                                                          text =>
                                                            text !== undefined
                                                        ) ||
                                                      selectedAnswerText
                                                        .filter(
                                                          item =>
                                                            parseInt(
                                                              item.answer_id
                                                            ) ===
                                                            parseInt(
                                                              qusAns.english_answer_id
                                                            )
                                                        )
                                                        .map(item => item.text)
                                                        .find(
                                                          text =>
                                                            text !== undefined
                                                        ) ||
                                                      "" // Find the first non-undefined text value
                                                    : isValuefield
                                                    ? selectedAnswerText
                                                        .filter(
                                                          item =>
                                                            parseInt(
                                                              item.answer_id
                                                            ) ===
                                                            parseInt(
                                                              qusAns.english_answer_id
                                                            )
                                                        )
                                                        .map(item => item.text)
                                                        .find(
                                                          text =>
                                                            text !== undefined
                                                        ) || ""
                                                    : filteredAllStuAns
                                                        .filter(
                                                          item =>
                                                            parseInt(
                                                              item.answer_id
                                                            ) ===
                                                            parseInt(
                                                              qusAns.english_answer_id
                                                            )
                                                        )
                                                        .reverse()
                                                        .map(item => item.text)
                                                        .find(
                                                          text =>
                                                            text !== undefined
                                                        ) || ""
                                                }
                                                autoComplete="off"  
                                                autoCorrect="off"   
                                                spellCheck="false"
                                                disabled={
                                                  filteredStuAns.length > 0
                                                    ? true
                                                    : selectedAnswerText.filter(
                                                        item =>
                                                          parseInt(
                                                            item.answer_id
                                                          ) ===
                                                            parseInt(
                                                              qusAns.english_answer_id
                                                            ) &&
                                                          item.text === "✔"
                                                      ).length > 0 || false
                                                }
                                                //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                onChange={e =>
                                                  handleAllowSpacetext(
                                                    e,
                                                    qusAns.english_answer_id,
                                                    qusAns.english_answer_part_id
                                                  )
                                                }
                                                // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                              />
                                              <label className="mb-0 ps-2">
                                                <input
                                                  type="checkbox"
                                                  className="custom_check_box"
                                                  id={
                                                    index1 +
                                                    Math.floor(
                                                      Math.random() * 5
                                                    )
                                                  }
                                                  defaultChecked={true}
                                                  checked={
                                                    selectedAnswerText.filter(
                                                      item =>
                                                        parseInt(
                                                          item.answer_id
                                                        ) ===
                                                          parseInt(
                                                            qusAns.english_answer_id
                                                          ) && item.text === "✔"
                                                    ).length > 0 || false
                                                  }
                                                  onChange={e => {
                                                    // const checkbox = e.target;
                                                    //   const input = checkbox.parentElement.previousElementSibling.querySelector('input[type="text"]');
                                                    //   if (input) {
                                                    //     input.disabled = checkbox.checked;
                                                    //   }
                                                    const value = e.target
                                                      .checked
                                                      ? "✔"
                                                      : ""
                                                    //console.log(value);
                                                    handletextCheck(
                                                      value,
                                                      qusAns.english_answer_id,
                                                      qusAns.english_answer_part_id
                                                    )
                                                  }}
                                                />
                                              </label>
                                            </li>

                                            {quspart.StudentAnsAttempts
                                              .length === 1 &&
                                            parseInt(
                                              quspart.english_question_part_automark
                                            ) === 1 ? (
                                              filteredStuAns.length >
                                              0 ? null : (
                                                <div className="d-flex justify-content-end">
                                                  <span className="text-danger font-size-14 mt-auto mb-2">
                                                    Try again with a different
                                                    answer.
                                                  </span>
                                                </div>
                                              )
                                            ) : null}
                                          </>
                                        )
                                      }
                                    }
                                  )}
                              </ul>
                            ) : quspart.english_question_part_answer_type ===
                              4 ? (
                              //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                              <ul className="dg-selector width-controll list-unstyled mb-0">
                                {resultData &&
                                resultData.is_auto_mark &&
                                resultData.is_auto_mark == "0" &&
                                parseInt(
                                  quspart.english_question_part_automark
                                ) <= 1 ? (
                                  <div className="d-flex justify-content-end">
                                    <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                      Manual Marking
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {quspart.english_question_answers &&
                                  rearrangeEvenOdd(
                                    quspart.english_question_answers
                                  ).map((qusAns, index1) => {
                                    //   if ((!qusAns.english_answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '')
                                    //     || qusAns.english_answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '').trim() === '')
                                    //   && !(/<img\s+[^>]*>/gi.test(qusAns.english_answer_text))) {
                                    //     return null; // Skip rendering if english_answer_text is null
                                    // }

                                    const cleanText = qusAns.english_answer_text
                                      .replace(/&nbsp;/g, "")
                                      .replace(/`/g, "")
                                      .replace(/<[^>]*>/g, "")
                                      .trim()

                                    const hasImage = /<img\s+[^>]*>/gi.test(
                                      qusAns.english_answer_text
                                    )

                                    if (!cleanText && !hasImage) {
                                      return null // Skip rendering if english_answer_text is effectively empty and no image is present
                                    }
                                    serialNumber++
                                    let filteredStuAns =
                                      flattenedStuAnswers.filter(
                                        stuAns =>
                                          parseInt(stuAns.part_id) ===
                                            parseInt(
                                              qusAns.english_answer_part_id
                                            ) &&
                                          parseInt(stuAns.answer_id) ===
                                            parseInt(qusAns.english_answer_id)
                                      ) || []

                                    var status_color =
                                      "selector-general cursor-pointer"
                                    var border_color = "border-start-secondary"
                                    if (filteredStuAns.length > 0) {
                                      if (
                                        parseInt(
                                          qusAns.english_answer_is_correct
                                        ) === 1
                                      ) {
                                        status_color = "selector-success"
                                        border_color = "border-start-success"
                                        correct_count += 1
                                      } else {
                                        status_color = "selector-wrong "
                                        border_color = "border-start-danger"
                                      }
                                    }
                                    //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                    return (
                                      <>
                                        <li
                                          className={
                                            selectedAnswer &&
                                            selectedAnswer ===
                                              qusAns.english_answer_id
                                              ? status_color +
                                                " active choose e_choose"
                                              : status_color +
                                                " choose e_choose"
                                          }
                                          key={index1}
                                          onClick={() =>
                                            selectedAnsOption(qusAns)
                                          }
                                        >
                                          <div className="p-2 px-3 font-size-18">
                                            {serialNumber}
                                          </div>
                                          <div
                                            className={
                                              "p-2 dg-media " + { border_color }
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html: qusAns.english_answer_text
                                                ? qusAns.english_answer_text
                                                : null,
                                            }}
                                          ></div>
                                        </li>
                                        {index1 + 1 >=
                                          quspart.english_question_answers
                                            .length &&
                                        quspart.StudentAnsAttempts.length ===
                                          1 &&
                                        parseInt(
                                          quspart.english_question_part_automark
                                        ) === 1 ? (
                                          correct_count > 0 ? null : (
                                            <div className="d-flex justify-content-end">
                                              <span className="text-danger font-size-14 mt-auto mb-2">
                                                Try again with a different
                                                answer.
                                              </span>
                                            </div>
                                          )
                                        ) : null}
                                      </>
                                    )
                                  })}
                              </ul>
                            ) : (
                              <p className="text-center">No option</p>
                            )}
                            {/* Anse category statement end */}
                          </div>
                        )
                      })}

                    <div>
                      {resultData && resultData.is_auto_mark == "0" ? (
                        ""
                      ) : selectedAnswerText &&
                        selectedAnswerText.length > 0 &&
                        nextQuestion ? (
                        <div className="text-end mt-1">
                          <Button
                            className="submit-btn-question btn btn-danger cs-btn-danger mt-1"
                            disabled={IsSubmited}
                            onClick={() => {
                              triggerSubmitFunction(selectedAnswerText)
                              setIsSubmited(true)
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                      ) : null}
                    </div>

                    {ActivityData.english_question_parts &&
                      ActivityData.english_question_parts.map(
                        (quspart, index2) => {
                          if (
                            quspart.english_question_answers &&
                            quspart.english_question_answers.length > 0 &&
                            quspart.english_question_answers[0]
                              .english_answer_text &&
                            quspart.english_question_answers[0]
                              .english_answer_text != ""
                          ) {
                            let serialNumber = 0
                            return index2 + 1 >
                              ActivityData1.is_current_tab_current_part &&
                              quspart.english_question_part_text ? (
                              <div
                                key={index2}
                                className="d-flex justify-content-between align-items-center mt-3"
                              >
                                <div className="d-flex gap-2 flex-row ">
                                  {/* <div className="mr-3">{index + 1}</div> */}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: quspart.english_question_part_text
                                        ? quspart.english_question_part_text
                                            .replace(
                                              /\[\[(.+?)\]\]/g,
                                              function (match, p1) {
                                                return p1.length > 1
                                                  ? `<span class="text-bg-yellow">${p1}</span>`
                                                  : `<span class="text-bg-blue">${p1}</span>`
                                              }
                                            )
                                            .replace(/\{\{/g, "<u>")
                                            .replace(/\}\}/g, "</u>")
                                            .replace(
                                              /\[(\d+)Answer char=\d+\]/g,
                                              ""
                                            )
                                            .replace(/\[Answer char=\d+\]/g, "")
                                        : null,
                                    }}
                                  ></div>
                                </div>
                                {/* Anse category statement Star */}
                                {quspart.english_question_part_answer_type ===
                                  8 ||
                                quspart.english_question_part_answer_type ===
                                  1 ||
                                quspart.english_question_part_answer_type ===
                                  7 ? (
                                  <ul className="dg-selector width-controll list-unstyled mb-0">
                                    {" "}
                                    {parseInt(
                                      quspart.english_question_part_automark
                                    ) === 1 ? (
                                      ""
                                    ) : (
                                      <div className="d-flex justify-content-end">
                                        <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                          Manual Marking
                                        </span>
                                      </div>
                                    )}
                                    {quspart.english_question_answers &&
                                      quspart.english_question_answers.map(
                                        (qusAns, index1) => {
                                          return (
                                            <li
                                              key={index1}
                                              className={
                                                "input_box small_input_box"
                                              }
                                            >
                                              <input
                                                id={
                                                  index1 +
                                                  Math.floor(Math.random() * 5)
                                                }
                                                type="text"
                                                className={"answereditor"}
                                                value={
                                                  "" // Find the first non-undefined text value
                                                }
                                                disabled={true}
                                                autoComplete="off"  
                                                autoCorrect="off"   
                                                spellCheck="false"
                                                //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                //  onChange={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                              />
                                            </li>
                                          )
                                        }
                                      )}
                                  </ul>
                                ) : quspart.english_question_part_answer_type ===
                                  4 ? (
                                  //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                                  <ul className="dg-selector width-controll list-unstyled mb-0">
                                    {parseInt(
                                      quspart.english_question_part_automark
                                    ) === 1 ? (
                                      ""
                                    ) : (
                                      <div className="d-flex justify-content-end">
                                        <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                          Manual Marking
                                        </span>
                                      </div>
                                    )}
                                    {quspart.english_question_answers &&
                                      rearrangeEvenOdd(
                                        quspart.english_question_answers
                                      ).map((qusAns, index1) => {
                                        //   if ((!qusAns.english_answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '')
                                        //     || qusAns.english_answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '').trim() === '')
                                        //   && !(/<img\s+[^>]*>/gi.test(qusAns.english_answer_text))) {
                                        //     return null; // Skip rendering if english_answer_text is null
                                        // }

                                        serialNumber++

                                        var status_color =
                                          "selector-general cursor-pointer"
                                        var border_color =
                                          "border-start-secondary"

                                        //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                        return (
                                          <li
                                            className={
                                              status_color + " choose e_choose"
                                            }
                                            key={index1}
                                          >
                                            <div className="p-2 px-3 font-size-18">
                                              {serialNumber}
                                            </div>
                                            <div
                                              className={
                                                "p-2 dg-media " +
                                                { border_color }
                                              }
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  qusAns.english_answer_text
                                                    ? qusAns.english_answer_text
                                                    : null,
                                              }}
                                            ></div>
                                          </li>
                                        )
                                      })}
                                  </ul>
                                ) : (
                                  <p className="text-center">No option</p>
                                )}
                                {/* Anse category statement end */}
                              </div>
                            ) : null
                          } else {
                            return index2 + 1 >
                              ActivityData1.is_current_tab_current_part &&
                              quspart.english_question_part_text ? (
                              <div key={index2} className="mt-3">
                                <div className="d-flex gap-2 flex-row w-75">
                                  {/* <div className="mr-3">{index + 1}</div> */}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: quspart.english_question_part_text
                                        ? quspart.english_question_part_text
                                            .replace(
                                              /\[\[(.+?)\]\]/g,
                                              function (match, p1) {
                                                return p1.length > 1
                                                  ? `<span class="text-bg-yellow">${p1}</span>`
                                                  : `<span class="text-bg-blue">${p1}</span>`
                                              }
                                            )
                                            .replace(/\{\{/g, "<u>")
                                            .replace(/\}\}/g, "</u>")
                                            .replace(
                                              /\[(\d+)Answer char=\d+\]/g,
                                              ""
                                            )
                                            .replace(/\[Answer char=\d+\]/g, "")
                                        : null,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            ) : null
                          }
                        }
                      )}
                  </li>
                </ul>

                {!nextQuestion ? (
                  <div className="navigator-wrapper position-relative">
                    {/* <div className="nv-dg-smile">
                      <img width={400} src={dsmile} alt="" /> 
                  </div> */}
                    <div className="nv-dg-smile-avatar">
                      {/* <img width={400} src={dsmile} alt="" /> */}
                      <div className="avatar-wrapper">
                        {/* student avatar start */}
                        <div className="super-profile_wrapper img-consolidate-display">
                          <div className="content-left">
                            <div className="super-avatar-display">
                              {/* <Slide
                              autoplay={false}
                              infinite={false}
                              onStartChange={togAvatarGendar}
                              {...properties}
                            > */}
                              {/* male functionality start */}
                              {!AvatarGendar ? (
                                <div className="each-slide-effect cursor-pointer">
                                  <div className="pirate-media male position-relative">
                                    <div className="avatar-display text-center position-relative">
                                      <div className="avatar-default">
                                        <img
                                          width={450}
                                          className=""
                                          src={piratemale}
                                          alt=""
                                        />
                                      </div>
                                      <div className="avatar-attachments">
                                        {maleAvatarHeadData &&
                                        maleAvatarHeadData != "" &&
                                        maleAvatarHeadData.image ? (
                                          <div className="head-float position-absolute">
                                            <img
                                              className=""
                                              src={modifyUrl(
                                                maleAvatarHeadData.image
                                              )}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarArmourData &&
                                        maleAvatarArmourData != "" &&
                                        maleAvatarArmourData.image ? (
                                          <div className="dress-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarArmourData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarPetData &&
                                        maleAvatarPetData != "" &&
                                        maleAvatarPetData.image ? (
                                          <div className="companion-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarPetData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarHandData &&
                                        maleAvatarHandData != "" &&
                                        maleAvatarHandData.image ? (
                                          <>
                                            <div className="hand-float position-absolute">
                                              <img
                                                className=""
                                                src={maleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>

                                            <div className="hand-float-revert position-absolute">
                                              <img
                                                className=""
                                                src={maleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                          </>
                                        ) : null}

                                        {maleAvatarWeaponData &&
                                        maleAvatarWeaponData != "" &&
                                        maleAvatarWeaponData.image ? (
                                          <div className="weapon-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarWeaponData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="each-slide-effect cursor-pointer">
                                  <div className="pirate-media female position-relative">
                                    <div className="avatar-display text-center position-relative">
                                      <div className="avatar-default">
                                        <img
                                          width={450}
                                          className=""
                                          src={piratefemale}
                                          alt=""
                                        />
                                      </div>
                                      <div className="avatar-attachments">
                                        {femaleAvatarHeadData &&
                                        femaleAvatarHeadData != "" &&
                                        femaleAvatarHeadData.image ? (
                                          <div className="head-float female-hd position-absolute">
                                            <img
                                              className=""
                                              src={modifyUrl(
                                                femaleAvatarHeadData.image
                                              )}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarArmourData &&
                                        femaleAvatarArmourData != "" &&
                                        femaleAvatarArmourData.image ? (
                                          <div className="dress-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarArmourData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarPetData &&
                                        femaleAvatarPetData != "" &&
                                        femaleAvatarPetData.image ? (
                                          <div className="companion-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarPetData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarHandData &&
                                        femaleAvatarHandData != "" &&
                                        femaleAvatarHandData.image ? (
                                          <>
                                            <div className="hand-float position-absolute">
                                              <img
                                                className=""
                                                src={femaleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                            <div className="hand-float-revert position-absolute">
                                              <img
                                                className=""
                                                src={femaleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                          </>
                                        ) : null}

                                        {femaleAvatarWeaponData &&
                                        femaleAvatarWeaponData != "" &&
                                        femaleAvatarWeaponData.image ? (
                                          <div className="weapon-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarWeaponData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Female functionality end */}
                              {/* </Slide> */}
                            </div>
                          </div>
                        </div>

                        {/* student avatar end */}
                      </div>
                    </div>
                    <div className="navigator-nxt" ref={navigatorRef}>
                      <p className="opacity-75">
                        SUGGESTED TIME:{" "}
                        {ActivityData.english_question_time_needed}
                      </p>
                      <p className="font-size-16 d-flex align-items-center gap-2">
                        <img width={24} src={awicon} />{" "}
                        {resultData ? resultData.latestScore : 0} /{" "}
                        {resultData && resultData.question_length
                          ? resultData.question_length * 2
                          : 0}{" "}
                        coins earned
                      </p>
                      <p className="opacity-75">
                        {resultData
                          ? parseInt(resultData.accuracyOfOtherStudents)
                          : 0}
                        % OF STUDENTS GOT IT RIGHT
                      </p>
                      {resultData ? (
                        !resultData.LastQuestion ? (
                          <Button
                            className="next-btn-question btn btn-danger cs-btn-danger mt-1"
                            onClick={() => {
                              //window.location.reload();
                              triggerFunction()
                              setnextQuestion(false)
                              setActivePartData([])
                              setIsSubmited(false)
                              setresultData(null)
                            }}
                          >
                            Next Question
                          </Button>
                        ) : (
                          <Button
                            className="summary-btn-question btn btn-danger cs-btn-danger mt-1"
                            onClick={() => assessementSummary()}
                          >
                            Go to summary
                          </Button>
                        )
                      ) : null}

                      {resultData &&
                      resultData.attempCount &&
                      parseInt(resultData.attempCount) >= 2 ? (
                        <span
                          className="font-size-15 mt-3 text-dark fw-bold"
                          onClick={tog_standard1}
                          style={{
                            fontStyle: "italic",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          It was marked incorrectly
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            )
          } else {
            return <p className="text-center">No question</p>
          }
        }
      })
    ) : ActivityData1 &&
      ActivityData1.fk_sub_id &&
      parseInt(ActivityData1.fk_sub_id) === 2 ? (
      ActivityData1.question.map((ActivityData, index) => {
        if (index === 0) {
          if (
            ActivityData &&
            ActivityData.question_parts &&
            ActivityData.question_parts.length > 0
          ) {
            return (
              <div className="hw-list mt-4 pb-100">
                {/* <ToastContainer autoClose={3000} /> */}
                <Modal isOpen={modal_standard1} toggle={tog_standard1}>
                  <ModalHeader toggle={tog_standard1}></ModalHeader>
                  <ModalBody>
                    <p className="text-center fw-bold text-dark mb-0 font-size-18">
                      Report this question to your tutor as being incorrectly
                      marked?
                    </p>
                  </ModalBody>
                  <ModalFooter className="justify-content-center">
                    <Button
                      className="btn-cst-success-md"
                      disabled={isFlagReport}
                      onClick={() => {
                        submitQuestionReport()
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn-cst-danger-md"
                      onClick={() => {
                        tog_standard1()
                      }}
                    >
                      No
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal isOpen={modal_standard} toggle={tog_standard}>
                  <Form
                    className="mt-4"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    action="#"
                  >
                    <ModalHeader toggle={tog_standard}>
                      Flag this Question
                    </ModalHeader>
                    <ModalBody>
                      <div className="flex-grow-1 mb-3 cs-rsm">
                        <Label>Select a Reason</Label>
                        <Select
                          name="reson"
                          id="reson"
                          className="cs-style bg-mutant"
                          value={optionGroup.find(
                            option =>
                              option.value &&
                              validation.values.reson &&
                              option.value.toString() ==
                                validation.values.reson.toString()
                          )}
                          placeholder="Select"
                          onChange={selectedOption => {
                            const event = {
                              target: {
                                name: "reson",
                                value: selectedOption["value"],
                              },
                            }
                            validation.handleChange(event)
                          }}
                          onBlur={event => {
                            validation.handleBlur(event)
                          }}
                          // invalid={
                          //   validation.touched.reson &&
                          //   validation.errors.reson
                          //     ? true
                          //     : false
                          // }
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                        {validation.touched.reson && validation.errors.reson ? (
                          <div className="invalid-feedback-all">
                            {validation.errors.reson}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex-grow-1 cs-rsm">
                        <Label>Reason:</Label>
                        <Input
                          style={{ height: "200px" }}
                          type="textarea"
                          name="reson_text"
                          id="reson_text"
                          // onChange={e=> {
                          // textareachange(e)
                          // }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.reson_text || ""}
                          // invalid={
                          //   validation.touched.reson_text &&
                          //   validation.errors.reson_text
                          //     ? true
                          //     : false
                          // }
                          
                          maxLength="225"
                          rows="3"
                          placeholder="Write your reason here..."
                        />
                        {validation.touched.reson_text &&
                        validation.errors.reson_text ? (
                          <div className="invalid-feedback-all">
                            {validation.errors.reson_text}
                          </div>
                        ) : null}
                      </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                      <Button
                        type="submit"
                        className="btn btn-danger cs-btn-danger"
                        disabled={isFlagReport}
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </Form>
                </Modal>
                <ul className="list-unstyled mb-0 question_container">
                  <li
                    className="justify-content-between"
                    onKeyDown={handleKeyDown}
                    tabIndex={index}
                  >
                    <div className="d-flex align-items-start gap-4 justify-content-end">
                      {/* <p className="mb-0 text-uppercase font-size-16">
                        {ActivityData.question_new_topic &&
                        ActivityData.question_new_topic.level_name
                          ? ActivityData.question_new_topic.level_name + " /"
                          : null}

                        {ActivityData.question_new_topic &&
                        ActivityData.question_new_topic.topic_name
                          ? ActivityData.question_new_topic.topic_name + " /"
                          : null}

                        {ActivityData.question_new_topic &&
                        ActivityData.question_new_topic.sub_topics_name
                          ? ActivityData.question_new_topic.sub_topics_name +
                            " /"
                          : null}
                      </p> */}
                      <div className="mb-0 d-flex align-items-center gap-2">
                        <p className="mb-0">
                          #
                          {ActivityData.question_id
                            ? ActivityData.question_id
                            : null}
                        </p>
                        <span onClick={tog_standard}>
                          <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                        </span>
                      </div>
                    </div>

                    <div
                      className="mt-4"
                      dangerouslySetInnerHTML={{
                        __html: ActivityData.question_text
                          ? ActivityData.question_text
                              .replace(/\[(\d+)Answer char=\d+\]/g, "")
                              .replace(/\[Answer char=\d+\]/g, "")
                          : null,
                      }}
                    ></div>

                    {/* Past question */}
                    {ActivityData.question_parts &&
                      ActivityData.question_parts.map((quspart, index) => {
                        const stuAnswersarray =
                          quspart.StudentAnsAttempts.map(stuAns => {
                            try {
                              return JSON.parse(stuAns.student_answer)
                            } catch (error) {
                              console.error("Error parsing JSON:", error)
                              return null // or handle the error in an appropriate way
                            }
                          }) || [] // Extracting session keys

                        // Assuming stuAnswersarray is a nested array
                        const flattenedStuAnswers = [].concat(
                          ...stuAnswersarray
                        )
                        let serialNumber = 0

                        return index + 1 <
                          ActivityData1.is_current_tab_current_part ? (
                          <div key={index}>
                            <div className="d-flex gap-2 flex-row ">
                              <div className="mr-3">
                                {index + 1}
                                {")"}
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: quspart.part_text
                                    ? quspart.part_text
                                        .replace(
                                          /\[(\d+)Answer char=\d+\]/g,
                                          ""
                                        )
                                        .replace(/\[Answer char=\d+\]/g, "")
                                    : null,
                                }}
                              ></div>
                            </div>
                            {/* Anse category statement Star */}
                            {quspart.part_answer_type === 2 ? (
                              <ul className="dg-selector width-controll list-unstyled mb-0">
                                {quspart.question_answers &&
                                  quspart.question_answers.map(
                                    (qusAns, index1) => {
                                      return quspart.StudentAnsAttempts.map(
                                        (qusStuAns, stuAnsindex) => {
                                          // Your logic for filtering student answers and determining status_color and border_color
                                          const stuAnswersarray =
                                            quspart.StudentAnsAttempts.filter(
                                              stuAns =>
                                                stuAns.atempt_count ===
                                                stuAnsindex + 1
                                            ).map(stuAns => {
                                              try {
                                                return JSON.parse(
                                                  stuAns.student_answer
                                                )
                                              } catch (error) {
                                                console.error(
                                                  "Error parsing JSON:",
                                                  error
                                                )
                                                return null // or handle the error in an appropriate way
                                              }
                                            }) || []

                                          //console.log(stuAnswersarray);
                                          // Assuming stuAnswersarray is a nested array
                                          const flattenedStuAnswers = [].concat(
                                            ...stuAnswersarray
                                          )
                                          let cleanedText =
                                            qusAns.answer_text
                                              .replace(/&nbsp;/g, "")
                                              .replace(/`/g, "")
                                              .replace(/<[^>]*>/g, "") || ""
                                          let resultTest = cleanedText
                                            .toLowerCase()
                                            .trim()
                                          let answers =
                                            resultTest.split("|") || []
                                          let firstAnswer = answers[0]
                                            ? answers[0].trim()
                                            : ""
                                          let secondAnswer = answers[1]
                                            ? answers[1].trim()
                                            : ""

                                          if (qusAns.answer_is_correct === 1) {
                                            let filteredStuAns =
                                              flattenedStuAnswers.filter(
                                                stuAns =>
                                                  parseInt(stuAns.part_id) ===
                                                    parseInt(
                                                      qusAns.answer_part_id
                                                    ) &&
                                                  (stuAns.text
                                                    .toLowerCase()
                                                    .trim() == resultTest ||
                                                    stuAns.text
                                                      .toLowerCase()
                                                      .trim() == firstAnswer ||
                                                    stuAns.text
                                                      .toLowerCase()
                                                      .trim() ==
                                                      secondAnswer) &&
                                                  parseInt(stuAns.answer_id) ===
                                                    parseInt(qusAns.answer_id)
                                              ) || []

                                            let filteredAllStuAns =
                                              flattenedStuAnswers.filter(
                                                stuAns =>
                                                  parseInt(stuAns.part_id) ===
                                                    parseInt(
                                                      qusAns.answer_part_id
                                                    ) &&
                                                  parseInt(stuAns.answer_id) ===
                                                    parseInt(qusAns.answer_id)
                                              ) || []

                                            return (
                                              <li
                                                key={index1}
                                                className={
                                                  filteredStuAns.length > 0
                                                    ? "input_box m_input_box"
                                                    : "input_box m_input_box"
                                                }
                                              >
                                                <input
                                                  id={
                                                    index1 +
                                                    Math.floor(
                                                      Math.random() * 5
                                                    )
                                                  }
                                                  type="text"
                                                  className={
                                                    filteredStuAns.length > 0
                                                      ? "answersuccess"
                                                      : "answererror"
                                                  }
                                                  
                                                  value={
                                                    filteredAllStuAns
                                                      .filter(
                                                        item =>
                                                          parseInt(
                                                            item.answer_id
                                                          ) ===
                                                          parseInt(
                                                            qusAns.answer_id
                                                          )
                                                      )
                                                      .map(item => item.text)
                                                      .find(
                                                        text =>
                                                          text !== undefined
                                                      ) || "" // Find the first non-undefined text value
                                                  }
                                                  disabled={true}
                                                  autoComplete="off"  
                                                  autoCorrect="off"   
                                                  spellCheck="false"
                                                  //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                  onChange={e =>
                                                    handletext(
                                                      e,
                                                      qusAns.answer_id,
                                                      qusAns.answer_part_id
                                                    )
                                                  }
                                                  // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                />
                                              </li>
                                            )
                                          }
                                        }
                                      )
                                    }
                                  )}
                              </ul>
                            ) : quspart.part_answer_type === 4 ? (
                              //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                              <ul className="dg-selector width-controll list-unstyled mb-0">
                                {quspart.question_answers &&
                                  rearrangeEvenOdd(
                                    quspart.question_answers
                                  ).map((qusAns, index1) => {
                                    //   if ((!qusAns.answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '')
                                    //     || qusAns.answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '').trim() === '')
                                    //   && !(/<img\s+[^>]*>/gi.test(qusAns.answer_text))) {
                                    //     return null; // Skip rendering if english_answer_text is null
                                    // }

                                    const cleanText = qusAns.answer_text
                                      .replace(/&nbsp;/g, "")
                                      .replace(/`/g, "")
                                      .replace(/<[^>]*>/g, "")
                                      .trim()

                                    const hasImage = /<img\s+[^>]*>/gi.test(
                                      qusAns.answer_text
                                    )

                                    if (!cleanText && !hasImage) {
                                      return null // Skip rendering if english_answer_text is effectively empty and no image is present
                                    }

                                    serialNumber++

                                    let filteredStuAns =
                                      flattenedStuAnswers.filter(
                                        stuAns =>
                                          parseInt(stuAns.part_id) ===
                                            parseInt(qusAns.answer_part_id) &&
                                          parseInt(stuAns.answer_id) ===
                                            parseInt(qusAns.answer_id)
                                      ) || []

                                    var status_color =
                                      "selector-general cursor-pointer"
                                    var border_color = "border-start-secondary"
                                    if (filteredStuAns.length > 0) {
                                      if (
                                        parseInt(qusAns.answer_is_correct) === 1
                                      ) {
                                        status_color = "selector-success"
                                        border_color = "border-start-success"
                                      } else {
                                        status_color = "selector-wrong "
                                        border_color = "border-start-danger"
                                      }
                                    }
                                    //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                    return (
                                      <li
                                        className={
                                          selectedAnswer &&
                                          selectedAnswer === qusAns.answer_id
                                            ? status_color +
                                              " active choose m_choose"
                                            : status_color + " choose m_choose"
                                        }
                                        key={index1}
                                      >
                                        <div className="p-2 px-3 font-size-18">
                                          {serialNumber}
                                        </div>
                                        <div
                                          className={
                                            "p-2 dg-media " + { border_color }
                                          }
                                          dangerouslySetInnerHTML={{
                                            __html: qusAns.answer_text
                                              ? qusAns.answer_text
                                              : null,
                                          }}
                                        ></div>
                                      </li>
                                    )
                                  })}
                              </ul>
                            ) : (
                              <p className="text-center">No option</p>
                            )}
                            {/* Anse category statement end */}
                          </div>
                        ) : null
                      })}

                    {ActivePartData &&
                      ActivePartData.map((quspart, index) => {
                        const stuAnswersarray =
                          quspart.StudentAnsAttempts.map(stuAns => {
                            try {
                              return JSON.parse(stuAns.student_answer)
                            } catch (error) {
                              console.error("Error parsing JSON:", error)
                              return null // or handle the error in an appropriate way
                            }
                          }) || [] // Extracting session keys
                        let correct_count = 0
                        // Assuming stuAnswersarray is a nested array
                        const flattenedStuAnswers = [].concat(
                          ...stuAnswersarray
                        )
                        let serialNumber = 0
                        return (
                          <div key={1001}>
                            <div className="d-flex gap-2 flex-row ">
                              <div className="mr-3">
                                {ActivityData1.is_current_tab_current_part}
                                {")"}
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: quspart.part_text
                                    ? quspart.part_text
                                        .replace(
                                          /\[(\d+)Answer char=\d+\]/g,
                                          ""
                                        )
                                        .replace(/\[Answer char=\d+\]/g, "")
                                    : null,
                                }}
                              ></div>
                            </div>
                            {/* Anse category statement Star */}
                            {quspart.part_answer_type === 2 ? (
                              <ul className="dg-selector width-controll list-unstyled mb-0">
                                {/* {resultData && resultData.is_auto_mark && resultData.is_auto_mark == '0' && parseInt(quspart.english_question_part_automark) <= 0 ?  <div className="d-flex justify-content-end">
                            <span className="bg-success mt-auto mb-2">Manual Marking</span>
                          </div> : ''} */}
                                {quspart.question_answers &&
                                  quspart.question_answers.map(
                                    (qusAns, index1) => {
                                      if (
                                        quspart.StudentAnsAttempts.length > 0
                                      ) {
                                        return quspart.StudentAnsAttempts.map(
                                          (qusStuAns, stuAnsindex) => {
                                            // Your logic for filtering student answers and determining status_color and border_color
                                            const stuAnswersarray =
                                              quspart.StudentAnsAttempts.filter(
                                                stuAns =>
                                                  stuAns.atempt_count ===
                                                  stuAnsindex + 1
                                              ).map(stuAns => {
                                                try {
                                                  return JSON.parse(
                                                    stuAns.student_answer
                                                  )
                                                } catch (error) {
                                                  console.error(
                                                    "Error parsing JSON:",
                                                    error
                                                  )
                                                  return null // or handle the error in an appropriate way
                                                }
                                              }) || []
                                            // console.log(stuAnswersarray);

                                            // Assuming stuAnswersarray is a nested array
                                            const flattenedStuAnswers =
                                              [].concat(...stuAnswersarray)
                                            let cleanedText =
                                              qusAns.answer_text
                                                .replace(/&nbsp;/g, "")
                                                .replace(/`/g, "")
                                                .replace(/<[^>]*>/g, "") || ""
                                            let resultTest = cleanedText
                                              .toLowerCase()
                                              .trim()
                                            let answers =
                                              resultTest.split("|") || []
                                            let firstAnswer = answers[0]
                                              ? answers[0].trim()
                                              : ""
                                            let secondAnswer = answers[1]
                                              ? answers[1].trim()
                                              : ""

                                            if (
                                              qusAns.answer_is_correct === 1
                                            ) {
                                              let filteredStuAns =
                                                flattenedStuAnswers.filter(
                                                  stuAns =>
                                                    parseInt(stuAns.part_id) ===
                                                      parseInt(
                                                        qusAns.answer_part_id
                                                      ) &&
                                                    (stuAns.text
                                                      .toLowerCase()
                                                      .trim() == resultTest ||
                                                      stuAns.text
                                                        .toLowerCase()
                                                        .trim() ==
                                                        firstAnswer ||
                                                      stuAns.text
                                                        .toLowerCase()
                                                        .trim() ==
                                                        secondAnswer) &&
                                                    parseInt(
                                                      stuAns.answer_id
                                                    ) ===
                                                      parseInt(qusAns.answer_id)
                                                ) || []

                                              let filteredAllStuAns =
                                                flattenedStuAnswers.filter(
                                                  stuAns =>
                                                    parseInt(stuAns.part_id) ===
                                                      parseInt(
                                                        qusAns.answer_part_id
                                                      ) &&
                                                    parseInt(
                                                      stuAns.answer_id
                                                    ) ===
                                                      parseInt(qusAns.answer_id)
                                                ) || []

                                              return (
                                                <>
                                                  <li
                                                    key={stuAnsindex}
                                                    className={
                                                      quspart.StudentAnsAttempts
                                                        .length > 0 &&
                                                      filteredStuAns.length > 0
                                                        ? "input_box m_input_box"
                                                        : "input_box m_input_box"
                                                    }
                                                  >
                                                    <input
                                                      id={
                                                        stuAnsindex +
                                                        Math.floor(
                                                          Math.random() * 5
                                                        )
                                                      }
                                                      type="text"
                                                      className={
                                                        quspart
                                                          .StudentAnsAttempts
                                                          .length > 0
                                                          ? filteredStuAns.length >
                                                            0
                                                            ? "answersuccess"
                                                            : "answererror"
                                                          : "answereditor"
                                                      }
                                                      value={
                                                        filteredAllStuAns
                                                          .filter(
                                                            item =>
                                                              parseInt(
                                                                item.answer_id
                                                              ) ===
                                                              parseInt(
                                                                qusAns.answer_id
                                                              )
                                                          )
                                                          .map(
                                                            item => item.text
                                                          )
                                                          .find(
                                                            text =>
                                                              text !== undefined
                                                          ) || "" // Find the first non-undefined text value
                                                      }
                                                      disabled={
                                                        quspart
                                                          .StudentAnsAttempts
                                                          .length > 0
                                                          ? filteredStuAns.length >
                                                            0
                                                            ? true
                                                            : true
                                                          : false
                                                      }
                                                      autoComplete="off"  
                                                      autoCorrect="off"   
                                                      spellCheck="false"
                                                      //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                      onChange={e =>
                                                        handletext(
                                                          e,
                                                          qusAns.answer_id,
                                                          qusAns.answer_part_id
                                                        )
                                                      }
                                                      // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                    />
                                                  </li>

                                                  {quspart.StudentAnsAttempts
                                                    .length === 1 &&
                                                  filteredStuAns.length ===
                                                    0 ? (
                                                    <li
                                                      key={index1 + 1}
                                                      className={
                                                        quspart
                                                          .StudentAnsAttempts
                                                          .length > 1 &&
                                                        filteredStuAns.length >
                                                          0
                                                          ? "input_box m_input_box"
                                                          : "input_box m_input_box"
                                                      }
                                                    >
                                                      <input
                                                        id={
                                                          index1 +
                                                          1 +
                                                          Math.floor(
                                                            Math.random() * 5
                                                          )
                                                        }
                                                        type="text"
                                                        autoFocus={true}
                                                        className={
                                                          quspart
                                                            .StudentAnsAttempts
                                                            .length > 1
                                                            ? filteredStuAns.length >
                                                              0
                                                              ? "answersuccess"
                                                              : "answererror "
                                                            : "answereditor"
                                                        }
                                                        value={
                                                          selectedAnswerText
                                                            .filter(
                                                              item =>
                                                                parseInt(
                                                                  item.answer_id
                                                                ) ===
                                                                parseInt(
                                                                  qusAns.answer_id
                                                                )
                                                            )
                                                            .map(
                                                              item => item.text
                                                            )
                                                            .find(
                                                              text =>
                                                                text !==
                                                                undefined
                                                            ) || ""
                                                        }
                                                        autoComplete="off"  
                                                        autoCorrect="off"   
                                                        spellCheck="false"
                                                        disabled={
                                                          quspart
                                                            .StudentAnsAttempts
                                                            .length > 1
                                                            ? filteredStuAns.length >
                                                              0
                                                              ? true
                                                              : true
                                                            : false
                                                        }
                                                        //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                        onChange={e =>
                                                          handletext(
                                                            e,
                                                            qusAns.answer_id,
                                                            qusAns.answer_part_id
                                                          )
                                                        }
                                                        // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                      />
                                                    </li>
                                                  ) : null}
                                                </>
                                              )
                                            }
                                          }
                                        )
                                      } else {
                                        const stuAnswersarray =
                                          quspart.StudentAnsAttempts.map(
                                            stuAns => {
                                              try {
                                                return JSON.parse(
                                                  stuAns.student_answer
                                                )
                                              } catch (error) {
                                                console.error(
                                                  "Error parsing JSON:",
                                                  error
                                                )
                                                return null // or handle the error in an appropriate way
                                              }
                                            }
                                          ) || [] // Extracting session keys
                                        // console.log(stuAnswersarray);
                                        // Assuming stuAnswersarray is a nested array

                                        const flattenedStuAnswers = [].concat(
                                          ...stuAnswersarray
                                        )
                                        let cleanedText =
                                          qusAns.answer_text
                                            .replace(/&nbsp;/g, "")
                                            .replace(/`/g, "")
                                            .replace(/<[^>]*>/g, "") || ""
                                        let resultTest = cleanedText
                                          .toLowerCase()
                                          .trim()
                                        let answers =
                                          resultTest.split("|") || []
                                        let firstAnswer = answers[0]
                                          ? answers[0].trim()
                                          : ""
                                        let secondAnswer = answers[1]
                                          ? answers[1].trim()
                                          : ""

                                        if (qusAns.answer_is_correct === 1) {
                                          let filteredStuAns =
                                            flattenedStuAnswers.filter(
                                              stuAns =>
                                                parseInt(stuAns.part_id) ===
                                                  parseInt(
                                                    qusAns.answer_part_id
                                                  ) &&
                                                (stuAns.text
                                                  .toLowerCase()
                                                  .trim() == resultTest ||
                                                  stuAns.text
                                                    .toLowerCase()
                                                    .trim() == firstAnswer ||
                                                  stuAns.text
                                                    .toLowerCase()
                                                    .trim() == secondAnswer) &&
                                                parseInt(stuAns.answer_id) ===
                                                  parseInt(qusAns.answer_id)
                                            ) || []

                                          let filteredAllStuAns =
                                            flattenedStuAnswers.filter(
                                              stuAns =>
                                                parseInt(stuAns.part_id) ===
                                                  parseInt(
                                                    qusAns.answer_part_id
                                                  ) &&
                                                parseInt(stuAns.answer_id) ===
                                                  parseInt(qusAns.answer_id)
                                            ) || []

                                          return (
                                            <>
                                              <li
                                                key={index1}
                                                className={
                                                  quspart.StudentAnsAttempts
                                                    .length > 0 &&
                                                  filteredStuAns.length > 0
                                                    ? "input_box m_input_box"
                                                    : "input_box m_input_box"
                                                }
                                              >
                                                <input
                                                  id={
                                                    index1 +
                                                    Math.floor(
                                                      Math.random() * 5
                                                    )
                                                  }
                                                  type="text"
                                                  autoFocus={true}
                                                  className={
                                                    quspart.StudentAnsAttempts
                                                      .length > 0
                                                      ? filteredStuAns.length >
                                                        0
                                                        ? "answersuccess"
                                                        : "answererror"
                                                      : "answereditor"
                                                  }
                                                  value={
                                                    resultData &&
                                                    resultData.is_correct &&
                                                    resultData.is_correct === 1
                                                      ? filteredAllStuAns
                                                          .filter(
                                                            item =>
                                                              parseInt(
                                                                item.answer_id
                                                              ) ===
                                                              parseInt(
                                                                qusAns.answer_id
                                                              )
                                                          )
                                                          .map(
                                                            item => item.text
                                                          )
                                                          .find(
                                                            text =>
                                                              text !== undefined
                                                          ) ||
                                                        selectedAnswerText
                                                          .filter(
                                                            item =>
                                                              parseInt(
                                                                item.answer_id
                                                              ) ===
                                                              parseInt(
                                                                qusAns.answer_id
                                                              )
                                                          )
                                                          .map(
                                                            item => item.text
                                                          )
                                                          .find(
                                                            text =>
                                                              text !== undefined
                                                          ) ||
                                                        "" // Find the first non-undefined text value
                                                      : selectedAnswerText
                                                          .filter(
                                                            item =>
                                                              parseInt(
                                                                item.answer_id
                                                              ) ===
                                                              parseInt(
                                                                qusAns.answer_id
                                                              )
                                                          )
                                                          .map(
                                                            item => item.text
                                                          )
                                                          .find(
                                                            text =>
                                                              text !== undefined
                                                          ) ||
                                                        filteredAllStuAns
                                                          .filter(
                                                            item =>
                                                              parseInt(
                                                                item.answer_id
                                                              ) ===
                                                              parseInt(
                                                                qusAns.answer_id
                                                              )
                                                          )
                                                          .map(
                                                            item => item.text
                                                          )
                                                          .find(
                                                            text =>
                                                              text !== undefined
                                                          ) ||
                                                        ""
                                                  }
                                                  autoComplete="off"  
                                                  autoCorrect="off"   
                                                  spellCheck="false"
                                                  disabled={
                                                    quspart.StudentAnsAttempts
                                                      .length > 0
                                                      ? filteredStuAns.length >
                                                        0
                                                        ? true
                                                        : true
                                                      : false
                                                  }
                                                  //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                  onChange={e =>
                                                    handletext(
                                                      e,
                                                      qusAns.answer_id,
                                                      qusAns.answer_part_id
                                                    )
                                                  }
                                                  // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                />
                                              </li>
                                            </>
                                          )
                                        }
                                      }
                                    }
                                  )}
                              </ul>
                            ) : quspart.part_answer_type === 4 ? (
                              //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                              <ul className="dg-selector width-controll list-unstyled mb-0">
                                {/* {resultData && resultData.is_auto_mark && resultData.is_auto_mark == '0' && parseInt(quspart.english_question_part_automark) <= 1 ?  <div className="d-flex justify-content-end">
                          <span className="bg-success mt-auto mb-2">Manual Marking</span>
                        </div> : ''} */}
                                {quspart.question_answers &&
                                  rearrangeEvenOdd(
                                    quspart.question_answers
                                  ).map((qusAns, index1) => {
                                    //   if ((!qusAns.answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '')
                                    //     || qusAns.answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '').trim() === '')
                                    //   && !(/<img\s+[^>]*>/gi.test(qusAns.answer_text))) {
                                    //     return null; // Skip rendering if maths question is null
                                    // }
                                    const cleanText = qusAns.answer_text
                                      .replace(/&nbsp;/g, "")
                                      .replace(/`/g, "")
                                      .replace(/<[^>]*>/g, "")
                                      .trim()

                                    const hasImage = /<img\s+[^>]*>/gi.test(
                                      qusAns.answer_text
                                    )

                                    if (!cleanText && !hasImage) {
                                      return null // Skip rendering if english_answer_text is effectively empty and no image is present
                                    }

                                    serialNumber++

                                    let filteredStuAns =
                                      flattenedStuAnswers.filter(
                                        stuAns =>
                                          parseInt(stuAns.part_id) ===
                                            parseInt(qusAns.answer_part_id) &&
                                          parseInt(stuAns.answer_id) ===
                                            parseInt(qusAns.answer_id)
                                      ) || []

                                    var status_color =
                                      "selector-general cursor-pointer"
                                    var border_color = "border-start-secondary"
                                    if (filteredStuAns.length > 0) {
                                      if (
                                        parseInt(qusAns.answer_is_correct) === 1
                                      ) {
                                        status_color = "selector-success"
                                        border_color = "border-start-success"
                                        correct_count += 1
                                      } else {
                                        status_color = "selector-wrong "
                                        border_color = "border-start-danger"
                                      }
                                    }

                                    //console.log(selectedAnswer)
                                    //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                    return (
                                      <>
                                        <li
                                          className={
                                            selectedAnswer &&
                                            selectedAnswer === qusAns.answer_id
                                              ? status_color +
                                                " active choose m_choose"
                                              : status_color +
                                                " choose m_choose"
                                          }
                                          key={index1}
                                          onClick={() =>
                                            selectedAnsOption(qusAns)
                                          }
                                        >
                                          <div className="p-2 px-3 font-size-18">
                                            {serialNumber}
                                          </div>
                                          <div
                                            className={
                                              "p-2 dg-media " + { border_color }
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html: qusAns.answer_text
                                                ? qusAns.answer_text
                                                : null,
                                            }}
                                          ></div>
                                        </li>{" "}
                                        {index1 + 1 >=
                                          quspart.question_answers.length &&
                                        quspart.StudentAnsAttempts.length ===
                                          1 ? (
                                          correct_count > 0 ? null : (
                                            <div className="d-flex justify-content-end">
                                              <span className="text-danger font-size-14 mt-auto mb-2">
                                                Try again with a different
                                                answer.
                                              </span>
                                            </div>
                                          )
                                        ) : null}
                                      </>
                                    )
                                  })}
                              </ul>
                            ) : (
                              <p className="text-center">No option</p>
                            )}
                            {/* Anse category statement end */}
                          </div>
                        )
                      })}

                    {!nextQuestion &&
                    parseInt(ActivityData1.is_current_tab_total_part) ===
                      parseInt(ActivityData1.is_current_tab_current_part) ? (
                      <>
                        <h4 className="font-size-16 fw-bold mb-2 mt-3">
                          Solution
                        </h4>

                        {ActivityData.question_parts &&
                          ActivityData.question_parts.map((quspart, index) => {
                            let filteredAns = quspart.question_answers.filter(
                              CorrAns =>
                                parseInt(CorrAns.answer_is_correct) === 1
                            )
                            return filteredAns.map((qusAns, feedindex1) => {
                              return (
                                <div
                                  className="m_feedBack"
                                  style={{
                                    border: "1px solid #1a1a1a",
                                    borderRadius: "4px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {/* <div className="p-2 px-3 font-size-18">{feedindex1 + 1}</div> */}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: qusAns.answer_text
                                        ? qusAns.answer_text
                                        : null,
                                    }}
                                  ></div>
                                </div>
                              )
                            })
                          })}
                      </>
                    ) : null}

                    <div className="text-end mt-3 mb-5">
                      {selectedAnswerText &&
                      selectedAnswerText != null &&
                      selectedAnswerText.length > 0 &&
                      nextQuestion ? (
                        <Button
                          className="submit-btn-question btn btn-danger cs-btn-danger mt-1"
                          disabled={IsSubmited}
                          onClick={() => {
                            triggerSubmitFunction(selectedAnswerText)
                            setIsSubmited(true)
                          }}
                        >
                          Submit
                        </Button>
                      ) : null}
                    </div>
                  </li>
                </ul>

                {!nextQuestion &&
                parseInt(ActivityData1.is_current_tab_total_part) ===
                  parseInt(ActivityData1.is_current_tab_current_part) ? (
                  <div className="navigator-wrapper position-relative">
                    {/* <div className="nv-dg-smile">
                      <img width={400} src={dsmile} alt="" /> 
                  </div> */}
                    <div className="nv-dg-smile-avatar">
                      {/* <img width={400} src={dsmile} alt="" /> */}
                      <div className="avatar-wrapper">
                        {/* student avatar start */}
                        <div className="super-profile_wrapper img-consolidate-display">
                          <div className="content-left">
                            <div className="super-avatar-display">
                              {/* <Slide
                              autoplay={false}
                              infinite={false}
                              onStartChange={togAvatarGendar}
                              {...properties}
                            > */}
                              {/* male functionality start */}
                              {!AvatarGendar ? (
                                <div className="each-slide-effect cursor-pointer">
                                  <div className="pirate-media male position-relative">
                                    <div className="avatar-display text-center position-relative">
                                      <div className="avatar-default">
                                        <img
                                          width={450}
                                          className=""
                                          src={piratemale}
                                          alt=""
                                        />
                                      </div>
                                      <div className="avatar-attachments">
                                        {maleAvatarHeadData &&
                                        maleAvatarHeadData != "" &&
                                        maleAvatarHeadData.image ? (
                                          <div className="head-float position-absolute">
                                            <img
                                              className=""
                                              src={modifyUrl(
                                                maleAvatarHeadData.image
                                              )}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarArmourData &&
                                        maleAvatarArmourData != "" &&
                                        maleAvatarArmourData.image ? (
                                          <div className="dress-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarArmourData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarPetData &&
                                        maleAvatarPetData != "" &&
                                        maleAvatarPetData.image ? (
                                          <div className="companion-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarPetData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {maleAvatarHandData &&
                                        maleAvatarHandData != "" &&
                                        maleAvatarHandData.image ? (
                                          <>
                                            <div className="hand-float position-absolute">
                                              <img
                                                className=""
                                                src={maleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>

                                            <div className="hand-float-revert position-absolute">
                                              <img
                                                className=""
                                                src={maleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                          </>
                                        ) : null}

                                        {maleAvatarWeaponData &&
                                        maleAvatarWeaponData != "" &&
                                        maleAvatarWeaponData.image ? (
                                          <div className="weapon-float position-absolute">
                                            <img
                                              className=""
                                              src={maleAvatarWeaponData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="each-slide-effect cursor-pointer">
                                  <div className="pirate-media female position-relative">
                                    <div className="avatar-display text-center position-relative">
                                      <div className="avatar-default">
                                        <img
                                          width={450}
                                          className=""
                                          src={piratefemale}
                                          alt=""
                                        />
                                      </div>
                                      <div className="avatar-attachments">
                                        {femaleAvatarHeadData &&
                                        femaleAvatarHeadData != "" &&
                                        femaleAvatarHeadData.image ? (
                                          <div className="head-float female-hd position-absolute">
                                            <img
                                              className=""
                                              src={modifyUrl(
                                                femaleAvatarHeadData.image
                                              )}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarArmourData &&
                                        femaleAvatarArmourData != "" &&
                                        femaleAvatarArmourData.image ? (
                                          <div className="dress-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarArmourData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarPetData &&
                                        femaleAvatarPetData != "" &&
                                        femaleAvatarPetData.image ? (
                                          <div className="companion-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarPetData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}

                                        {femaleAvatarHandData &&
                                        femaleAvatarHandData != "" &&
                                        femaleAvatarHandData.image ? (
                                          <>
                                            <div className="hand-float position-absolute">
                                              <img
                                                className=""
                                                src={femaleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                            <div className="hand-float-revert position-absolute">
                                              <img
                                                className=""
                                                src={femaleAvatarHandData.image}
                                                alt=""
                                              />
                                            </div>
                                          </>
                                        ) : null}

                                        {femaleAvatarWeaponData &&
                                        femaleAvatarWeaponData != "" &&
                                        femaleAvatarWeaponData.image ? (
                                          <div className="weapon-float position-absolute">
                                            <img
                                              className=""
                                              src={femaleAvatarWeaponData.image}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Female functionality end */}
                              {/* </Slide> */}
                            </div>
                          </div>
                        </div>

                        {/* student avatar end */}
                      </div>
                    </div>
                    <div className="navigator-nxt" ref={navigatorRef}>
                      <p className="opacity-75">
                        SUGGESTED TIME: {ActivityData.question_time_needed}
                      </p>
                      <p className="font-size-16 d-flex align-items-center gap-2">
                        <img width={24} src={awicon} />{" "}
                        {resultData ? resultData.latestScore : 0} /{" "}
                        {resultData && resultData.question_length
                          ? resultData.question_length * 2
                          : 0}{" "}
                        coins earned
                      </p>
                      {/* <p className="opacity-75">{parseInt(resultData.accuracyOfOtherStudents)}% OF STUDENTS GOT IT RIGHT</p> */}
                      {resultData ? (
                        !resultData.LastQuestion ? (
                          <Button
                            className="next-btn-question btn btn-danger cs-btn-danger mt-1"
                            onClick={() => {
                              //window.location.reload();
                              triggerFunction()
                              setnextQuestion(false)
                              setActivePartData([])
                              setIsSubmited(false)
                              setresultData(null)
                            }}
                            
                          >
                            Next Question
                          </Button>
                        ) : (
                          <Button
                            className="summary-btn-question btn btn-danger cs-btn-danger mt-1"
                            onClick={() => assessementSummary()}
                          >
                            Go to summary
                          </Button>
                        )
                      ) : null}

                      {resultData &&
                      resultData.attempCount &&
                      parseInt(resultData.attempCount) >= 2 ? (
                        <span
                          className="font-size-15 mt-3 text-dark fw-bold"
                          onClick={tog_standard1}
                          style={{
                            fontStyle: "italic",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          It was marked incorrectly
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            )
          } else {
            return <p className="text-center">No question</p>
          }
        }
      })
    ) : (
      <p>No Question</p>
    )
  }
)

export default Comprehension
