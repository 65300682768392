import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { Container, Button, Progress } from "reactstrap"


import "react-multi-carousel/lib/styles.css"

import { Link, useParams, useNavigate, useLocation  } from "react-router-dom"
import classnames from "classnames"

import hw1 from "../../assets/images/services-icon/hw1.svg"
import hw2 from "../../assets/images/services-icon/hw2.svg"
import hw4 from "../../assets/images/services-icon/hw4.svg"
import hw5 from "../../assets/images/services-icon/meter.svg"
import awicon from "../../assets/images/services-icon/coin.png"
import duration from "../../assets/images/services-icon/duration.png"

// import images

import home from "../../assets/images/services-icon/home.svg"
import us1 from "../../assets/images/services-icon/us1.png"

//i18n
import { withTranslation } from "react-i18next";

import jwtDecode from 'jwt-decode';
import axios from "axios";
// import { BASE_URL } from "../../global";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SECURITY_KEY, BASE_URL } from "../../global";
import moment from "moment-timezone";

import Loader from "react-spinner-loader";

const LessonPlan = props => {

  const { key } = useParams();
  let pk_ses_key = null;
  if(key && key.length >= 16){
     pk_ses_key = key;
  }else{
    window.history.back()
  }

//Dedoce user details start
function decodeAccessToken(token) {
  try {
    const decoded = jwtDecode(token);
    return { success: true, data: decoded };
  } catch (error) {
    return { success: false, error: error.message };
  }
}
  const [username, setusername] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("authStudentUser")) {
      
        const obj = JSON.parse(localStorage.getItem("authStudentUser"));
        const jwtdecode=decodeAccessToken(obj);

      
        setusername(jwtdecode.data);
      //}
    }
  }, [props.success]);
  const [loader, setLoader] = useState(false);
  const [sessionLessonData, setSessionLessonData] = useState([]);


  const authUser = JSON.parse(localStorage.getItem('authStudentUser')) || null;

  const navigate = useNavigate();
  const location = useLocation();

  
  const filterState = location?.state || "";

  useEffect(() => {
    fetchData()
  }, [location])

  const fetchData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        pk_ses_key &&
        pk_ses_key != null &&
        pk_ses_key !=''
      ) {
        setLoader(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL+"studentSession/StudentSessionsActivityDeatils", {
            pk_ses_key: pk_ses_key
          })
          .then(responseSession => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties

            if (
              responseSession.data.session != null &&
              responseSession.data.session != ""
            ) {
              setLoader(false);
              //console.log(responseSession);
              setSessionLessonData(responseSession.data);
            } else {
              //console.error("Home card details not get from database.");
              setSessionLessonData([]);
               setLoader(false);
            }
          })
          .catch(error => {
             setLoader(false);
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
         setLoader(false);
        navigate("/classes-list")
      }
    } else {
      // setLoader(false);
      navigate("/login")
    }
  }



  const getUniqueSubjectNames = jsonData => {
    const uniqueSubjectNames = new Set()

    // Check if the necessary data is available
    if (
      jsonData &&
      jsonData.session &&
      jsonData.session.tbl_session_time &&
      jsonData.session.tbl_session_time.tbl_student_enrolment
    ) {
      // Iterate through student enrollments
      jsonData.session.tbl_session_time.tbl_student_enrolment.forEach(
        enrollment => {
          // Check if the necessary data is available and is an array
          if (
            enrollment &&
            enrollment.tbl_enrolment_subject &&
            Array.isArray(enrollment.tbl_enrolment_subject)
          ) {
            // Iterate through enrollment subjects
            enrollment.tbl_enrolment_subject.forEach(subject => {
              // Assuming subject_name is a property of the subject object and is not null/undefined
              if (subject && subject.subject_name != null) {
                uniqueSubjectNames.add(subject)
              }
            })
          }
        }
      )
    }

    // Convert Set to array if needed
    return Array.from(uniqueSubjectNames)
  }

  const CountTotalCoins = enrolment => {
    let total_coins_stu = 0

    let Completed_total_stu = 0
    //console.log(enrolment);
    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        Completed_total_stu +=
          activity.coins_earned != null && activity.coins_earned != ""
            ? parseInt(activity.coins_earned)
            : 0
        //console.log("tim"+activity.LessonActivitiesQuestions.length);
        // total_coins_stu +=
        //   activity.LessonActivitiesQuestions.length > 0
        //     ? activity.LessonActivitiesQuestions.length * 2
        //     : 0

            if(activity.LessonActivitiesQuestions.length > 0 && parseInt(activity.activity_type) === 0){
              activity.LessonActivitiesQuestions.forEach(lsquestion => {
                  if(parseInt(lsquestion.fk_sub_id) === 2 && lsquestion.question.length > 0 ){
                    total_coins_stu += lsquestion.question.length * 2;
                    // lsquestion.question.forEach(lsquestionParts => {
                    //   total_coins_stu += lsquestionParts.question_parts.length * 2;
                    // });
                  }else if(parseInt(lsquestion.fk_sub_id) === 1 && lsquestion.english_question.length > 0){


                    lsquestion.english_question.forEach((question, index3) => {
                      if((parseInt(question.english_question_category_id) === 10 || parseInt(question.english_question_category_id) === 13)){

                        question.english_question_answers.length > 0 && question.english_question_answers[0].english_answer_text 
                        && question.english_question_answers[0].english_answer_text!='' ? total_coins_stu += 2 : null;

                      }else{
                        total_coins_stu +=2;
                      }
                    })
                   
                   // total_coins_stu += lsquestion.english_question.length * 2;
                    // lsquestion.english_question.forEach(lsquestionParts => {
                    //   total_coins_stu += lsquestionParts.english_question_parts.length * 2;
                    // });
                  }
                });
    
            }else if(activity.LessonActivitiesQuestions.length > 0 && parseInt(activity.activity_type) === 2){
                  activity.LessonActivitiesQuestions.forEach(lsquestion => {
                    if(parseInt(lsquestion.fk_sub_id) === 2 && lsquestion.question.length > 0){
                      //lsquestion.question.forEach(lsquestionParts => {
                        total_coins_stu += lsquestion.question.length * 2;
                      //});
                    }else if(parseInt(lsquestion.fk_sub_id) === 1 && lsquestion.english_question.length > 0){
                      // lsquestion.english_question.forEach(lsquestionParts => {
                        total_coins_stu += lsquestion.english_question.length * 2;
                      //});
                    }
                  });
      
            }else{
    
              activity.LessonActivitiesQuestions.forEach(lsquestion => {
                if(lsquestion.question.length > 0){
                  //lsquestion.question.forEach(lsquestionParts => {
                    total_coins_stu += lsquestion.question.length * 2;
                  //});
                }
              });
              
            }
      })
    }

    return Completed_total_stu + "/" + total_coins_stu
  }


  
  const CountLessonTotalActivity = enrolment => {
    let total_activity_stu = 0
    let completed_activity_stu = 0


    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
     

      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        if (parseInt(activity.marked_for_homework) === 0) {
          completed_activity_stu +=
            activity.status != null && activity.status == "1" ? 1 : 0
          total_activity_stu += 1
        }
      })
    }

    if (total_activity_stu === 0) {
      return <p className="mt-1 mb-1 text-danger font-size-10">No activities assigned</p>
    }

    return (
      <>
        <p className="mb-0 font-size-18 text-white-50">
          {completed_activity_stu}/{total_activity_stu} Activities 
          </p>
      </>
    )
  }



  const getUniqueTopicsNames = jsonData => {
    const uniqueTopicsNames = new Set()
  
    //console.log(jsonData);
  
    // Check if the necessary data is available
    if (jsonData) {
      // Iterate through student enrollments
      // jsonData.forEach((enrollment) => {
      // Check if the necessary data is available and is an array
      if (jsonData && Array.isArray(jsonData)) {
        // Iterate through enrollment subjects
        jsonData.forEach(subject => {
          subject.forEach(data => {
            if (data && data.topic_name != null) {
              uniqueTopicsNames.add(data.topic_name)
            }
          })
        })
      }
      //});
    }
  
    // Convert Set to array if needed
    return Array.from(uniqueTopicsNames)
  }




//redirect Link
const assessementActivity = (activity) =>{

  if(activity.pk_lesson_activity_key && activity.pk_lesson_activity_key!=''){

    if(activity.fk_sub_id && activity.fk_sub_id!=''){
      
      navigate("/assessment/"+activity.pk_lesson_activity_key+"?type="+activity.fk_sub_id+"&homework=0");

    }else{
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

  }else{
    toast.error("Sorry, Activity Key Missing", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
  
}


const assessementActivitySummary = (activity) =>{

  if(activity.pk_lesson_activity_key && activity.pk_lesson_activity_key!=''){

    if(activity.fk_sub_id && activity.fk_sub_id!=''){
      
      navigate("/summary/"+activity.pk_lesson_activity_key+"?type="+activity.fk_sub_id+"&homework=0");

    }else{
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

  }else{
    toast.error("Sorry, Activity Key Missing", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
  
}


//diagnosticActivity



const diagnosticActivity = (activity) =>{

  if(activity.pk_lesson_activity_key && activity.pk_lesson_activity_key!=''){

    if(activity.fk_sub_id && activity.fk_sub_id!=''){
      
      navigate("/diagnostics/"+activity.pk_lesson_activity_key+"?type="+activity.fk_sub_id+"&homework=0");

    }else{
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

  }else{
    toast.error("Sorry, Activity Key Missing", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
  
}



const diagnosticActivitySummary = (activity) =>{

  if(activity.pk_lesson_activity_key && activity.pk_lesson_activity_key!=''){

    if(activity.fk_sub_id && activity.fk_sub_id!=''){
      
      navigate("/diagnostic-summary/"+activity.pk_lesson_activity_key+"?type="+activity.fk_sub_id+"&homework=0");

    }else{
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

  }else{
    toast.error("Sorry, Activity Key Missing", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
  
}


const VideoActivity = (activity) =>{

  if(activity.pk_lesson_activity_key && activity.pk_lesson_activity_key!=''){

    if(activity.fk_sub_id && activity.fk_sub_id!=''){
      
      navigate("/video/"+activity.pk_lesson_activity_key+"?type="+activity.fk_sub_id+"&homework=0");

    }else{
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

  }else{
    toast.error("Sorry, Activity Key Missing", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
  
}


const LinkActivity = (activity) =>{

  if(activity.pk_lesson_activity_key && activity.pk_lesson_activity_key!=''){

    if(activity.fk_sub_id && activity.fk_sub_id!=''){
      
      navigate("/link/"+activity.pk_lesson_activity_key+"?type="+activity.fk_sub_id+"&homework=0");

    }else{
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

  }else{
    toast.error("Sorry, Activity Key Missing", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
  
}


const TaskActivity = (activity) =>{

  if(activity.pk_lesson_activity_key && activity.pk_lesson_activity_key!=''){

    if(activity.fk_sub_id && activity.fk_sub_id!=''){
      
      navigate("/task/"+activity.pk_lesson_activity_key+"?type="+activity.fk_sub_id+"&homework=0");

    }else{
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

  }else{
    toast.error("Sorry, Activity Key Missing", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
  
}


const WorksheetActivity = (activity) =>{

  if(activity.pk_lesson_activity_key && activity.pk_lesson_activity_key!=''){

    if(activity.fk_sub_id && activity.fk_sub_id!=''){
      
      navigate("/worksheet/"+activity.pk_lesson_activity_key+"?type="+activity.fk_sub_id+"&homework=0");

    }else{
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

  }else{
    toast.error("Sorry, Activity Key Missing", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
  
}

const connectLost = () =>{
    toast.error("Connection to server lost! Kindly inform admin", {
      autoClose: 3000, // 3 seconds in this example
    })
}


const formatTime = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  return formattedTime;
};








const handleBackClick = () => {
  
  navigate("/classes-list", {
    state: {
      filterState   
    }
  });
};





  return (
    <React.Fragment>
      <Loader
        show={loader}
        type="body"
      // stack="vertical"
      // message="Loading Data"
      />
      <ToastContainer autoClose={3000} />
      <div className="page-wrapper list-bg">
        <div className="cs-pg-header bg-dark-blue py-3 pb-5">
          <Container className="custom-wt">
            <div className="">
              <div className="mt-0 text-white"  onClick={handleBackClick}>
                {/* <Link className="text-white font-size-14"  to={"/classes-list"}> */}
                  {" "}
                  <span>
                    <i className="fas fa-chevron-left me-1"></i>
                  </span>{" "}
                  BACK
                {/* </Link> */}
              </div>
              <div className="cl-pr-profile d-flex justify-content-between">
                <div className="d-flex">
                  <div className="position-relative me-4" style={{ width: "105px" }}>
                    <img
                      className="avatar-lg rounded-circle"
                      src={us1}
                      alt=""
                    />
                    <span className="pr-name">{sessionLessonData &&
                                  sessionLessonData.session &&
                                  sessionLessonData.session.tut_fname && sessionLessonData.session.tut_fname!=''
                                    ? sessionLessonData.session.tut_fname+ ' '+  sessionLessonData.session.tut_surname
                                    : "-"}</span>
                  </div>
                  <div className="">
                    <h3 className="pg-title text-start">Hi {username ? username.st_first_name ? username.st_first_name+' '+username.st_surname : '' : ''}!</h3>
                    <h5 className="fw-bold text-white mb-0 font-size-20">
                      Welcome to my{" "}
               
          {(sessionLessonData && sessionLessonData) ? 
                              getUniqueSubjectNames(sessionLessonData).map((subjectId, index) => (
                                  <div key={subjectId} className={(parseInt(subjectId.subject_id) === 1 ? 'badge bg-info font-size-15 fw-bold p-2' : 'badge bg-success font-size-15 fw-bold p-2')}>
                                      {subjectId.subject_name}
                                  </div>
                              )) 
                          : null}
                     {" "}
                      class. {" "}({sessionLessonData &&
                                  sessionLessonData.session &&
                                  sessionLessonData.session.tbl_session_time &&
                                  sessionLessonData.session.tbl_session_time
                                    .sest_name
                                    ? sessionLessonData.session.tbl_session_time
                                        .sest_name
                                    : "-"})
                    </h5>

                    <div className="d-flex align-items-center mt-5">
                    {sessionLessonData &&
                    sessionLessonData.session &&
                    sessionLessonData.session.tbl_session_time &&
                    sessionLessonData.session.tbl_session_time.tbl_student_enrolment.length > 0 ?  
                      CountLessonTotalActivity(sessionLessonData.session.tbl_session_time.tbl_student_enrolment[0]) || null : null }
                       
                     
                      <p className="mb-0 d-flex align-items-center gap-2 font-size-18 text-white-50 ms-5">
                        <img width={24} className="me-2" src={awicon} alt="" /> 
                        {sessionLessonData &&
                    sessionLessonData.session &&
                    sessionLessonData.session.tbl_session_time &&
                    sessionLessonData.session.tbl_session_time.tbl_student_enrolment.length > 0 ?  
                    CountTotalCoins(sessionLessonData.session.tbl_session_time.tbl_student_enrolment[0]) || 0 : 0 }{" "}coins earned
                      </p>
                    </div>
                  </div>
                </div>
                <div className="custom-prg-wrapper prg-success">
                  <div className="cs-circle text-center">
                    <div>

                      <span className="text-success text-uppercase">
                         {sessionLessonData &&
                      sessionLessonData.session &&
                      sessionLessonData.session.tbl_session_time &&
                      sessionLessonData.session.tbl_session_time
                        .tbl_student_enrolment &&
                      sessionLessonData.session.tbl_session_time
                        .tbl_student_enrolment[0] &&
                      sessionLessonData.session.tbl_session_time
                        .tbl_student_enrolment[0].tbl_session_attendance &&
                      sessionLessonData.session.tbl_session_time
                        .tbl_student_enrolment[0].tbl_session_attendance
                        .attendance_status != null ? 
                          sessionLessonData.session.tbl_session_time
                            .tbl_student_enrolment[0].tbl_session_attendance
                            .attendance_status != "6" && sessionLessonData.session.ses_is_flexi == 1 ? (
                         
                            'FLEXI CLASS'
                         
                        ) : (
                          sessionLessonData.session.tbl_session_time
                          .tbl_student_enrolment[0].tbl_session_attendance
                          .attendance_status === 0 && parseInt(sessionLessonData.session.ses_status) === 1 ? (
                         
                            'LESSON STARTED'
                         
                        ) : sessionLessonData.session.tbl_session_time
                          .tbl_student_enrolment[0].tbl_session_attendance
                          .attendance_status === 0 ? (
                         
                            'NOT STARTED YET'
                         
                        ) : sessionLessonData.session.tbl_session_time
                            .tbl_student_enrolment[0].tbl_session_attendance
                            .attendance_status == "1" ? (
                         'ONGOING'
                        ) : sessionLessonData.session.tbl_session_time
                            .tbl_student_enrolment[0].tbl_session_attendance
                            .attendance_status == "2" ? (
                         
                            'ATTENDED'
                          
                        ) : sessionLessonData.session.tbl_session_time
                            .tbl_student_enrolment[0].tbl_session_attendance
                            .attendance_status == "3" ? (
                         
                           'MISSED'
                         
                        ) : sessionLessonData.session.tbl_session_time
                            .tbl_student_enrolment[0].tbl_session_attendance
                            .attendance_status == "5" ? (
                          
                           'DEFERED REQUESTED'
                          
                        ) : sessionLessonData.session.tbl_session_time
                            .tbl_student_enrolment[0].tbl_session_attendance
                            .attendance_status == "6" ? (
                         'CANCELLED'
                        ) : null
                      ) : null}
                      </span>
                      <h2 className="luminous-wht">  {sessionLessonData &&
                        sessionLessonData.session &&
                        sessionLessonData.session.ses_date
                          ? moment(
                              sessionLessonData.session.ses_date,
                              moment.ISO_8601
                            ).format("DD MMM")
                          : null}</h2>
                      <p className="text-uppercase mb-0 luminous-wht">
                      
                        {sessionLessonData &&
                        sessionLessonData.session &&
                        sessionLessonData.session.ses_start_time
                          ? moment(
                              sessionLessonData.session.ses_start_time,
                              "HH:mm:ss"
                            ).format("hh:mm A")
                          : null}
                       
                      </p>
                      <p className="text-uppercase mb-0 luminous-wht">
                      {sessionLessonData &&
                        sessionLessonData.session &&
                        sessionLessonData.session.ses_end_time
                          ? moment(
                              sessionLessonData.session.ses_end_time,
                              "HH:mm:ss"
                            ).format("hh:mm A")
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
              <div className="d-flex justify-content-end">
                  {sessionLessonData?.session?.ses_is_flexi == 1 ?
                  <p  style={{
                      width: "30%",
                      color: "#b5c4d7",
                    }}>*Flexi lesson means the student can attempt the activities within the next {parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0} days from the date of the scheduled class. </p>
                  : null }
                </div>
                
            </div>
          </Container>
        </div>
        <Container className="custom-wt">
          <div className="page-inner_section cs-mt">
            <div className="pd-homework-section custom-width-hold pb-100">
              <div className="">
                <h5 className="mb-4 text-dark opacity-50 fw-bold font-size-24">
                  My Lesson Plan
                </h5>
              </div>

              <div className="hw-list mt-4">
                <ul className="list-unstyled mb-0">
{console.log(moment(sessionLessonData?.session?.ses_date).tz(localStorage.getItem("cent_timezone_identifier") 
                                          || "Europe/London").add((parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0), 'days') )}
{console.log(moment().tz(localStorage.getItem("cent_timezone_identifier") || "Europe/London"))}
   {/*  Activity lesson start */}

   {sessionLessonData &&
                      sessionLessonData.session &&
                      sessionLessonData.session.tbl_session_time &&
                      sessionLessonData.session.tbl_session_time.tbl_student_enrolment.map(
                        (enrolment, index) => {
                          //console.log(enrolment)

                          if (
                            enrolment.tbl_student &&
                            enrolment.tbl_student.Lesson &&
                            enrolment.tbl_student.Lesson.LessonActivities
                              .length > 0
                          ) {
                            // const activities = enrolment.tbl_student.Lesson.LessonActivities;

                            //     if (!activities || activities.length === 0) {
                            //       return <p className='text-danger'>No tasks assigned</p>;
                            //     }

                            //     const filteredActivities = activities.filter(activity => parseInt(activity.marked_for_homework) === 0);

                            //     if (filteredActivities.length === 0) {
                            //       return <p className='text-danger'>No tasks assigned</p>;
                            //     }

                            return enrolment.tbl_student.Lesson.LessonActivities.map(
                              (activity, index1) => {
                                if (
                                  parseInt(activity.marked_for_homework) === 0
                                ) {
                                  // console.log(activity)
                                  if (
                                    activity.activity_type === 0 &&
                                    activity.fk_sub_id === 2
                                  ) {
                                    let total_coins = 0;
                                    let total_time = 0;
                                        
                                        
                                        let total_question = 0;
                                        let count_answer = 0;
                                        let percentage = 0;
                                        //let manual_mark = 0;

                                        if (
                                          activity.LessonActivitiesQuestions &&
                                          activity.LessonActivitiesQuestions.length > 0
                                        ) {
                                          //total_question += activity.LessonActivitiesQuestions.length;
                                        
                                          activity.LessonActivitiesQuestions.forEach((activityQuestion, index2) => {

                                            
                                            if (activityQuestion.question && activityQuestion.question.length > 0) {
                                              let filteredManualMark =[];
                                              
                                             // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                                // if(filteredManualMark.length > 0){
                                                //   manual_mark += 1;
                                                // } 
                                              activityQuestion.question.forEach((question, index3) => {
                                                
                                                total_question += 1;
                                                total_coins +=2;
                                                if (question.StudentAnsAttempts && question.StudentAnsAttempts.length > 0) {
                                                  question.StudentAnsAttempts.forEach((stuAnswer) => {
                                                    total_time += parseInt(stuAnswer.submitted_on);
                                                    
                                                  });
                                                  count_answer += 1; // Increment count_answer by 1 for each occurrence
                                                }
                                              });
                                            }
                                          });
                                        }
                                        if(total_question >= count_answer && total_question != 0){
                                          percentage = (count_answer/total_question) * 100;
                                        }
                                       


                                       // let link_generate = '';

                                        //console.log(sessionLessonData.session.ses_is_completed)
                                   // console.log(count_answer);
                                    return (
                                      <li className="d-flex justify-content-between" key={index1}>
                                      <div className="d-flex align-items-start">
                                      {activity && activity.status && parseInt(activity.status) === 1 ?
                                          <div className="hw-icon bg-success text-white font-size-24 me-3">
                                            <i className="fas fa-check-circle"></i>
                                          </div>
                                          : 
                                          <div className="hw-icon">
                                            <img width={24} className="" src={hw1} alt="" />
                                          </div>
                                          }
                                        
                                        <div>
                                          <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                            ASSESSMENT
                                          </p>
                                          <h4 className=" fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0]
                                                .node_name
                                            : null}
                                          </h4>
                                          <div className="ft-util d-flex">
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img
                                                width={24}
                                                className="me-2"
                                                src={duration}
                                                alt=""
                                              />
                                              {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "} Minutes
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img width={24} className="me-2" src={awicon} alt="" />
                                              {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "} Coins
                                            </div>
                                            
                                          </div>
                                        </div>
                                      </div>
                                      {activity && activity.status && parseInt(activity.status) === 1 ?
                                      <div className="hw-status text-end">
                                       <div className="direct_link" onClick={()=> assessementActivitySummary(activity)}> <div className="d-flex align-items-center text-success fw-bold text-uppercase">
                                          <span className="mcr-success"></span>Completed
                                        </div></div>
                                        <p className="mb-0 text-uppercase font-size-12">{formatTime(total_time)} Mins</p>
                                      </div>

                                      :
                                      ((sessionLessonData && sessionLessonData.session && sessionLessonData.session.ses_is_completed 
                                        && parseInt(sessionLessonData.session.ses_is_completed) === 1 && sessionLessonData?.session?.ses_is_flexi != 1) 
                                      ||
                                      (sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) === 1 
                                      && sessionLessonData?.session?.ses_is_flexi == 1 && 
                                      (moment(sessionLessonData.session.ses_date).tz(localStorage.getItem("cent_timezone_identifier") 
                                          || "Europe/London").add((parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0), 'days') 
                                        < 
                                        moment().tz(localStorage.getItem("cent_timezone_identifier") || "Europe/London")
                                      )
                                      )) ?
                                      <div className="direct_link">
                                        <div className="hw-status text-end">
                                        {parseInt(percentage) !== 0 ?
                                        <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                          Incompleted
                                        </div>
                                        : 
                                        <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                          Missed
                                        </div> }
                                      </div>
                                      </div>
                                      :
                                      sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) !== 1 ?
                                      <div className="direct_link">
                                        <div className="hw-status text-end">
                                        
                                          <div className="d-flex align-items-center text-dark fw-bold text-uppercase">
                                            SESSION HAS NOT BEEN STARTED
                                          </div>
                                        
                                        </div>
                                      </div>

                                      :
                                      <div className="direct_link"> 
                                      <div className="hw-status text-end">
                                        {parseInt(percentage) !== 0 ?
                                          <Button className="btn btn-danger cs-btn-danger text-uppercase"  onClick={()=> assessementActivity(activity)}>
                                            Resume
                                          </Button>
                                          :
                                        <Button className="btn btn-danger cs-btn-danger text-uppercase"  onClick={()=> assessementActivity(activity)}>
                                        Start
                                      </Button> }
                                    </div>
                                      </div>
                                      }
                                      <div className="ph_list-progressbar">
                                        <Progress color="success" value={percentage !== 0 ? percentage : 0} />
                                      </div>
                                    </li>

                                      

                                    )
                                  } else if (
                                    activity.activity_type === 0 &&
                                    activity.fk_sub_id === 1
                                  ) {
                                    let total_coins = 0;
                                    let total_time = 0;
                                        
                                        
                                        let total_question = 0;
                                        let count_answer = 0;
                                        let percentage = 0;
                                        let manual_mark = 0;
                                        let manual_mark_review = 0;
                                        let time_editing_category = 0;
                                        if (
                                          activity.LessonActivitiesQuestions &&
                                          activity.LessonActivitiesQuestions.length > 0
                                        ) {
                                          //total_question += activity.LessonActivitiesQuestions.length;
                                        
                                          activity.LessonActivitiesQuestions.forEach((activityQuestion, index2) => {

                                            manual_mark_review += parseInt(activityQuestion.marked_for_manual_marking);
                                            if (activityQuestion.english_question && activityQuestion.english_question.length > 0) {
                                              let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                                if(filteredManualMark.length > 0){
                                                  manual_mark += 1;
                                                } 
                                              activityQuestion.english_question.forEach((question, index3) => {

                                                if((parseInt(question.english_question_category_id) === 10 || 
                                                parseInt(question.english_question_category_id) === 13 || 
                                                parseInt(question.english_question_category_id) === 12 ||
                                                parseInt(question.english_question_category_id) === 8 )){

                                                if(index3 === 0 && question.english_question_time_needed === 'Less than 2 minutes'){
                                                  time_editing_category += 2;
                                                }else if(index3 === 0 && question.english_question_time_needed === '2-5 minutes'){
                                                  time_editing_category += 5;
                                                }else if(index3 === 0 && question.english_question_time_needed === '5-10 minutes'){
                                                  time_editing_category += 10;
                                                }else if(index3 === 0 && question.english_question_time_needed === '10-15 minutes'){
                                                  time_editing_category += 15;
                                                }else if(index3 === 0 && question.english_question_time_needed === '15-20 minutes'){
                                                  time_editing_category += 20;
                                                }else if(index3 === 0 && question.english_question_time_needed === '20-30 minutes'){
                                                  time_editing_category += 30;
                                                }else if(index3 === 0 && question.english_question_time_needed === '30-60 minutes'){
                                                  time_editing_category += 60;
                                                }
                                              }else{
                                                time_editing_category = activity.total_activity_time;
                                              }


                                                if((parseInt(question.english_question_category_id) === 10 || parseInt(question.english_question_category_id) === 13)){

                                                  question.english_question_answers.length > 0 && question.english_question_answers[0].english_answer_text 
                                                  && question.english_question_answers[0].english_answer_text!='' ? (total_question += 1, total_coins += 2) : null;
                                                  
                                                }else{
                                                  total_question += 1;
                                                  total_coins +=2;
                                                 
                                                }
                                               
                                                //total_coins +=2;
                                                if (question.StudentAnsAttempts && question.StudentAnsAttempts.length > 0) {
                                                  question.StudentAnsAttempts.forEach((stuAnswer) => {
                                                    total_time += parseInt(stuAnswer.submitted_on);
                                                    
                                                  });
                                                  count_answer += 1; // Increment count_answer by 1 for each occurrence
                                                }
                                              });
                                            }
                                          });
                                        }
                                        if(total_question >= count_answer && total_question != 0){
                                          percentage = (count_answer/total_question) * 100;
                                        }
                                        // console.log('quest'+total_question)
                                        // console.log('ans'+count_answer)
                                    return (

                                      <li className="d-flex justify-content-between" key={index1}>
                                      <div className="d-flex align-items-start">
                                      {activity && activity.status && parseInt(activity.status) === 1 ?
                                          <div className="hw-icon bg-success text-white font-size-24 me-3">
                                            <i className="fas fa-check-circle"></i>
                                          </div>
                                          : 
                                          <div className="hw-icon">
                                            <img width={24} className="" src={hw1} alt="" />
                                          </div>
                                          }
                                        
                                        <div>
                                          <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                            ASSESSMENT
                                          </p>
                                          <h4 className=" fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0]
                                                .node_name
                                            : null}
                                          </h4>
                                          <div className="ft-util d-flex">
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img
                                                width={24}
                                                className="me-2"
                                                src={duration}
                                                alt=""
                                              />
                                              {time_editing_category
                                                    ? time_editing_category
                                                    : 0}{" "} Minutes
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img width={24} className="me-2" src={awicon} alt="" />
                                              {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "} Coins
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {activity && activity.status && parseInt(activity.status) === 1 && manual_mark > 0 ? <span className="badge bg-primary font-size-14 mt-auto mb-2">Manual Marking</span> : null}
                                            </div>

                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {activity && activity.status && parseInt(activity.status) === 1 && manual_mark > 0 && parseInt(manual_mark_review)<= 0 ? <span className="badge bg-primary font-size-14 mt-auto mb-2">In Review</span> : null}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {activity && activity.status && parseInt(activity.status) === 1 ?

                                      <div className="hw-status text-end">
                                        <div className="direct_link" onClick={()=> assessementActivitySummary(activity)}>
                                        <div className="d-flex align-items-center text-success fw-bold text-uppercase">
                                          <span className="mcr-success"></span>Completed
                                        </div>
                                        </div>
                                        <p className="mb-0 text-uppercase font-size-12">{formatTime(total_time)} Mins</p>
                                      </div>

                                      :
                                      ((sessionLessonData && sessionLessonData.session && sessionLessonData.session.ses_is_completed 
                                        && parseInt(sessionLessonData.session.ses_is_completed) === 1 && sessionLessonData?.session?.ses_is_flexi != 1) 
                                      ||
                                      (sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) === 1 
                                      && sessionLessonData?.session?.ses_is_flexi == 1 && 
                                      (moment(sessionLessonData.session.ses_date).tz(localStorage.getItem("cent_timezone_identifier") 
                                          || "Europe/London").add((parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0), 'days') 
                                        < 
                                        moment().tz(localStorage.getItem("cent_timezone_identifier") || "Europe/London")
                                      )
                                      ))?
                                      <div className="direct_link">
                                      <div className="hw-status text-end">
                                        {parseInt(percentage) !== 0 ?
                                        <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                          Incompleted
                                        </div>
                                        : 
                                        <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                          Missed
                                        </div>}
                                      </div>
                                      </div>
                                      :
                                      sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) !== 1 ?
                                      <div className="direct_link">
                                        <div className="hw-status text-end">
                                        
                                          <div className="d-flex align-items-center text-dark fw-bold text-uppercase">
                                            SESSION HAS NOT BEEN STARTED
                                          </div>
                                        
                                        </div>
                                      </div>

                                      :
                                      <div  className="direct_link">
                                      <div className="hw-status text-end">
                                        {parseInt(percentage) !== 0 ?
                                         
                                          <Button className="btn btn-danger cs-btn-danger text-uppercase"  onClick={()=> assessementActivity(activity)}>
                                            Resume
                                          </Button>
                                          :
                                          <Button className="btn btn-danger cs-btn-danger text-uppercase"  onClick={()=> assessementActivity(activity)}>
                                            Start
                                          </Button>
                                           }
                                      </div>
                                      </div>
                                      }
                                      <div className="ph_list-progressbar">
                                        <Progress color="success" value={percentage !== 0 ? percentage : 0} />
                                      </div>
                                    </li>

                                   
                                    )
                                  } else if (
                                    activity.activity_type === 1 
                                  ) {
                                    let total_coins = 0;
                                    let total_time = 0;
                                        
                                        
                                        let total_question = 0;
                                        let count_answer = 0;
                                        let percentage = 0;
                                        //let manual_mark = 0;

                                        if (
                                          activity.LessonActivitiesQuestions &&
                                          activity.LessonActivitiesQuestions.length > 0
                                        ) {
                                          //total_question += activity.LessonActivitiesQuestions.length;
                                        
                                          activity.LessonActivitiesQuestions.forEach((activityQuestion, index2) => {

                                            
                                            if (activityQuestion.question && activityQuestion.question.length > 0) {
                                              let filteredManualMark =[];
                                              
                                             // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                                // if(filteredManualMark.length > 0){
                                                //   manual_mark += 1;
                                                // } 
                                              activityQuestion.question.forEach((question, index3) => {
                                                
                                                total_question += 1;
                                                total_coins +=2;
                                                if (question.StudentAnsAttempts && question.StudentAnsAttempts.length > 0) {
                                                  question.StudentAnsAttempts.forEach((stuAnswer) => {
                                                    total_time += parseInt(stuAnswer.submitted_on);
                                                    
                                                  });
                                                  count_answer += 1; // Increment count_answer by 1 for each occurrence
                                                }
                                              });
                                            }
                                          });
                                        }
                                        if(total_question >= count_answer && total_question != 0){
                                          percentage = (count_answer/total_question) * 100;
                                        }

                                    // console.log(activity.activity_node[0].);
                                    return (
                                  <li className="d-flex justify-content-between" key={index1}>
                                      <div className="d-flex align-items-start">
                                      {activity && activity.status && parseInt(activity.status) === 1 ?
                                          <div className="hw-icon bg-success text-white font-size-24 me-3">
                                            <i className="fas fa-check-circle"></i>
                                          </div>
                                          : 
                                          <div className="hw-icon">
                                            <img width={24} className="" src={hw2} alt="" />
                                          </div>
                                          }
                                        
                                        <div>
                                          <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                            VIDEO
                                          </p>
                                          <h4 className=" fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name !=
                                            ""
                                            ? activity.activity_node[0].name
                                            : null}
                                          </h4>
                                          <div className="ft-util d-flex">
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img
                                                width={24}
                                                className="me-2"
                                                src={duration}
                                                alt=""
                                              />
                                              {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "} Minutes
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img width={24} className="me-2" src={awicon} alt="" />
                                              {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "} Coins
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {activity && activity.status && parseInt(activity.status) === 1 ?

                                        <div className="hw-status text-end">
                                          <div className="direct_link">
                                          <div className="d-flex align-items-center text-success fw-bold text-uppercase" onClick={()=> VideoActivity(activity)}>
                                            <span className="mcr-success"></span>Completed
                                          </div>
                                          </div>
                                          <p className="mb-0 text-uppercase font-size-12">{formatTime(total_time)} Mins</p>
                                        </div>

                                        :
                                        ((sessionLessonData && sessionLessonData.session && sessionLessonData.session.ses_is_completed 
                                          && parseInt(sessionLessonData.session.ses_is_completed) === 1 && sessionLessonData?.session?.ses_is_flexi != 1) 
                                        ||
                                        (sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) === 1 
                                        && sessionLessonData?.session?.ses_is_flexi == 1 && 
                                        (moment(sessionLessonData.session.ses_date).tz(localStorage.getItem("cent_timezone_identifier") 
                                            || "Europe/London").add((parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0), 'days') 
                                          < 
                                          moment().tz(localStorage.getItem("cent_timezone_identifier") || "Europe/London")
                                        )
                                        ))?
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Incompleted
                                          </div>
                                          : 
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Missed
                                          </div>}
                                        </div>
                                        </div>
                                        :
                                        sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) !== 1 ?
                                      <div className="direct_link">
                                        <div className="hw-status text-end">
                                        
                                          <div className="d-flex align-items-center text-dark fw-bold text-uppercase">
                                            SESSION HAS NOT BEEN STARTED
                                          </div>
                                        
                                        </div>
                                      </div>

                                      :
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                            
                                            <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> VideoActivity(activity)}>
                                              Resume
                                            </Button>
                                            :
                                            <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> VideoActivity(activity)}>
                                              Start
                                            </Button> }
                                        </div>
                                        </div>
                                        }

                                     
                                      <div className="ph_list-progressbar">
                                        <Progress color="success" value={percentage !== 0 ? percentage : 0} />
                                      </div>
                                    </li>


                                    )
                                  } else if (
                                    activity.activity_type === 2 &&
                                    activity.fk_sub_id === 1
                                  ) {

                                    let total_coins = 0;
                                    let total_time = 0;
                                        
                                        
                                        let total_question = 0;
                                        let count_answer = 0;
                                        let percentage = 0;
                                        let manual_mark = 0;
                                        if (
                                          activity.LessonActivitiesQuestions &&
                                          activity.LessonActivitiesQuestions.length > 0
                                        ) {
                                          //total_question += activity.LessonActivitiesQuestions.length;
                                        
                                          activity.LessonActivitiesQuestions.forEach((activityQuestion, index2) => {

                                            
                                            if (activityQuestion.english_question && activityQuestion.english_question.length > 0) {
                                              let filteredManualMark = [];
                                              // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                              //   if(filteredManualMark.length > 0){
                                              //     manual_mark += 1;
                                              //   } 
                                              activityQuestion.english_question.forEach((question, index3) => {
                                                
                                                total_question += 1;
                                                total_coins +=2;
                                                if (question.StudentAnsAttempts && question.StudentAnsAttempts.length > 0) {
                                                  question.StudentAnsAttempts.forEach((stuAnswer) => {
                                                    total_time += parseInt(stuAnswer.submitted_on);
                                                    
                                                  });
                                                  count_answer += 1; // Increment count_answer by 1 for each occurrence
                                                }
                                              });
                                            }
                                          });
                                        }
                                        if(total_question >= count_answer && total_question != 0){
                                          percentage = (count_answer/total_question) * 100;
                                        }

                                    // console.log(activity.activity_node[0].);
                                    return (

                                      <li className="d-flex justify-content-between" key={index1}>
                                      <div className="d-flex align-items-start">
                                      {activity && activity.status && parseInt(activity.status) === 1 ?
                                          <div className="hw-icon bg-success text-white font-size-24 me-3">
                                            <i className="fas fa-check-circle"></i>
                                          </div>
                                          : 
                                          <div className="hw-icon">
                                            <img width={24} className="" src={hw5} alt="" />
                                          </div>
                                          }
                                        
                                        <div>
                                          <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                            DIAGNOSTICS
                                          </p>
                                          <h4 className=" fw-bold font-size-24">
                                          {activity.topics &&
                                          activity.topics.length > 0
                                            ? getUniqueTopicsNames(
                                                activity.topics
                                              )
                                            : null}
                                          </h4>
                                          <div className="ft-util d-flex">
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img
                                                width={24}
                                                className="me-2"
                                                src={duration}
                                                alt=""
                                              />
                                              {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "} Minutes
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img width={24} className="me-2" src={awicon} alt="" />
                                              {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "} Coins
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {activity && activity.status && parseInt(activity.status) === 1 ?

                                        <div className="hw-status text-end">
                                          <div className="direct_link">
                                          <div className="d-flex align-items-center text-success fw-bold text-uppercase" onClick={()=> diagnosticActivitySummary(activity)}>
                                            <span className="mcr-success"></span>Completed
                                          </div>
                                          </div>
                                          <p className="mb-0 text-uppercase font-size-12">{formatTime(total_time)} Mins</p>
                                        </div>

                                        :
                                        ((sessionLessonData && sessionLessonData.session && sessionLessonData.session.ses_is_completed 
                                          && parseInt(sessionLessonData.session.ses_is_completed) === 1 && sessionLessonData?.session?.ses_is_flexi != 1) 
                                        ||
                                        (sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) === 1 
                                        && sessionLessonData?.session?.ses_is_flexi == 1 && 
                                        (moment(sessionLessonData.session.ses_date).tz(localStorage.getItem("cent_timezone_identifier") 
                                            || "Europe/London").add((parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0), 'days') 
                                          < 
                                          moment().tz(localStorage.getItem("cent_timezone_identifier") || "Europe/London")
                                        )
                                        ))?
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Incompleted
                                          </div>
                                          : 
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Missed
                                          </div>}
                                        </div>
                                        </div>
                                        :
                                        sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) !== 1 ?
                                      <div className="direct_link">
                                        <div className="hw-status text-end">
                                        
                                          <div className="d-flex align-items-center text-dark fw-bold text-uppercase">
                                            SESSION HAS NOT BEEN STARTED
                                          </div>
                                        
                                        </div>
                                      </div>

                                      :
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                          <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> diagnosticActivity(activity)}>
                                            Resume
                                          </Button>
                                            :
                                            <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> diagnosticActivity(activity)}>
                                              Start
                                            </Button>
                                            }
                                        </div>
                                        </div>
                                        }
                                      <div className="ph_list-progressbar">
                                        <Progress color="success" value={percentage !== 0 ? percentage : 0} />
                                      </div>
                                    </li>
                                      
                                    )
                                  } else if (
                                    activity.activity_type === 2 &&
                                    activity.fk_sub_id === 2
                                  ) {
                                    let total_coins = 0;
                                    let total_time = 0;
                                        
                                        
                                        let total_question = 0;
                                        let count_answer = 0;
                                        let percentage = 0;
                                        let manual_mark = 0;
                                        if (
                                          activity.LessonActivitiesQuestions &&
                                          activity.LessonActivitiesQuestions.length > 0
                                        ) {
                                          //total_question += activity.LessonActivitiesQuestions.length;
                                        
                                          activity.LessonActivitiesQuestions.forEach((activityQuestion, index2) => {

                                            
                                            if (activityQuestion.question && activityQuestion.question.length > 0) {
                                              let filteredManualMark = [];
                                              // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                              //   if(filteredManualMark.length > 0){
                                              //     manual_mark += 1;
                                              //   } 
                                              activityQuestion.question.forEach((question, index3) => {
                                                
                                                total_question += 1;
                                                total_coins +=2;
                                                if (question.StudentAnsAttempts && question.StudentAnsAttempts.length > 0) {
                                                  question.StudentAnsAttempts.forEach((stuAnswer) => {
                                                    total_time += parseInt(stuAnswer.submitted_on);
                                                    
                                                  });
                                                  count_answer += 1; // Increment count_answer by 1 for each occurrence
                                                }
                                              });
                                            }
                                          });
                                        }
                                        if(total_question >= count_answer && total_question != 0){
                                          percentage = (count_answer/total_question) * 100;
                                        }
                                    
                                    // console.log(activity.activity_node[0].);
                                    return (

                                      <li className="d-flex justify-content-between" key={index1}>
                                      <div className="d-flex align-items-start">
                                      {activity && activity.status && parseInt(activity.status) === 1 ?
                                          <div className="hw-icon bg-success text-white font-size-24 me-3">
                                            <i className="fas fa-check-circle"></i>
                                          </div>
                                          : 
                                          <div className="hw-icon">
                                            <img width={24} className="" src={hw5} alt="" />
                                          </div>
                                          }
                                        
                                        <div>
                                          <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                            DIAGNOSTICS
                                          </p>
                                          <h4 className=" fw-bold font-size-24">
                                          {activity.topics &&
                                          activity.topics.length > 0
                                            ? getUniqueTopicsNames(
                                                activity.topics
                                              )
                                            : null}
                                          </h4>
                                          <div className="ft-util d-flex">
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img
                                                width={24}
                                                className="me-2"
                                                src={duration}
                                                alt=""
                                              />
                                              {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "} Minutes
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img width={24} className="me-2" src={awicon} alt="" />
                                              {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "} Coins
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {activity && activity.status && parseInt(activity.status) === 1 ?

                                        <div className="hw-status text-end">
                                          <div className="direct_link">
                                          <div className="d-flex align-items-center text-success fw-bold text-uppercase"  onClick={()=> diagnosticActivitySummary(activity)}>
                                            <span className="mcr-success"></span>Completed
                                          </div>
                                          </div>
                                          <p className="mb-0 text-uppercase font-size-12">{formatTime(total_time)} Mins</p>
                                        </div>

                                        :

                                        ((sessionLessonData && sessionLessonData.session && sessionLessonData.session.ses_is_completed 
                                          && parseInt(sessionLessonData.session.ses_is_completed) === 1 && sessionLessonData?.session?.ses_is_flexi != 1) 
                                        ||
                                        (sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) === 1 
                                        && sessionLessonData?.session?.ses_is_flexi == 1 && 
                                        (moment(sessionLessonData.session.ses_date).tz(localStorage.getItem("cent_timezone_identifier") 
                                            || "Europe/London").add((parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0), 'days') 
                                          < 
                                          moment().tz(localStorage.getItem("cent_timezone_identifier") || "Europe/London")
                                        )
                                        ))?
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Incompleted
                                          </div>
                                          : 
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Missed
                                          </div>}
                                        </div>
                                        </div>
                                        :
                                        sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) !== 1 ?
                                      <div className="direct_link">
                                        <div className="hw-status text-end">
                                        
                                          <div className="d-flex align-items-center text-dark fw-bold text-uppercase">
                                            SESSION HAS NOT BEEN STARTED
                                          </div>
                                        
                                        </div>
                                      </div>

                                      :
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                            <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> diagnosticActivity(activity)}>
                                              Resume
                                            </Button>
                                            :
                                            <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> diagnosticActivity(activity)}>
                                              Start
                                            </Button>
                                             }
                                        </div>
                                        </div>
                                        }

                                      <div className="ph_list-progressbar">
                                        <Progress color="success" value={percentage !== 0 ? percentage : 0} />
                                      </div>
                                    </li>


                                     
                                    )
                                  } else if (
                                    activity.activity_type === 3 
                                  ) {
                                    let total_coins = 0;
                                    let total_time = 0;
                                        
                                        
                                        let total_question = 0;
                                        let count_answer = 0;
                                        let percentage = 0;
                                        //let manual_mark = 0;

                                        if (
                                          activity.LessonActivitiesQuestions &&
                                          activity.LessonActivitiesQuestions.length > 0
                                        ) {
                                          //total_question += activity.LessonActivitiesQuestions.length;
                                        
                                          activity.LessonActivitiesQuestions.forEach((activityQuestion, index2) => {

                                            
                                            if (activityQuestion.question && activityQuestion.question.length > 0) {
                                              let filteredManualMark =[];
                                              
                                             // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                                // if(filteredManualMark.length > 0){
                                                //   manual_mark += 1;
                                                // } 
                                              activityQuestion.question.forEach((question, index3) => {
                                                
                                                total_question += 1;
                                                total_coins +=2;
                                                if (question.StudentAnsAttempts && question.StudentAnsAttempts.length > 0) {
                                                  question.StudentAnsAttempts.forEach((stuAnswer) => {
                                                    total_time += parseInt(stuAnswer.submitted_on);
                                                    
                                                  });
                                                  count_answer += 1; // Increment count_answer by 1 for each occurrence
                                                }
                                              });
                                            }
                                          });
                                        }
                                        if(total_question >= count_answer && total_question != 0){
                                          percentage = (count_answer/total_question) * 100;
                                        }

                                    // console.log(activity.activity_node[0].);
                                    return (

                                      <li className="d-flex justify-content-between" key={index1}>
                                      <div className="d-flex align-items-start">
                                      {activity && activity.status && parseInt(activity.status) === 1 ?
                                          <div className="hw-icon bg-success text-white font-size-24 me-3">
                                            <i className="fas fa-check-circle"></i>
                                          </div>
                                          : 
                                          <div className="hw-icon">
                                            <img width={24} className="" src={hw2} alt="" />
                                          </div>
                                          }
                                        
                                        <div>
                                          <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                            LINK
                                          </p>
                                          <h4 className=" fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name !=
                                            ""
                                            ? activity.activity_node[0].name
                                            : null}
                                          </h4>
                                          <div className="ft-util d-flex">
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img
                                                width={24}
                                                className="me-2"
                                                src={duration}
                                                alt=""
                                              />
                                              {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "} Minutes
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img width={24} className="me-2" src={awicon} alt="" />
                                              {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "} Coins
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {activity && activity.status && parseInt(activity.status) === 1 ?

                                        <div className="hw-status text-end">
                                          <div className="direct_link">
                                          <div className="d-flex align-items-center text-success fw-bold text-uppercase" onClick={()=> LinkActivity(activity)}>
                                            <span className="mcr-success"></span>Completed
                                          </div>
                                          </div>
                                          <p className="mb-0 text-uppercase font-size-12">{formatTime(total_time)} Mins</p>
                                        </div>

                                        :

                                        ((sessionLessonData && sessionLessonData.session && sessionLessonData.session.ses_is_completed 
                                          && parseInt(sessionLessonData.session.ses_is_completed) === 1 && sessionLessonData?.session?.ses_is_flexi != 1) 
                                        ||
                                        (sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) === 1 
                                        && sessionLessonData?.session?.ses_is_flexi == 1 && 
                                        (moment(sessionLessonData.session.ses_date).tz(localStorage.getItem("cent_timezone_identifier") 
                                            || "Europe/London").add((parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0), 'days') 
                                          <  
                                          moment().tz(localStorage.getItem("cent_timezone_identifier") || "Europe/London")
                                        )
                                        ))?
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Incompleted
                                          </div>
                                          : 
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Missed
                                          </div>}
                                        </div>
                                        </div>
                                        :
                                        sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) !== 1 ?
                                      <div className="direct_link">
                                        <div className="hw-status text-end">
                                        
                                          <div className="d-flex align-items-center text-dark fw-bold text-uppercase">
                                            SESSION HAS NOT BEEN STARTED
                                          </div>
                                        
                                        </div>
                                      </div>

                                      :
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                            
                                            <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> LinkActivity(activity)}>
                                              Resume
                                            </Button>
                                            :
                                            <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> LinkActivity(activity)}>
                                              Start
                                            </Button>
                                             }
                                        </div>
                                        </div>
                                        }

                                      <div className="ph_list-progressbar">
                                        <Progress color="success" value={percentage !== 0 ? percentage : 0} />
                                      </div>
                                    </li>

                                    
                                    )
                                  } else if (
                                    activity.activity_type === 4 
                                  ) {
                                    let total_coins = 0;
                                    let total_time = 0;
                                        
                                        
                                        let total_question = 0;
                                        let count_answer = 0;
                                        let percentage = 0;
                                        //let manual_mark = 0;

                                        if (
                                          activity.LessonActivitiesQuestions &&
                                          activity.LessonActivitiesQuestions.length > 0
                                        ) {
                                          //total_question += activity.LessonActivitiesQuestions.length;
                                        
                                          activity.LessonActivitiesQuestions.forEach((activityQuestion, index2) => {

                                            
                                            if (activityQuestion.question && activityQuestion.question.length > 0) {
                                              let filteredManualMark =[];
                                              
                                             // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                                // if(filteredManualMark.length > 0){
                                                //   manual_mark += 1;
                                                // } 
                                              activityQuestion.question.forEach((question, index3) => {
                                                
                                                total_question += 1;
                                                total_coins +=2;
                                                if (question.StudentAnsAttempts && question.StudentAnsAttempts.length > 0) {
                                                  question.StudentAnsAttempts.forEach((stuAnswer) => {
                                                    total_time += parseInt(stuAnswer.submitted_on);
                                                    
                                                  });
                                                  count_answer += 1; // Increment count_answer by 1 for each occurrence
                                                }
                                              });
                                            }
                                          });
                                        }
                                        if(total_question >= count_answer && total_question != 0){
                                          percentage = (count_answer/total_question) * 100;
                                        }

                                    // console.log(activity.activity_node[0].);
                                    return (

                                    <li className="d-flex justify-content-between" key={index1}>
                                      <div className="d-flex align-items-start">
                                      {activity && activity.status && parseInt(activity.status) === 1 ?
                                          <div className="hw-icon bg-success text-white font-size-24 me-3">
                                            <i className="fas fa-check-circle"></i>
                                          </div>
                                          : 
                                          <div className="hw-icon">
                                            <img width={24} className="" src={hw1} alt="" />
                                          </div>
                                          }
                                        
                                        <div>
                                          <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                            TASK
                                          </p>
                                          <h4 className=" fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name !=
                                            ""
                                            ? activity.activity_node[0].name
                                            : null}
                                          </h4>
                                          <div className="ft-util d-flex">
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img
                                                width={24}
                                                className="me-2"
                                                src={duration}
                                                alt=""
                                              />
                                              {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "} Minutes
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img width={24} className="me-2" src={awicon} alt="" />
                                              {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "} Coins
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {activity && activity.status && parseInt(activity.status) === 1 ?

                                        <div className="hw-status text-end">
                                          <div className="direct_link">
                                          <div className="d-flex align-items-center text-success fw-bold text-uppercase" onClick={()=> TaskActivity(activity)}>
                                            <span className="mcr-success"></span>Completed
                                          </div>
                                          </div>
                                          <p className="mb-0 text-uppercase font-size-12">{formatTime(total_time)} Mins</p>
                                        </div>

                                        :

                                        ((sessionLessonData && sessionLessonData.session && sessionLessonData.session.ses_is_completed 
                                          && parseInt(sessionLessonData.session.ses_is_completed) === 1 && sessionLessonData?.session?.ses_is_flexi != 1) 
                                        ||
                                        (sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) === 1 
                                        && sessionLessonData?.session?.ses_is_flexi == 1 && 
                                        (moment(sessionLessonData.session.ses_date).tz(localStorage.getItem("cent_timezone_identifier") 
                                            || "Europe/London").add((parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0), 'days') 
                                          <  
                                          moment().tz(localStorage.getItem("cent_timezone_identifier") || "Europe/London")
                                        )
                                        ))?
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Incompleted
                                          </div>
                                          : 
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Missed
                                          </div>}
                                        </div>
                                        </div>
                                        :
                                        sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) !== 1 ?
                                        <div className="direct_link">
                                          <div className="hw-status text-end">
                                          
                                            <div className="d-flex align-items-center text-dark fw-bold text-uppercase">
                                              SESSION HAS NOT BEEN STARTED
                                            </div>
                                          
                                          </div>
                                        </div>
  
                                        :
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                            
                                            <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> TaskActivity(activity)}>
                                              Resume
                                            </Button>
                                            :
                                            <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> TaskActivity(activity)}>
                                              Start
                                            </Button>
                                            }
                                        </div>
                                        </div>
                                        }
                                      <div className="ph_list-progressbar">
                                        <Progress color="success" value={percentage !== 0 ? percentage : 0} />
                                      </div>
                                    </li>


                                    )
                                  } else if (
                                    activity.activity_type === 5 
                                  ) {
                                    let total_coins = 0;
                                    let total_time = 0;
                                        
                                        
                                        let total_question = 0;
                                        let count_answer = 0;
                                        let percentage = 0;
                                        //let manual_mark = 0;
                                        let manual_mark_review = 0;
                                        if (
                                          activity.LessonActivitiesQuestions &&
                                          activity.LessonActivitiesQuestions.length > 0
                                        ) {
                                          //total_question += activity.LessonActivitiesQuestions.length;
                                        
                                          activity.LessonActivitiesQuestions.forEach((activityQuestion, index2) => {
                                            total_question += 1;
                                            manual_mark_review += parseInt(activityQuestion.marked_for_manual_marking);
                                            if (activityQuestion.StudentAnsAttempts && activityQuestion.StudentAnsAttempts.length > 0) {
                                              activityQuestion.StudentAnsAttempts.forEach((stuAnswer) => {
                                                total_time += parseInt(stuAnswer.submitted_on);
                                                
                                              });
                                              count_answer += 1; // Increment count_answer by 1 for each occurrence
                                            }
                                            if (activityQuestion.question && activityQuestion.question.length > 0) {
                                              let filteredManualMark =[];
                                              
                                             // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                                // if(filteredManualMark.length > 0){
                                                //   manual_mark += 1;
                                                // } 
                                              activityQuestion.question.forEach((question, index3) => {
                                                
                                                
                                                total_coins +=2;
                                                
                                              });
                                            }
                                          });
                                        }
                                        if(total_question >= count_answer && total_question != 0){
                                          percentage = (count_answer/total_question) * 100;
                                        }
                                    
                                    // console.log(activity.activity_node[0].);
                                    return (
                                      <li className="d-flex justify-content-between" key={index1}>
                                      <div className="d-flex align-items-start">
                                      {activity && activity.status && parseInt(activity.status) === 1 ?
                                          <div className="hw-icon bg-success text-white font-size-24 me-3">
                                            <i className="fas fa-check-circle"></i>
                                          </div>
                                          : 
                                          <div className="hw-icon">
                                            <img width={24} className="" src={hw4} alt="" />
                                          </div>
                                          }
                                        
                                        <div>
                                          <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                            WORKSHEET
                                          </p>
                                          <h4 className=" fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name !=
                                            ""
                                            ? activity.activity_node[0].name
                                            : null}
                                          </h4>
                                          <div className="ft-util d-flex">
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img
                                                width={24}
                                                className="me-2"
                                                src={duration}
                                                alt=""
                                              />
                                              {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "} Minutes
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img width={24} className="me-2" src={awicon} alt="" />
                                              {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "} Coins
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {activity && activity.status && parseInt(activity.status) === 1 ? <span className="badge bg-primary font-size-14 mt-auto mb-2">Manual Marking</span> : null}
                                            </div>

                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {activity && activity.status && parseInt(activity.status) === 1 && parseInt(manual_mark_review)<= 0 ? <span className="badge bg-primary font-size-14 mt-auto mb-2">In Review</span> : null}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {activity && activity.status && parseInt(activity.status) === 1 ?

                                        <div className="hw-status text-end">
                                          <div className="direct_link">
                                          <div className="d-flex align-items-center text-success fw-bold text-uppercase" onClick={()=> WorksheetActivity(activity)}>
                                            <span className="mcr-success"></span>Completed
                                          </div>
                                          </div>
                                          <p className="mb-0 text-uppercase font-size-12">{formatTime(total_time)} Mins</p>
                                        </div>

                                        :
                                        ((sessionLessonData && sessionLessonData.session && sessionLessonData.session.ses_is_completed 
                                          && parseInt(sessionLessonData.session.ses_is_completed) === 1 && sessionLessonData?.session?.ses_is_flexi != 1) 
                                        ||
                                        (sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) === 1 
                                        && sessionLessonData?.session?.ses_is_flexi == 1 && 
                                        (moment(sessionLessonData.session.ses_date).tz(localStorage.getItem("cent_timezone_identifier") 
                                            || "Europe/London").add((parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0), 'days') 
                                          <  
                                          moment().tz(localStorage.getItem("cent_timezone_identifier") || "Europe/London")
                                        )
                                        ))?
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Incompleted
                                          </div>
                                          : 
                                          <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                            Missed
                                          </div>}
                                        </div>
                                        </div>
                                        :
                                        sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) !== 1 ?
                                        <div className="direct_link">
                                          <div className="hw-status text-end">
                                          
                                            <div className="d-flex align-items-center text-dark fw-bold text-uppercase">
                                              SESSION HAS NOT BEEN STARTED
                                            </div>
                                          
                                          </div>
                                        </div>
  
                                        :
                                        <div className="direct_link">
                                        <div className="hw-status text-end">
                                          {parseInt(percentage) !== 0 ?
                                            
                                            <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> WorksheetActivity(activity)}>
                                              Resume
                                            </Button>
                                            :
                                            <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> WorksheetActivity(activity)}>
                                              Start
                                            </Button>
                                             }
                                        </div>
                                        </div>
                                        }

                                      <div className="ph_list-progressbar">
                                        <Progress color="success" value={percentage !== 0 ? percentage : 0} />
                                      </div>
                                    </li>
                                    )
                                  } else if (
                                    activity.activity_type === 6 &&
                                    (activity.fk_sub_id === 1 ||
                                      activity.fk_sub_id === 2)
                                  ) {
                                    let total_coins = 0;
                                    let total_time = 0;
                                        
                                        
                                        let total_question = 0;
                                        let count_answer = 0;
                                        let percentage = 0;
                                        //let manual_mark = 0;

                                        if (
                                          activity.LessonActivitiesQuestions &&
                                          activity.LessonActivitiesQuestions.length > 0
                                        ) {
                                          //total_question += activity.LessonActivitiesQuestions.length;
                                        
                                          activity.LessonActivitiesQuestions.forEach((activityQuestion, index2) => {

                                            
                                            if (activityQuestion.question && activityQuestion.question.length > 0) {
                                              let filteredManualMark =[];
                                              
                                             // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                                // if(filteredManualMark.length > 0){
                                                //   manual_mark += 1;
                                                // } 
                                              activityQuestion.question.forEach((question, index3) => {
                                                
                                                total_question += 1;
                                                total_coins +=2;
                                                if (question.StudentAnsAttempts && question.StudentAnsAttempts.length > 0) {
                                                  question.StudentAnsAttempts.forEach((stuAnswer) => {
                                                    total_time += parseInt(stuAnswer.submitted_on);
                                                    
                                                  });
                                                  count_answer += 1; // Increment count_answer by 1 for each occurrence
                                                }
                                              });
                                            }
                                          });
                                        }
                                        if(total_question >= count_answer && total_question != 0){
                                          percentage = (count_answer/total_question) * 100;
                                        }
                                    // console.log(activity.activity_node[0].);
                                    return (


                                      <li className="d-flex justify-content-between" key={index1}>
                                      <div className="d-flex align-items-start">
                                      {activity && activity.status && parseInt(activity.status) === 1 ?
                                          <div className="hw-icon bg-success text-white font-size-24 me-3">
                                            <i className="fas fa-check-circle"></i>
                                          </div>
                                          : 
                                          <div className="hw-icon">
                                            <img width={24} className="" src={hw2} alt="" />
                                          </div>
                                          }
                                        
                                        <div>
                                          <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                            VIDEO
                                          </p>
                                          <h4 className=" fw-bold font-size-24">
                                          {activity.activity_node && activity.activity_node[0] &&
                                          activity.activity_node[0].video_name !=
                                            ""
                                            ? activity.activity_node[0].video_name
                                            : null}
                                          </h4>
                                          <div className="ft-util d-flex">
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img
                                                width={24}
                                                className="me-2"
                                                src={duration}
                                                alt=""
                                              />
                                              {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "} Minutes
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img width={24} className="me-2" src={awicon} alt="" />
                                              {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "} Coins
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {activity && activity.status && parseInt(activity.status) === 1 ?

                                      <div className="hw-status text-end">
                                        <div className="direct_link">
                                        <div className="d-flex align-items-center text-success fw-bold text-uppercase" onClick={()=> VideoActivity(activity)}>
                                          <span className="mcr-success"></span>Completed
                                        </div>
                                        </div>
                                        <p className="mb-0 text-uppercase font-size-12">{formatTime(total_time)} Mins</p>
                                      </div>

                                      :

                                      ((sessionLessonData && sessionLessonData.session && sessionLessonData.session.ses_is_completed 
                                        && parseInt(sessionLessonData.session.ses_is_completed) === 1 && sessionLessonData?.session?.ses_is_flexi != 1) 
                                      ||
                                      (sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) === 1 
                                      && sessionLessonData?.session?.ses_is_flexi == 1 && 
                                      (moment(sessionLessonData.session.ses_date).tz(localStorage.getItem("cent_timezone_identifier") 
                                          || "Europe/London").add((parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0), 'days') 
                                        <  
                                        moment().tz(localStorage.getItem("cent_timezone_identifier") || "Europe/London")
                                      )
                                      ))?
                                      <div className="direct_link">
                                      <div className="hw-status text-end">
                                        {parseInt(percentage) !== 0 ?
                                        <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                          Incompleted
                                        </div>
                                        : 
                                        <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                          Missed
                                        </div>}
                                      </div>
                                      </div>
                                      :
                                      sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) !== 1 ?
                                      <div className="direct_link">
                                        <div className="hw-status text-end">
                                        
                                          <div className="d-flex align-items-center text-dark fw-bold text-uppercase">
                                            SESSION HAS NOT BEEN STARTED
                                          </div>
                                        
                                        </div>
                                      </div>

                                      :
                                      <div className="direct_link">
                                      <div className="hw-status text-end">
                                        {parseInt(percentage) !== 0 ?
                                          
                                          <Button className="btn btn-danger cs-btn-danger text-uppercase"  onClick={()=> VideoActivity(activity)}>
                                            Resume
                                          </Button>
                                          :
                                          <Button className="btn btn-danger cs-btn-danger text-uppercase"  onClick={()=> VideoActivity(activity)}>
                                            Start
                                          </Button>
                                           }
                                      </div>
                                      </div>
                                      }
                                      <div className="ph_list-progressbar">
                                        <Progress color="success" value={percentage !== 0 ? percentage : 0} />
                                      </div>
                                    </li>

                                    )
                                  } else if (
                                    activity.activity_type === 7 &&
                                    (activity.fk_sub_id === 1 ||
                                      activity.fk_sub_id === 2)
                                  ) {
                                    let total_coins = 0;
                                    let total_time = 0;
                                        
                                        
                                        let total_question = 0;
                                        let count_answer = 0;
                                        let percentage = 0;
                                        //let manual_mark = 0;
                                        let manual_mark_review = 0;
                                        if (
                                          activity.LessonActivitiesQuestions &&
                                          activity.LessonActivitiesQuestions.length > 0
                                        ) {
                                          //total_question += activity.LessonActivitiesQuestions.length;
                                        
                                          activity.LessonActivitiesQuestions.forEach((activityQuestion, index2) => {
                                            total_question += 1;
                                            manual_mark_review += parseInt(activityQuestion.marked_for_manual_marking);
                                            if (activityQuestion.StudentAnsAttempts && activityQuestion.StudentAnsAttempts.length > 0) {
                                              activityQuestion.StudentAnsAttempts.forEach((stuAnswer) => {
                                                total_time += parseInt(stuAnswer.submitted_on);
                                                
                                              });
                                              count_answer += 1; // Increment count_answer by 1 for each occurrence
                                            }
                                            
                                            if (activityQuestion.question && activityQuestion.question.length > 0) {
                                              let filteredManualMark =[];
                                              
                                             // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                                // if(filteredManualMark.length > 0){
                                                //   manual_mark += 1;
                                                // } 
                                              activityQuestion.question.forEach((question, index3) => {
                                                
                                                total_coins +=2;
                                                
                                              });
                                            }
                                          });
                                        }
                                        if(total_question >= count_answer && total_question != 0){
                                          percentage = (count_answer/total_question) * 100;
                                        }
                                    // console.log(activity.activity_node[0].);
                                    return (

                                      <li className="d-flex justify-content-between" key={index1}>
                                      <div className="d-flex align-items-start">
                                      {activity && activity.status && parseInt(activity.status) === 1 ?
                                          <div className="hw-icon bg-success text-white font-size-24 me-3">
                                            <i className="fas fa-check-circle"></i>
                                          </div>
                                          : 
                                          <div className="hw-icon">
                                            <img width={24} className="" src={hw4} alt="" />
                                          </div>
                                          }
                                        
                                        <div>
                                          <p className="text-warning mb-2 fw-bold font-size-12 text-uppercase">
                                            WORKSHEET
                                          </p>
                                          <h4 className=" fw-bold font-size-24">
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0].node_name
                                            : null}
                                          </h4>
                                          <div className="ft-util d-flex">
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img
                                                width={24}
                                                className="me-2"
                                                src={duration}
                                                alt=""
                                              />
                                              {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "} Minutes
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {" "}
                                              <img width={24} className="me-2" src={awicon} alt="" />
                                              {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "} Coins
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                              {activity && activity.status && parseInt(activity.status) === 1 ? <span className="badge bg-primary font-size-14 mt-auto mb-2">Manual Marking</span> : null}
                                            </div>
                                            <div className="d-flex align-items-center font-size-18 fw-medium me-3">
                                            {activity && activity.status && parseInt(activity.status) === 1 && parseInt(manual_mark_review)<= 0 ? <span className="badge bg-primary font-size-14 mt-auto mb-2">In Review</span> : null}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {activity && activity.status && parseInt(activity.status) === 1 ?

                                      <div className="hw-status text-end">
                                        <div className="direct_link">
                                        <div className="d-flex align-items-center text-success fw-bold text-uppercase" onClick={()=> WorksheetActivity(activity)}>
                                          <span className="mcr-success"></span>Completed
                                        </div>
                                        </div>
                                        <p className="mb-0 text-uppercase font-size-12">{formatTime(total_time)} Mins</p>
                                      </div>

                                      :

                                      ((sessionLessonData && sessionLessonData.session && sessionLessonData.session.ses_is_completed 
                                        && parseInt(sessionLessonData.session.ses_is_completed) === 1 && sessionLessonData?.session?.ses_is_flexi != 1) 
                                      ||
                                      (sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) === 1 
                                      && sessionLessonData?.session?.ses_is_flexi == 1 && 
                                      (moment(sessionLessonData.session.ses_date).tz(localStorage.getItem("cent_timezone_identifier") 
                                          || "Europe/London").add((parseInt(sessionLessonData?.session?.tut_flexi_days) ||  0), 'days') 
                                        <  
                                        moment().tz(localStorage.getItem("cent_timezone_identifier") || "Europe/London")
                                      )
                                      ))?
                                      <div className="direct_link">
                                      <div className="hw-status text-end">
                                        {parseInt(percentage) !== 0 ?
                                        <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                          Incompleted
                                        </div>
                                        : 
                                        <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                          Missed
                                        </div>}
                                      </div>
                                      </div>
                                      :
                                      sessionLessonData && sessionLessonData.session && parseInt(sessionLessonData.session.ses_status) !== 1 ?
                                      <div className="direct_link">
                                        <div className="hw-status text-end">
                                        
                                          <div className="d-flex align-items-center text-dark fw-bold text-uppercase">
                                            SESSION HAS NOT BEEN STARTED
                                          </div>
                                        
                                        </div>
                                      </div>

                                      :
                                      <div className="direct_link">
                                      <div className="hw-status text-end">
                                        {parseInt(percentage) !== 0 ?
                                         
                                          <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> WorksheetActivity(activity)}>
                                            Resume
                                          </Button>
                                          :
                                          <Button className="btn btn-danger cs-btn-danger text-uppercase" onClick={()=> WorksheetActivity(activity)}>
                                            Start
                                          </Button>
                                          }
                                      </div>
                                      </div>
                                      }
                                      <div className="ph_list-progressbar">
                                        <Progress color="success" value={percentage !== 0 ? percentage : 0} />
                                      </div>
                                    </li>
                                     
                                    )
                                   } else {
                                    return null
                                  }
                                }
                              }
                            )
                          } else {
                            return <p>No Activities</p>
                          }
                        }
                      )}
                    {/* activity End */}

                 
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

LessonPlan.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(LessonPlan)
