import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Container, Button, Collapse, Table } from "reactstrap"

import "react-multi-carousel/lib/styles.css"

import { Link, useNavigate, useLocation, useParams } from "react-router-dom"
import classnames from "classnames"

import hdog from "../../assets/images/services-icon/happy_dog.png"
import awicon from "../../assets/images/services-icon/coin.png"
import duration from "../../assets/images/services-icon/duration.png"

// import images
import { SECURITY_KEY, BASE_URL } from "../../global"
import us1 from "../../assets/images/services-icon/us1.png"

//i18n
import { withTranslation } from "react-i18next"

import jwtDecode from "jwt-decode"
import axios from "axios"
// import { BASE_URL } from "../../global";

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import moment from "moment"

import Loader from "react-spinner-loader"

const DiagnosticSummary = props => {
  const { key } = useParams()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const type = searchParams.get("type") || null
  const homework = searchParams.get("homework") || null

  let pk_activity_key = null
  let IsType = null
  let IsHomework = null
  if (
    key &&
    key.length >= 16 &&
    type &&
    type != null &&
    homework &&
    homework != null
  ) {
    pk_activity_key = key
    IsType = type
    IsHomework = homework
  } else {
    window.history.back()
  }

  const [loader, setLoader] = useState(false)
  const [ActivityData, setActivityData] = useState([])

  const authUser = JSON.parse(localStorage.getItem("authStudentUser")) || null

  const navigate = useNavigate()
  useEffect(() => {
    fetchAssementSummaryData()
  }, [location])

  const fetchAssementSummaryData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (pk_activity_key && pk_activity_key != null && pk_activity_key != "") {
        setLoader(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "studentSession/StudentActivitySummary", {
            pk_activity_key: pk_activity_key,
            subject_id: IsType,
          })
          .then(responseSummary => {
            // console.log(responseSummary);
            setLoader(false)
            if (responseSummary.data != "") {
              setActivityData(responseSummary.data)
            } else {
              setActivityData(null)
            }
          })
          .catch(error => {
            setLoader(false)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setLoader(false)
        window.history.back()
      }
    } else {
      setLoader(false)
      navigate("/login")
    }
  }

  const CountTotalCoins = question => {
    let total_coins = 0

    if (question && question.length > 0) {
      question.forEach(activity => {
        if (activity.fk_sub_id === "1") {
          total_coins += activity.english_question.length * 2
        } else if (activity.fk_sub_id === "2" || activity.fk_sub_id === "3") {
          total_coins += activity.question.length * 2
        }
      })
    }
    // setTotalCoins(total_coins);
    return total_coins
  }

  const [col1, setCol1] = useState(true)
  const [col2, setCol2] = useState(false)
  const [col3, setCol3] = useState(false)

  function t_col1() {
    setCol1(!col1)
    //setCol1(true)
    setCol2(false)
    setCol3(false)
  }
  function t_col2() {
    setCol2(!col2)
    setCol1(false)
    //setCol2(true)
    setCol3(false)
  }
  function t_col3() {
    setCol1(false)
    setCol2(false)
    //setCol3(true)
    setCol3(!col3)
  }

  const backLessonPlane = () => {
    if (parseInt(IsHomework) === 1) {
      let filterState = location?.state || "";
      navigate("/homework-list", {
        state: {
          filterState   
        }
      } );
    } else {
      if (
        ActivityData &&
        ActivityData.fk_sest_key &&
        ActivityData.fk_sest_key != ""
      ) {
        navigate("/lesson-plan/" + ActivityData.fk_sest_key)
      } else {
        navigate("/classes-list")
      }
    }
  }

  const questionDetails = data => {
    if (pk_activity_key && IsType && IsHomework) {
      if (data && data != "") {
        navigate(
          "/diagnostic-details/" +
            pk_activity_key +
            "?type=" +
            IsType +
            "&homework=" +
            IsHomework +
            "&question_no=" +
            data
        )
      } else {
        toast.error("Sorry, Question data Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  const formatTime = totalSeconds => {
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60
    const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
    return formattedTime
  }

  const handleHomeworkClick = () => {
    const filterState = location?.state || ""
    navigate("/homework-list", {
      state: {
        filterState,
      },
    })
  }
  return (
    <React.Fragment>
      <Loader
        show={loader}
        type="body"
        // stack="vertical"
        // message="Loading Data"
      />
      <div className="page-wrapper list-bg">
        <div className="cs-pg-header bg-dark-blue py-4">
          <Container className="custom-wt">
            <div className="d-flex align-items-center gap-4">
              <div className="mt-0 border-right me-4" style={{ width: "20%" }}>
                {parseInt(IsHomework) === 1 ? (
                  // <Link className="text-white font-size-14 text-uppercase"  to={"/homework-list"}> {" "}
                  //   <span>
                  //     <i className=" fas fa-chevron-left me-1"></i>
                  //   </span>{" "}
                  //   BACK TO HOMEWORK
                  // </Link>
                  <div
                    className="text-white font-size-14 text-uppercase cursor-pointer"
                    onClick={handleHomeworkClick}
                  >
                    {" "}
                    <span>
                      <i className=" fas fa-chevron-left me-1"></i>
                    </span>{" "}
                    BACK TO HOMEWORK
                  </div>
                ) : ActivityData &&
                  ActivityData.fk_sest_key &&
                  ActivityData.fk_sest_key != "" ? (
                  <div
                    className="text-white font-size-14 text-uppercase cursor-pointer"
                    onClick={() => backLessonPlane()}
                  >
                    {" "}
                    <span>
                      <i className="fas fa-chevron-left me-1"></i>
                    </span>{" "}
                    Back to lesson plan
                  </div>
                ) : (
                  <Link
                    className="text-white font-size-14 text-uppercase"
                    to={"/homework-list"}
                  >
                    {" "}
                    <span>
                      <i className=" fas fa-chevron-left me-1"></i>
                    </span>{" "}
                    BACK
                  </Link>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-between flex-grow-1">
                <h6 className="mb-0 font-size-20 text-white w-60 fw-regular">
                  {ActivityData &&
                  ActivityData.topics &&
                  ActivityData.topics.length > 0
                    ? ActivityData.topics
                        .flat()
                        .map(obj => obj.topic_name)
                        .join(", ")
                    : null}
                </h6>
                <p className="mb-0 text-uppercase luminous-wht">
                  {" "}
                  <span className="text-yellow me-1">
                    {ActivityData && ActivityData.subject_name
                      ? ActivityData.subject_name
                      : null}
                  </span>{" "}
                  Diagnostic{" "}
                </p>
                <div className="luminous-wht font-size-16">
                  <img width={24} className="" src={awicon} alt="" />{" "}
                  {ActivityData.coins_earned ? ActivityData.coins_earned : 0}/
                  {ActivityData.LessonActivitiesQuestions &&
                  ActivityData.LessonActivitiesQuestions.length > 0
                    ? CountTotalCoins(ActivityData.LessonActivitiesQuestions)
                    : 0}
                  coins earned
                </div>
              </div>
            </div>
          </Container>
        </div>
        <Container className="custom-wt">
          <div className="page-inner_section cs-mt">
            <div className="pd-homework-section summary-width-hold pb-100">
              <div className="summary-wrapper">
                <div className="summary-header d-flex justify-content-between align-items-center position-relative">
                  <div className="h-dg-img-wrapper">
                    <img className="" src={hdog} alt="" />
                  </div>
                  <div className="summary-cta d-flex justify-content-between align-items-center">
                    <div className="me-5">
                      <h4 className="text-danger fw-super-bold font-size-24">
                        Awesome
                      </h4>
                      <h2 style={{ fontSize: "36px" }} className="fw-strong">
                        Activity{" "}
                        {ActivityData &&
                        ActivityData.status &&
                        ActivityData.status === 1
                          ? "Completed!"
                          : "Incompleted!"}
                      </h2>
                    </div>
                    <div
                      onClick={() => backLessonPlane()}
                      className="btn btn-danger cs-btn-danger cursor-pointer text-uppercase"
                    >
                      {parseInt(IsHomework) === 1
                        ? "Back to Homework"
                        : ActivityData &&
                          ActivityData.fk_sest_key &&
                          ActivityData.fk_sest_key != ""
                        ? "Back to lesson plan"
                        : "Back"}{" "}
                      <span>
                        <i className="fas fa-chevron-right"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hw-list mt-4">
                <ul className="list-unstyled mb-0">
                  <li className="justify-content-between">
                    <p className="mb-0 text-uppercase luminous-wht">
                      {" "}
                      <span className="text-warning me-1">
                        {ActivityData && ActivityData.subject_name
                          ? ActivityData.subject_name
                          : null}
                      </span>{" "}
                      Diagnostic{" "}
                    </p>
                    <div className="d-flex align-items-start border-bottom pb-4">
                      <div className="summary-child-left me-5">
                        <h3 className="bigger-ft mb-0">
                          {ActivityData &&
                          ActivityData.topics &&
                          ActivityData.topics.length > 0
                            ? ActivityData.topics
                                .flat()
                                .map(obj => obj.topic_name)
                                .join(", ")
                            : null}
                        </h3>
                        <div className="font-size-20 fw-bold text-dark d-flex align-items-center mt-0">
                          Report Card <span className="circle ms-2"></span>{" "}
                          <span className="fw-light ms-2">
                            Completed{" "}
                            {ActivityData && ActivityData.updated_on
                              ? "on " +
                                moment(
                                  ActivityData.updated_on,
                                  moment.ISO_8601
                                ).format("DD-MM-YYYY")
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1 d-flex align-items-center gap-2 justify-content-end">
                        <div>
                          <p className="mb-2 fw-bold font-size-14 text-uppercase">
                            Coins <br></br>Earned
                          </p>
                          <div className="">
                            <h2 className="font-size-20">
                              <span className="bigger-ft">
                                {ActivityData.coins_earned
                                  ? ActivityData.coins_earned
                                  : 0}
                              </span>
                              /
                              {ActivityData.LessonActivitiesQuestions &&
                              ActivityData.LessonActivitiesQuestions.length > 0
                                ? CountTotalCoins(
                                    ActivityData.LessonActivitiesQuestions
                                  )
                                : 0}
                            </h2>
                          </div>
                        </div>
                        <img width={48} className="" src={awicon} alt="" />
                      </div>
                    </div>
                    <h4 className="mt-3 font-size-20 mb-3">Mastery Score</h4>
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="font-size-18 mb-0">
                        {ActivityData.topic_name
                          ? ActivityData.topic_name
                          : null}
                      </p>
                      <h6 className="font-size-18 fw-bold mb-0">
                        {ActivityData.masteryscore
                          ? ActivityData.masteryscore
                          : null}
                      </h6>
                    </div>

                    <div className="accordion summary-accordian" id="accordion">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className={classnames(
                              {
                                collapsed: col1 === false,
                              },
                              "accordion-button fw-semibold"
                            )}
                            type="button"
                            onClick={t_col1}
                            style={{ cursor: "pointer" }}
                          >
                            Question Summary
                          </button>
                        </h2>
                        <Collapse isOpen={col1} className="accordion-collapse">
                          <div className="accordion-body p-0">
                            <div className="table-responsive mt-4">
                              <Table className="table custom-table">
                                <thead>
                                  <tr>
                                    <th width="8%" className="text-center">
                                      Qns
                                    </th>
                                    <th width="60%">Topic</th>
                                    <th width="20%" className="text-center">
                                      Time Taken
                                    </th>
                                    <th width="6%"></th>
                                    <th width="6%"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ActivityData &&
                                  ActivityData.LessonActivitiesQuestions &&
                                  ActivityData.LessonActivitiesQuestions
                                    .length > 0 &&
                                  parseInt(ActivityData.fk_sub_id) === 1 ? (
                                    ActivityData.LessonActivitiesQuestions.map(
                                      (activity, index) =>
                                        activity.english_question &&
                                        activity.english_question.length > 0 &&
                                        activity.english_question.map(
                                          (question, index1) => {
                                            if (
                                              parseInt(
                                                question.diagnostic_category_id
                                              ) === 1
                                            ) {
                                              let question_status = 1
                                              let total_mark = 0
                                              let total_time = 0
                                              let manual_mark = 0
                                              if (
                                                question.StudentAnsAttempts &&
                                                question.StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                let filteredManualMark = []
                                                // let filteredManualMark = question.english_question_parts.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)));
                                                // if(filteredManualMark.length > 0){
                                                //   manual_mark += 1;
                                                // }
                                                // question.english_question_parts.forEach((activityQuestionPart) => {

                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                      if (
                                                        stuAnswer.points_obtained
                                                      ) {
                                                        total_mark += parseInt(
                                                          stuAnswer.points_obtained
                                                        )
                                                      }
                                                    }
                                                  )

                                                  if (
                                                    question.StudentAnsAttempts
                                                      .length > 0 &&
                                                    total_mark === 0
                                                  ) {
                                                    question_status = 3
                                                  } else if (
                                                    question.StudentAnsAttempts
                                                      .length > 0 &&
                                                    total_mark >= 1
                                                  ) {
                                                    question_status = 4
                                                  }
                                                  // else if (activityQuestionPart.StudentAnsAttempts.length === 2 && total_mark > 0) {
                                                  //   question_status = 5;
                                                  // }
                                                }
                                                // });
                                              }

                                              return (
                                                <tr key={index}>
                                                  <td className="text-center font-size-18 fw-strong">
                                                    {index + 1}
                                                  </td>
                                                  <td>
                                                    {parseInt(
                                                      question.diagnostic_category_id
                                                    ) === 1 ? (
                                                      <div
                                                        className=""
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            question.diagnostic_text,
                                                        }}
                                                      ></div>
                                                    ) : (
                                                      <div
                                                        className=""
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            question.diagnostic_text,
                                                        }}
                                                      ></div>
                                                    )}
                                                    {/* {manual_mark > 0 ? <div className="d-flex justify-content-end">
                            <span className="bg-success text-black mt-auto mb-2">Manual Marking</span>
                          </div> : null} */}
                                                  </td>
                                                  <td className="text-center font-size-18">
                                                    {formatTime(total_time)}
                                                  </td>
                                                  <td
                                                    className={`text-center p-0 ${
                                                      question_status === 4
                                                        ? "it-success"
                                                        : question_status === 3
                                                        ? "it-danger"
                                                        : question_status === 5
                                                        ? " it-success sm-ctait-success"
                                                        : null
                                                    }`}
                                                  >
                                                    {manual_mark > 0 ? (
                                                      <span className="text-center text-warning">
                                                        M
                                                      </span>
                                                    ) : question_status ===
                                                      4 ? (
                                                      <i className="fas fa-check"></i>
                                                    ) : question_status ===
                                                      3 ? (
                                                      <i className="mdi mdi-close-thick"></i>
                                                    ) : question_status ===
                                                      5 ? (
                                                      <i className="fas fa-check text-warning"></i>
                                                    ) : null}
                                                  </td>
                                                  <td className="sm-cta text-center p-0">
                                                    <div
                                                      className="p-3 border-0"
                                                      onClick={() =>
                                                        questionDetails(
                                                          index + 1
                                                        )
                                                      }
                                                    >
                                                      <i className="mdi mdi-arrow-top-right-thick"></i>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )
                                            }
                                            return null // If category id is not 1, return null
                                          }
                                        )
                                    )
                                  ) : ActivityData &&
                                    ActivityData.LessonActivitiesQuestions &&
                                    ActivityData.LessonActivitiesQuestions
                                      .length > 0 &&
                                    parseInt(ActivityData.fk_sub_id) === 2 ? (
                                    ActivityData.LessonActivitiesQuestions.map(
                                      (activity, index) =>
                                        activity.question &&
                                        activity.question.length > 0 &&
                                        activity.question.map(
                                          (question, index1) => {
                                            if (
                                              parseInt(
                                                question.diagnostic_category_id
                                              ) === 0
                                            ) {
                                              let question_status = 1
                                              let total_mark = 0
                                              let total_time = 0
                                              let manual_mark = 0
                                              var question_text = ""
                                              //  if (question.question_parts && question.question_parts.length > 0) {
                                              let filteredManualMark = []
                                              //let filteredManualMark = question.question_parts.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)));
                                              // if(filteredManualMark.length > 0){
                                              //   manual_mark += 1;
                                              // }
                                              //question.question_parts.forEach((activityQuestionPart) => {
                                              question_text +=
                                                question.diagnostic_text

                                              if (
                                                question.StudentAnsAttempts &&
                                                question.StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  stuAnswer => {
                                                    total_time += parseInt(
                                                      stuAnswer.submitted_on
                                                    )
                                                    if (
                                                      stuAnswer.points_obtained
                                                    ) {
                                                      total_mark += parseInt(
                                                        stuAnswer.points_obtained
                                                      )
                                                    }
                                                  }
                                                )

                                                if (
                                                  question.StudentAnsAttempts
                                                    .length > 0 &&
                                                  total_mark === 0
                                                ) {
                                                  question_status = 3
                                                } else if (
                                                  question.StudentAnsAttempts
                                                    .length > 0 &&
                                                  total_mark >= 1
                                                ) {
                                                  question_status = 4
                                                  // } else if (activityQuestionPart.StudentAnsAttempts.length === 2 && total_mark > 0) {
                                                  //   question_status = 5;
                                                }
                                              }
                                              //   });
                                              // }

                                              return (
                                                <tr key={index}>
                                                  <td className="text-center font-size-18 fw-strong">
                                                    {index + 1}
                                                  </td>
                                                  <td>
                                                    {question_text != "" ? (
                                                      <div
                                                        className=""
                                                        dangerouslySetInnerHTML={{
                                                          __html: question_text,
                                                        }}
                                                      ></div>
                                                    ) : null}
                                                    {/* {manual_mark > 0 ? <div className="d-flex justify-content-end">
                          <span className="bg-success text-black mt-auto mb-2">Manual Marking</span>
                        </div> : null} */}
                                                  </td>
                                                  <td className="text-center font-size-18">
                                                    {formatTime(total_time)}
                                                  </td>
                                                  <td
                                                    className={`text-center p-0 ${
                                                      question_status === 4
                                                        ? "it-success"
                                                        : question_status === 3
                                                        ? "it-danger"
                                                        : question_status === 5
                                                        ? " it-success sm-ctait-success"
                                                        : null
                                                    }`}
                                                  >
                                                    {manual_mark > 0 ? (
                                                      <span className="text-center text-warning">
                                                        M
                                                      </span>
                                                    ) : question_status ===
                                                      4 ? (
                                                      <i className="fas fa-check"></i>
                                                    ) : question_status ===
                                                      3 ? (
                                                      <i className="mdi mdi-close-thick"></i>
                                                    ) : question_status ===
                                                      5 ? (
                                                      <i className="fas fa-check text-warning"></i>
                                                    ) : null}
                                                  </td>
                                                  <td className="sm-cta text-center p-0">
                                                    <div
                                                      className="p-3 border-0"
                                                      onClick={() =>
                                                        questionDetails(
                                                          index + 1
                                                        )
                                                      }
                                                    >
                                                      <i className="mdi mdi-arrow-top-right-thick"></i>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )
                                            }
                                            return null // If category id is not 1, return null
                                          }
                                        )
                                    )
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="5"
                                        className="text-center font-size-18 fw-medium"
                                      >
                                        No data yet
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

DiagnosticSummary.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(DiagnosticSummary)
