import React, { useState, useEffect, useCallback, useRef } from "react"
import {
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap"
import { SECURITY_KEY, BASE_URL } from "../../global"
import "react-multi-carousel/lib/styles.css"

import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"

import awicon from "../../assets/images/services-icon/coin.png"
import Select from "react-select"

import dsmile from "../../assets/images/services-icon/dog_smile.png"

//i18n
import axios from "axios"
// import { BASE_URL } from "../../global";

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import moment from "moment"

import Loader from "react-spinner-loader"
// import Comprehension from "./diagnostics/Comprehension";
// import Pagination from "./diagnostics/Pagination";
//emoje inactive
import em1 from "../../assets/images/services-icon/em1.svg"
import em2 from "../../assets/images/services-icon/em2.svg"
import em3 from "../../assets/images/services-icon/em3.svg"
import em4 from "../../assets/images/services-icon/em4.svg"
import em5 from "../../assets/images/services-icon/em5.svg"

//emoje active
import ema1 from "../../assets/images/services-icon/ema1.svg"
import ema2 from "../../assets/images/services-icon/ema2.svg"
import ema3 from "../../assets/images/services-icon/ema3.svg"
import ema4 from "../../assets/images/services-icon/ema4.svg"
import ema5 from "../../assets/images/services-icon/ema5.svg"
// // Timer component that manages timer state and logic
// const TimerComponent = () => {
//   const [timer, setTimer] = useState(0);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setTimer(prevTimer => prevTimer + 1);
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   return timer; // Return timer value instead of JSX
// };

// // Receiving component that displays the timer value
// const TimerDisplay = React.memo(({ timerValue }) => {
//   return <div>Timer Value: {timerValue}</div>;
// });
import { MathJax, MathJaxContext } from "better-react-mathjax"
const PreviewAssessmentDetails = React.memo(() => {
  document.title = "MyGooRoo Student App"
  const { key } = useParams()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const type = searchParams.get("type") || null
  const homework = searchParams.get("homework") || null

  const question_no = 1

  const st_key = searchParams.get("st_key") || null

  let pk_activity_key = null
  let IsType = null
  let IsHomework = null
  let IsQuestionNo = 1
  let IsStKey = null
  if (
    key &&
    key.length >= 16 &&
    type &&
    type != null &&
    homework &&
    homework != null &&
    st_key &&
    st_key != ""
  ) {
    pk_activity_key = key
    IsType = type
    IsHomework = homework
    IsQuestionNo = question_no
    IsStKey = st_key
  } else {
    window.history.back()
  }

  //common

  const [loader, setLoader] = useState(false)
  const [ActivityData, setActivityData] = useState([])
  const [TopicData, setTopicData] = useState(null)

  // console.log(otherData);
  // const authUser = JSON.parse(localStorage.getItem('authUser')) || null;

  const navigate = useNavigate()
  useEffect(() => {
    fetchData()
  }, [location])

  const fetchData = async () => {
    // if (authUser && authUser != "" && authUser != null) {
    if (pk_activity_key && pk_activity_key != null && pk_activity_key != "") {
      setLoader(true)
      setActivityData([])
      //axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "studentSession/StudentPreviewAssessmentQuestions", {
          pk_activity_key: pk_activity_key,
          subject_id: type,
          pk_student_key: IsStKey,
        })
        .then(responseActivity => {
          // Assuming the API response is an array of objects with 'id' and 'value' properties
          setLoader(false)

          if (responseActivity.data.LessonActivitiesQuestions.length > 0) {
            setActivityData(responseActivity.data)
            const topicNames = responseActivity.data.topics
              .flat()
              .map(obj => obj.topic_name)
            const mergedTopics = topicNames.join(", ")
            //console.log(mergedTopics);
            setTopicData(mergedTopics)
          } else {
            setActivityData([])
            setTopicData(null)
          }
        })
        .catch(error => {
          setLoader(false)
          // if (
          //   error.response.status === 403 &&
          //   error.response.data.error === "jwt expired"
          // ) {
          // localStorage.clear()
          // window.history.back()
          //}
        })
    } else {
      setLoader(false)
      window.history.back()
    }
    // } else {
    //   // setLoader(false);
    //   window.history.back()
    // }
  }

  // const CountTotalCoins = question => {
  //   let total_coins = 0;

  //   if (question && question.length > 0) {
  //       question.forEach(activity => {
  //           if (activity.fk_sub_id === '1') {
  //               total_coins += activity.english_question.length * 2;
  //           } else if (activity.fk_sub_id === '2' || activity.fk_sub_id === '3') {
  //               total_coins += activity.question.length * 2;
  //           }
  //       });
  //   }
  //  // setTotalCoins(total_coins);
  //   return total_coins;
  // };

  const CountTotalCoins = question => {
    let total_coins = 0

    if (question && question.length > 0) {
      question.forEach(activity => {
        if (activity.fk_sub_id === "1") {
          if (
            activity.english_question &&
            (parseInt(
              activity.english_question[0].english_question_category_id
            ) === 10 ||
              parseInt(
                activity.english_question[0].english_question_category_id
              ) === 13)
          ) {
            activity.english_question.map((question, index1) => {
              let total_part = question.english_question_parts
              let array_part_data = total_part.filter(
                part =>
                  part.english_question_answers.length > 0 &&
                  part.english_question_answers[0].english_answer_text &&
                  part.english_question_answers[0].english_answer_text != ""
              )
              total_coins += array_part_data.length * 2 || 0
            })
          } else {
            total_coins += activity.english_question.reduce(
              (acc, question) =>
                acc + question.english_question_parts.length * 2,
              0
            )
          }
          // total_coins += activity.english_question.reduce((acc, question) => acc + question.english_question_parts.length * 2, 0);
        } else if (activity.fk_sub_id === "2" || activity.fk_sub_id === "3") {
          total_coins += activity.question.reduce(
            (acc, question) => acc + question.question_parts.length * 2,
            0
          )
        }
      })
    }
    // setTotalCoins(total_coins);
    return total_coins
  }

  // useEffect(() => {

  // if(ActivityData && ActivityData.activity_node && ActivityData.activity_node.length > 0){
  //   setTopicData(ActivityData.activity_node[0]);
  // }else{
  //   setTopicData(null)
  // }

  // }, [ActivityData, key])

  //const timerValue = TimerComponent(); // Get timer value from TimerComponent

  const [customActiveTab, setcustomActiveTab] = useState(IsQuestionNo)

  function toggleCustom(tab) {
    // console.log(tab);
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [ResonTextData, setResonText] = useState("")
  const [modal_standard, setmodal_standard] = useState(false)

  const [isFlagReport, setFlagReport] = useState(false)

  const [selectedGroup, setselectedGroup] = useState(null)

  const [selectQuestion, setselectQuestion] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    // {
    // label: "Select Subject",
    // options: [
    { label: "Spelling error / Typo", value: "Spelling error / Typo" },
    { label: "The solution is wrong", value: "The solution is wrong" },
    {
      label: "The question is not relevant to the topic",
      value: "The question is not relevant to the topic",
    },
    {
      label: "The question cannot be solved",
      value: "The question cannot be solved",
    },
    {
      label: "The question is incomplete",
      value: "The question is incomplete",
    },
    { label: "Others", value: "Others" },
    // ],
    // },
  ]

  function textareachange(event) {
    const text = event.target.value
    // if (count > 0) {
    setResonText(text)
    // }
    //settextcount(event.target.value.length)
  }
  const tog_standard = data => {
    //console.log(data);
    setmodal_standard(!modal_standard)
    setselectedGroup(null)
    setResonText("")
    setselectQuestion(data ? data : null)
  }

  function submitFlagReport() {
    //   if (authUser && authUser != "" && authUser != null) {
    // setFlagReport(true);
    //   axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
    //   axios.post(BASE_URL+'studentSession/flagReport', {
    //     fk_lesson_activity_key: selectQuestion.fk_lesson_activity_key,
    //     fk_lesson_activity_question_key : selectQuestion.pk_lesson_activity_question_key,
    //     fk_sub_id: selectQuestion.fk_sub_id,
    //     question_id: selectQuestion.fk_question_id,
    //     reson_text: ResonTextData,
    //     reson: selectedGroup
    //   }).then(responseFlag => {
    //     //console.log(responseSessionEnd.data);
    //     // Assuming the API response is an array of objects with 'id' and 'value' properties
    //     if (parseInt(responseFlag.data) === 1) {
    //       tog_standard();
    //       setFlagReport(false);
    //       toast.success("Thank you! Our Curriculum team will review and add 5 coins if the issue reported is correct.", {
    //         autoClose: 3000, // 3 seconds in this example
    //       });
    //     } else {
    //       tog_standard();
    //       setFlagReport(false);
    //       toast.error("Failure! Unable to insert data. Try again later", {
    //         autoClose: 3000, // 3 seconds in this example
    //       });
    //     }
    //   })
    //     .catch(error => {
    //       //console.log(error)
    //       tog_standard();
    //       setFlagReport(false);
    //       if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
    //         localStorage.clear();
    //         navigate("/login");
    //       }
    //     });
    // }
    // else {
    //   setFlagReport(false);
    //   navigate("/login");
    // }
  }

  //console.log(ActivityData);

  function rearrangeEvenOddSecond(array) {
    const even = [] // To store even-indexed elements
    const odd = [] // To store odd-indexed elements

    array.forEach((element, index) => {
      if (index % 2 === 0) {
        even.push(element)
      } else {
        odd.push(element)
      }
    })

    // Combine odd and even arrays
    const rearranged = [...odd, ...even]

    return rearranged // Return the final rearranged array
  }

  function rearrangeEvenOdd(array) {
    const even = [] // To store even-indexed elements
    const odd = [] // To store odd-indexed elements

    array.forEach((element, index) => {
      if (index % 2 === 0) {
        even.push(element)
      } else {
        odd.push(element)
      }
    })

    // Combine odd and even arrays
    const rearranged = [...odd, ...even]

    // Check if further rearrangement is needed
    if (rearranged.length > 1) {
      return rearrangeEvenOddSecond(rearranged) // Call recursively for further rearrangement
    }

    return rearranged // Return the final rearranged array
  }

  // Shuffle the array only once
  // function shuffleArray(array) {
  //   for (let i = array.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  //   }
  //   return array;
  // }
  return (
    <React.Fragment>
      <MathJax inline dynamic>
        <Loader
          show={loader}
          type="body"
          // stack="vertical"
          // message="Loading Data"
        />
        <ToastContainer autoClose={3000} />

        <Modal isOpen={modal_standard} toggle={tog_standard}>
          <ModalHeader toggle={tog_standard}>Flag this Question</ModalHeader>
          <ModalBody>
            <div className="flex-grow-1 mb-3 cs-rsm">
              <Label>Select a Reason</Label>
              <Select
                className="cs-style bg-mutant"
                value={optionGroup.find(
                  option =>
                    option.value &&
                    selectedGroup &&
                    option.value.toString() == selectedGroup.toString()
                )}
                placeholder="Select"
                onChange={e => {
                  handleSelectGroup(e.value)
                }}
                options={optionGroup}
                classNamePrefix="select2-selection"
              />
            </div>
            <div className="flex-grow-1 cs-rsm">
              <Label>Reason:</Label>
              <Input
                style={{ height: "200px" }}
                type="textarea"
                id="textarea"
                value={ResonTextData}
                onChange={e => {
                  textareachange(e)
                }}
                maxLength="225"
                rows="3"
                placeholder="Write your reason here..."
              />
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button
              className="btn btn-danger cs-btn-danger"
              disabled={isFlagReport}
              onClick={() => {
                submitFlagReport()
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>

        <div className="page-wrapper">
          <div className="cs-pg-header bg-dark-blue py-4">
            <Container className="custom-wt">
              <div className="d-flex align-items-center">
                <div
                  className="mt-0 border-right me-4"
                  style={{ width: "20%" }}
                >
                  <div
                    className="text-white font-size-14 text-uppercase"
                    to={"#"}
                    onClick={() => history.back()}
                  >
                    {" "}
                    <span>
                      <i className=" fas fa-chevron-left me-1"></i>
                    </span>{" "}
                    back to tutor app
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-grow-1">
                  <h6 className="mb-0 font-size-20 text-white w-60 fw-regular">
                    {ActivityData &&
                    ActivityData.activity_node &&
                    ActivityData.activity_node[0].node_name
                      ? ActivityData.activity_node[0].node_name
                      : null}
                  </h6>
                  <p className="mb-0 text-uppercase luminous-wht">
                    {" "}
                    <span className="text-yellow me-1">
                      {ActivityData && ActivityData.subject_name
                        ? ActivityData.subject_name
                        : null}
                    </span>{" "}
                    Assessment{" "}
                  </p>
                  <div className="luminous-wht font-size-16">
                    <img width={24} className="" src={awicon} alt="" />{" "}
                    {ActivityData && ActivityData.coins_earned
                      ? ActivityData.coins_earned
                      : 0}
                    /
                    {ActivityData &&
                    ActivityData.LessonActivitiesQuestions &&
                    ActivityData.LessonActivitiesQuestions.length > 0
                      ? CountTotalCoins(ActivityData.LessonActivitiesQuestions)
                      : 0}{" "}
                    coins earned
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <Container className="custom-wt">
            <div className="assessement-section summary-width-hold mt-4">
              <Nav tabs className="nav-tabs-custom mb-3">
                {ActivityData &&
                ActivityData.LessonActivitiesQuestions &&
                ActivityData.LessonActivitiesQuestions.length > 0
                  ? ActivityData.LessonActivitiesQuestions.map(
                      (activity, index) => {
                        if (
                          activity.english_question &&
                          activity.english_question.length > 0 &&
                          parseInt(activity.fk_sub_id) === 1
                        ) {
                          return activity.english_question.map(
                            (question, index1) => {
                              if (
                                parseInt(
                                  question.english_question_category_id
                                ) === 1 ||
                                parseInt(
                                  question.english_question_category_id
                                ) === 8 ||
                                parseInt(
                                  question.english_question_category_id
                                ) === 2 ||
                                parseInt(
                                  question.english_question_category_id
                                ) === 12 ||
                                parseInt(
                                  question.english_question_category_id
                                ) === 13 ||
                                parseInt(
                                  question.english_question_category_id
                                ) === 10
                              ) {
                                let filteredManualMark =
                                  question.english_question_parts.filter(
                                    manualMark =>
                                      parseInt(
                                        manualMark.english_question_part_automark
                                      ) === 0
                                  )
                                let question_status = 1

                                // console.log(active_state)
                                if (
                                  question.english_question_parts &&
                                  question.english_question_parts.length > 0
                                ) {
                                  //let total_coins = question.english_question_parts.length * 2;
                                  //setTotalCoins(prevTotalCoins => prevTotalCoins + total_coins);
                                  question.english_question_parts.forEach(
                                    (activityQuestionPart, index2) => {
                                      let partManualMark = parseInt(
                                        activityQuestionPart.english_question_part_automark
                                      )

                                      let total_mark = 0
                                      if (
                                        activityQuestionPart.StudentAnsAttempts &&
                                        activityQuestionPart.StudentAnsAttempts
                                          .length > 0
                                      ) {
                                        activityQuestionPart.StudentAnsAttempts.forEach(
                                          (stuAnswer, index3) => {
                                            if (
                                              stuAnswer.points_obtained != null
                                            ) {
                                              total_mark += parseInt(
                                                stuAnswer.points_obtained
                                              ) // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )

                                        if (
                                          activityQuestionPart
                                            .StudentAnsAttempts.length >= 2 &&
                                          total_mark === 0
                                        ) {
                                          question_status = 3
                                        } else if (
                                          activityQuestionPart
                                            .StudentAnsAttempts.length >= 1 &&
                                          total_mark >= 2
                                        ) {
                                          question_status = 4
                                        } else if (
                                          activityQuestionPart
                                            .StudentAnsAttempts.length >= 2 &&
                                          total_mark > 0
                                        ) {
                                          question_status = 5
                                        } else if (
                                          activityQuestionPart
                                            .StudentAnsAttempts.length === 1 &&
                                          total_mark >= 0 &&
                                          partManualMark <= 0
                                        ) {
                                          question_status = 2
                                        } else {
                                          question_status = 2
                                        }
                                      } else {
                                        question_status = 2
                                      }
                                      // console.log('status -'+i+' - '+question_status);
                                    }
                                  )
                                }

                                return (
                                  <NavItem key={index}>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleCustom(index + 1)
                                      }}
                                      className={
                                        parseInt(customActiveTab) === index + 1
                                          ? "active"
                                          : "" + question_status &&
                                            question_status === 1
                                          ? " display-hold_active"
                                          : question_status &&
                                            question_status === 2
                                          ? " display-number"
                                          : question_status &&
                                            question_status === 3
                                          ? " display-attempt_fail"
                                          : question_status &&
                                            question_status === 4
                                          ? " display-attempt1"
                                          : question_status &&
                                            question_status === 5
                                          ? " display-attempt2"
                                          : " display-number"
                                      }
                                    >
                                      {question_status &&
                                      question_status === 1 ? (
                                        <span className="font-size-20 fw-bold">
                                          {filteredManualMark.length > 0
                                            ? "M"
                                            : index + 1}
                                        </span>
                                      ) : question_status &&
                                        question_status === 2 ? (
                                        <span className="font-size-20 fw-bold">
                                          {filteredManualMark.length > 0
                                            ? "M"
                                            : index + 1}
                                        </span>
                                      ) : question_status &&
                                        question_status === 3 ? (
                                        filteredManualMark.length > 0 ? (
                                          <span className="font-size-20 fw-bold">
                                            M
                                          </span>
                                        ) : (
                                          <i className="mdi mdi-close-thick text-danger font-size-20"></i>
                                        )
                                      ) : question_status &&
                                        question_status === 4 ? (
                                        filteredManualMark.length > 0 ? (
                                          <span className="font-size-20 fw-bold">
                                            M
                                          </span>
                                        ) : (
                                          <i className="fas fa-check text-success font-size-20"></i>
                                        )
                                      ) : question_status &&
                                        question_status === 5 ? (
                                        filteredManualMark.length > 0 ? (
                                          <span className="font-size-20 fw-bold">
                                            M
                                          </span>
                                        ) : (
                                          <i className="fas fa-check text-warning font-size-20"></i>
                                        )
                                      ) : (
                                        <span className="font-size-20 fw-bold">
                                          {filteredManualMark.length > 0
                                            ? "M"
                                            : index + 1}
                                        </span>
                                      )}
                                    </NavLink>

                                    {/* } */}
                                  </NavItem>
                                )
                              }
                            }
                          )
                        } else if (
                          activity.question &&
                          activity.question.length > 0 &&
                          parseInt(activity.fk_sub_id) === 2
                        ) {
                          //maths
                          return activity.question.map((question, index1) => {
                            // console.log('enter2')
                            if (question.question_id != null) {
                              let filteredManualMark = []
                              //let filteredManualMark = question.question_parts.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                              let question_status = 1

                              if (
                                question.question_parts &&
                                question.question_parts.length > 0
                              ) {
                                //let total_coins = question.english_question_parts.length * 2;
                                //setTotalCoins(prevTotalCoins => prevTotalCoins + total_coins);
                                question.question_parts.forEach(
                                  (activityQuestionPart, index2) => {
                                    //let partManualMark = parseInt(activityQuestionPart.english_question_part_automark);

                                    let total_mark = 0
                                    if (
                                      activityQuestionPart.StudentAnsAttempts &&
                                      activityQuestionPart.StudentAnsAttempts
                                        .length > 0
                                    ) {
                                      activityQuestionPart.StudentAnsAttempts.forEach(
                                        (stuAnswer, index3) => {
                                          if (
                                            stuAnswer.points_obtained != null
                                          ) {
                                            total_mark += parseInt(
                                              stuAnswer.points_obtained
                                            ) // Increment count_answer by 1 for each occurrence
                                          }
                                        }
                                      )

                                      if (
                                        activityQuestionPart.StudentAnsAttempts
                                          .length === 2 &&
                                        total_mark === 0
                                      ) {
                                        question_status = 3
                                      } else if (
                                        activityQuestionPart.StudentAnsAttempts
                                          .length >= 1 &&
                                        total_mark >= 2
                                      ) {
                                        question_status = 4
                                      } else if (
                                        activityQuestionPart.StudentAnsAttempts
                                          .length === 2 &&
                                        total_mark > 0
                                      ) {
                                        question_status = 5

                                        // }else if(activityQuestionPart.StudentAnsAttempts.length ===1 && total_mark >= 0 && partManualMark <= 0){
                                        //   question_status = 2;
                                      } else {
                                        question_status = 2
                                      }
                                    } else {
                                      question_status = 2
                                    }
                                    // console.log('status -'+i+' - '+question_status);
                                  }
                                )
                              }

                              return (
                                <NavItem key={index}>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      toggleCustom(index + 1)
                                    }}
                                    className={
                                      parseInt(customActiveTab) === index + 1
                                        ? "active"
                                        : "" + question_status &&
                                          question_status === 1
                                        ? " display-hold_active"
                                        : question_status &&
                                          question_status === 2
                                        ? " display-number"
                                        : question_status &&
                                          question_status === 3
                                        ? " display-attempt_fail"
                                        : question_status &&
                                          question_status === 4
                                        ? " display-attempt1"
                                        : question_status &&
                                          question_status === 5
                                        ? " display-attempt2"
                                        : " display-number"
                                    }
                                  >
                                    {question_status &&
                                    question_status === 1 ? (
                                      <span className="font-size-20 fw-bold">
                                        {filteredManualMark.length > 0
                                          ? "M"
                                          : index + 1}
                                      </span>
                                    ) : question_status &&
                                      question_status === 2 ? (
                                      <span className="font-size-20 fw-bold">
                                        {filteredManualMark.length > 0
                                          ? "M"
                                          : index + 1}
                                      </span>
                                    ) : question_status &&
                                      question_status === 3 ? (
                                      filteredManualMark.length > 0 ? (
                                        <span className="font-size-20 fw-bold">
                                          M
                                        </span>
                                      ) : (
                                        <i className="mdi mdi-close-thick text-danger font-size-20"></i>
                                      )
                                    ) : question_status &&
                                      question_status === 4 ? (
                                      filteredManualMark.length > 0 ? (
                                        <span className="font-size-20 fw-bold">
                                          M
                                        </span>
                                      ) : (
                                        <i className="fas fa-check text-success font-size-20"></i>
                                      )
                                    ) : question_status &&
                                      question_status === 5 ? (
                                      filteredManualMark.length > 0 ? (
                                        <span className="font-size-20 fw-bold">
                                          M
                                        </span>
                                      ) : (
                                        <i className="fas fa-check text-warning font-size-20"></i>
                                      )
                                    ) : (
                                      <span className="font-size-20 fw-bold">
                                        {filteredManualMark.length > 0
                                          ? "M"
                                          : index + 1}
                                      </span>
                                    )}
                                  </NavLink>

                                  {/* } */}
                                </NavItem>
                              )
                            }
                          })
                        } else {
                          //else
                        }
                      }
                    )
                  : null}
              </Nav>

              <p className="font-size-16 fw-semibold mb-4 mt-2">
                {ActivityData?.tutor_comments && (
                  <>
                    <b>Tutor comments:</b> {ActivityData.tutor_comments}
                  </>
                )}
              </p>
              <TabContent activeTab={customActiveTab} className="text-muted">
                {ActivityData &&
                ActivityData.LessonActivitiesQuestions &&
                ActivityData.LessonActivitiesQuestions.length > 0
                  ? ActivityData.LessonActivitiesQuestions.map(
                      (ActivityData1, activeindex) => {
                        if (
                          ActivityData1.english_question &&
                          ActivityData1.english_question.length > 0 &&
                          parseInt(ActivityData1.fk_sub_id) === 1
                        ) {
                          return ActivityData1.english_question.map(
                            (InnerActivityData, index) => {
                              if (index === 0) {
                                if (
                                  InnerActivityData &&
                                  InnerActivityData.english_question_category_id &&
                                  parseInt(
                                    InnerActivityData.english_question_category_id
                                  ) === 1
                                ) {
                                  return (
                                    <TabPane
                                      tabId={activeindex + 1}
                                      key={activeindex + 1}
                                      className={
                                        parseInt(customActiveTab) ===
                                        activeindex + 1
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <div className="hw-list mt-4 pb-100">
                                        <ul className="list-unstyled mb-0 question_container">
                                          <li className="justify-content-between">
                                            <div className="d-flex align-items-start gap-4 justify-content-end">
                                              {/* <p className="mb-0 text-uppercase font-size-16">
                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .level_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .level_name + " /"
                                                  : null}

                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .strand_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .strand_name + " /"
                                                  : null}

                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .topic_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .topic_name + " /"
                                                  : null}

                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .sub_topic_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .sub_topic_name
                                                  : null}
                                              </p> */}
                                              <div className="mb-0 d-flex align-items-center gap-2">
                                                <p className="mb-0">
                                                  #
                                                  {InnerActivityData.english_question_id
                                                    ? InnerActivityData.english_question_id
                                                    : null}
                                                </p>
                                                <span>
                                                  <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                                                </span>
                                              </div>
                                            </div>

                                            <div
                                              className="mt-4"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  InnerActivityData.english_question_text
                                                    ? InnerActivityData.english_question_text
                                                        .replace(
                                                          /\[(\d+)Answer char=\d+\]/g,
                                                          ""
                                                        )
                                                        .replace(
                                                          /\[Answer char=\d+\]/g,
                                                          ""
                                                        )
                                                    : null,
                                              }}
                                            ></div>

                                            <ul className="dg-selector width-controll list-unstyled mb-0">
                                              {InnerActivityData.english_question_parts &&
                                                InnerActivityData.english_question_parts.map(
                                                  (quspart, index) => {
                                                    //const stuAnswersarray = quspart.StudentAnsAttempts.map(stuAns => stuAns.student_answer);

                                                    const stuAnswersarray =
                                                      quspart.StudentAnsAttempts.map(
                                                        stuAns => {
                                                          try {
                                                            return JSON.parse(
                                                              stuAns.student_answer
                                                            )
                                                          } catch (error) {
                                                            console.error(
                                                              "Error parsing JSON:",
                                                              error
                                                            )
                                                            return null // or handle the error in an appropriate way
                                                          }
                                                        }
                                                      ) || [] // Extracting session keys

                                                    // Assuming stuAnswersarray is a nested array
                                                    const flattenedStuAnswers =
                                                      [].concat(
                                                        ...stuAnswersarray
                                                      )
                                                    let serialNumber = 0
                                                    //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                                                    return (
                                                      quspart.english_question_answers &&
                                                      rearrangeEvenOdd(
                                                        quspart.english_question_answers
                                                      ).map(
                                                        (qusAns, index1) => {
                                                          const cleanText =
                                                            qusAns.english_answer_text
                                                              .replace(
                                                                /&nbsp;/g,
                                                                ""
                                                              )
                                                              .replace(/`/g, "")
                                                              .replace(
                                                                /<[^>]*>/g,
                                                                ""
                                                              )
                                                              .trim()

                                                          const hasImage =
                                                            /<img\s+[^>]*>/gi.test(
                                                              qusAns.english_answer_text
                                                            )

                                                          if (
                                                            !cleanText &&
                                                            !hasImage
                                                          ) {
                                                            return null // Skip rendering if english_answer_text is effectively empty and no image is present
                                                          }
                                                          serialNumber++
                                                          let filteredStuAns =
                                                            flattenedStuAnswers.filter(
                                                              stuAns =>
                                                                parseInt(
                                                                  stuAns.part_id
                                                                ) ===
                                                                  parseInt(
                                                                    qusAns.english_answer_part_id
                                                                  ) &&
                                                                parseInt(
                                                                  stuAns.answer_id
                                                                ) ===
                                                                  parseInt(
                                                                    qusAns.english_answer_id
                                                                  )
                                                            ) || []

                                                          var status_color =
                                                            "selector-general cursor-pointer"
                                                          var border_color =
                                                            "border-start-secondary"
                                                          if (
                                                            filteredStuAns.length >
                                                            0
                                                          ) {
                                                            if (
                                                              parseInt(
                                                                qusAns.english_answer_is_correct
                                                              ) === 1
                                                            ) {
                                                              status_color =
                                                                "selector-success"
                                                              border_color =
                                                                "border-start-success"
                                                            } else {
                                                              status_color =
                                                                "selector-wrong "
                                                              border_color =
                                                                "border-start-danger"
                                                            }
                                                          }
                                                          //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                                          return (
                                                            <li
                                                              className={
                                                                status_color +
                                                                " choose e_choose"
                                                              }
                                                              key={index1}
                                                            >
                                                              <div className="p-2 px-3 font-size-18">
                                                                {serialNumber}
                                                              </div>
                                                              <div
                                                                className={
                                                                  "p-2 dg-media " +
                                                                  {
                                                                    border_color,
                                                                  }
                                                                }
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    qusAns.english_answer_text
                                                                      ? qusAns.english_answer_text
                                                                      : null,
                                                                }}
                                                              ></div>
                                                            </li>
                                                          )
                                                        }
                                                      )
                                                    )
                                                  }
                                                )}
                                            </ul>
                                            <>
                                              <h4 className="font-size-16 fw-bold mb-2 mt-3">
                                                Feedback
                                              </h4>
                                              <div
                                                className="e_feedBack"
                                                style={{
                                                  border: "1px solid #1a1a1a",
                                                  borderRadius: "4px",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {InnerActivityData.english_question_parts &&
                                                  InnerActivityData.english_question_parts.map(
                                                    (quspart, index) => {
                                                      let filteredAns =
                                                        quspart.english_question_answers.filter(
                                                          CorrAns =>
                                                            parseInt(
                                                              CorrAns.english_answer_is_correct
                                                            ) === 1
                                                        )
                                                      return filteredAns.map(
                                                        (qusAns, index1) => {
                                                          return (
                                                            <div
                                                              dangerouslySetInnerHTML={{
                                                                __html:
                                                                  qusAns.english_answer_text
                                                                    ? qusAns.english_answer_text
                                                                    : null,
                                                              }}
                                                            ></div>
                                                          )
                                                        }
                                                      )
                                                    }
                                                  )}
                                              </div>
                                            </>
                                          </li>
                                        </ul>

                                        <div className="action-btns d-flex justify-content-between gap-4 mt-5">
                                          {/* Render a "Prev" button if the active index is greater than 0 */}
                                          {activeindex + 1 > 1 ? (
                                            <Button
                                              className="btn btn-danger cs-btn-danger mt-1"
                                              onClick={() => {
                                                toggleCustom(activeindex)
                                              }}
                                            >
                                              Prev
                                            </Button>
                                          ) : null}

                                          {/* Render a "Next" button if the active index is less than the total length of LessonActivitiesQuestions array */}
                                          {activeindex + 1 <
                                          ActivityData.LessonActivitiesQuestions
                                            .length ? (
                                            <Button
                                              className="btn btn-danger cs-btn-danger mt-1"
                                              onClick={() => {
                                                toggleCustom(
                                                  activeindex + 1 + 1
                                                )
                                              }}
                                            >
                                              Next
                                            </Button>
                                          ) : null}
                                        </div>
                                      </div>
                                    </TabPane>
                                  )
                                } else if (
                                  InnerActivityData &&
                                  InnerActivityData.english_question_category_id &&
                                  (parseInt(
                                    InnerActivityData.english_question_category_id
                                  ) === 8 ||
                                    parseInt(
                                      InnerActivityData.english_question_category_id
                                    ) === 2 ||
                                    parseInt(
                                      InnerActivityData.english_question_category_id
                                    ) === 12)
                                ) {
                                  return (
                                    <TabPane
                                      tabId={activeindex + 1}
                                      key={activeindex + 1}
                                      className={
                                        parseInt(customActiveTab) ===
                                        activeindex + 1
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <div className="hw-list mt-4 pb-100 not_scroll">
                                        <ul className="list-unstyled mb-0 question_container">
                                          <li className="justify-content-between">
                                            <div className="d-flex align-items-start gap-4 justify-content-end">
                                              {/* <p className="mb-0 text-uppercase font-size-16">
                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .level_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .level_name + " /"
                                                  : null}

                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .strand_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .strand_name + " /"
                                                  : null}

                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .topic_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .topic_name + " /"
                                                  : null}

                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .sub_topic_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .sub_topic_name
                                                  : null}
                                              </p> */}
                                              <div className="mb-0 d-flex align-items-center gap-2">
                                                <p className="mb-0">
                                                  #
                                                  {InnerActivityData.english_question_id
                                                    ? InnerActivityData.english_question_id
                                                    : null}
                                                </p>
                                                <span>
                                                  <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              className="mt-3"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  InnerActivityData.english_question_instruction
                                                    ? InnerActivityData.english_question_instruction
                                                        .replace(
                                                          /\[(\d+)Answer char=\d+\]/g,
                                                          ""
                                                        )
                                                        .replace(
                                                          /\[Answer char=\d+\]/g,
                                                          ""
                                                        )
                                                    : null,
                                              }}
                                            ></div>

                                            {/* Choice */}
                                            {InnerActivityData &&
                                            InnerActivityData.english_question_choices &&
                                            InnerActivityData
                                              .english_question_choices.length >
                                              0 ? (
                                              <div className="mt-4 my-2 border border-dark choice-options_wrapper">
                                                {InnerActivityData.english_question_choices.map(
                                                  (qusChoice, Cindex) => (
                                                    <div
                                                      class="choice-options_child"
                                                      key={Cindex}
                                                    >
                                                      <div>
                                                        {qusChoice.choice_no}
                                                      </div>
                                                      <div>
                                                        {qusChoice.choice_text}
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : null}

                                            {/* <div className="mt-4" dangerouslySetInnerHTML={{
                                  __html: InnerActivityData.english_question_text ? InnerActivityData.english_question_text.replace(/\[(\d+)Answer char=\d+\]/g, '').replace(/\[Answer char=\d+\]/g, '') : null,
                                }}>
                              </div> */}
                                            {InnerActivityData.english_question_text &&
                                            parseInt(
                                              InnerActivityData.english_question_category_id
                                            ) === 8 ? (
                                              <div
                                                className="mt-4"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    InnerActivityData.english_question_text
                                                      ? InnerActivityData.english_question_text
                                                          .replace(
                                                            /\[(\d+)Answer char=\d+\]/g,
                                                            ""
                                                          )
                                                          .replace(
                                                            /\[Answer char=\d+\]/g,
                                                            ""
                                                          )
                                                          .replace(
                                                            /&nbsp;/g,
                                                            ""
                                                          )
                                                          .split("</p>")
                                                          .map(
                                                            (
                                                              paragraph,
                                                              index
                                                            ) =>
                                                              paragraph
                                                                ? `<div class="d-flex"><p class="me-2">${
                                                                    index + 1
                                                                  } ${paragraph}</p></div>`
                                                                : ""
                                                          )
                                                          .filter(Boolean)
                                                          .join("")
                                                      : null,
                                                }}
                                              />
                                            ) : (
                                              <div
                                                className="mt-4"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    InnerActivityData.english_question_text
                                                      ? InnerActivityData.english_question_text
                                                          .replace(
                                                            /\[(\d+)Answer char=\d+\]/g,
                                                            ""
                                                          )
                                                          .replace(
                                                            /\[Answer char=\d+\]/g,
                                                            ""
                                                          )
                                                      : null,
                                                }}
                                              ></div>
                                            )}
                                            {InnerActivityData &&
                                            InnerActivityData.part_heading &&
                                            InnerActivityData.part_heading !=
                                              "" ? (
                                              <div
                                                className="mt-4"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    InnerActivityData.part_heading,
                                                }}
                                              ></div>
                                            ) : null}
                                            {/* File option */}
                                            <div className="mt-4 my-2">
                                              {InnerActivityData &&
                                              InnerActivityData.english_question_files &&
                                              InnerActivityData
                                                .english_question_files.length >
                                                0
                                                ? InnerActivityData.english_question_files.map(
                                                    (qusFile, imgindex) => (
                                                      <img
                                                        key={imgindex}
                                                        alt=""
                                                        style={{
                                                          height: "auto",
                                                          maxWidth: "100%",
                                                        }}
                                                        src={`https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/question_files/${qusFile.file_name}`}
                                                      />
                                                    )
                                                  )
                                                : null}
                                            </div>

                                            {/* Past question */}
                                            {InnerActivityData.english_question_parts &&
                                              InnerActivityData.english_question_parts.map(
                                                (quspart, index) => {
                                                  const stuAnswersarray =
                                                    quspart.StudentAnsAttempts.map(
                                                      stuAns => {
                                                        try {
                                                          return JSON.parse(
                                                            stuAns.student_answer
                                                          )
                                                        } catch (error) {
                                                          console.error(
                                                            "Error parsing JSON:",
                                                            error
                                                          )
                                                          return null // or handle the error in an appropriate way
                                                        }
                                                      }
                                                    ) || [] // Extracting session keys

                                                  // Assuming stuAnswersarray is a nested array
                                                  const flattenedStuAnswers =
                                                    [].concat(
                                                      ...stuAnswersarray
                                                    )
                                                  let serialNumber = 0
                                                  return (
                                                    <div key={index}>
                                                      <div className="d-flex gap-2 flex-row ">
                                                        <div className="mr-3">
                                                          {index + 1}
                                                          {")"}
                                                        </div>
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              quspart.english_question_part_text
                                                                ? quspart.english_question_part_text
                                                                    .replace(
                                                                      /\[(\d+)Answer char=\d+\]/g,
                                                                      ""
                                                                    )
                                                                    .replace(
                                                                      /\[Answer char=\d+\]/g,
                                                                      ""
                                                                    )
                                                                : null,
                                                          }}
                                                        ></div>
                                                      </div>
                                                      {/* Anse category statement Star */}
                                                      {quspart.english_question_part_answer_type ===
                                                        8 ||
                                                      quspart.english_question_part_answer_type ===
                                                        1 ||
                                                      quspart.english_question_part_answer_type ===
                                                        7 ? (
                                                        <ul className="dg-selector width-controll list-unstyled mb-0">
                                                          {" "}
                                                          {parseInt(
                                                            quspart.english_question_part_automark
                                                          ) === 1 ? (
                                                            ""
                                                          ) : (
                                                            <div className="d-flex justify-content-end">
                                                              <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                                                Manual Marking
                                                              </span>
                                                            </div>
                                                          )}
                                                          {quspart.english_question_answers &&
                                                            quspart.english_question_answers.map(
                                                              (
                                                                qusAns,
                                                                index1
                                                              ) => {
                                                                // Your logic for filtering student answers and determining status_color and border_color
                                                                const stuAnswersarray =
                                                                  quspart.StudentAnsAttempts.map(
                                                                    stuAns => {
                                                                      try {
                                                                        return JSON.parse(
                                                                          stuAns.student_answer
                                                                        )
                                                                      } catch (error) {
                                                                        console.error(
                                                                          "Error parsing JSON:",
                                                                          error
                                                                        )
                                                                        return null // or handle the error in an appropriate way
                                                                      }
                                                                    }
                                                                  ) || [] // Extracting session keys
                                                                //console.log(stuAnswersarray);
                                                                // Assuming stuAnswersarray is a nested array
                                                                const flattenedStuAnswers =
                                                                  [].concat(
                                                                    ...stuAnswersarray
                                                                  )
                                                                let cleanedText =
                                                                  qusAns.english_answer_text
                                                                    .replace(
                                                                      /&nbsp;/g,
                                                                      ""
                                                                    )
                                                                    .replace(
                                                                      /`/g,
                                                                      ""
                                                                    )
                                                                    .replace(
                                                                      /<[^>]*>/g,
                                                                      ""
                                                                    ) || ""
                                                                let resultTest =
                                                                  cleanedText
                                                                    .toLowerCase()
                                                                    .trim()
                                                                let answers =
                                                                  resultTest.split(
                                                                    "|"
                                                                  ) || []
                                                                let firstAnswer =
                                                                  answers[0]
                                                                    ? answers[0].trim()
                                                                    : ""
                                                                let secondAnswer =
                                                                  answers[1]
                                                                    ? answers[1].trim()
                                                                    : ""

                                                                if (
                                                                  qusAns.english_answer_is_correct ===
                                                                  1
                                                                ) {
                                                                  let filteredStuAns =
                                                                    flattenedStuAnswers.filter(
                                                                      stuAns =>
                                                                        parseInt(
                                                                          stuAns.part_id
                                                                        ) ===
                                                                          parseInt(
                                                                            qusAns.english_answer_part_id
                                                                          ) &&
                                                                        (stuAns.text
                                                                          .toLowerCase()
                                                                          .trim() ==
                                                                          resultTest ||
                                                                          stuAns.text
                                                                            .toLowerCase()
                                                                            .trim() ==
                                                                            firstAnswer ||
                                                                          stuAns.text
                                                                            .toLowerCase()
                                                                            .trim() ==
                                                                            secondAnswer) &&
                                                                        parseInt(
                                                                          stuAns.answer_id
                                                                        ) ===
                                                                          parseInt(
                                                                            qusAns.english_answer_id
                                                                          )
                                                                    ) || []

                                                                  let filteredAllStuAns =
                                                                    flattenedStuAnswers.filter(
                                                                      stuAns =>
                                                                        parseInt(
                                                                          stuAns.part_id
                                                                        ) ===
                                                                          parseInt(
                                                                            qusAns.english_answer_part_id
                                                                          ) &&
                                                                        parseInt(
                                                                          stuAns.answer_id
                                                                        ) ===
                                                                          parseInt(
                                                                            qusAns.english_answer_id
                                                                          )
                                                                    ) || []

                                                                  return (
                                                                    <li
                                                                      key={
                                                                        index1
                                                                      }
                                                                      className={
                                                                        parseInt(
                                                                          quspart.english_question_part_automark
                                                                        ) === 1
                                                                          ? filteredStuAns.length >
                                                                            0
                                                                            ? "input_box e_input_box"
                                                                            : "input_box e_input_box"
                                                                          : " input_box e_input_box"
                                                                      }
                                                                    >
                                                                      <textarea
                                                                        id={
                                                                          index1 +
                                                                          Math.floor(
                                                                            Math.random() *
                                                                              5
                                                                          )
                                                                        }
                                                                        type="text"
                                                                        className={
                                                                          parseInt(
                                                                            quspart.english_question_part_automark
                                                                          ) ===
                                                                            1 &&
                                                                          quspart
                                                                            .StudentAnsAttempts
                                                                            .length >
                                                                            0
                                                                            ? filteredStuAns.length >
                                                                              0
                                                                              ? "answersuccess"
                                                                              : "answererror "
                                                                            : "answereditor"
                                                                        }
                                                                        value={
                                                                          filteredAllStuAns
                                                                            .filter(
                                                                              item =>
                                                                                parseInt(
                                                                                  item.answer_id
                                                                                ) ===
                                                                                parseInt(
                                                                                  qusAns.english_answer_id
                                                                                )
                                                                            )
                                                                            .reverse()
                                                                            .map(
                                                                              item =>
                                                                                item.text
                                                                            )
                                                                            .find(
                                                                              text =>
                                                                                text !==
                                                                                undefined
                                                                            ) ||
                                                                          "" // Find the first non-undefined text value
                                                                        }
                                                                        disabled={
                                                                          true
                                                                        }
                                                                        //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                                        onChange={e =>
                                                                          handletext(
                                                                            e,
                                                                            qusAns.english_answer_id,
                                                                            qusAns.english_answer_part_id
                                                                          )
                                                                        }
                                                                        // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                                      />
                                                                    </li>
                                                                  )
                                                                }
                                                              }
                                                            )}
                                                        </ul>
                                                      ) : quspart.english_question_part_answer_type ===
                                                        4 ? (
                                                        //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                                                        <ul className="dg-selector width-controll list-unstyled mb-0">
                                                          {parseInt(
                                                            quspart.english_question_part_automark
                                                          ) === 1 ? (
                                                            ""
                                                          ) : (
                                                            <div className="d-flex justify-content-end">
                                                              <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                                                Manual Marking
                                                              </span>
                                                            </div>
                                                          )}
                                                          {quspart.english_question_answers &&
                                                            rearrangeEvenOdd(
                                                              quspart.english_question_answers
                                                            ).map(
                                                              (
                                                                qusAns,
                                                                index1
                                                              ) => {
                                                                const cleanText =
                                                                  qusAns.english_answer_text
                                                                    .replace(
                                                                      /&nbsp;/g,
                                                                      ""
                                                                    )
                                                                    .replace(
                                                                      /`/g,
                                                                      ""
                                                                    )
                                                                    .replace(
                                                                      /<[^>]*>/g,
                                                                      ""
                                                                    )
                                                                    .trim()

                                                                const hasImage =
                                                                  /<img\s+[^>]*>/gi.test(
                                                                    qusAns.english_answer_text
                                                                  )

                                                                if (
                                                                  !cleanText &&
                                                                  !hasImage
                                                                ) {
                                                                  return null // Skip rendering if english_answer_text is effectively empty and no image is present
                                                                }

                                                                serialNumber++

                                                                let filteredStuAns =
                                                                  flattenedStuAnswers.filter(
                                                                    stuAns =>
                                                                      parseInt(
                                                                        stuAns.part_id
                                                                      ) ===
                                                                        parseInt(
                                                                          qusAns.english_answer_part_id
                                                                        ) &&
                                                                      parseInt(
                                                                        stuAns.answer_id
                                                                      ) ===
                                                                        parseInt(
                                                                          qusAns.english_answer_id
                                                                        )
                                                                  ) || []

                                                                var status_color =
                                                                  "selector-general cursor-pointer"
                                                                var border_color =
                                                                  "border-start-secondary"
                                                                if (
                                                                  filteredStuAns.length >
                                                                    0 &&
                                                                  parseInt(
                                                                    quspart.english_question_part_automark
                                                                  ) === 1
                                                                ) {
                                                                  if (
                                                                    parseInt(
                                                                      qusAns.english_answer_is_correct
                                                                    ) === 1
                                                                  ) {
                                                                    status_color =
                                                                      "selector-success"
                                                                    border_color =
                                                                      "border-start-success"
                                                                  } else {
                                                                    status_color =
                                                                      "selector-wrong "
                                                                    border_color =
                                                                      "border-start-danger"
                                                                  }
                                                                }
                                                                //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                                                return (
                                                                  <li
                                                                    className={
                                                                      status_color +
                                                                      " choose e_choose"
                                                                    }
                                                                    key={index1}
                                                                  >
                                                                    <div className="p-2 px-3 font-size-18">
                                                                      {
                                                                        serialNumber
                                                                      }
                                                                    </div>
                                                                    <div
                                                                      className={
                                                                        "p-2 dg-media " +
                                                                        {
                                                                          border_color,
                                                                        }
                                                                      }
                                                                      dangerouslySetInnerHTML={{
                                                                        __html:
                                                                          qusAns.english_answer_text
                                                                            ? qusAns.english_answer_text
                                                                            : null,
                                                                      }}
                                                                    ></div>
                                                                  </li>
                                                                )
                                                              }
                                                            )}
                                                        </ul>
                                                      ) : (
                                                        <p className="text-center">
                                                          No option
                                                        </p>
                                                      )}
                                                      {/* Anse category statement end */}
                                                    </div>
                                                  )
                                                }
                                              )}

                                            {/* past question End */}

                                            <>
                                              <h4 className="font-size-16 fw-bold mb-2 mt-3">
                                                Feedback
                                              </h4>
                                              <div
                                                className="e_feedBack"
                                                style={{
                                                  border: "1px solid #1a1a1a",
                                                  borderRadius: "4px",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {InnerActivityData.english_question_parts &&
                                                  InnerActivityData.english_question_parts.map(
                                                    (quspart, index) => (
                                                      <div
                                                        className="d-flex gap-2 flex-row"
                                                        key={index}
                                                      >
                                                        <div className="mr-3">
                                                          {index + 1}
                                                        </div>
                                                        {quspart.english_question_answers
                                                          .filter(
                                                            CorrAns =>
                                                              parseInt(
                                                                CorrAns.english_answer_is_correct
                                                              ) === 1
                                                          )
                                                          .map(
                                                            (
                                                              qusAns,
                                                              index1
                                                            ) => (
                                                              <div
                                                                key={index1}
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    qusAns.english_answer_text
                                                                      ? qusAns.english_answer_text
                                                                      : null,
                                                                }}
                                                              ></div>
                                                            )
                                                          )}
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                            </>
                                          </li>
                                        </ul>

                                        <div className="action-btns d-flex justify-content-between gap-4 mt-5">
                                          {/* Render a "Prev" button if the active index is greater than 0 */}
                                          {activeindex + 1 > 1 ? (
                                            <Button
                                              className="btn btn-danger cs-btn-danger mt-1"
                                              onClick={() => {
                                                toggleCustom(activeindex)
                                              }}
                                            >
                                              Prev
                                            </Button>
                                          ) : null}

                                          {/* Render a "Next" button if the active index is less than the total length of LessonActivitiesQuestions array */}
                                          {activeindex + 1 <
                                          ActivityData.LessonActivitiesQuestions
                                            .length ? (
                                            <Button
                                              className="btn btn-danger cs-btn-danger mt-1"
                                              onClick={() => {
                                                toggleCustom(
                                                  activeindex + 1 + 1
                                                )
                                              }}
                                            >
                                              Next
                                            </Button>
                                          ) : null}
                                        </div>
                                      </div>
                                    </TabPane>
                                  )
                                } else if (
                                  InnerActivityData &&
                                  InnerActivityData.english_question_category_id &&
                                  (parseInt(
                                    InnerActivityData.english_question_category_id
                                  ) === 13 ||
                                    parseInt(
                                      InnerActivityData.english_question_category_id
                                    ) === 10)
                                ) {
                                  return (
                                    <TabPane
                                      tabId={activeindex + 1}
                                      key={activeindex + 1}
                                      className={
                                        parseInt(customActiveTab) ===
                                        activeindex + 1
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <div className="hw-list mt-4 pb-100 not_scroll">
                                        <ul className="list-unstyled mb-0 question_container">
                                          <li className="justify-content-between">
                                            <div className="d-flex align-items-start gap-4 justify-content-end">
                                              {/* <p className="mb-0 text-uppercase font-size-16">
                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .level_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .level_name + " /"
                                                  : null}

                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .strand_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .strand_name + " /"
                                                  : null}

                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .topic_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .topic_name + " /"
                                                  : null}

                                                {InnerActivityData.english_question_has_sub_topic &&
                                                InnerActivityData
                                                  .english_question_has_sub_topic
                                                  .sub_topic_name
                                                  ? InnerActivityData
                                                      .english_question_has_sub_topic
                                                      .sub_topic_name
                                                  : null}
                                              </p> */}
                                              <div className="mb-0 d-flex align-items-center gap-2">
                                                <p className="mb-0">
                                                  #
                                                  {InnerActivityData.english_question_id
                                                    ? InnerActivityData.english_question_id
                                                    : null}
                                                </p>
                                                <span
                                                  onClick={() =>
                                                    tog_standard(ActivityData1)
                                                  }
                                                >
                                                  <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              className="mt-3"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  InnerActivityData.english_question_instruction
                                                    ? InnerActivityData.english_question_instruction
                                                        .replace(
                                                          /\[(\d+)Answer char=\d+\]/g,
                                                          ""
                                                        )
                                                        .replace(
                                                          /\[Answer char=\d+\]/g,
                                                          ""
                                                        )
                                                    : null,
                                              }}
                                            ></div>
                                            {/* Choice */}
                                            {InnerActivityData &&
                                            InnerActivityData.english_question_choices &&
                                            InnerActivityData
                                              .english_question_choices.length >
                                              0 ? (
                                              <div className="mt-4 my-2 border border-dark choice-options_wrapper">
                                                {InnerActivityData.english_question_choices.map(
                                                  (qusChoice, Cindex) => (
                                                    <div
                                                      class="choice-options_child"
                                                      key={Cindex}
                                                    >
                                                      <div>
                                                        {qusChoice.choice_no}
                                                      </div>
                                                      <div>
                                                        {qusChoice.choice_text}
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : null}

                                            <div
                                              className="mt-4"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  InnerActivityData.english_question_text
                                                    ? InnerActivityData.english_question_text
                                                        .replace(
                                                          /\[(\d+)Answer char=\d+\]/g,
                                                          ""
                                                        )
                                                        .replace(
                                                          /\[Answer char=\d+\]/g,
                                                          ""
                                                        )
                                                    : null,
                                              }}
                                            ></div>
                                            {InnerActivityData &&
                                            InnerActivityData.part_heading &&
                                            InnerActivityData.part_heading !=
                                              "" ? (
                                              <div
                                                className="mt-4"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    InnerActivityData.part_heading,
                                                }}
                                              ></div>
                                            ) : null}
                                            {/* File option */}
                                            <div className="mt-4 my-2">
                                              {InnerActivityData &&
                                              InnerActivityData.english_question_files &&
                                              InnerActivityData
                                                .english_question_files.length >
                                                0
                                                ? InnerActivityData.english_question_files.map(
                                                    (qusFile, imgindex) => (
                                                      <img
                                                        key={imgindex}
                                                        alt=""
                                                        style={{
                                                          height: "auto",
                                                          maxWidth: "100%",
                                                        }}
                                                        src={`https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/question_files/${qusFile.file_name}`}
                                                      />
                                                    )
                                                  )
                                                : null}
                                            </div>

                                            {/* Past question */}
                                            {InnerActivityData.english_question_parts &&
                                              InnerActivityData.english_question_parts.map(
                                                (quspart, index) => {
                                                  if (
                                                    quspart.english_question_answers &&
                                                    quspart
                                                      .english_question_answers
                                                      .length > 0 &&
                                                    quspart
                                                      .english_question_answers[0]
                                                      .english_answer_text &&
                                                    quspart
                                                      .english_question_answers[0]
                                                      .english_answer_text != ""
                                                  ) {
                                                    const stuAnswersarray =
                                                      quspart.StudentAnsAttempts.map(
                                                        stuAns => {
                                                          try {
                                                            return JSON.parse(
                                                              stuAns.student_answer
                                                            )
                                                          } catch (error) {
                                                            console.error(
                                                              "Error parsing JSON:",
                                                              error
                                                            )
                                                            return null // or handle the error in an appropriate way
                                                          }
                                                        }
                                                      ) || [] // Extracting session keys

                                                    // Assuming stuAnswersarray is a nested array
                                                    const flattenedStuAnswers =
                                                      [].concat(
                                                        ...stuAnswersarray
                                                      )
                                                    let serialNumber = 0
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="d-flex justify-content-between align-items-center mt-3"
                                                      >
                                                        <div className="d-flex gap-2 flex-row ">
                                                          {/* <div className="mr-3">{index + 1}</div> */}
                                                          <div
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                quspart.english_question_part_text
                                                                  ? quspart.english_question_part_text
                                                                      .replace(
                                                                        /\[\[(.+?)\]\]/g,
                                                                        function (
                                                                          match,
                                                                          p1
                                                                        ) {
                                                                          return p1.length >
                                                                            1
                                                                            ? `<span class="text-bg-yellow">${p1}</span>`
                                                                            : `<span class="text-bg-blue">${p1}</span>`
                                                                        }
                                                                      )
                                                                      .replace(
                                                                        /\{\{/g,
                                                                        "<u>"
                                                                      )
                                                                      .replace(
                                                                        /\}\}/g,
                                                                        "</u>"
                                                                      )
                                                                      .replace(
                                                                        /\[(\d+)Answer char=\d+\]/g,
                                                                        ""
                                                                      )
                                                                      .replace(
                                                                        /\[Answer char=\d+\]/g,
                                                                        ""
                                                                      )
                                                                  : null,
                                                            }}
                                                          ></div>
                                                        </div>
                                                        {/* Anse category statement Star */}
                                                        {quspart.english_question_part_answer_type ===
                                                          8 ||
                                                        quspart.english_question_part_answer_type ===
                                                          1 ||
                                                        quspart.english_question_part_answer_type ===
                                                          7 ? (
                                                          <ul className="dg-selector width-controll list-unstyled mb-0">
                                                            {" "}
                                                            {parseInt(
                                                              quspart.english_question_part_automark
                                                            ) === 1 ? (
                                                              ""
                                                            ) : (
                                                              <div className="d-flex justify-content-end">
                                                                <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                                                  Manual Marking
                                                                </span>
                                                              </div>
                                                            )}
                                                            {quspart.english_question_answers &&
                                                              quspart.english_question_answers.map(
                                                                (
                                                                  qusAns,
                                                                  index1
                                                                ) => {
                                                                  // Your logic for filtering student answers and determining status_color and border_color
                                                                  const stuAnswersarray =
                                                                    quspart.StudentAnsAttempts.map(
                                                                      stuAns => {
                                                                        try {
                                                                          return JSON.parse(
                                                                            stuAns.student_answer
                                                                          )
                                                                        } catch (error) {
                                                                          console.error(
                                                                            "Error parsing JSON:",
                                                                            error
                                                                          )
                                                                          return null // or handle the error in an appropriate way
                                                                        }
                                                                      }
                                                                    ) || [] // Extracting session keys
                                                                  //console.log(stuAnswersarray);
                                                                  // Assuming stuAnswersarray is a nested array
                                                                  const flattenedStuAnswers =
                                                                    [].concat(
                                                                      ...stuAnswersarray
                                                                    )
                                                                  let cleanedText =
                                                                    qusAns.english_answer_text
                                                                      .replace(
                                                                        /&nbsp;/g,
                                                                        ""
                                                                      )
                                                                      .replace(
                                                                        /`/g,
                                                                        ""
                                                                      )
                                                                      .replace(
                                                                        /<[^>]*>/g,
                                                                        ""
                                                                      ) || ""
                                                                  let resultTest =
                                                                    cleanedText
                                                                      .toLowerCase()
                                                                      .trim()
                                                                  let answers =
                                                                    resultTest.split(
                                                                      "|"
                                                                    ) || []
                                                                  let firstAnswer =
                                                                    answers[0]
                                                                      ? answers[0].trim()
                                                                      : "No_Data"
                                                                  let secondAnswer =
                                                                    answers[1]
                                                                      ? answers[1].trim()
                                                                      : "No_Data"

                                                                  if (
                                                                    qusAns.english_answer_is_correct ===
                                                                    1
                                                                  ) {
                                                                    let filteredStuAns =
                                                                      flattenedStuAnswers.filter(
                                                                        stuAns =>
                                                                          parseInt(
                                                                            stuAns.part_id
                                                                          ) ===
                                                                            parseInt(
                                                                              qusAns.english_answer_part_id
                                                                            ) &&
                                                                          (stuAns.text
                                                                            .toLowerCase()
                                                                            .trim() ==
                                                                            resultTest ||
                                                                            stuAns.text
                                                                              .toLowerCase()
                                                                              .trim() ==
                                                                              firstAnswer ||
                                                                            stuAns.text
                                                                              .toLowerCase()
                                                                              .trim() ==
                                                                              secondAnswer) &&
                                                                          parseInt(
                                                                            stuAns.answer_id
                                                                          ) ===
                                                                            parseInt(
                                                                              qusAns.english_answer_id
                                                                            )
                                                                      ) || []

                                                                    let filteredAllStuAns =
                                                                      flattenedStuAnswers.filter(
                                                                        stuAns =>
                                                                          parseInt(
                                                                            stuAns.part_id
                                                                          ) ===
                                                                            parseInt(
                                                                              qusAns.english_answer_part_id
                                                                            ) &&
                                                                          parseInt(
                                                                            stuAns.answer_id
                                                                          ) ===
                                                                            parseInt(
                                                                              qusAns.english_answer_id
                                                                            )
                                                                      ) || []

                                                                    return (
                                                                      <li
                                                                        key={
                                                                          index1
                                                                        }
                                                                        className={
                                                                          parseInt(
                                                                            quspart.english_question_part_automark
                                                                          ) ===
                                                                          1
                                                                            ? filteredStuAns.length >
                                                                              0
                                                                              ? "input_box small_input_box"
                                                                              : "input_box small_input_box"
                                                                            : " input_box small_input_box"
                                                                        }
                                                                      >
                                                                        <input
                                                                          id={
                                                                            index1 +
                                                                            Math.floor(
                                                                              Math.random() *
                                                                                5
                                                                            )
                                                                          }
                                                                          type="text"
                                                                          className={
                                                                            parseInt(
                                                                              quspart.english_question_part_automark
                                                                            ) ===
                                                                              1 &&
                                                                            quspart
                                                                              .StudentAnsAttempts
                                                                              .length >
                                                                              0
                                                                              ? filteredStuAns.length >
                                                                                0
                                                                                ? "answersuccess"
                                                                                : "answererror "
                                                                              : "answereditor"
                                                                          }
                                                                          value={
                                                                            filteredAllStuAns
                                                                              .filter(
                                                                                item =>
                                                                                  parseInt(
                                                                                    item.answer_id
                                                                                  ) ===
                                                                                  parseInt(
                                                                                    qusAns.english_answer_id
                                                                                  )
                                                                              )
                                                                              .reverse()
                                                                              .map(
                                                                                item =>
                                                                                  item.text
                                                                              )
                                                                              .find(
                                                                                text =>
                                                                                  text !==
                                                                                  undefined
                                                                              ) ||
                                                                            "" // Find the first non-undefined text value
                                                                          }
                                                                          disabled={
                                                                            true
                                                                          }
                                                                          //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                                          onChange={e =>
                                                                            handletext(
                                                                              e,
                                                                              qusAns.english_answer_id,
                                                                              qusAns.english_answer_part_id
                                                                            )
                                                                          }
                                                                          // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                                        />
                                                                      </li>
                                                                    )
                                                                  }
                                                                }
                                                              )}
                                                          </ul>
                                                        ) : quspart.english_question_part_answer_type ===
                                                          4 ? (
                                                          //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                                                          <ul className="dg-selector width-controll list-unstyled mb-0">
                                                            {parseInt(
                                                              quspart.english_question_part_automark
                                                            ) === 1 ? (
                                                              ""
                                                            ) : (
                                                              <div className="d-flex justify-content-end">
                                                                <span className="badge bg-primary font-size-14 mt-auto mb-2">
                                                                  Manual Marking
                                                                </span>
                                                              </div>
                                                            )}
                                                            {quspart.english_question_answers &&
                                                              rearrangeEvenOdd(
                                                                quspart.english_question_answers
                                                              ).map(
                                                                (
                                                                  qusAns,
                                                                  index1
                                                                ) => {
                                                                  const cleanText =
                                                                    qusAns.english_answer_text
                                                                      .replace(
                                                                        /&nbsp;/g,
                                                                        ""
                                                                      )
                                                                      .replace(
                                                                        /`/g,
                                                                        ""
                                                                      )
                                                                      .replace(
                                                                        /<[^>]*>/g,
                                                                        ""
                                                                      )
                                                                      .trim()

                                                                  const hasImage =
                                                                    /<img\s+[^>]*>/gi.test(
                                                                      qusAns.english_answer_text
                                                                    )

                                                                  if (
                                                                    !cleanText &&
                                                                    !hasImage
                                                                  ) {
                                                                    return null // Skip rendering if english_answer_text is effectively empty and no image is present
                                                                  }

                                                                  serialNumber++

                                                                  let filteredStuAns =
                                                                    flattenedStuAnswers.filter(
                                                                      stuAns =>
                                                                        parseInt(
                                                                          stuAns.part_id
                                                                        ) ===
                                                                          parseInt(
                                                                            qusAns.english_answer_part_id
                                                                          ) &&
                                                                        parseInt(
                                                                          stuAns.answer_id
                                                                        ) ===
                                                                          parseInt(
                                                                            qusAns.english_answer_id
                                                                          )
                                                                    ) || []

                                                                  var status_color =
                                                                    "selector-general cursor-pointer"
                                                                  var border_color =
                                                                    "border-start-secondary"
                                                                  if (
                                                                    filteredStuAns.length >
                                                                      0 &&
                                                                    parseInt(
                                                                      quspart.english_question_part_automark
                                                                    ) === 1
                                                                  ) {
                                                                    if (
                                                                      parseInt(
                                                                        qusAns.english_answer_is_correct
                                                                      ) === 1
                                                                    ) {
                                                                      status_color =
                                                                        "selector-success"
                                                                      border_color =
                                                                        "border-start-success"
                                                                    } else {
                                                                      status_color =
                                                                        "selector-wrong "
                                                                      border_color =
                                                                        "border-start-danger"
                                                                    }
                                                                  }
                                                                  //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                                                  return (
                                                                    <li
                                                                      className={
                                                                        status_color +
                                                                        " choose e_choose"
                                                                      }
                                                                      key={
                                                                        index1
                                                                      }
                                                                    >
                                                                      <div className="p-2 px-3 font-size-18">
                                                                        {
                                                                          serialNumber
                                                                        }
                                                                      </div>
                                                                      <div
                                                                        className={
                                                                          "p-2 dg-media " +
                                                                          {
                                                                            border_color,
                                                                          }
                                                                        }
                                                                        dangerouslySetInnerHTML={{
                                                                          __html:
                                                                            qusAns.english_answer_text
                                                                              ? qusAns.english_answer_text
                                                                              : null,
                                                                        }}
                                                                      ></div>
                                                                    </li>
                                                                  )
                                                                }
                                                              )}
                                                          </ul>
                                                        ) : (
                                                          <p className="text-center">
                                                            No option
                                                          </p>
                                                        )}
                                                        {/* Anse category statement end */}
                                                      </div>
                                                    )
                                                  } else {
                                                    return quspart.english_question_part_text ? (
                                                      <div
                                                        key={index}
                                                        className="mt-3"
                                                      >
                                                        <div className="d-flex gap-2 flex-row ">
                                                          {/* <div className="mr-3">{index + 1}</div> */}
                                                          <div
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                quspart.english_question_part_text
                                                                  ? quspart.english_question_part_text
                                                                      .replace(
                                                                        /\[\[(.+?)\]\]/g,
                                                                        function (
                                                                          match,
                                                                          p1
                                                                        ) {
                                                                          return p1.length >
                                                                            1
                                                                            ? `<span class="text-bg-yellow">${p1}</span>`
                                                                            : `<span class="text-bg-blue">${p1}</span>`
                                                                        }
                                                                      )
                                                                      .replace(
                                                                        /\{\{/g,
                                                                        "<u>"
                                                                      )
                                                                      .replace(
                                                                        /\}\}/g,
                                                                        "</u>"
                                                                      )
                                                                      .replace(
                                                                        /\[(\d+)Answer char=\d+\]/g,
                                                                        ""
                                                                      )
                                                                      .replace(
                                                                        /\[Answer char=\d+\]/g,
                                                                        ""
                                                                      )
                                                                  : null,
                                                            }}
                                                          ></div>
                                                        </div>
                                                      </div>
                                                    ) : null
                                                  }
                                                }
                                              )}

                                            {/* past question End */}

                                            <>
                                              <h4 className="font-size-16 fw-bold mb-2 mt-3">
                                                Feedback
                                              </h4>
                                              <div
                                                className="e_feedBack gap-5 d-flex"
                                                style={{
                                                  border: "1px solid #1a1a1a",
                                                  borderRadius: "4px",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                <div>
                                                  {InnerActivityData.english_question_parts &&
                                                    InnerActivityData.english_question_parts.map(
                                                      (quspart, index) => {
                                                        if (
                                                          quspart.english_question_answers &&
                                                          quspart
                                                            .english_question_answers
                                                            .length > 0 &&
                                                          quspart
                                                            .english_question_answers[0]
                                                            .english_answer_text &&
                                                          quspart
                                                            .english_question_answers[0]
                                                            .english_answer_text !=
                                                            ""
                                                        ) {
                                                          return (
                                                            <div
                                                              className="d-flex gap-2 flex-row"
                                                              key={index}
                                                            >
                                                              {/* <div className="mr-3">{index + 1}</div> */}
                                                              {quspart.english_question_answers
                                                                .filter(
                                                                  CorrAns =>
                                                                    parseInt(
                                                                      CorrAns.english_answer_is_correct
                                                                    ) === 1
                                                                )
                                                                .map(
                                                                  (
                                                                    qusAns,
                                                                    index1
                                                                  ) => (
                                                                    <div
                                                                      key={
                                                                        index1
                                                                      }
                                                                      dangerouslySetInnerHTML={{
                                                                        __html:
                                                                          qusAns.english_answer_text
                                                                            ? qusAns.english_answer_text
                                                                            : null,
                                                                      }}
                                                                    ></div>
                                                                  )
                                                                )}
                                                            </div>
                                                          )
                                                        }
                                                      }
                                                    )}
                                                </div>
                                                <div>
                                                  {InnerActivityData.english_question_parts &&
                                                    InnerActivityData.english_question_parts.map(
                                                      (quspart, index) => {
                                                        if (
                                                          quspart.english_question_answers &&
                                                          quspart
                                                            .english_question_answers
                                                            .length > 0 &&
                                                          quspart
                                                            .english_question_answers[0]
                                                            .english_answer_text &&
                                                          quspart
                                                            .english_question_answers[0]
                                                            .english_answer_text !=
                                                            ""
                                                        ) {
                                                          return (
                                                            <div
                                                              className="d-flex gap-2 flex-row"
                                                              key={index}
                                                            >
                                                              {/* <div className="mr-3">{index + 1}</div> */}
                                                              {quspart.english_question_answers
                                                                .filter(
                                                                  CorrAns =>
                                                                    parseInt(
                                                                      CorrAns.english_answer_is_correct
                                                                    ) === 1
                                                                )
                                                                .map(
                                                                  (
                                                                    qusAns,
                                                                    index1
                                                                  ) => (
                                                                    <div
                                                                      key={
                                                                        index1
                                                                      }
                                                                      dangerouslySetInnerHTML={{
                                                                        __html:
                                                                          qusAns.english_answer_feedback
                                                                            ? qusAns.english_answer_feedback
                                                                            : "&nbsp;",
                                                                      }}
                                                                    ></div>
                                                                  )
                                                                )}
                                                            </div>
                                                          )
                                                        }
                                                      }
                                                    )}
                                                </div>
                                              </div>
                                            </>
                                          </li>
                                        </ul>

                                        <div className="action-btns d-flex justify-content-between gap-4 mt-5">
                                          {/* Render a "Prev" button if the active index is greater than 0 */}
                                          {activeindex + 1 > 1 ? (
                                            <Button
                                              className="btn btn-danger cs-btn-danger mt-1"
                                              onClick={() => {
                                                toggleCustom(activeindex)
                                              }}
                                            >
                                              Prev
                                            </Button>
                                          ) : null}

                                          {/* Render a "Next" button if the active index is less than the total length of LessonActivitiesQuestions array */}
                                          {activeindex + 1 <
                                          ActivityData.LessonActivitiesQuestions
                                            .length ? (
                                            <Button
                                              className="btn btn-danger cs-btn-danger mt-1"
                                              onClick={() => {
                                                toggleCustom(
                                                  activeindex + 1 + 1
                                                )
                                              }}
                                            >
                                              Next
                                            </Button>
                                          ) : null}
                                        </div>
                                      </div>
                                    </TabPane>
                                  )
                                } else {
                                  return (
                                    <p className="text-center">No question</p>
                                  )
                                }
                              }
                            }
                          )
                        } else if (
                          ActivityData1 &&
                          ActivityData1.fk_sub_id &&
                          parseInt(ActivityData1.fk_sub_id) === 2
                        ) {
                          return ActivityData1.question.map(
                            (InnerActivityData, index) => {
                              if (index === 0) {
                                if (
                                  InnerActivityData &&
                                  InnerActivityData.question_parts &&
                                  InnerActivityData.question_parts.length > 0
                                ) {
                                  return (
                                    <TabPane
                                      tabId={activeindex + 1}
                                      key={activeindex + 1}
                                      className={
                                        parseInt(customActiveTab) ===
                                        activeindex + 1
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <div className="hw-list mt-4 pb-100">
                                        <ul className="list-unstyled mb-0 question_container">
                                          <li className="justify-content-between">
                                            <div className="d-flex align-items-start gap-4 justify-content-end">
                                              {/* <p className="mb-0 text-uppercase font-size-16">
                                                {InnerActivityData.question_new_topic &&
                                                InnerActivityData
                                                  .question_new_topic.level_name
                                                  ? InnerActivityData
                                                      .question_new_topic
                                                      .level_name + " /"
                                                  : null}

                                                {InnerActivityData.question_new_topic &&
                                                InnerActivityData
                                                  .question_new_topic.topic_name
                                                  ? InnerActivityData
                                                      .question_new_topic
                                                      .topic_name + " /"
                                                  : null}

                                                {InnerActivityData.question_new_topic &&
                                                InnerActivityData
                                                  .question_new_topic
                                                  .sub_topics_name
                                                  ? InnerActivityData
                                                      .question_new_topic
                                                      .sub_topics_name + " /"
                                                  : null}
                                              </p> */}
                                              <div className="mb-0 d-flex align-items-center gap-2">
                                                <p className="mb-0">
                                                  #
                                                  {InnerActivityData.question_id
                                                    ? InnerActivityData.question_id
                                                    : null}
                                                </p>
                                                <span>
                                                  <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                                                </span>
                                              </div>
                                            </div>

                                            <div
                                              className="mt-4"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  InnerActivityData.question_text
                                                    ? InnerActivityData.question_text
                                                        .replace(
                                                          /\[(\d+)Answer char=\d+\]/g,
                                                          ""
                                                        )
                                                        .replace(
                                                          /\[Answer char=\d+\]/g,
                                                          ""
                                                        )
                                                    : null,
                                              }}
                                            ></div>

                                            {/* Past question */}
                                            {InnerActivityData.question_parts &&
                                              InnerActivityData.question_parts.map(
                                                (quspart, index) => {
                                                  const stuAnswersarray =
                                                    quspart.StudentAnsAttempts.map(
                                                      stuAns => {
                                                        try {
                                                          return JSON.parse(
                                                            stuAns.student_answer
                                                          )
                                                        } catch (error) {
                                                          console.error(
                                                            "Error parsing JSON:",
                                                            error
                                                          )
                                                          return null // or handle the error in an appropriate way
                                                        }
                                                      }
                                                    ) || [] // Extracting session keys

                                                  // Assuming stuAnswersarray is a nested array
                                                  const flattenedStuAnswers =
                                                    [].concat(
                                                      ...stuAnswersarray
                                                    )
                                                  let serialNumber = 0
                                                  return (
                                                    <div key={index}>
                                                      <div className="d-flex gap-2 flex-row ">
                                                        <div className="mr-3">
                                                          {index + 1}
                                                          {")"}
                                                        </div>
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              quspart.part_text
                                                                ? quspart.part_text
                                                                    .replace(
                                                                      /\[(\d+)Answer char=\d+\]/g,
                                                                      ""
                                                                    )
                                                                    .replace(
                                                                      /\[Answer char=\d+\]/g,
                                                                      ""
                                                                    )
                                                                : null,
                                                          }}
                                                        ></div>
                                                      </div>
                                                      {/* Anse category statement Star */}
                                                      {quspart.part_answer_type ===
                                                      2 ? (
                                                        <ul className="dg-selector width-controll list-unstyled mb-0">
                                                          {quspart.question_answers &&
                                                            quspart.question_answers.map(
                                                              (
                                                                qusAns,
                                                                index1
                                                              ) => {
                                                                if (
                                                                  quspart
                                                                    .StudentAnsAttempts
                                                                    .length > 0
                                                                ) {
                                                                  return quspart.StudentAnsAttempts.map(
                                                                    (
                                                                      qusStuAns,
                                                                      stuAnsindex
                                                                    ) => {
                                                                      // Your logic for filtering student answers and determining status_color and border_color
                                                                      const stuAnswersarray =
                                                                        quspart.StudentAnsAttempts.filter(
                                                                          stuAns =>
                                                                            stuAns.atempt_count ===
                                                                            stuAnsindex +
                                                                              1
                                                                        ).map(
                                                                          stuAns => {
                                                                            try {
                                                                              return JSON.parse(
                                                                                stuAns.student_answer
                                                                              )
                                                                            } catch (error) {
                                                                              console.error(
                                                                                "Error parsing JSON:",
                                                                                error
                                                                              )
                                                                              return null // or handle the error in an appropriate way
                                                                            }
                                                                          }
                                                                        ) || []

                                                                      //console.log(stuAnswersarray);
                                                                      // Assuming stuAnswersarray is a nested array
                                                                      const flattenedStuAnswers =
                                                                        [].concat(
                                                                          ...stuAnswersarray
                                                                        )
                                                                      //  let cleanedText = qusAns.answer_text.replace(/&nbsp;/g, '').replace(/`/g, '').replace(/<[^>]*>/g, '') || '';
                                                                      //  let resultTest = cleanedText.toLowerCase().trim();
                                                                      //  let answers = resultTest.split('|') || [];
                                                                      //  let firstAnswer = answers[0] ? answers[0].trim() : "";
                                                                      //  let secondAnswer = answers[1] ? answers[1].trim() : "";

                                                                      if (
                                                                        qusAns.answer_is_correct ===
                                                                        1
                                                                      ) {
                                                                        let filteredStuAns =
                                                                          flattenedStuAnswers.filter(
                                                                            stuAns =>
                                                                              parseInt(
                                                                                stuAns.part_id
                                                                              ) ===
                                                                                parseInt(
                                                                                  qusAns.answer_part_id
                                                                                ) &&
                                                                              parseInt(
                                                                                qusStuAns.attempt_status
                                                                              ) ===
                                                                                1 &&
                                                                              parseInt(
                                                                                stuAns.answer_id
                                                                              ) ===
                                                                                parseInt(
                                                                                  qusAns.answer_id
                                                                                )
                                                                          ) ||
                                                                          []

                                                                        let filteredAllStuAns =
                                                                          flattenedStuAnswers.filter(
                                                                            stuAns =>
                                                                              parseInt(
                                                                                stuAns.part_id
                                                                              ) ===
                                                                                parseInt(
                                                                                  qusAns.answer_part_id
                                                                                ) &&
                                                                              parseInt(
                                                                                stuAns.answer_id
                                                                              ) ===
                                                                                parseInt(
                                                                                  qusAns.answer_id
                                                                                )
                                                                          ) ||
                                                                          []

                                                                        return (
                                                                          <li
                                                                            key={
                                                                              index1
                                                                            }
                                                                            className={
                                                                              filteredStuAns.length >
                                                                              0
                                                                                ? " input_box m_input_box"
                                                                                : "input_box m_input_box"
                                                                            }
                                                                          >
                                                                            <input
                                                                              id={
                                                                                index1 +
                                                                                Math.floor(
                                                                                  Math.random() *
                                                                                    5
                                                                                )
                                                                              }
                                                                              type="text"
                                                                              className={
                                                                                parseInt(
                                                                                  qusStuAns.attempt_status
                                                                                ) ===
                                                                                1
                                                                                  ? "answersuccess"
                                                                                  : "answererror"
                                                                              }
                                                                              value={
                                                                                filteredAllStuAns
                                                                                  .filter(
                                                                                    item =>
                                                                                      parseInt(
                                                                                        item.answer_id
                                                                                      ) ===
                                                                                      parseInt(
                                                                                        qusAns.answer_id
                                                                                      )
                                                                                  )
                                                                                  .map(
                                                                                    item =>
                                                                                      item.text
                                                                                  )
                                                                                  .find(
                                                                                    text =>
                                                                                      text !==
                                                                                      undefined
                                                                                  ) ||
                                                                                "" // Find the first non-undefined text value
                                                                              }
                                                                              disabled={
                                                                                true
                                                                              }
                                                                              //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                                              onChange={e =>
                                                                                handletext(
                                                                                  e,
                                                                                  qusAns.answer_id,
                                                                                  qusAns.answer_part_id
                                                                                )
                                                                              }
                                                                              // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                                            />
                                                                          </li>
                                                                        )
                                                                      }
                                                                    }
                                                                  )
                                                                } else {
                                                                  return (
                                                                    <li
                                                                      key={
                                                                        index1
                                                                      }
                                                                      className={
                                                                        "input_box m_input_box"
                                                                      }
                                                                    >
                                                                      <input
                                                                        id={
                                                                          index1 +
                                                                          Math.floor(
                                                                            Math.random() *
                                                                              5
                                                                          )
                                                                        }
                                                                        type="text"
                                                                        className={
                                                                          "answereditor"
                                                                        }
                                                                        value={
                                                                          "" // Find the first non-undefined text value
                                                                        }
                                                                        disabled={
                                                                          true
                                                                        }
                                                                        //onKeyUp={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                                        //onChange={(e) => handletext(e, qusAns.answer_id, qusAns.answer_part_id)}
                                                                        // onBlur={(e) => handletext(e, qusAns.english_answer_id, qusAns.english_answer_part_id)}
                                                                      />
                                                                    </li>
                                                                  )
                                                                }
                                                              }
                                                            )}
                                                        </ul>
                                                      ) : quspart.part_answer_type ===
                                                        4 ? (
                                                        //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                                                        <ul className="dg-selector width-controll list-unstyled mb-0">
                                                          {quspart.question_answers &&
                                                            rearrangeEvenOdd(
                                                              quspart.question_answers
                                                            ).map(
                                                              (
                                                                qusAns,
                                                                index1
                                                              ) => {
                                                                const cleanText =
                                                                  qusAns.answer_text
                                                                    .replace(
                                                                      /&nbsp;/g,
                                                                      ""
                                                                    )
                                                                    .replace(
                                                                      /`/g,
                                                                      ""
                                                                    )
                                                                    .replace(
                                                                      /<[^>]*>/g,
                                                                      ""
                                                                    )
                                                                    .trim()

                                                                const hasImage =
                                                                  /<img\s+[^>]*>/gi.test(
                                                                    qusAns.answer_text
                                                                  )

                                                                if (
                                                                  !cleanText &&
                                                                  !hasImage
                                                                ) {
                                                                  return null // Skip rendering if english_answer_text is effectively empty and no image is present
                                                                }

                                                                serialNumber++
                                                                let filteredStuAns =
                                                                  flattenedStuAnswers.filter(
                                                                    stuAns =>
                                                                      parseInt(
                                                                        stuAns.part_id
                                                                      ) ===
                                                                        parseInt(
                                                                          qusAns.answer_part_id
                                                                        ) &&
                                                                      parseInt(
                                                                        stuAns.answer_id
                                                                      ) ===
                                                                        parseInt(
                                                                          qusAns.answer_id
                                                                        )
                                                                  ) || []

                                                                var status_color =
                                                                  "selector-general cursor-pointer"
                                                                var border_color =
                                                                  "border-start-secondary"
                                                                if (
                                                                  filteredStuAns.length >
                                                                  0
                                                                ) {
                                                                  if (
                                                                    parseInt(
                                                                      qusAns.answer_is_correct
                                                                    ) === 1
                                                                  ) {
                                                                    status_color =
                                                                      "selector-success"
                                                                    border_color =
                                                                      "border-start-success"
                                                                  } else {
                                                                    status_color =
                                                                      "selector-wrong "
                                                                    border_color =
                                                                      "border-start-danger"
                                                                  }
                                                                }
                                                                //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                                                return (
                                                                  <li
                                                                    className={
                                                                      status_color +
                                                                      " choose m_choose"
                                                                    }
                                                                    key={index1}
                                                                  >
                                                                    <div className="p-2 px-3 font-size-18">
                                                                      {
                                                                        serialNumber
                                                                      }
                                                                    </div>
                                                                    <div
                                                                      className={
                                                                        "p-2 dg-media " +
                                                                        {
                                                                          border_color,
                                                                        }
                                                                      }
                                                                      dangerouslySetInnerHTML={{
                                                                        __html:
                                                                          qusAns.answer_text
                                                                            ? qusAns.answer_text
                                                                            : null,
                                                                      }}
                                                                    ></div>
                                                                  </li>
                                                                )
                                                              }
                                                            )}
                                                        </ul>
                                                      ) : (
                                                        <p className="text-center">
                                                          No option
                                                        </p>
                                                      )}
                                                      {/* Anse category statement end */}
                                                    </div>
                                                  )
                                                }
                                              )}

                                            <>
                                              <h4 className="font-size-16 fw-bold mb-2 mt-3">
                                                Feedback
                                              </h4>

                                              {InnerActivityData.question_parts &&
                                                InnerActivityData.question_parts.map(
                                                  (quspart, index) => {
                                                    let filteredAns =
                                                      quspart.question_answers.filter(
                                                        CorrAns =>
                                                          parseInt(
                                                            CorrAns.answer_is_correct
                                                          ) === 1
                                                      )
                                                    return filteredAns.map(
                                                      (qusAns, feedindex1) => {
                                                        return (
                                                          <div
                                                            className="m_feedBack"
                                                            style={{
                                                              border:
                                                                "1px solid #1a1a1a",
                                                              borderRadius:
                                                                "4px",
                                                              fontSize: "16px",
                                                            }}
                                                          >
                                                            {/* <div className="p-2 px-3 font-size-18">{feedindex1 + 1}</div> */}
                                                            <div
                                                              dangerouslySetInnerHTML={{
                                                                __html:
                                                                  qusAns.answer_text
                                                                    ? qusAns.answer_text
                                                                    : null,
                                                              }}
                                                            ></div>
                                                          </div>
                                                        )
                                                      }
                                                    )
                                                  }
                                                )}
                                            </>
                                          </li>
                                        </ul>
                                        <div className="action-btns d-flex justify-content-between gap-4 mt-5">
                                          {/* Render a "Prev" button if the active index is greater than 0 */}
                                          {activeindex + 1 > 1 ? (
                                            <Button
                                              className="btn btn-danger cs-btn-danger mt-1"
                                              onClick={() => {
                                                toggleCustom(activeindex)
                                              }}
                                            >
                                              Prev
                                            </Button>
                                          ) : null}

                                          {/* Render a "Next" button if the active index is less than the total length of LessonActivitiesQuestions array */}
                                          {activeindex + 1 <
                                          ActivityData.LessonActivitiesQuestions
                                            .length ? (
                                            <Button
                                              className="btn btn-danger cs-btn-danger mt-1"
                                              onClick={() => {
                                                toggleCustom(
                                                  activeindex + 1 + 1
                                                )
                                              }}
                                            >
                                              Next
                                            </Button>
                                          ) : null}
                                        </div>
                                      </div>
                                    </TabPane>
                                  )
                                } else {
                                  return (
                                    <p className="text-center">No question</p>
                                  )
                                }
                              }
                            }
                          )
                        }
                      }
                    )
                  : null}
              </TabContent>

              {/* <Comprehension triggerFunction={handleFunctionTrigger} triggerSubmitFunction={handleSubmitFunction} data={ActiviteQAData} part={ActivityPart} responceData={submitOtherData} key={activityKey}/> */}
            </div>
          </Container>
        </div>
      </MathJax>
    </React.Fragment>
  )
})

export default PreviewAssessmentDetails
