import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Container } from "reactstrap"

import "react-multi-carousel/lib/styles.css"

import { Link, useNavigate, useLocation } from "react-router-dom"
import classnames from "classnames"
import { SECURITY_KEY, BASE_URL } from "../../global"
// import images

import home from "../../assets/images/services-icon/home.svg"
import us1 from "../../assets/images/services-icon/us1.png"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//i18n
import { withTranslation } from "react-i18next"
//pass request back end
import axios from "axios"
import moment from "moment"
import Loader from "react-spinner-loader"
const ClassListDetails = props => {
  const [loader, setLoader] = useState(false)
  const [selectedSubject, setSelectedSubject] = useState([2])

  const [selectedStudentStatus, setSelectedStuStatus] = useState([2])

  //Session start
  const [SessionData, setSessionData] = useState([])
  //Session end

  // const subjectOptions = [
  //   { subject_name: 'Mathematics', subject_id: 2 },
  //   { subject_name: 'English', subject_id: 1 },
  //   // { subject_name: 'Others', subject_id: 3 }
  // ];

  const [subjectOptions, setSubjectOption] = useState([
    { subject_name: "Mathematics", subject_id: 2 },
    { subject_name: "English", subject_id: 1 },
    // { subject_name: 'Others', subject_id: 3 }
  ])

  const navigate = useNavigate()
  const location = useLocation();
  //subject end



  const authUserSubject = JSON.parse(localStorage.getItem("subject")) || null
  // useEffect(() => {
  //   if (!authUserSubject || authUserSubject.trim() === "") {
  //     setSubjectOption([
  //       { subject_name: "Mathematics", subject_id: 2 },
  //       { subject_name: "English", subject_id: 1 },
  //     ])
  //     if (selectedSubject !== 2) {
  //       setSelectedSubject([2])
  //     }
  //   } else {
  //     let subjectIdsArray
  //     if (authUserSubject.includes(",")) {
  //       subjectIdsArray = authUserSubject.split(",")
  //     } else {
  //       // Handle case when authUserSubject is a single ID without comma
  //       subjectIdsArray = [authUserSubject]
  //     }

  //     if (
  //       subjectIdsArray.length === 2 &&
  //       subjectIdsArray.includes("1") &&
  //       subjectIdsArray.includes("2")
  //     ) {
  //       setSubjectOption([
  //         { subject_name: "Mathematics", subject_id: 2 },
  //         { subject_name: "English", subject_id: 1 },
  //       ])
  //       if (selectedSubject !== 2) {
  //         setSelectedSubject([2])
  //       }
  //     } else if (
  //       subjectIdsArray.length === 1 &&
  //       subjectIdsArray.includes("1")
  //     ) {
  //       setSubjectOption([{ subject_name: "English", subject_id: 1 }])
  //       if (selectedSubject !== 1) {
  //         setSelectedSubject([1])
  //       }
  //     } else if (
  //       subjectIdsArray.length === 1 &&
  //       subjectIdsArray.includes("2")
  //     ) {
  //       setSubjectOption([{ subject_name: "Mathematics", subject_id: 2 }])
  //       if (selectedSubject !== 2) {
  //         setSelectedSubject([2])
  //       }
  //     } else {
  //       setSubjectOption([
  //         { subject_name: "Mathematics", subject_id: 2 },
  //         { subject_name: "English", subject_id: 1 },
  //       ])
  //       if (selectedSubject !== 2) {
  //         setSelectedSubject([2])
  //       }
  //     }
  //   }
  // }, [authUserSubject])
  const [isenrolsubjectreceived, setisenrolsubjectreceived] = useState(false)
  console.log(selectedSubject)
  const fetchStudEnrollsubject = async () => {
    if (authUser && authUser != "" && authUser != null) {
      setLoader(true)
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "studentHomeWork/GetStudentEnrolledSubjects")
        .then(responseSession => {
          if (responseSession.data.length > 0) {
            setSubjectOption(responseSession.data)
            const allSubjectIds = responseSession.data.map(
              subject => subject.subject_id
            )

            const subjectFromState = location?.state?.filterState?.subject_id || allSubjectIds || [2];
            setSelectedSubject(subjectFromState);
            const statusFronstate = location?.state?.filterState?.status_id || [2];
            setSelectedStuStatus(statusFronstate);

            
            
            setisenrolsubjectreceived(true)
          } else {
            setSelectedSubject([2])
            setisenrolsubjectreceived(true)
          }
        })
        .catch(error => {
          setisenrolsubjectreceived(true)

          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      setisenrolsubjectreceived(true)
      navigate("/login")
    }
  }

  const studentAttenStatusOptions = [
    { attenStatus: "Attended", status_id: 2 },
    { attenStatus: "Missed", status_id: 3 },
    { attenStatus: "Ongoing", status_id: 1 },
    { attenStatus: "Upcoming", status_id: 0 },
    { attenStatus: "Cancelled", status_id: 6 },
    { attenStatus: "Flexi Class", status_id: 7 },
  ]

  const handleSelectSubject = subject => {
    setSelectedSubject(subject)
  }
  const handleSelectStuStatus = status => {
    setSelectedStuStatus(status)
  }

  // const handleToggleSelectSubject = subject_id => {
  //   setSelectedSubject(prevSubject => {
  //     if (prevSubject.includes(subject_id)) {
  //       // Remove the status_id from the array if already present
  //       return prevSubject.filter(id => id !== subject_id)
  //     } else {
  //       // Add the status_id to the array if not already present
  //       return [...prevSubject, subject_id]
  //     }
  //   })
  //   setCurrentPage(1)
  // }
  const handleToggleSelectSubject = subject_id => {
    setSelectedSubject(prevSubject => {
      const updatedSubjects = prevSubject.includes(subject_id)
        ? prevSubject.filter(id => id !== subject_id) // Remove if already selected
        : [...prevSubject, subject_id]; // Add if not selected

   
        navigate(location.pathname, {
          state: {
            ...location.state, // Preserve previous state
            filterState: {
              ...location.state?.filterState, // Preserve filterState if exists
              subject_id: updatedSubjects, // Update the subject_id
            },
          },
        });

      // Return the updated selected subjects
      return updatedSubjects;
    });

    setCurrentPage(1); // Reset the page to 1 whenever subjects are toggled
  };
  // const handleToggleStuStatus = status_id => {
  //   setSelectedStuStatus(prevStatuses => {
  //     if (prevStatuses.includes(status_id)) {
  //       // Remove the status_id from the array if already present
  //       return prevStatuses.filter(id => id !== status_id)
  //     } else {
  //       // Add the status_id to the array if not already present
  //       return [...prevStatuses, status_id]
  //     }
  //   })
  //   setCurrentPage(1)
  // }

  const handleToggleStuStatus = (status_id) => {
    setSelectedStuStatus((prevStatuses) => {
      const updatedStatuses = prevStatuses.includes(status_id)
        ? prevStatuses.filter((id) => id !== status_id) // Remove if already selected
        : [...prevStatuses, status_id]; // Add if not selected
  
      // Update location.state using navigate
      navigate(location.pathname, {
        state: {
          ...location.state, // Preserve previous state
          filterState: {
            ...location.state?.filterState, // Preserve filterState if exists
            status_id: updatedStatuses, // Update the selected status_id
          },
        },
      });
  
      setCurrentPage(1); // Reset the page to 1 whenever statuses are toggled
  
      return updatedStatuses; // Return the updated selected statuses
    });
  };
  useEffect(() => {
    fetchStudEnrollsubject()
  }, [])

  const authUser = JSON.parse(localStorage.getItem("authStudentUser")) || null

 
  const move_to_homework = () => {
    toast.info("Activities moved to homework.", {
      autoClose: 3000, // 3 seconds in this example
    })
  }

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 6
  const [totalSessions, setTotalSessions] = useState(1)

  useEffect(() => {
    if (isenrolsubjectreceived) {
      fetchSessionListData()
    }
  }, [currentPage, selectedSubject, selectedStudentStatus])
  const fetchSessionListData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      setLoader(true)
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL + "studentSession/StudentSessionsDeatils", {
          subject_id: selectedSubject,
          status_id: selectedStudentStatus,
          page: currentPage,
          items_per_page: itemsPerPage,
        })
        .then(responseSession => {
          // Assuming the API response is an array of objects with 'id' and 'value' properties
          //console.log(responseMastryScore);

          if (responseSession.data.data.length > 0) {
            // setSessionData(responseSession.data)
            setSessionData(responseSession.data.data)
            setTotalSessions(responseSession.data.totalCount)
          } else {
            setSessionData([])
          }

          setLoader(false)
        })
        .catch(error => {
          // console.log(error.response.data.error)
          setLoader(false)
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      setLoader(false)
      navigate("/login")
    }
  }

  const sortSessions = (data, types) => {
    let sortedData = [...data]

    if (
      types.includes(1) ||
      types.includes(3) ||
      types.includes(7) ||
      types.includes(0)
    ) {
      sortedData = sortedData.sort((a, b) => {
        const dateA = moment(
          a.ses_date + " " + a.ses_start_time,
          "YYYY-MM-DD HH:mm:ss"
        )
        const dateB = moment(
          b.ses_date + " " + b.ses_start_time,
          "YYYY-MM-DD HH:mm:ss"
        )
        return dateA - dateB // Ascending order
      })
    } else if (types.includes(2) || types.includes(4) || types.includes(6)) {
      sortedData = sortedData.sort((a, b) => {
        const dateA = moment(
          a.ses_date + " " + a.ses_start_time,
          "YYYY-MM-DD HH:mm:ss"
        )
        const dateB = moment(
          b.ses_date + " " + b.ses_start_time,
          "YYYY-MM-DD HH:mm:ss"
        )
        return dateB - dateA // Descending order
      })
    }

    return sortedData
  }

  // Sort sessions based on the sortType
  const sortedSessions = sortSessions(SessionData, selectedStudentStatus)

  const totalPages = Math.ceil(totalSessions / itemsPerPage)

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentSessions = SessionData.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = page => {
    setCurrentPage(page)
    // fetchSessionListData();
  }


  const handleNavigate = (Session) => {
    const sessionPath = Session &&
                              Session.attendance_status &&
                              ((parseInt(Session.attendance_status) === 3 &&
                                parseInt(Session.ses_is_flexi) != 1) ||
                                parseInt(Session.attendance_status) === 6)
                                ? "#"
                                : "/lesson-plan/" + Session.pk_ses_key;

    // Pass state programmatically using useNavigate
    if (sessionPath !== "#") {
      navigate(sessionPath, {
        state: { 
          subject_id: selectedSubject || "1",
          status_id: selectedStudentStatus || "4"
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Loader
        show={loader}
        type="body"
        // stack="vertical"
        // message="Loading Data"
      />
      <ToastContainer autoClose={3000} />
      <div className="page-wrapper list-bg">
        <div className="cs-pg-header bg-dark-blue py-3 pb-5">
          <Container className="custom-wt">
            <div className="">
              <div className="mt-0">
                <Link className="text-white font-size-14" to={"/"}>
                  {" "}
                  <span>
                    <i className="fas fa-chevron-left me-1"></i>
                  </span>{" "}
                  BACK TO HOME
                </Link>
              </div>
              <div className="pg-title">View All Classes</div>
            </div>
          </Container>
        </div>
        <Container className="custom-wt">
          <div className="page-inner_section cs-mt">
            <div className="page-header mb-4">
              <div className="pg-info d-flex flex-wrap align-items-center">
                <ul className="pg-tab list-unstyled mb-0 d-flex align-items-center mb-3 flex-wrap">
                  <li>SUBJECT</li>
                  {subjectOptions.map(
                    (
                      subject // Map over each subject in subjectOptions
                    ) => (
                      <li
                        key={subject.subject_id}
                        className={
                          selectedSubject.includes(subject.subject_id)
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handleToggleSelectSubject(subject.subject_id)
                        }
                      >
                        {selectedSubject.includes(subject.subject_id) && (
                          <span>
                            <i className="fas fa-check"></i>
                          </span>
                        )}{" "}
                        {subject.subject_name}
                      </li>
                    )
                  )}
                  {/* {subjectOptions.map((subject) => (// Map over each subject in subjectOptions 
                    <li
                      key={subject} // Set a unique key for each list item
                      className={selectedSubject === subject.subject_id ? "active" : ""} // Set class to "active" if selectedSubject matches the current subject 
                      onClick={() => handleSelectSubject(subject.subject_id)} // Set up click event handler to call handleSelectSubject with the current subject 
                    >
                      {selectedSubject === subject.subject_id && ( // Conditionally render a checkmark if selectedSubject matches the current subject 
                        <span>
                          <i className="fas fa-check"></i>
                        </span>
                      )} {" "} {subject.subject_name}
                    </li>
                  ))} */}
                </ul>
                <ul className="pg-tab list-unstyled mb-0 d-flex align-items-center mb-3 flex-wrap">
                  <li>STATUS</li>
                  {studentAttenStatusOptions.map(
                    (
                      stuAttend // Map over each subject in subjectOptions
                    ) => (
                      <li
                        key={stuAttend.status_id}
                        className={
                          selectedStudentStatus.includes(stuAttend.status_id)
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handleToggleStuStatus(stuAttend.status_id)
                        }
                      >
                        {selectedStudentStatus.includes(
                          stuAttend.status_id
                        ) && (
                          <span>
                            <i className="fas fa-check"></i>
                          </span>
                        )}{" "}
                        {stuAttend.attenStatus}
                      </li>
                    )
                  )}

                  {/* <li className="active">
                    {" "}
                    <span>
                      <i className="fas fa-check"></i>
                    </span>{" "}
                    Attended
                  </li>
                  <li>Missed</li>
                  <li>Ongoing</li>
                  <li>Upcoming</li> */}
                </ul>
              </div>
            </div>
            <div style={{ paddingBottom: "100px" }}>
              <div className="clsses-fview-list">
                {SessionData && SessionData.length > 0 ? (
                  SessionData.map((Session, index) => {
                    // const first_value = MostpracticedTopicsData[0].total_question;

                    return (
                      <div className="ucs-hz-list" key={index}  onClick={() => handleNavigate(Session)}>
                        {/* <Link
                          to={{
                            pathname:
                              Session &&
                              Session.attendance_status &&
                              ((parseInt(Session.attendance_status) === 3 &&
                                parseInt(Session.ses_is_flexi) != 1) ||
                                parseInt(Session.attendance_status) === 6)
                                ? "#"
                                : "/lesson-plan/" + Session.pk_ses_key,
                                state: { 
                              subject_id: selectedSubject || "1",
                              status_id: selectedStudentStatus || "4"
                             }
                          }}
                        > */}
                        
                          {Session && Session.subject_id && Session.subject_name
                            ? Session.subject_id
                                .split(",")
                                .map((subjectId, index) => (
                                  <div
                                    key={subjectId}
                                    className={
                                      parseInt(subjectId) === 1
                                        ? "badge bg-info font-size-14 p-2 mb-3"
                                        : "badge bg-success font-size-14 p-2 mb-3"
                                    }
                                  >
                                    {Session.subject_name.split(",")[index]}
                                  </div>
                                ))
                            : null}

                          <p className="fw-bold font-size-16 mb-2 opacity-75">
                            {Session && Session.ses_date
                              ? moment(
                                  Session.ses_date,
                                  moment.ISO_8601
                                ).format("D MMM, YYYY")
                              : "-"}
                          </p>
                          <h4 className="fw-bold mb-1 font-size-24">
                            {" "}
                            {Session && Session.ses_date
                              ? moment(
                                  Session.ses_date,
                                  moment.ISO_8601
                                ).format("dddd")
                              : "-"}
                          </h4>
                          <h4 className="fw-bold mb-3 font-size-24">
                            {Session && Session.ses_start_time
                              ? moment(
                                  Session.ses_start_time,
                                  "HH:mm:ss"
                                ).format("hh:mm A")
                              : "-"}{" "}
                            -{" "}
                            {Session && Session.ses_end_time
                              ? moment(Session.ses_end_time, "HH:mm:ss").format(
                                  "hh:mm A"
                                )
                              : "-"}
                          </h4>
                          <div className="d-flex mb-3 mt-5">
                            <div className="font-size-16 me-3">
                              <img
                                width={24}
                                className="me-2"
                                src={home}
                                alt=""
                              />
                              {Session && Session.cent_name
                                ? Session.cent_name
                                : null}
                            </div>
                            <div className="font-size-16">
                              <img
                                width={28}
                                height={28}
                                className="rounded-circle obj-fit-cover me-2"
                                src={us1}
                                alt=""
                              />
                              {Session &&
                              Session.tut_fname &&
                              Session.tut_fname != ""
                                ? Session.tut_fname + " " + Session.tut_surname
                                : null}
                            </div>
                          </div>

                          {Session &&
                          Session.attendance_status &&
                          Session.ses_is_flexi &&
                          parseInt(Session.attendance_status) != 6 &&
                          parseInt(Session.ses_is_flexi) == 1 ? (
                            <div className="ucs-status ucs-missed w-100 position-relative">
                              Flexi Class
                            </div>
                          ) : Session &&
                            Session.attendance_status &&
                            parseInt(Session.attendance_status) === 0 ? (
                            <div className="ucs-status w-100">
                              Not started yet
                            </div>
                          ) : Session &&
                            Session.attendance_status &&
                            parseInt(Session.attendance_status) === 1 ? (
                            <div className="ucs-status ucs-ongoing w-100 position-relative">
                              Ongoing
                            </div>
                          ) : Session &&
                            Session.attendance_status &&
                            parseInt(Session.attendance_status) === 2 ? (
                            <div className="ucs-status ucs-attended w-100 position-relative">
                              Attended{" "}
                              <span
                                className="position-absolute"
                                style={{ right: "20px ", top: "12px" }}
                              >
                                <i className="fas fa-check-circle"></i>
                              </span>{" "}
                            </div>
                          ) : Session &&
                            Session.attendance_status &&
                            parseInt(Session.attendance_status) === 3 ? (
                            <div className="ucs-status ucs-missed w-100 position-relative">
                              Class Missed{" "}
                            </div>
                          ) : Session &&
                            Session.attendance_status &&
                            parseInt(Session.attendance_status) === 6 ? (
                            <div className="ucs-status ucs-missed w-100 position-relative">
                              Cancelled{" "}
                            </div>
                          ) : Session &&
                            Session.ses_status &&
                            parseInt(Session.ses_status) === 1 ? (
                            <div className="ucs-status w-100">
                              Lesson Started
                            </div>
                          ) : (
                            <div className="ucs-status w-100">
                              Not started yet
                            </div>
                          )}

                          {Session &&
                          Session.attendance_status &&
                          parseInt(Session.attendance_status) ===
                            0 ? null : Session &&
                            Session.attendance_status &&
                            parseInt(Session.attendance_status) === 1 ? (
                            <p className="text-danger text-center mt-3 mb-0">
                              RESUME
                            </p>
                          ) : Session &&
                            Session.attendance_status &&
                            parseInt(Session.attendance_status) === 2 ? (
                            <p className="text-success text-center mt-3 mb-0">
                              FOR LESSON DETAILS CLICK HERE
                            </p>
                          ) : Session &&
                            Session.attendance_status &&
                            parseInt(Session.attendance_status) ===
                              3 ? null : null}
                        {/* </Link> */}
                        
                      </div>
                    )
                  })
                ) : (
                  <div>No classes </div>
                )}
                {/* <Link to={"/lesson-plan/"} className="ucs-hz-list">
                <div className="badge bg-info font-size-14 p-2 mb-3">
                  English
                </div>
                <p className="fw-bold font-size-16 mb-2 opacity-75">
                  1 Feb, 2024
                </p>
                <h4 className="fw-bold mb-1 font-size-24">Thursday</h4>
                <h4 className="fw-bold mb-3 font-size-24">
                  12:00 pm - 1:00 pm
                </h4>
                <div className="d-flex mb-3 mt-5">
                  <div className="font-size-16 me-3">
                    <img width={24} className="me-2" src={home} alt="" />
                    Louis parker
                  </div>
                  <div className="font-size-16">
                    <img
                      width={28}
                      height={28}
                      className="rounded-circle me-2"
                      src={us1}
                      alt=""
                    />
                    David Wills
                  </div>
                </div>

                <div className="ucs-status ucs-attended w-100 position-relative">
                  Attended{" "}
                  <span className="position-absolute" style={{ right: "20px ", top:"12px" }}>
                    <i className="fas fa-check-circle"></i>
                  </span>{" "}
                </div>
                <p className="text-success text-center mt-3 mb-0">FOR LESSON DETAILS CLICK HERE</p>
              </Link>
              <div className="ucs-hz-list">
                <div className="badge bg-success font-size-14 p-2 mb-3">
                  Mathematics
                </div>
                <p className="fw-bold font-size-16 mb-2 opacity-75">
                  1 Feb, 2024
                </p>
                <h4 className="fw-bold mb-1 font-size-24">Thursday</h4>
                <h4 className="fw-bold mb-3 font-size-24">
                  12:00 pm - 1:00 pm
                </h4>
                <div className="d-flex mb-3 mt-5">
                  <div className="font-size-16 me-3">
                    <img width={24} className="me-2" src={home} alt="" />
                    Louis parker
                  </div>
                  <div className="font-size-16">
                    <img
                      width={28}
                      height={28}
                      className="rounded-circle me-2"
                      src={us1}
                      alt=""
                    />
                    David Wills
                  </div>
                </div>

                <div className="ucs-status ucs-attended w-100 position-relative">
                  Attended{" "}
                  <span className="position-absolute" style={{ right: "20px ", top:"12px" }}>
                    <i className="fas fa-check-circle"></i>
                  </span>{" "}
                </div>
                <p className="text-success text-center mt-3 mb-0">FOR LESSON DETAILS CLICK HERE</p>
              </div>
              <div className="ucs-hz-list">
                <div className="badge bg-info font-size-14 p-2 mb-3">
                  English
                </div>
                <p className="fw-bold font-size-16 mb-2 opacity-75">
                  1 Feb, 2024
                </p>
                <h4 className="fw-bold mb-1 font-size-24">Thursday</h4>
                <h4 className="fw-bold mb-3 font-size-24">
                  12:00 pm - 1:00 pm
                </h4>
                <div className="d-flex mb-3 mt-5">
                  <div className="font-size-16 me-3">
                    <img width={24} className="me-2" src={home} alt="" />
                    Louis parker
                  </div>
                  <div className="font-size-16">
                    <img
                      width={28}
                      height={28}
                      className="rounded-circle me-2"
                      src={us1}
                      alt=""
                    />
                    David Wills
                  </div>
                </div>

                <div className="ucs-status w-100">Not started yet</div>
              </div>
              <div className="ucs-hz-list">
                <div className="badge bg-success font-size-14 p-2 mb-3">
                  Mathematics
                </div>
                <p className="fw-bold font-size-16 mb-2 opacity-75">
                  1 Feb, 2024
                </p>
                <h4 className="fw-bold mb-1 font-size-24">Thursday</h4>
                <h4 className="fw-bold mb-3 font-size-24">
                  12:00 pm - 1:00 pm
                </h4>
                <div className="d-flex mb-3 mt-5">
                  <div className="font-size-16 me-3">
                    <img width={24} className="me-2" src={home} alt="" />
                    Louis parker
                  </div>
                  <div className="font-size-16">
                    <img
                      width={28}
                      height={28}
                      className="rounded-circle me-2"
                      src={us1}
                      alt=""
                    />
                    David Wills
                  </div>
                </div>

                <div className="ucs-status ucs-ongoing w-100 position-relative">
                  Ongoing{" "}
                  {" "}
                </div>
                <p className="text-danger text-center mt-3 mb-0">RESUME</p>
              </div>
              <div className="ucs-hz-list">
                <div className="badge bg-success font-size-14 p-2 mb-3">
                  Mathematics
                </div>
                <p className="fw-bold font-size-16 mb-2 opacity-75">
                  1 Feb, 2024
                </p>
                <h4 className="fw-bold mb-1 font-size-24">Thursday</h4>
                <h4 className="fw-bold mb-3 font-size-24">
                  12:00 pm - 1:00 pm
                </h4>
                <div className="d-flex mb-3 mt-5">
                  <div className="font-size-16 me-3">
                    <img width={24} className="me-2" src={home} alt="" />
                    Louis parker
                  </div>
                  <div className="font-size-16">
                    <img
                      width={28}
                      height={28}
                      className="rounded-circle me-2"
                      src={us1}
                      alt=""
                    />
                    David Wills
                  </div>
                </div>

                <div className="ucs-status ucs-missed w-100 position-relative">
                  Class Missed{" "}
                  {" "}
                </div>
                <p className="text-danger text-center mt-3 mb-0">LESSON PLAN MOVED TO HOMEWORK</p>
              </div> */}
              </div>

              <div className="d-flex justify-content-center mt-4">
                <button
                  className="btn btn-primary-secondary me-2"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <i className="fas fa-chevron-left"></i> Previous
                </button>

                <span className="pagination-info  mt-2">
                  Page {currentPage} of {totalPages}
                </span>

                <button
                  className="btn btn-primary-secondary ms-2"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

ClassListDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ClassListDetails)
