import React, { useState, useEffect, useCallback, useRef } from "react"
import {
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap"
import duration from "../../assets/images/services-icon/duration.png"
import "react-multi-carousel/lib/styles.css"

import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"

import awicon from "../../assets/images/services-icon/coin.png"
import Select from "react-select"

import dsmile from "../../assets/images/services-icon/dog_smile.png"

//i18n
import axios from "axios"
import { SECURITY_KEY, BASE_URL } from "../../global"
// import { BASE_URL } from "../../global";

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import moment from "moment"

import Loader from "react-spinner-loader"
import Comprehension from "./diagnostics/Comprehension"
import Pagination from "./diagnostics/Pagination"

// // Timer component that manages timer state and logic
// const TimerComponent = () => {
//   const [timer, setTimer] = useState(0);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setTimer(prevTimer => prevTimer + 1);
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   return timer; // Return timer value instead of JSX
// };

// // Receiving component that displays the timer value
// const TimerDisplay = React.memo(({ timerValue }) => {
//   return <div>Timer Value: {timerValue}</div>;
// });
import { MathJax, MathJaxContext } from "better-react-mathjax"
const Diagnostics = React.memo(() => {
  const { key } = useParams()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const type = searchParams.get("type") || null
  const homework = searchParams.get("homework") || null

  let pk_activity_key = null
  let IsType = null
  let IsHomework = null
  if (
    key &&
    key.length >= 16 &&
    type &&
    type != null &&
    homework &&
    homework != null
  ) {
    pk_activity_key = key
    IsType = type
    IsHomework = homework
  } else {
    window.history.back()
  }

  //Timmere start

  const [seconds, setSeconds] = useState(0)
  const [isRunning, setIsRunning] = useState(false)

  useEffect(() => {
    let timer
    if (isRunning) {
      timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1)
      }, 1000)
    }

    return () => clearInterval(timer)
  }, [isRunning])

  const formatTime = time => {
    const minutes = Math.floor(time / 60)
    const remainingSeconds = time % 60
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`
  }

  const handleToggleTimer = () => {
    setIsRunning(prevState => !prevState)
  }

  const handleResetTimer = () => {
    setSeconds(0)
    setIsRunning(true)
  }

  //Timer end
  //common

  const [loader, setLoader] = useState(false)
  const [ActivityData, setActivityData] = useState([])
  const [TopicData, setTopicData] = useState(null)
  const [activityKey, setKey] = useState(0)

  const [ActivityPart, setActivityPart] = useState(null)
  const [ActiviteQAData, setActivityQAData] = useState([])
  const [otherData, setOtherData] = useState([])
  const [AnswerData, setAnswerData] = useState([])

  const [submitOtherData, setSubmitOtherData] = useState(null)

  // console.log(otherData);
  const authUser = JSON.parse(localStorage.getItem("authStudentUser")) || null

  const navigate = useNavigate()
  useEffect(() => {
    fetchData()
  }, [location])

  const fetchAttendanceData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (pk_activity_key && pk_activity_key != null && pk_activity_key != "") {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "studentSession/StudentAttendance", {
            pk_activity_key: pk_activity_key,
          })
          .then(responseAttendance => {})
          .catch(error => {
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        window.history.back()
      }
    } else {
      navigate("/login")
    }
  }

  const fetchData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (pk_activity_key && pk_activity_key != null && pk_activity_key != "") {
        setLoader(true)
        setActivityData([])
        setActivityPart(null)
        setActivityQAData([])
        setOtherData([])
        setAnswerData([])
        setSubmitOtherData(null)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "studentSession/StudentAssessmentQuestions", {
            pk_activity_key: pk_activity_key,
            subject_id: type,
          })
          .then(responseActivity => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            setLoader(false)

            if (responseActivity.data.LessonActivitiesQuestions.length > 0) {
              // Assuming LessonActivitiesQuestions is an array of question objects
              responseActivity.data.LessonActivitiesQuestions.forEach(
                question => {
                  if (
                    question.fk_sub_id &&
                    parseInt(question.fk_sub_id) === 1
                  ) {
                    if (
                      question.english_question &&
                      question.english_question.length === 0
                    ) {
                      toast.error("Question data not found", {
                        autoClose: 3000, // 3 seconds
                        onClose: () => {
                          history.back() // Navigate back in history after toast is closed
                        },
                      })
                    }
                  } else if (
                    question.fk_sub_id &&
                    parseInt(question.fk_sub_id) === 2
                  ) {
                    if (question.question && question.question.length === 0) {
                      toast.error("Question data not found", {
                        autoClose: 3000, // 3 seconds
                        onClose: () => {
                          history.back() // Navigate back in history after toast is closed
                        },
                      })
                    }
                  }
                }
              )

              setActivityData(responseActivity.data)
              const topicNames = responseActivity.data.topics
                .flat()
                .map(obj => obj.topic_name)
              const mergedTopics = topicNames.join(", ")
              //console.log(mergedTopics);
              setTopicData(mergedTopics)
              handleResetTimer()
              if (parseInt(IsHomework) === 1) {
              } else {
                fetchAttendanceData()
              }
            } else {
              toast.error("Question data not found", {
                autoClose: 3000, // 3 seconds
                onClose: () => {
                  history.back() // Navigate back in history after toast is closed
                },
              })
              setActivityData([])
              setTopicData(null)
            }
          })
          .catch(error => {
            setLoader(false)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setLoader(false)
        window.history.back()
      }
    } else {
      // setLoader(false);
      navigate("/login")
    }
  }

  const CountTotalCoins = question => {
    let total_coins = 0

    if (question && question.length > 0) {
      question.forEach(activity => {
        if (activity.fk_sub_id === "1") {
          total_coins += activity.english_question.length * 2
        } else if (activity.fk_sub_id === "2" || activity.fk_sub_id === "3") {
          total_coins += activity.question.length * 2
        }
      })
    }
    // setTotalCoins(total_coins);
    return total_coins
  }

  // useEffect(() => {

  // if(ActivityData && ActivityData.activity_node && ActivityData.activity_node.length > 0){
  //   setTopicData(ActivityData.activity_node[0]);
  // }else{
  //   setTopicData(null)
  // }

  // }, [ActivityData, key])

  const onSubmitedAnswer = Answer => {
    // setIsSubmited(true);
    // handleToggleTimer();
    const AnswerArray = Answer[0]

    // console.log(otherData.data);
    // console.log(seconds);
    if (authUser && authUser != "" && authUser != null) {
      if (
        pk_activity_key &&
        pk_activity_key != null &&
        pk_activity_key != "" &&
        AnswerArray.Answer.length > 0
      ) {
        // console.log(AnswerArray.Answer);
        let IsFirstQuestion = otherData.data.IsFirstQuestion || 0
        let IsLastQuestion = otherData.data.IsLastQuestion || 0
        let ActiveData = otherData.data.activeData || null
        let ResonTestAnswer = AnswerArray.ResonTestAnswer || null
        let Confident = AnswerArray.Confident || null
        let is_first_question
        if (IsFirstQuestion === 1) {
          is_first_question = true
        } else {
          is_first_question = false
        }
        let is_last_question
        if (IsFirstQuestion === IsLastQuestion) {
          is_last_question = true
        } else {
          is_last_question = false
        }

        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "studentAnswer/submitQuestionDignostics", {
            topic_id: ActiveData.topic_id,
            answer_text: AnswerArray.Answer,
            is_first_question: is_first_question,
            is_last_question: is_last_question,
            lesson_activity_key: ActiveData.fk_lesson_activity_key,
            lesson_key: ActiveData.fk_lesson_key,
            lesson_question_key: ActiveData.pk_lesson_activity_question_key,
            part_id: AnswerArray.Answer[0].part_id,
            question_id: ActiveData.fk_question_id,
            question_length: IsLastQuestion,
            question_no: IsFirstQuestion,
            sest_key: ActivityData.fk_sest_key,
            subject_id: ActiveData.fk_sub_id,
            ResonTestAnswer: ResonTestAnswer,
            Confident: Confident,
            submitted_on: seconds,
          })
          .then(async responseresult => {
            // setIsSubmited(false);
            setAnswerData(null)
            if (responseresult.data.length > 0) {
              setSubmitOtherData(responseresult.data[0])
            }
          })
          .catch(error => {
            //setIsSubmited(false);
            setAnswerData(null)
            window.history.back()
            console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        //setIsSubmited(false);
        window.history.back()
      }
    } else {
      //setIsSubmited(false);
      navigate("/login")
    }
  }

  const handleFunctionTrigger = useCallback(() => {
    fetchData()
  }, [isRunning])

  const handleSubmitFunction = useCallback(
    Answer_data => {
      if (Answer_data && Answer_data != null && Answer_data.length > 0) {
        setOtherData(prevData => ({ ...prevData, Answer_data }))
        setAnswerData(Answer_data)
      }
    },
    [otherData]
  )

  useEffect(() => {
    if (AnswerData && AnswerData != null && AnswerData.length > 0) {
      onSubmitedAnswer(AnswerData)
    }
  }, [AnswerData])

  const handleSetActiveData = useCallback(
    data => {
      // console.log(data);
      setActivityQAData({
        ...data.activeData, // Spread the properties of data.activeData
        is_last_question: data.IsLastQuestion, // Add the new property is_last_question
        is_first_question: data.IsFirstQuestion,
        is_total_tab: data.IsTotalTab,
        is_current_tab: data.IsCurrentTab,
        is_current_tab_total_part: data.IsCurrentTabTotalPart,
        is_current_tab_current_part: data.IsCurrentTabCurrentPart,
        IsHomework: IsHomework,
      })
      setActivityPart(data.ActivityPartData)
      setOtherData(prevData => ({ ...prevData, data }))
      // setOtherData(...data);
    },
    [isRunning]
  )

  // console.log(ActiviteQAData);
  // console.log(ActivityPart);
  //const timerValue = TimerComponent(); // Get timer value from TimerComponent

  const handleHomeworkClick = () => {
    const filterState = location?.state || ""
    navigate("/homework-list", {
      state: {
        filterState,
      },
    })
  }
  return (
    <React.Fragment>
      <Loader
        show={loader}
        type="body"
        // stack="vertical"
        // message="Loading Data"
      />
      <ToastContainer autoClose={3000} />

      <div className="page-wrapper">
        <div className="cs-pg-header bg-dark-blue py-4">
          <Container className="custom-wt">
            <div className="d-flex align-items-center">
              <div className="mt-0 border-right me-4" style={{ width: "20%" }}>
                {parseInt(IsHomework) === 1 ? (
                  // <Link className="text-white font-size-14 text-uppercase"  to={"/homework-list"}> {" "}
                  // <span>
                  //   <i className=" fas fa-chevron-left me-1"></i>
                  // </span>{" "}
                  // BACK TO HOMEWORK
                  // </Link>
                  <div
                    className="text-white font-size-14 text-uppercase cursor-pointer"
                    onClick={handleHomeworkClick}
                  >
                    {" "}
                    <span>
                      <i className=" fas fa-chevron-left me-1"></i>
                    </span>{" "}
                    BACK TO HOMEWORK
                  </div>
                ) : (
                  <div
                    className="text-white font-size-14 text-uppercase"
                    to={"#"}
                    onClick={() => history.back()}
                  >
                    {" "}
                    <span>
                      <i className=" fas fa-chevron-left me-1"></i>
                    </span>{" "}
                    Back
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-between flex-grow-1">
                <h6 className="mb-0 font-size-20 text-white w-60 fw-regular">
                  {TopicData ? TopicData : null}
                </h6>
                <p className="mb-0 text-uppercase luminous-wht">
                  {" "}
                  <span className="text-yellow me-1">
                    {ActivityData && ActivityData.subject_name
                      ? ActivityData.subject_name
                      : null}
                  </span>{" "}
                  Diagnostics{" "}
                </p>
                <div className="luminous-wht font-size-16">
                  <img width={24} className="" src={awicon} alt="" />{" "}
                  {ActivityData && ActivityData.coins_earned
                    ? ActivityData.coins_earned
                    : 0}
                  /
                  {ActivityData &&
                  ActivityData.LessonActivitiesQuestions &&
                  ActivityData.LessonActivitiesQuestions.length > 0
                    ? CountTotalCoins(ActivityData.LessonActivitiesQuestions)
                    : 0}{" "}
                  coins earned
                </div>
              </div>
            </div>
          </Container>
        </div>
        <Container className="custom-wt">
          <div className="assessement-section summary-width-hold mt-4">
            <Nav tabs className="nav-tabs-custom mb-3">
              <Pagination
                ActivityData1={ActivityData.LessonActivitiesQuestions}
                keyData={activityKey}
                triggerSetActiveData={handleSetActiveData}
              />
            </Nav>

            {/* {isRunning ? <div className="custom-timer">
          <h1 className="text-end">{formatTime(seconds)}</h1>
        </div> : null } */}
            {isRunning &&
            ActivityPart != null &&
            ActiviteQAData != null &&
            parseInt(ActivityData.tut_clock_is_active) != 1 ? (
              <div className="custom-timer d-flex align-items-center">
                <img width={32} className="me-3" src={duration} alt="" />
                <h1 className="text-end mb-0">{formatTime(seconds)}</h1>
              </div>
            ) : null}
            <p className="font-size-16 fw-semibold mb-4 mt-2">
              {ActivityData?.tutor_comments && (
                <>
                  <b>Tutor comments:</b> {ActivityData.tutor_comments}
                </>
              )}
            </p>
            <MathJax inline dynamic>
              <Comprehension
                triggerFunction={handleFunctionTrigger}
                triggerSubmitFunction={handleSubmitFunction}
                data={ActiviteQAData}
                part={ActivityPart}
                responceData={submitOtherData}
                key={activityKey}
              />
            </MathJax>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
})

export default Diagnostics
